/**
 * @param {Array} array - array of objects
 * @param {String} property - string representing the key name inside the object in the array
 * @returns {Array} - duplicate free array of the property passed
 */
export default function uniqBy({ array, property }) {
  if (!Array.isArray(array)) {
    /* eslint-disable */
    return console.error("The 'array' parameter is not an array.")
    /* eslint-enable */
  }

  if (typeof property !== "string") {
    /* eslint-disable */
    return console.error("The 'property' parameter is not a string.")
    /* eslint-enable */
  }

  if (!array.some((element) => property in element) || !array.length) {
    return array
  }

  const tempSet = new Set()

  const filteredArr = array.filter((element) => {
    const isDuplicate = tempSet.has(element[property]);
    tempSet.add(element[property]);
    return !isDuplicate;
  });

  return filteredArr
}
