let clickHandler;

const clickOutsideHandlers = new Map();

function handleGlobalClick(event) {
  for (const [el, handler] of clickOutsideHandlers) {
    if (el !== event.target && !el.contains(event.target)) {
      handler(event);
    }
  }
}

export default {
  bind(el, binding) {
    if (!(binding.value && typeof binding.value === "object"
      && Object.keys(binding.value).includes("isActive")
      && Object.keys(binding.value).includes("callback"))) {
      /* eslint-disable */
      console.error("please pass an object with { isActive: () => Boolean, callback: function reference}")
      /* eslint-disable */
      return
    }
    const {
      isActive,
      callback,
      stopPropagation = true,
    } = binding.value;

    clickHandler = (event) => {
      if (stopPropagation) {
        event.stopPropagation();
      }

      if (isActive()) {
        callback(event);
      }
    }

    clickOutsideHandlers.set(el, clickHandler);

    if (clickOutsideHandlers.size === 1) {
      document.body.addEventListener("click", handleGlobalClick);
    }
  },

  unbind(el) {
    clickOutsideHandlers.delete(el);
    if (clickOutsideHandlers.size === 0) {
      document.body.removeEventListener("click", handleGlobalClick);
    }
  },
}
