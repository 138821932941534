import API from "@/utils/input-output/api/index"
import constants from "./constants"

export default {
  namespaced: true,
  state: {
    unseenNotifications: 0,
    unreadNotifications: 0,
  },

  mutations: {
    SET_UNSEEN_NOTIFICATIONS(state, data) {
      state.unseenNotifications = data
    },

    SET_UNREAD_NOTIFICATIONS(state, data) {
      state.unreadNotifications = data
    },
  },

  actions: {
    decrementUnreadNotifications({ commit, state }) {
      commit("SET_UNREAD_NOTIFICATIONS", state.unreadNotifications - 1)
    },

    markAllAsRead({ commit, rootGetters }, { lastNotificationID }) {
      if (rootGetters["Authorization/isLoginAsActivated"]) {
        return false
      }

      const data = {
        uuid: lastNotificationID,
      }

      const persona = rootGetters["User/userPersona"]

      return new Promise((resolve, reject) => {
        API({
          url: constants.markAllAsRead({ persona }),
          method: "POST",
          data,
          requestId: "markAllAsRead",
        }).then(() => {
          commit("SET_UNREAD_NOTIFICATIONS", 0)
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },

    setUnseenNotificationCount({ commit, rootGetters }) {
      if (rootGetters["Authorization/isLoginAsActivated"]) return

      const persona = rootGetters["User/userPersona"]

      API({
        url: constants.unseenCount({ persona }),
        method: "GET",
        requestId: "setUnseenNotificationCount",
        initiator: "global",
        cancelWhenLeaveInitiator: true,
      }).then(({ data }) => {
        commit("SET_UNSEEN_NOTIFICATIONS", data.payload.data)
      })
    },

    setUnreadNotificationCount({ commit, rootGetters }) {
      if (rootGetters["Authorization/isLoginAsActivated"]) return

      const persona = rootGetters["User/userPersona"]

      API({
        url: constants.unreadCount({ persona }),
        method: "GET",
        requestId: "setUnreadNotificationCount",
      }).then(({ data }) => {
        commit("SET_UNREAD_NOTIFICATIONS", data.payload.data)
      })
    },

    setNotificationsToBeSeen({ commit, rootGetters }, id) {
      if (rootGetters["Authorization/isLoginAsActivated"]) return

      const persona = rootGetters["User/userPersona"]

      API({
        url: constants.setNotificationsToBeSeen({ id, persona }),
        method: "put",
        requestId: "setNotificationsToBeSeen",
      }).then(() => {
        commit("SET_UNSEEN_NOTIFICATIONS", 0)
      })
    },
  },
}
