import API from "@/utils/input-output/api/index";
import constants from "./constants";

export default {
  namespaced: true,
  state: {
    campaignData: {},
    selectedCoupons: [],
    trackingLinks: {
      links: [],
      pagination: {},
    },
    offerUrlDetails: {},
    deactivationData: {},
    CGTeam: [],
    campaignManagers: [],
    salesManager: {},
  },

  mutations: {
    SET_CAMPAIGN_DATA: (state, value) => {
      state.campaignData = value;
    },
    SET_CG_TEAM: (state, value) => {
      state.CGTeam = value
    },
    SET_CAMPAIGN_MANAGERS: (state, value) => {
      state.campaignManagers = value
    },
    SET_SALES_MANAGER: (state, value) => {
      state.salesManager = value
    },

    SET_SELECTED_COUPONS: (state, value) => {
      state.selectedCoupons = value;
    },

    SET_COUPON_REQUESTS_COUNT: (state, value) => {
      state.campaignData.couponRequestsCount = value;
    },

    SET_TRACKING_LINKS: (state, value) => {
      state.trackingLinks = value;
    },

    SET_OFFER_URL_DETAILS: (state, value) => {
      state.offerUrlDetails = value;
    },

    SET_PARTNER_DEACTIVATION_DATA: (state, value) => {
      state.deactivationData = value
    },
  },

  getters: {
    isCurrentUserIsTheActiveCampaignAssignedManager(state, getters, rootState) {
      const userId = rootState.User.user.id
      return state.campaignManagers.map((el) => el.id)
        .includes(userId);
    },
    isCurrentUserIsCGTeamMember(state, getters, rootState) {
      const userId = rootState.User.user.id
      return state.CGTeam.map((el) => el.id).includes(userId);
    },
    isAgencyCampaign(state) {
      return state.campaignData.agency !== null && state.campaignData.agency !== undefined
    },
  },

  actions: {
    fetchCampaign({
      state,
      commit,
      dispatch,
      rootGetters,
    }, { id }) {
      if (id) {
        commit("SET_CAMPAIGN_DATA", {})
        const isAgency = rootGetters["Authorization/isAgency"]
        API({
          url: constants.campaignDetails({
            campaignId: id,
            isAgency,
          }),
          method: "get",
          requestId: "campaignDetails",
        })
          .then(({ data }) => {
            let offerUrls = []
            if (data.payload.data.offerUrls) {
              offerUrls = data.payload.data.offerUrls.map((el) => ({
                ...el,
                default_deep_link: el.default_deep_link ? decodeURIComponent(el.default_deep_link) : null,
              }))
            }

            commit("SET_CAMPAIGN_DATA", {
              ...data.payload.data,
              offerUrls,
            });
            if (state.campaignData.isAttributionDeactivated && state.campaignData.application_status === "APPROVED") {
              dispatch("getDeactivationData")
            } else {
              commit("SET_PARTNER_DEACTIVATION_DATA", {})
            }
          });
      }
    },
    fetchCGTeam({ commit, rootGetters }, { id }) {
      const isAgency = rootGetters["Authorization/isAgency"]
      const isPartner = rootGetters["Authorization/isPartner"]
      if (isAgency || isPartner) return

      API({
        url: constants.CGTeam({
          campaignId: id,
        }),
        requestId: "fetchCGTeamData",
        method: "get",
      }).then(({ data }) => {
        commit("SET_CG_TEAM", data.payload.data)
      });
    },
    fetchCampaignManagers({ commit, rootGetters }, { id }) {
      const isAgency = rootGetters["Authorization/isAgency"]

      API({
        url: constants.campaignManagers({
          campaignId: id,
          isAgency,
        }),
        requestId: "fetchCampaignManagers",
        method: "get",
      }).then(({ data }) => {
        commit("SET_CAMPAIGN_MANAGERS", data.payload.data)
      });
    },
    fetchSalesManager({ commit, rootGetters }, { id }) {
      const isAgency = rootGetters["Authorization/isAgency"]
      const isPartner = rootGetters["Authorization/isPartner"]
      if (isAgency || isPartner) return

      API({
        url: constants.salesManager({
          campaignId: parseInt(id, 10),
          isAgency,
        }),
        requestId: "fetchSalesManager",
        method: "get",
      }).then(({ data }) => {
        commit("SET_SALES_MANAGER", data.payload.data)
      });
    },
    getDeactivationData({
      state,
      commit,
    }) {
      API({
        url: constants.deactivationData({ campaignID: state.campaignData.id }),
        method: "get",
        requestId: "deactivationData",
      })
        .then(({ data }) => {
          commit("SET_PARTNER_DEACTIVATION_DATA", data.payload.data);
        });
    },

    updateSelectedCoupons({ commit }, data) {
      commit("SET_SELECTED_COUPONS", data);
    },

    selectSingleCoupon({ commit }, data) {
      commit("SET_SELECTED_COUPONS", data);
    },

    resetSelectedCoupons({ commit }) {
      commit("SET_SELECTED_COUPONS", []);
    },

    removeSelectedCoupon({
      commit,
      state,
    }, { id }) {
      commit(
        "SET_SELECTED_COUPONS",
        state.selectedCoupons.filter((coupon) => coupon.id !== id),
      );
    },

    setCouponRequestsCount({ commit }, data) {
      commit("SET_COUPON_REQUESTS_COUNT", data);
    },

    fetchTrackingLinks({
      state,
      commit,
      rootGetters,
    }, {
      campaignId,
      offerUrlId,
      page = 1,
      limit = 10,
    }) {
      const isAgency = rootGetters["Authorization/isAgency"]
      API({
        url: constants.trackingLinks({
          campaignId,
          offerUrlId,
          isAgency,
        }),
        method: "get",
        params: {
          page,
          limit,
        },
        requestId: "fetchTrackingLinks",
      })
        .then(({ data }) => {
          let links = []

          if (page === 1) {
            commit("SET_TRACKING_LINKS", {
              links: [],
              pagination: {},
            })
            links = data.payload.data
          } else {
            links = [
              ...state.trackingLinks.links,
              ...data.payload.data,
            ]
          }

          links = links.map((el, index) => ({
            num: index + 1,
            ...el,
          }))

          commit("SET_TRACKING_LINKS", {
            links,
            pagination: data.payload.pagination,
          })
        })
    },

    fetchOfferUrlDetails({
      commit,
      rootGetters,
    }, {
      campaignId,
      offerUrlId,
    }) {
      const isAgency = rootGetters["Authorization/isAgency"]
      API({
        url: constants.offerUrlDetails({
          campaignId,
          offerUrlId,
          isAgency,
        }),
        method: "get",
        requestId: "fetchOfferUrlDetails",
      })
        .then(({ data: { payload: { data } } }) => {
          commit("SET_OFFER_URL_DETAILS", data)
        })
    },
  },
};
