<script>
  import { Portal } from "@linusborg/vue-simple-portal";
  import trackResponse from "@/utils/miscellaneous/track-response/index";
  import BaseImage from "@/components/partials/BaseImage.vue";
  import API from "@/utils/input-output/api/index";
  import ActionsContainer from "../../actions/Index.vue";
  import ActionBtn from "../../actions/ActionBtn.vue";
  import RejectCouponModal from "../modals/reject-coupons/Index.vue";
  import mixin from "../mixin";
  import constants from "../../../constants";

  export default {
    components: {
      ActionsContainer,
      ActionBtn,
      RejectCouponModal,
      BaseImage,
      Portal,
    },

    mixins: [mixin],

    data() {
      return {
        status: "",
      };
    },

    computed: {
      partner() {
        return this.notification.content.partner;
      },
      campaign() {
        return this.notification.content.campaign;
      },
      couponRequest() {
        return this.notification.content.couponRequest;
      },
      isCurrentRouteNotifications() {
        return (
          this.$route.name === "coupon-requests"
          || this.$route.name === "all-notifications"
        );
      },
      userPersona() {
        return this.$store.getters["User/userPersona"];
      },
    },

    mounted() {
      this.status = this.notification.status;
    },

    methods: {
      actionHandler(action) {
        this[action.toLowerCase()]();
      },

      allocate() {
        this.setNotificationAsRead();
        this.actionTaken(this.notification.id).then(() => {
          this.status = "action-taken";
          this.$router.push({
            name: "coupons-request-allocation",
            params: {
              id: this.campaign.id,
              requestInfo: this.notification,
            },
          });
        });
      },

      reject() {
        this.showDialog = true;
        this.$emit("on-popup-state-update", "opened");
      },

      hideDialog() {
        this.showDialog = false;
        this.$emit("on-popup-state-update", "closed");
      },

      rejectRequest() {
        this.setNotificationAsRead();
        this.isSubmitting = true;
        API({
          url: constants.rejectRequest({
            campaignID: this.campaign.id,
            couponRequestID: this.couponRequest.id,
            persona: this.userPersona,
          }),
          method: "PUT",
          requestId: "rejectCouponRequest",
        })
          .then(() => {
            this.isSubmitting = false;
            this.hideDialog();

            trackResponse({
              type: "success",
              msg: this.$t("messages.rejectCouponsRequest"),
            });

            this.actionTaken(this.notification.id).then(() => {
              this.status = "action-taken";
            });
          })
          .catch(() => {
            this.isSubmitting = false;
            this.hideDialog();
          });
      },
    },
  };
</script>
<template>
  <div
    class="w-100"
    :class="[
      isCurrentRouteNotifications &&
        'd-flex justify-content-between align-items-center',
    ]"
  >
    <portal>
      <transition name="fadeUp">
        <RejectCouponModal
          v-if="showDialog"
          :is-submitting="isSubmitting"
          :partner="partner"
          @cancel="hideDialog"
          @confirm="rejectRequest"
        />
      </transition>
    </portal>
    <div class="d-flex justify-content-start align-items-center">
      <div class="img-container m-r-12">
        <BaseImage
          :src="campaign.logo"
          width="40"
          height="40"
          class="radius-full cursor-pointer fitted-img"
          :name="`notification-img-${notification.id}`"
        />
      </div>
      <div class="m-r-15">
        <p
          class="p-base-medium text-neutral-400 m-b-9"
          :class="{ 'cursor-pointer': canViewCampaigns }"
          @click="viewCampaignDetails"
        >
          <span class="text-neutral-900 m-r-3">{{ partner.name }}</span>
          <span class="m-r-3">{{
            $t(`notifications.${notification.type}.${"has-requested"}`)
          }}</span>
          <span class="text-neutral-900 m-r-3">{{ couponRequest.count }}</span>
          <span class="m-r-3">{{
            $t(`notifications.${notification.type}.${"coupons-for"}`)
          }}</span>
          <span class="text-neutral-900 m-r-3">{{ campaign.name }}</span>
          <span>{{
            $t(`notifications.${notification.type}.${"campaign"}`)
          }}</span>
        </p>
      </div>
    </div>
    <ActionsContainer
      v-if="status !== 'action-taken' && !isLoginAsActivated"
      :is-in-dropdown="!isCurrentRouteNotifications"
    >
      <ActionBtn
        v-for="(action, i) in notification.actions"
        :key="`action-${action}-${i}`"
        :action="action"
        :is-small="!isCurrentRouteNotifications"
        @action-handler="actionHandler(action)"
      />
    </ActionsContainer>
  </div>
</template>
<style scoped lang='sass'></style>
