<script>
  import setPagination from "@/utils/data-manipulation/set-pagination/index";
  import API from "@/utils/input-output/api/index";
  import BellIcon from "./Bell.vue";
  import TimeLineItemShimmer from "../../partials/base-timeline/TimeLineItemShimmer.vue";
  import LoadingState from "./LoadingState.vue";
  import NotificationItem from "./NotificationItem.vue";
  import EmptyState from "../../partials/base-timeline/components/EmptyState.vue";
  import constants from "../constants";

  export default {
    components: {
      LoadingState,
      NotificationItem,
      TimeLineItemShimmer,
      EmptyState,
      BellIcon,
    },

    data() {
      return {
        showDropdown: false,
        hasClickOutSideOption: true,
        content: [],
        isLoading: false,
        lastNotificationID: "",
        pagination: {},
      };
    },
    computed: {
      unseenNotifications() {
        return this.$store.state.Notifications.unseenNotifications;
      },
      unreadNotifications() {
        return this.$store.state.Notifications.unreadNotifications;
      },
      isLoginAsActivated() {
        return this.$store.getters["Authorization/isLoginAsActivated"];
      },
      userPersona() {
        return this.$store.getters["User/userPersona"];
      },
    },
    methods: {
      showDropdownHandler() {
        this.showDropdown = !this.showDropdown;
        if (this.showDropdown) {
          this.fetchNotifications({ page: 1 });
        } else {
          this.cancelPendingRequest();
          this.content = []
          this.isLoading = false
        }
        this.$gtag("event", "Notification Bell button clicks");
      },

      closeDropdown() {
        if (!this.hasClickOutSideOption) return;
        this.cancelPendingRequest();
        this.showDropdown = false;
        this.content = []
        this.isLoading = false
      },
      cancelPendingRequest() {
        this.$store.dispatch("Loader/cancelRequests", {
          requests: [{ id: "fetchNotificationsDropdown" }],
        });
      },
      handleClickOutSide(val) {
        if (val === "closed") {
          // to avoid closing the notification modal once the user closed any modal inside the notification modal
          setTimeout(() => {
            this.hasClickOutSideOption = true;
          }, 1000);
        } else {
          this.hasClickOutSideOption = false;
        }
      },
      // load more functionality to be centralized
      loadMore({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (
          scrollTop + (clientHeight + 10) >= scrollHeight
          && !this.isLoading
          && this.pagination.currentPage < this.pagination.total
        ) {
          const page = this.pagination.currentPage + 1;
          this.fetchNotifications({ page });
        }
      },

      fetchNotifications({ page }) {
        this.isLoading = true;
        API({
          url: constants.fetchNotifications({ persona: this.userPersona }),
          method: "get",
          params: {
            limit: 10,
            page,
          },
          requestId: "fetchNotificationsDropdown",
        })
          .then(({ data }) => {
            if (page === 1) {
              this.content = data.payload.data;
            } else {
              this.content = [...this.content, ...data.payload.data];
            }
            this.pagination = setPagination(data.payload.pagination);
            this.isLoading = false;

            if (page !== 1) return;

            this.lastNotificationID = data.payload.lastNotificationId;

            if (this.content.length) {
              this.$store.dispatch(
                "Notifications/setNotificationsToBeSeen",
                this.lastNotificationID,
              );
            }

            this.$store.dispatch("Notifications/setUnreadNotificationCount");
          })
          .catch((error) => {
            this.isLoading = !!error?.message?.isClientError;
          });
      },

      markAllAsRead() {
        this.isLoading = true;
        this.$store
          .dispatch("Notifications/markAllAsRead", {
            lastNotificationID: this.lastNotificationID,
          })
          .then(() => {
            this.isLoading = false;
          });
      },
      setNotificationAsRead({ notification }) {
        const index = this.content.findIndex((el) => el.id === notification.id);
        if (index > -1) {
          this.$set(this.content, index, { ...notification, status: "read" });
        }
      },
    },
  };
</script>

<template>
  <div v-click-outside="{isActive: ()=> showDropdown , callback: closeDropdown}">
    <div class="profile-box">
      <div class="cursor-pointer" @click="showDropdownHandler">
        <div
          v-if="unseenNotifications > 0 && !isLoginAsActivated"
          class="text-base-white p-small-medium number-container-filled"
        >
          {{ unseenNotifications > 99 ? "99+" : unseenNotifications }}
        </div>
        <BellIcon color="var(--secondary-500)" />
      </div>

      <transition name="fade">
        <ul
          v-if="showDropdown"
          class="notification-dropdown"
          @scroll="loadMore"
        >
          <li class="p-y-18 p-x-18 notification-title bg-base-white">
            <div class="d-flex justify-content-between align-items-center">
              <div class="heading-5-bold">
                {{ $t("titles.notifications") }}
              </div>
              <div
                v-if="unreadNotifications > 0"
                class="d-flex align-items-center"
              >
                <p
                  class="text-secondary-500 cursor-pointer p-base-medium p-r-6"
                  @click="markAllAsRead"
                >
                  {{ $t("buttons.markAllAsRead") }}
                </p>
              </div>
            </div>
          </li>

          <EmptyState
            v-if="!content.length && !isLoading"
            :message="$t('labels.noData')"
          />

          <LoadingState v-if="!content.length && isLoading" />

          <template v-if="content.length">
            <NotificationItem
              v-for="notification in content"
              :key="notification.id"
              :content="notification"
              @on-popup-state-update="handleClickOutSide"
              @set-notification-as-read="
                setNotificationAsRead({ notification })
              "
            />
            <li v-if="isLoading" class="p-y-18 p-x-21">
              <TimeLineItemShimmer :is-small="true" />
            </li>
          </template>

          <li class="see-all-container bg-base-white text-center p-18">
            <router-link
              name="notification-see-all"
              to="/notifications"
              class="w-100 text-secondary-500 p-small-bold cursor-pointer text-decoration-none"
            >
              <div>
                {{ $t("labels.seeAll") }}
              </div>
            </router-link>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="sass">
@import "bell-dropdown"
</style>
