<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="20" height="20"
    viewBox="0 0 20 20" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.44 0C6.87638 0.00731219 4.41349 0.998914 2.56 2.77V1C2.56 0.734784 2.45464 0.48043 2.2671 0.292893C2.07957
        0.105357 1.82521 0 1.56 0C1.29478 0 1.04043 0.105357 0.852891 0.292893C0.665354 0.48043 0.559998 0.734784 0.559998
        1V5.5C0.559998 5.76522 0.665354 6.01957 0.852891 6.20711C1.04043 6.39464 1.29478 6.5 1.56 6.5H6.06C6.32521 6.5 6.57957
        6.39464 6.7671 6.20711C6.95464 6.01957 7.06 5.76522 7.06 5.5C7.06 5.23478 6.95464 4.98043 6.7671 4.79289C6.57957 4.60536
        6.32521 4.5 6.06 4.5H3.66C4.57171 3.53701 5.7085 2.81576 6.96813 2.40114C8.22775 1.98651 9.57069 1.89152 10.8761 2.1247C12.1816
        2.35788 13.4086 2.91193 14.4468 3.73699C15.485 4.56205 16.3018 5.63226 16.8237 6.85133C17.3457 8.0704 17.5564 9.4001 17.4369
        10.7208C17.3174 12.0415 16.8715 13.3118 16.1392 14.4174C15.407 15.5231 14.4114 16.4293 13.242 17.0547C12.0726 17.68 10.7661
        18.0049 9.44 18C9.17478 18 8.92043 18.1054 8.73289 18.2929C8.54535 18.4804 8.44 18.7348 8.44 19C8.44 19.2652 8.54535 19.5196
        8.73289 19.7071C8.92043 19.8946 9.17478 20 9.44 20C12.0922 20 14.6357 18.9464 16.5111 17.0711C18.3864 15.1957 19.44 12.6522
        19.44 10C19.44 7.34784 18.3864 4.8043 16.5111 2.92893C14.6357 1.05357 12.0922 0 9.44 0ZM9.44 6C9.17478 6 8.92043 6.10536
        8.73289 6.29289C8.54535 6.48043 8.44 6.73478 8.44 7V10C8.44 10.2652 8.54535 10.5196 8.73289 10.7071C8.92043 10.8946 9.17478
        11 9.44 11H11.44C11.7052 11 11.9596 10.8946 12.1471 10.7071C12.3346 10.5196 12.44 10.2652 12.44 10C12.44 9.73478 12.3346
        9.48043 12.1471 9.29289C11.9596 9.10536 11.7052 9 11.44 9H10.44V7C10.44 6.73478 10.3346 6.48043 10.1471 6.29289C9.95957 6.10536
        9.70521 6 9.44 6Z" :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
