<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1098 15.3901L8.22984 19.2701C7.76007 19.7235 7.1327 19.9768 6.47984 19.9768C5.82698 19.9768
      5.1996 19.7235 4.72984 19.2701C4.49944 19.0406 4.31663 18.7679 4.19189 18.4676C4.06715 18.1673
      4.00294 17.8453 4.00294 17.5201C4.00294 17.1949 4.06715 16.8729 4.19189 16.5726C4.31663 16.2723
      4.49944 15.9996 4.72984 15.7701L8.60984 11.8901C8.79814 11.7018 8.90393 11.4464 8.90393
      11.1801C8.90393 10.9138 8.79814 10.6584 8.60984 10.4701C8.42153 10.2818 8.16614 10.176 7.89984
      10.176C7.63353 10.176 7.37814 10.2818 7.18984 10.4701L3.30984 14.3601C2.52819 15.2109 2.10546
      16.3308 2.12991 17.4858C2.15436 18.6409 2.62412 19.7419 3.44107 20.5589C4.25802 21.3758 5.359
      21.8456 6.51408 21.87C7.66917 21.8945 8.78904 21.4717 9.63984 20.6901L13.5298 16.8101C13.7181
      16.6218 13.8239 16.3664 13.8239 16.1001C13.8239 15.8338 13.7181 15.5784 13.5298 15.3901C13.3415
      15.2018 13.0861 15.096 12.8198 15.096C12.5535 15.096 12.2981 15.2018 12.1098 15.3901ZM20.6898
      3.31009C19.8486 2.4741 18.7108 2.00488 17.5248 2.00488C16.3389 2.00488 15.2011 2.4741 14.3598
      3.31009L10.4698 7.19009C10.3766 7.28333 10.3026 7.39402 10.2522 7.51585C10.2017 7.63767 10.1757
      7.76824 10.1757 7.90009C10.1757 8.03195 10.2017 8.16252 10.2522 8.28434C10.3026 8.40617 10.3766
      8.51686 10.4698 8.61009C10.5631 8.70333 10.6738 8.77729 10.7956 8.82775C10.9174 8.87821 11.048
      8.90419 11.1798 8.90419C11.3117 8.90419 11.4423 8.87821 11.5641 8.82775C11.6859 8.77729 11.7966
      8.70333 11.8898 8.61009L15.7698 4.73009C16.2396 4.27672 16.867 4.02335 17.5198 4.02335C18.1727
      4.02335 18.8001 4.27672 19.2698 4.73009C19.5002 4.95958 19.683 5.2323 19.8078 5.53261C19.9325
      5.83292 19.9967 6.15491 19.9967 6.48009C19.9967 6.80528 19.9325 7.12727 19.8078 7.42758C19.683
      7.72789 19.5002 8.00061 19.2698 8.23009L15.3898 12.1101C15.2961 12.2031 15.2217 12.3137 15.1709
      12.4355C15.1202 12.5574 15.094 12.6881 15.094 12.8201C15.094 12.9521 15.1202 13.0828 15.1709
      13.2047C15.2217 13.3265 15.2961 13.4371 15.3898 13.5301C15.4828 13.6238 15.5934 13.6982 15.7153
      13.749C15.8371 13.7998 15.9678 13.8259 16.0998 13.8259C16.2318 13.8259 16.3626 13.7998 16.4844
      13.749C16.6063 13.6982 16.7169 13.6238 16.8098 13.5301L20.6898 9.64009C21.5258 8.79887 21.995
      7.66107 21.995 6.47509C21.995 5.28912 21.5258 4.15131 20.6898 3.31009ZM8.82984 15.1701C8.92328
      15.2628 9.03409 15.3361 9.15593 15.3859C9.27777 15.4356 9.40823 15.4609 9.53984 15.4601C9.67144
      15.4609 9.80191 15.4356 9.92374 15.3859C10.0456 15.3361 10.1564 15.2628 10.2498 15.1701L15.1698
      10.2501C15.3581 10.0618 15.4639 9.8064 15.4639 9.54009C15.4639 9.27379 15.3581 9.0184 15.1698
      8.83009C14.9815 8.64179 14.7261 8.536 14.4598 8.536C14.1935 8.536 13.9381 8.64179 13.7498
      8.83009L8.82984 13.7501C8.73611 13.8431 8.66171 13.9537 8.61095 14.0755C8.56018 14.1974 8.53404
      14.3281 8.53404 14.4601C8.53404 14.5921 8.56018 14.7228 8.61095 14.8447C8.66171 14.9665 8.73611
      15.0771 8.82984 15.1701Z"
      :fill="
        isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'
      "
    />
  </svg>
</template>
