/** Coupons */
import AllocationExpired from "./coupons/AllocationExpired.vue"
import AllocationAssigned from "./coupons/AllocationAssigned.vue"
import RequestCreated from "./coupons/RequestCreated.vue"
import RequestRejected from "./coupons/RequestRejected.vue"
import RequestAllocated from "./coupons/RequestAllocated.vue"
/** Campaigns */
import ApplicationApproved from "./campaigns/ApplicationApproved.vue"
import ApplicationRejected from "./campaigns/ApplicationRejected.vue"
import Paused from "./campaigns/Paused.vue"
import Resumed from "./campaigns/Resumed.vue"
import Stopped from "./campaigns/Stopped.vue"
import ApplicationRequest from "./campaigns/ApplicationRequest.vue"
import StatusChanged from "./campaigns/StatusChanged.vue"
import LinkCampaignAccess from "./campaigns/LinkCampaignAccess.vue"
import UpdatedByAdvertiser from "./campaigns/UpdatedByAdvertiser.vue"
import GivingAgencyCampaignAccess from "./campaigns/GivingAgencyCampaignAccess.vue"
import ReceivingAgencyCampaignAccess from "./campaigns/ReceivingAgencyCampaignAccess.vue"
import CampaignCreatedByAgency from "./campaigns/CampaignCreatedByAgency.vue"
import CapUsageAlert from "./campaigns/CapUsageAlert.vue"
import CapConsumed from "./campaigns/CapConsumed.vue"
import CapDeleted from "./campaigns/CapDeleted.vue"
import CapIncreased from "./campaigns/CapIncreased.vue"
import CapExtended from "./campaigns/CapExtended.vue"
import AccessRevoked from "./campaigns/access-revoked/Index.vue"
import UnassignedCGFromCampaign from "./campaigns/UnassignedCGFromCampaign.vue"
import AssignedCGMToCampaign from "./campaigns/AssignedCGMToCampaign.vue"
import AssignedCGDToCampaign from "./campaigns/AssignedCGDToCampaign.vue"
/** Partners */
import PartnerValidationApproved from "./partners/PartnerValidationApproved.vue"
import PartnerReferredPublisher from "./partners/PartnerReferredPublisher.vue"
import PartnerReferringPublisher from "./partners/PartnerReferringPublisher.vue"
import PartnerLoyaltyReward from "./partners/PartnerLoyaltyReward.vue"
import TeamValidationApproved from "./partners/TeamValidationApproved.vue"
/** Revenues and Payouts */
import RevenueExpiration from "./revenues-and-payouts/RevenueExpiration.vue"
import PayoutExpiration from "./revenues-and-payouts/PayoutExpiration.vue"
/** Miscellaneous */
import PartnerProfileAction from "./miscellaneous/PartnerProfileAction.vue"
import UpdatePartnerProfile from "./miscellaneous/UpdatePartnerProfile.vue"
import UserTagged from "./miscellaneous/UserTagged.vue"
/** Announcements */
import Insights from "./announcements/Insights.vue"
import Promotions from "./announcements/Promotions.vue"
import Updates from "./announcements/Updates.vue"
import Boostiny from "./announcements/Boostiny.vue"
/** Payment Validation */
import ManagementApproved from "./partner-payment/ManagementApproved.vue"
import ValidationPaid from "./partner-payment/ValidationPaid.vue"
import ValidationBounced from "./partner-payment/ValidationBounced.vue"
import VATInvoicesUploaded from "./partner-payment/VATInvoicesUploaded.vue"
import InvoicesApproved from "./partner-payment/InvoicesApproved.vue"
import PendingPaymentActions from "./partner-payment/PendingPaymentActions.vue"
import UserTaggedInValidation from "./partner-payment/UserTagged.vue"
/** Validation */
import SheetAccepted from "./validation/SheetAccepted.vue"
import SheetFlagged from "./validation/SheetFlagged.vue"
import SheetRejected from "./validation/SheetRejected.vue"
import SheetUploaded from "./validation/SheetUploaded.vue"
import SheetValidated from "./validation/SheetValidated.vue"
import SheetUndone from "./validation/SheetUndone.vue"
import DaysUpdated from "./validation/DaysUpdated.vue"
import CommentCreated from "./validation/CommentCreated.vue"
import CommentReply from "./validation/CommentReply.vue"
import CommentTag from "./validation/CommentTag.vue"

/** Change reporting line */
import MovedUser from "./change-reporting-line/MovedUser.vue"
import OldManager from "./change-reporting-line/OldManager.vue"
import NewManager from "./change-reporting-line/NewManager.vue"

/** Migrate user campaigns */
import OldUser from "./migrate-user-campaigns/OldUser.vue"
import NewUser from "./migrate-user-campaigns/NewUser.vue"
import Head from "./migrate-user-campaigns/Head.vue"

export default {
  /** Coupons */
  "coupon-allocation-expired": AllocationExpired,
  "coupon-allocation-assigned": AllocationAssigned,
  "coupon-request-created": RequestCreated,
  "coupon-request-rejected": RequestRejected,
  "coupon-request-allocated": RequestAllocated,
  /** Campaigns */
  "campaign-application-approved": ApplicationApproved,
  "campaign-application-rejected": ApplicationRejected,
  "campaign-paused": Paused,
  "campaign-resumed": Resumed,
  "campaign-stopped": Stopped,
  "link-campaign-access": LinkCampaignAccess,
  "campaign-application-request": ApplicationRequest,
  "campaign-status-changed": StatusChanged,
  "campaign-updated-by-advertiser": UpdatedByAdvertiser,
  "campaign-access-agency-given": GivingAgencyCampaignAccess,
  "campaign-access-agency-received": ReceivingAgencyCampaignAccess,
  "campaign-created-by-agency": CampaignCreatedByAgency,
  "campaign-cap-usage-alert": CapUsageAlert,
  "campaign-cap-consumed": CapConsumed,
  "campaign-cap-deleted": CapDeleted,
  "campaign-cap-increased": CapIncreased,
  "campaign-cap-extended": CapExtended,
  "campaign-access-revoked": AccessRevoked,
  "cg-team-unassigned-from-campaign": UnassignedCGFromCampaign,
  "cgm-team-assigned-to-campaign": AssignedCGMToCampaign,
  "cgd-team-assigned-to-campaign": AssignedCGDToCampaign,
  /** Partners */
  "partner-validation-approved-for-partners": PartnerValidationApproved,
  "partner-validation-approved-for-team": TeamValidationApproved,
  "partner-referring-publisher": PartnerReferringPublisher,
  "partner-referred-publisher": PartnerReferredPublisher,
  "partner-loyalty-reward": PartnerLoyaltyReward,
  /** Revenues and Payouts */
  "revenue-expiration": RevenueExpiration,
  "payout-expiration": PayoutExpiration,
  /** Miscellaneous */
  "miscellaneous-request-update-partner-profile": UpdatePartnerProfile,
  "miscellaneous-request-update-partner-profile-action": PartnerProfileAction,
  "miscellaneous-user-tagged-in-partner-comment": UserTagged,
  /** Announcements */
  "announcements-insights": Insights,
  "announcements-promotions": Promotions,
  "announcements-updates": Updates,
  "announcements-boostiny": Boostiny,
  /** Partner Payment */
  "partner-payment-validation-management-approved": ManagementApproved,
  "partner-payment-validation-paid": ValidationPaid,
  "partner-payment-validation-bounced": ValidationBounced,
  "partners-uploaded-vat-invoices": VATInvoicesUploaded,
  "partners-approved-invoices": InvoicesApproved,
  "pending-payment-actions": PendingPaymentActions,
  "user-tagged-in-partner-payment-validation-comment": UserTaggedInValidation,
  /** Validation */
  "campaign-validation-sheet-accepted": SheetAccepted,
  "campaign-validation-flagged": SheetFlagged,
  "campaign-validation-sheet-rejected": SheetRejected,
  "campaign-validation-sheet-uploaded": SheetUploaded,
  "campaign-validation-approved": SheetValidated,
  "undo-campaign-validation-approval": SheetUndone,
  "campaign-validation-days-changed": DaysUpdated,
  "user-mentioned-in-campaign-validation-comment": CommentTag,
  "user-created-campaign-validation-comment": CommentCreated,
  "user-replied-campaign-validation-comment": CommentReply,

  /** Change reporting line */
  "reporting-line-changed-for-employee": MovedUser,
  "reporting-line-moved-from-manager": OldManager,
  "reporting-line-added-to-manager": NewManager,

  /** Migrate user campaigns */
  "migrate-campaigns-from-user": OldUser,
  "migrate-campaigns-to-user": NewUser,
  "migrate-campaigns-for-head": Head,
  "migrate-campaigns-for-team": OldUser,

}
