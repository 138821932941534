<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="22" height="23"
    viewBox="0 0 22 23" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 11.0799V11.9999C20.9988 14.1563 20.3005 16.2545 19.0093 17.9817C17.7182 19.7088 15.9033 20.9723 13.8354 21.5838C11.7674
      22.1952 9.55726 22.1218 7.53447 21.3744C5.51168 20.6271 3.78465 19.246 2.61096 17.4369C1.43727 15.6279 0.879791 13.4879 1.02168
      11.3362C1.16356 9.18443 1.99721 7.13619 3.39828 5.49694C4.79935 3.85768 6.69279 2.71525 8.79619 2.24001C10.8996 1.76477 13.1003
      1.9822 15.07 2.85986M21 3.99986L11 14.0099L8.00001 11.0099"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
