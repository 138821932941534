import { render, staticRenderFns } from "./MobileView.vue?vue&type=template&id=981c13ac&scoped=true"
import script from "./MobileView.vue?vue&type=script&lang=js"
export * from "./MobileView.vue?vue&type=script&lang=js"
import style0 from "./MobileView.vue?vue&type=style&index=0&id=981c13ac&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "981c13ac",
  null
  
)

export default component.exports