export default {
  labels: {
    unknown: "Unknown",
    lateValidation: "Late Validation",
    onTimeValidation: "On Time Validation",
    payments: "Payments",
    totalPayment: "Total Payment",
    newAdset: "أدخل اسم مجموعة الإعلانات الجديدة",
    selectAdset: "حدد مجموعة الإعلانات من القائمة",
    at: "في",
    lastModified: "آخر تحديث",
    today: "اليوم",
    yesterday: "أمس",
    validationDays: "Validation Days",
    last7Days: "اخر سبع أيام",
    timePickerLast7Days: "أخر أسبوع",
    last30Days: "اخر 30 يوم",
    timePickerLast30Days: "أخر شهر",
    thisMonth: "هذا الشهر",
    lastMonth: "الشهر الماضي",
    orders: "الطلبات",
    revenues: "الإيرادات",
    revenueUsd: "الإيرادات",
    globalPayout: "Global Payout",
    has: "Has",
    profit: "الربح",
    currentMonth: "Current Month",
    lastSixMonths: "Last 6 Months",
    creatives: "Creatives",
    otherReason: "Other Reason",
    followers: "Followers",
    engagementRate: "Engagement Rate",
    credibilityScore: "Credibility Score",
    poor: "Poor",
    good: "Good",
    veryGood: "Very Good",
    excellent: "Excellent",
    exceptional: "Exceptional",
    audience: "Audience",
    age: "Age",
    geographicalReach: "Geographical Reach",
    languages: "Languages",
    areasOfInterest: "Areas Of Interest",

    socialData: "Social Data",
    performanceSummary: "Performance Summary",
    countrySet: "Country set",
    operators: {
      contains: "Contains",
      "not-contains": "Not contains",
      between: "between",
    },
    selectedPartners: "selected partners",
    approvalRejection: "Approval Rejection",
    viewLinks: "View Links",
    deactivatePartner: "Deactivate Partner",
    chooseDateOfRevokement: "Choose Date of Revokement",
    chooseReasonOfRevokement: "Choose Reason of Revokement",
    shortenURLAppear: "shorten URL will appear here",
    viewLess: "View Less",
    deletedUser: "Deleted User",
    otherCountries: "Other Countries",
    legalEntities: "Legal Entities",
    allOther: "All Other",
    arabyadsFZ: "Arabyads FZ",
    arabyadsEgypt: "Arabyads Egypt",
    "arabyadsM&K": "Arabyads M&K",
    chooseEntities: "Choose entities for campaign",
    chooseCountries: "Choose Countries",
    specificCountries: "Specific Countries",
    margin: "هامش الربح",
    "payout-revenue": "Payouts and Revenues",
    noBannersYet: "No Ads Banners Yet",
    noExpirationDate: "No Expiration Date",
    bannerStartDate: "Banner Start Date",
    bannerEndDate: "Banner End Date",
    redirection: {
      campaignName: "Campaign Name",
      link: "Link",
    },
    validation: "Validation",
    noEntityAdded: "No legal entity added",
    dashboardBanner: "Overview Banner",
    campaignBanner: "Campaign Banner",
    campaignsBanner: "Campaign Banner",
    mobileBanner: "Mobile Banner",
    campaignScreen: "Campaign Screen",
    overviewScreen: "Overview Screen",
    redirectionType: "Redirection Type",
    redirectionCampaign: "Redirection Campaign",
    redirectionLink: "Redirection Link",
    stop: "Stop",
    remove: "Remove",
    totalCost: "المصاريف",
    cost: "المصاريف",
    partnerRevenue: "الارباح",
    ratableId: "Ratable ID",
    ratableType: "Ratable Type",
    upcoming: "Upcoming",
    running: "Running",
    fileUrl: "File URL",
    updatedBy: "Updated By",
    gapsConfirmed: "Gaps Confirmed",
    belongsTo: "Belongs To",
    adSetName: "Adset Name",
    expiresAt: "Expires At",
    zohoRequestId: "Zoho Request ID",
    performedByName: "Performed By (Name)",
    performedByEmail: "Performed By (Email)",
    noCapAdded: "No Cap Added",
    budgetCap: "Budget Cap",
    inSmall: "in",
    conversionCap: "Conversion Cap",
    daysRemaining: "day remaining | days remaining",
    email: "البريد إلكتروني",
    password: "كلمة المرور",
    conversions: "التحويلات",
    datePeriod: "Date Period",
    capType: "Cap type",
    newCap: "New Cap",
    noCaps: "No caps available",
    hasExperienceInCpsModel: "Has CPS Experience",
    salesAmounts: "المبيعات",
    bonus: "إضافات",
    fine: "خصم",
    extra: "إضافات",
    deduction: "خصومات",
    by: "بواسطة",
    howYouWillPromote: "كيف ستحقق اهداف الحمله؟",
    all: "الكل",
    mine: "شركائي",
    others: "اخرى",
    active: "المفعله",
    stopped: "المتوقفه",
    offer: "offer",
    paused: "موقفه مؤقتا",
    campaignStatus: "الحالة",
    campaignCreationYear: "تاريخ بداية الحملة",
    creationYear: "Creation Year",
    campaignExtraAndDeduction: "الإضافات والخصومات",
    campaign: "الحملة",
    approved: "تم الموافقه عليها ",
    pending: "معلقه",
    rejected: "مرفوضه",
    total: "إجمالي",
    missing: "مفقودة",
    notJoined: "جديده",
    name: "الاسم",
    roleName: "دور",
    buName: "وحدة العمل",
    teamLeaderName: " قائد الفريق",
    contact: "رقم الهاتف",
    isEmpty: "فارغ",
    isNotEmpty: "غير فارغ",
    dateIs: "التاريخ",
    payment: "دفع",
    dateBefore: "التاريخ قبل",
    dateAfter: "التاريخ بعد",
    numberIsEqual: "الرقم يساوي",
    numberNotEqual: "الرقم لا يساوي",
    numberBetween: "الرقم ما بين",
    numberGreaterThan: "الرقم اكبر من",
    numberGreaterThanOrEqual: "لرقم اكبر من او يساوي",
    numberLessThan: "الرقم اقل من",
    numberLessThanOrEqual: "الرقم اقل من او يساوي",
    textContains: "يحتوي على",
    textNotContains: "لا يحتوي على",
    textStartWith: "يبدا ب",
    textEndWith: "ينتهي ب",
    multipleMatch: "يحتوي على ايا من",
    textExactly: "النص يكون",
    value: "القيمه",
    filterBy: "ابحث بال",
    showing: "عرض",
    entries: "صف",
    campaignName: "الحملة",
    status: "الحالة",
    category: "الفئه",
    coupons: "الكوبونات",
    links: "رابط",
    advertiserName: "المعلن",
    commission: "النسبه",
    comment: "التعليق",
    actions: "الإجراء",
    allocated: "مخصصة",
    unallocated: "غير مخصصة",
    missingCouponsCount: "مفقوده",
    applyFor: "قدم",
    edit: "تعديل",
    viewPayouts: "العمولات",
    viewRevenues: "الارباح",
    viewExtrasAndDeductions: "الإضافات والخصومات",
    loading: "جاري التحميل",
    code: "الكوبون",
    syncId: "Sync id",
    bu: "القسم",
    teamLeader: "قائد الفريق",
    teamMember: "المسؤل",
    partnerName: "اسم الشريك",
    partnerId: "معرف الشريك",
    startDate: "تاريخ التخصيص",
    endDate: "تاريخ الانتهاء",
    INF: "مؤثر",
    AFF: "تابع",
    AALab: "AA Lab",
    MB: "Media Buyer",
    netPayout: "Payouts",
    grossPayout: "Gross Payout",
    trafficSource: "Traffic Source",
    costType: "Cost Type",
    campaignDetails: "تفاصيل الحملة",
    campaignApplications: "طلبات الاشتراك",
    campaignCoupons: "الكوبونات",
    campaignRevenues: "الإيرادات",
    campaignPayouts: "العمولات",
    campaignPartners: "Partners",
    campaignTeamAccess: "Team",
    activityStatus: "Activity Status",
    allocationStatus: "حالة التخصيص",
    uploadBanners: "Upload Banners",
    missingCoupons: "مفقودة",
    ignoredCoupons: "مرفوضة",
    campaignList: "الحملات",
    couponsAllocation: "تخصيص الكوبونات",
    selected: "تم اختيار",
    CampaignBonusAndFines: "الإضافات والخصومات",
    inActive: "مأرشفه",
    partner: "الشريك",
    notProvided: "غير موجود",
    days: "يوم",
    budget: "الميزانيه",
    month: "شهر",
    tips: "النصائح",
    campaignManager: "مسؤل الحمله",
    phoneNumber: "رقم الهاتف",
    campaignsList: "الحملات",
    performanceReport: "الاداء",
    campaignReport: "تحليل الحمالات",
    INFAnalysis: "Influencer Analysis",
    AFFAnalysis: "Publisher Analysis",
    ANAnalysis: "Publisher Analysis",
    partnerReport: "تحليل الشركاء",
    dimensions: "Dimensions",
    metrics: "Metrics",
    partners: "شريك",
    invitation: "دعوة للانضمام",
    invitationInfluencer: "استدعي المؤثر",
    invitationAffiliate: "استدعي الشركة التابعة",
    invite: "استدعي",
    teamLeaders: "قادة الفرق",
    teamMembers: "قادة الحملات",
    accountsManager: "مدير الحملة",
    campaigns: "الحملات",
    salesAmountUsd: "المبيعات بالدولار",
    salesAmount: "المبيعات",
    payoutCost: "Payout cost",
    fixedCost: "Fixed cost",
    MBCost: "MB cost",
    aovUsd: "AOV USD",
    date: "التاريخ",
    originalCurrency: "Original currency",
    country: "الدوله",
    aov: "AOV",
    customerType: "Customer type",
    totalCostUsd: "Total cost USD",
    current: "الحالي",
    past: "السابق",
    future: "المقبل",
    condition: "Condition",
    details: "Details",
    type: "النوع",
    cycle: "الشهر",
    cycles: "Cycles",
    clone: "نسخ",
    deleteRecord: "مسح",
    default: "عامة",
    coupon: "كوبون",
    lastRequestedAt: "Last requested at",
    editCampaign: "تعديل الحملة",
    "12MonthsData": "بيانات ١٢ شهر",
    influencerPaymentLimit: "Influencer Payment Limit",
    publisherPaymentLimit: "Publisher Payment Limit",
    "6MonthsData": "بيانات ٦ شهور",
    "3MonthsData": "بيانات ٣ شهور",
    payoutSupportingText: "A percentage of the overall revenue",
    configured: "Configured",
    announcement: "Announcement",
    system: "System",
    comission: "Comission",
    commissions: "النسبه",
    showRevenue: "اظهر الارباح",
    deletePayout: "Delete Payout",
    deleteRevenue: "Delete Revenue",
    clientContactEmail: "Client Contact Email",
    clientContactName: "Client Contact Name",
    addClientContact: "Add New Client Contact",
    deleteExtraDeduction: "Delete Extra Deduction",
    conditions: "Conditions",
    typeOfPayment: "Type of payment",
    paymentType: "Type of payment",
    payoutType: "Payout Type",
    revenueType: "Revenue Type",
    fixed: "Fixed",
    perOrder: "Per-order",
    revShare: "Revenue share",
    createRecord: "اضافة {type} {level}",
    editRecord: "تعديل  {type} {level}",
    cloneRecord: "نسخ {type} {level}",
    valueUSD: "Value USD",
    valuePercentage: "Value %",
    hasPayout: "has a payout",
    hasRevenue: "has a revenue",
    startsIn: "starts in",
    and: "and",
    endsIn: "ends in",
    applicationDate: "تاريخ الطلب",
    promoteCampaignAnswer: "هدف الطلب",
    rejectionReason: "Reason of rejection",
    applicationStatus: "Application Status",
    notes: "الملاحظات",
    personalInfo: "معلومات شخصية",
    integrationError: "Integration error",
    yesterdayOrders: "yesterday orders",
    yesterdaySalesAmount: "yesterday sales amount",
    last30DaysOrders: "last 30 days orders",
    last30DaysSalesAmount: "last 30 days sales amount",
    allOrders: "all orders",
    allSalesAmount: "all sales amount",
    myCoupons: "My Coupons",
    to: "الي",
    of: "من",
    grossConversion: "التحويل الإجمالي",
    netConversion: "صافي التحويل",
    grossRevenue: "إجمالي الإيرادات",
    netRevenue: "صافي الإيرادات",
    campaignValidation: "الحملة",
    partnersValidation: "الشركاء",
    startedWithArabyAds: "Since you started with Boostiny",
    numberOfCoupons: "كوبونات",
    numberOfCampaigns: "الحملات",
    revenue: "الارباح",
    payout: "العمولات",
    model: "Model",
    AALabs: "AA Labs",
    level: "Level",
    addRevenue: "Add Revenue",
    addPayout: "Add Payout",
    editPayout: "Edit Payout",
    editRevenue: "Edit Revenue",
    revenueModal: "ارباح",
    payoutModal: "عمولة",
    fileUpload: "رفع الملف",
    couponsInformation: "Coupons Information",
    browseCouponsFile: "Browse Coupons File",
    or: "أو",
    created: "created",
    createdAt: "Created at",
    invalid: "invalid",
    are: "are {category}",
    is: "is",
    deactivationNote: "Deactivation Note",
    deactivationReason: "Deactivation Reason",
    deactivationReasonId: "Deactivation Reason ID",
    willBe: "will be {category}",
    adSet: "Adset",
    searchToGetMoreData: "search to get more data",
    selectAll: "select all {field}",
    unselectAll: "unselect all {field}",
    advertiser: "المعلن",
    advertiserId: "المعلن",
    websiteURLEn: "رابط الموقع باللغة الإنجليزية",
    websiteURLAr: "رابط الموقع باللغة العربية",
    creativesURLEn: "رابط الاعلانات باللغة الإنجليزية",
    creativesURLAr: "رابط الاعلانات باللغة العربية",
    passwordConfirmation: "Password Confirmation",
    office: "مكتب",
    campaignDetailsReport: "الأداء",
    validationStatus: "Validation status",
    selectCycle: "حدد الدورة",
    deleteExtra: "حذف الزائد",
    deleteDeduction: "حذف الخصم",
    socialAccounts: "حسابات السوشيال ميديا",
    bankInfo: "معلومات البنك",
    gender: "الجنس",
    english: "الانجليزيه",
    campaign_type: "نوع الحملة",
    arabic: "العربيه",
    maxCancellationRatio: "Max cancellation ratio",
    sendSummary: "ملخص",
    sendInvitaion: "دعوة",
    loginAs: "دخول كشريك",
    goToChat: "الرسائل",
    notValidated: "Not validated",
    validatedDate: "تم التحقق منه حتى {date}",
    managementApproved: "Management approved",
    validationNotProvided: "Validation not provided",
    invalidCell: "Invalid cell",
    validTypes: "The valid cell types are",
    paid: "مدفوع",
    partnerPayment: "المدفوعات",
    validations: "Validations",
    boostinyPasswordRecoveryMessage: "يرجى إدخال بريدك الإلكتروني المستخدم فى Boostiny ",
    revgatePasswordRecoveryMessage: "يرجى إدخال بريدك الإلكتروني المستخدم فى Revgate ",
    administrator: "ادمن",
    head: "Head",
    bI: "BI",
    powerUser: "Power user",
    dateOfRequest: "Date of request",
    promotionDescriptionEn: "وصف العروض باللغة الإنجليزية",
    promotionDescriptionAr: "وصف العروض باللغة العربيه",

    parntersScope: "Filter By Partners",
    paymentUpdateValidation: "تحديث الدفع",

    browseCsvFiles: "Browse .csv files...",
    runValidation: "تشغيل التحقق",
    update: "تحديث",
    updated: "تم التحديث",

    couponCreated: "إضافة الكوبون",
    changePartner: "تعديل الشريك",
    actionDate: "تاريخ التغيير",
    allocationDate: "تاريخ التخصيص",

    allocatedCoupons: "المخصصة",
    unallocatedCoupons: "الغير مخصصة",
    inactiveApplications: "غير مفعله",
    team: "المستخدمين",
    buIds: "القسم",
    goToOldVersion: "اذهب الي النسخه القديمه",
    notProvidedCampaign: "Not provided Campaign",
    paymentMethod: "حدد طريقة الدفع المفضلة لديك",
    bankTransfer: "التحويل المصرفي",
    city: "المدينة",
    branch: "الفرع",
    iban: "ايبان",
    swift: "رمز السويفت",
    paypal: "بايبال",
    mobileCash: "موبايل كاش",
    accountHolderName: "اسم صاحب الحساب",
    bankName: "اسم البنك",
    mobileCashProvider: "موبايل كاش",
    mobileCashId: "موبايل كاش ID",
    mobileCashID: "موبايل كاش ID",
    publisherType: "Publisher Type",
    publisherLevel: "Publisher Level",
    influencerType: "Influencer Type",
    profileType: "نوع الملف الشخصي",
    categories: "فئات الخبرة",
    rateCardFile: "إرفاق قائمة الأسعار الخاصة بك",
    rateDetails: "ذكر تفاصيل الأسعار الخاصة بك",
    verificationIdFile: "هوية التحقق (مثال: جواز السفر)",
    reference: "المرجع في ArabyAds",
    workWithAgent: "هل تعمل مع وكيل",
    agentName: "اسم الوكيل",
    agentContact: "بيانات اتصال الوكيل",
    termsAndConditions: "أنا موافق على الشروط والأحكام",
    yes: "نعم",
    no: "لا",
    workWithAgentQuestion: "هل تعمل مع وكيل؟",
    female: "انثي",
    male: "ذكر",
    facebook: "فيسبوك",
    twitter: "تويتر",
    tiktok: "تيك توك",
    tikTokUserName: "تيك توك",
    snapchatUserName: "سنابشات",
    telegramUserName: "تليغرام",
    youtubeChannel: "يوتيوب",
    instagram: "إنستغرام",
    youtube: "يوتيوب",
    telegram: "تيليجرام",
    na: "prefer not to say",
    accountType: "Account Type",
    whatsappNumber: "رقم الواتساب",
    bankAccountHolderName: "اسم صاحب البنك",
    comments: "التعليقات",
    addComment: "إضافة تعليق",
    noCommentsForPartner: "هنا لا توجد تعليقات لهذا الشريك",
    facebookUserName: "فاسبوك",
    instagramUserName: "انستاقرام",
    twitterUserName: "تويتر",
    countryOfResidence: "بلد الإقامة",
    nationality: "الجنسية",
    whatsapp: "رقم الواتساب",
    vodafonecash: "Vodafon cash",
    wecash: "We Cash",
    orangecash: "Orange cash",
    etisalatcash: "Etisalate cash",
    alahlyphonecash: "Al Ahly Phone Cash (NBE)",

    VODAFONECASH: "فوادفون كاش",
    ETISALATCASH: "اتصالات كاش",
    WECASH: "وي كاش",
    ORANGECASH: "اورنج كاش",
    ALAHLYPHONECASH: "Al Ahly Phone Cash (NBE)",

    influencer: "Influencer",
    Influencer: "Influencer",
    agency: "Agency",
    page: "Page",
    publisher: "Publisher",
    Page: "Page",
    Publisher: "Publisher",
    bankCity: "City",
    bankBranch: "Branch",
    bankHolderName: "Holder Name",
    "fixed&RevShare": "Fixed & Revenue share",
    affiliateYearsOfExperience: "Publisher years of experience",

    Fixed: "Fixed",
    RevShare: "Revenue share",
    "Fixed&RevShare": "Fixed and Revenue share",

    address: " العنوان",
    companyName: "اسم الشركه",
    adress: "Adress",
    websiteLink: "رابط الموقع",
    action: "الاجراء",
    updatedAt: "Updated At",
    campaignId: "Campaign ID",
    adSetId: "Adset ID",
    websiteLinkMessage: "website link. Ex. https://www.example.com",
    cpsExperienceQuestion: "هل لديك خبرة في آلية عمل كوبون CPS؟",
    yearsAsAffiliate: "سنوات الخبرة كـ ناشر",
    affiliateNetwork: "Other Publisher Networks you work with",
    hasCpsExperience: "لديه خبرة في CPS",
    affiliateNetworks: "الشبكات التابعة",
    skype: "سكايب",
    rateCard: "قائمة الأسعار",
    verificationId: "بطاقة الهوية",
    oldData: "المعلومات القديمة",
    newData: "المعلومات الحديثة",
    signedContract: "العقد",
    website: "الموقع",
    selectWebsite: "Select the website category",
    selectMobile: "Select Mobile App source",
    selectSocial: "Instgram, Facebook, and 7 more…",
    selectMediaBuying: "Select media buying traffic source",
    selectTrafficSource: "Select traffic source",
    rejectionReasonTitle: "سبب الرفض",
    snapchat: "سناب شات",
    campaignReadyPartners: "حملة جاهزة",
    registeredPartners: "تسجيل",
    registeredAt: "سجل في",
    approve: "Approve",
    reject: "Reject",
    skypeAccount: "سكايب",
    advertiserSentLink: "Advertiser sent link",
    arabyadsReference: "Arabyads Reference",
    rate: "Rate",
    partnerType: "Partner Type",
    managementUnit: "Partner Management Unit",
    attachment: "ملحق",
    firstName: "الاسم الاول",
    lastName: "اللقب",
    yearsOfAffiliateExperience: "عدد سنين الخبره في مجال التسويق بالعموله",
    otherAffiliateNetworks: "منصات تسويق بالعموله اخرى عملت معاها",
    URL: "لينك",
    Agency: "مؤسسه",
    influenceType: "نوع المؤثر",
    mobile_app: "تطبيق موبيل",
    media_buying: "Media buying",
    channels: "Channels",
    appName: "App name",

    basicInfo: "معلومات أساسية",
    socialMediaInfo: "معلومات وسائل التواصل الإجتماعي",
    paymentDetails: "بيانات الدفع",
    preferences: "التفضيلات",
    trafficSources: "مصادر الزوار",

    addAppName: "أضف اسم التطبيق",
    selectAppCategory: "الرجاء تحديد فئة التطبيق",
    cashback: "كاش باك",
    priceComparison: "مقارنة الأسعار",
    content: "المحتوى",
    contentEn: "Content (En)",
    contentAr: "Content (Ar)",
    search: "البحث",
    social: "شبكات التواصل الاجتماعي",
    native: "محلي",
    display: "عرض",
    video: "فيديو",
    pinterest: "بينتيريست",
    youTube: "يوتويب",
    linkedIn: "لينكد إن",
    linkedin: "لينكد إن",
    whatsApp: "واتساب",
    selectMobileAppSource: "حدد مصدر تطبيق الهاتف المحمول",
    selectMediaBuyingTrafficSource: "حدد وسيلة شراء إعلانات",
    websiteBoxTitle: "حدد فئة الموقع",
    socialMediaTitle: "حدد إنتسغرام وفيسبوك و أكثر..",
    emailTitle: "حدد البريد الإلكتروني",
    addWebsiteName: "اضف اسم الموقع",
    assetOwners: "Asset owners",
    network: "Network",
    socialAssets: "Social assets",
    loyaltyPrograms: "Boost & Earn",
    organicActivities: "Organic activities",
    mediaBuying: "Media buying",

    nano: "Nano",
    micro: "Micro",
    macro: "Macro",
    mega: "Mega",
    Nano: "Nano",
    Micro: "Micro",
    Macro: "Macro",
    Mega: "Mega",
    emailDataCollectionSource: "Email data collection source",

    individual: "Individual",
    yearsOfExperience: "Years of experience",
    mobileApp: "تطبيق موبيل",
    andOthers: "واخرون",
    seeAll: "اظهار الكل",

    shortDescriptionForInfluencer: "قد قمت ببناء قاعدة من المتابعين على المنصات الاجتماعية ويمكنك التأثير على اختيارات متابعيك.",
    shortDescriptionForPublisher: `أنت تمتلك موقع إلكتروني أو تطبيق أو تدير صفحة اجتماعية
     أو تقوم بالتسويق الرقمي ويمكنك الترويج للمنتجات أو الخدمات لجذب العملاء.`,

    //  app labelsc
    getApp: "تنزيل التطبيق",
    downloadApp: "قم بتنزيل تطبيق {appName}",
    downloadAppBtn: "تنزيل التطبيق",
    continueInBrowser: "واصل إستخدام المتصفح",
    downloadAppMessage: "قم بمسح الكود لتنزيل تطبيق {appName}",

    // notifications
    allNotifications: "كل الإشعارات",
    couponRequests: "طلبات الكوبونات",
    couponRequestsCount: "طلبات الكوبونات",

    requestCouponTitle: "طلب كوبونات",
    howManyCouponsYouWant: "كم عدد الكوبونات التي تريدها؟",
    notifSettingsDescription: "قم بتشغيل الإشعارات أو إيقاف تشغيلها بناءً على تفضيلاتك الخاصة",
    couponCodeAllocation: "تخصيص رمز القسيمة",
    couponCodeExpiration: "انتهاء صلاحية رمز القسيمة",
    couponRequestRejection: "رفض طلب القسيمة",
    campaignAllocationRejection: "رفض طلب الحملة",
    campaignStatusUpdate: "تحديث حالة الحملة (متوقفة مؤقتًا / متوقفة / مستأنفة)",
    campaignApplicationApproval: "الموافقة على طلب الحملة",
    PUBLIC_VISIBLE_WITH_MANUAL_APPROVAL: "Needs Admin Approval",
    PUBLIC_VISIBLE: "Public",
    PRIVATE: "Private",
    versionHistory: "تاريخ النسخ",
    whatsNew: "ما هو الجديد؟",
    boostinyWebVersion: "نسخة ويب لبوستيني",
    revgateWebVersion: "نسخة ويب لريفجيت",

    approvalConfirmation: "Approval Confirmation",

    only: "فقط",
    allocatedCouponsLabel: "الكوبونات المخصصة",
    numRequestCoupons: "عدد الكوبونات المطلوبة",

    // versions
    releaseDate: "Release Date",
    features: "Features",
    enhancements: "Enhancements",
    bugFixes: "Bug Fixes",
    more: "more",
    less: "less",

    createdBy: "Added by",
    nonePerformingCode: "Non - Performing Codes",
    whatIsNew: "What's New",
    latestVersionMessage: "We have updated you  to the latest version <b>of {projectName}</b>",

    payoutTypes: "Payout types",
    check: "Check",

    lastPayment: "آخر دفعة",
    inProgress: "قيد التقدم",
    upcomingPayment: "الدفعة القادمة",
    // announcements

    announcementsType: "Announcements Type",
    promotions: "عروض",
    insights: "أفكار",
    updates: "التحديثات",
    both: "Both",
    subject: "Subject",
    subjectEn: "Subject(En)",
    subjectAr: "Subject(العربية)",
    announcementLinkEn: "Content (Announcement Link) (En)",
    announcementLinkAr: "Content (Announcement Link) (Ar)",
    allPartners: "All Partners",
    partnersExcept: "Partners except",
    specificPartners: "Specific partners",
    thereIsNoAnnouncements: "There is no announcements",
    bannerHeight: "Banner Height",
    bannerMaxSize: "Banner Max Size",
    tillToday: "حتى اليوم",
    validatedTill: "Validated from March 2021 till",
    compareDates: `مقارنه اخر <span class="text-secondary-500 p-base-bold">{comparingDate} ايام</span> بي
     <span class="text-secondary-500 p-base-bold">{from} -{to}</span>`,
    sendAt: "Send At",
    sentAt: "Sent At",
    sent: "Sent Immediately",

    adset: "Adset",
    countries: "البلاد",
    comparing: "مقارنه",
    allCountries: "كل البلدان",
    profile: "الملف الشخصي",
    uploadPartnersFile: "تحميل ملف",
    searchPartners: "بحث",
    member: "شريك",
    notMember: "ليس شريك",

    revenueTypes: "Revenue Types",
    "revenue-payout": "Payout & Revenue",
    expiringSoon: "Expiring soon",
    expiresIn: "ينتهي بعد",
    expired: "منتهيه",

    newStartDate: "New Start Date",
    newEndDate: "New End Date",
    newValue: "New Value",
    newMaxValue: "New Max Value",
    paymentCycles: "دورات الدفع",
    orderID: "Order ID",

    campaignIntegration: "Campaign Integration",
    dateTime: "تاريخ & الوقت",
    closedBy: "أغلقت بواسطة",

    addNewValueForPayouts: "Add new value for this missing payout period",
    addNewValueForRevenues: "Add new value for this missing revenue period",
    validationComment: "Validation Comment",
    paymentCycle: "Payment Cycle",

    campaignsSummary: "Summary",
    bus: "Business Units",
    team_leaders: "Team Leaders",
    team_members: "Account managers",
    "not-Validated": "not validated",
    "financial-pending": "Financial pending",
    "not-provided": "Not provided",
    "management-approved": "CEO approved",
    expiresToday: "Expires today",
    URLName: "URL Name",
    campaignCode: "Code",
    salesManagerContact: "Sales Manager Contact",
    estimatedCancellationRate: "Estimated Cancellation Rate %",
    partnerPayout: "Partner payout",
    campaignTeam: "المستخدمين",
    accountNumber: "رقم الحساب",
    currency: "Currency",
    yet_to_share: "بعد للمشاركة",
    signed_sealed: "موقعة ومختومة",
    signed: "موقعة",
    viewed: "شوهد",
    agreementEsign: "التوقيع الإلكتروني للاتفاقية",
    initiated: "بدأت",
    declined: "مرفوضة",
    approved_no_agreement: "تمت الموافقة بدون اتفاق",
    agreementAvailable: "الاتفاق متاح",
    cancellationAgreement: "توقيع اتفاقية الإلغاء",
    agreement: "الاتفاق",
    changeTrafficSource: "Change Traffic Source",
    agreementUnavailable: "Agreement Unavailable",
    agreementUploaded: "Agreement Uploaded",
    salesManager: "Sales Manager",
    finance: "Finance",
    send: "Send",
    userPMU: "وحدة إدارة الشريك",
    role: "الوظيفة",
    "pending-cgm": "Pending CGM",

    lastUpdated: "التحديث الاخير",
    dailyPerformanceSummary: "Daily Performance Summary",
    joined: "Joined",
    an: "Publisher",
    inf: "Influencer",
    dateRange: "Date Range",
    chooseDate: "Choose Date",
    sendInvitation: "Invite",
    approvedAt: "Approved at",
    requested: "Requested",
    confirm: "Confirm",
    nameDetails: "Name/ID/Email/Phone",
    partnersNameDetails: "Partners Name/ID/Email",
    boostiny: "Boostiny",

    // dashboard
    performing: "Performing",
    nonPerforming: "Non-Performing",
    rating: "ratings",
    validated: "Validated",
    canceled: "Cancelled",
    validationCycle: "Validation cycle",
    cancellationRate: "Cancellation rate",
    approvalRate: "Approval rate",
    "not-validated": "Yet to approve",

    advertisers: "Advertisers",
    confirmation: "Confirmation",
    paypalAccount: "Paypal account",
    accountName: "Account name",
    showProfileWithUpdates: "Show profile with updates",
    from: "from",
    present: "Present",
    on: "on",
    miscellaneous: "متنوع",
    suspend: "Suspend",
    reasonForSuspend: "Reason For Suspend",
    intimatePartnerViaMail: "Intimate {partnerName} via mail",
    revertSuspension: "Revert Suspension",
    suspended: "Suspended",
    deleted: "Deleted",
    online: "Online",
    sorryYourAccountIsCurrentlySuspended: "Sorry,Your account is currently suspended!",
    maxValueUSD: "Max Value (USD)",
    maxValue: "Max Value",
    clientContact: "Client Contact",
    haveNetValues: "Have Net Values",
    clientContacts: "Client Contacts",
    chooseTheClientContact: "Choose the Client contact",
    newClientContact: "New Client Contact",
    mbCost: "MB Cost",
    "<< Unallocated - MB >>": "Unallocated",
    google: "Google",
    aaAssociate: "AA Associate",
    notAaAssociateMember: "Not AA Associate member",
    aaAssociatedMember: "AA Associated member",
    reasonForSuspension: "Reason for suspension",
    pointToContact: "Point To Contact",
    infCampaignManagerId: "INF Campaign Manager",
    anCampaignManagerId: "AN Campaign Manager",
    myConversions: "My Conversions",
    alreadyApplied: "Already Applied",
    viewMore: "View more",
    hometown: "City",
    match: "Match",
    salesManagers: "Sales Managers",
    apiConfigurations: "API Configurations",
    generatesApiAccessMsg: "This API key generates access to your campaigns, coupons and reports",
    learnMoreAbout: "Learn more about",
    boostinyApiDocumentation: "Boostiny API documentation",
    generateYourApiNow: "Generate your API now!",
    revokeApi: "Revoke API",
    copy: "Copy",
    activeCampaignCouponEndpointUrl: "Active campaigns & coupons endpoint URL",
    activeCampaignEndpointUrl: "Active campaigns endpoint URL",
    activeCouponEndpointUrl: "Active coupons endpoint URL",
    reportEndpointUrl: "Reports endpoint URL",
    linkReportEndpointUrl: "Link reports endpoint URL",
    apiDetails: "API details",
    apiKey: "API Key",
    revokeMsg: "Revoke API will remove API access!",

    sendMessage: "Send Message",
    deleteUser: "Delete user",
    deletedUsers: "Deleted users",
    deletedBy: "Deleted By",
    deletedAt: "Deleted At",
    id: "ID",
    archivedCoupons: "Archived Coupons",
    archivedAt: "Archived At",
    archivedBy: "Archived By",
    archive: "Archive",
    archived: "Archived",
    allocate: "Allocate",
    ignore: "Ignore",
    add: "Add",
    updateAdset: "Update Adset",
    archiveCoupon: "Archived Coupons",
    updateCountry: "Update Country",
    selectCountry: "Select Country",
    selectUnselectAll: "Select / Un-Select All {field}",
    couponType: "Coupon Type",
    validationSheet: "Validation Sheet",
    downloadSampleFile: "Sample File",
    downloadSampleFileCategoryLevel: "Sample File Category Level",
    downloadListsOfReference: "Download lists of reference",
    file: "File",
    deleteSheet: "Delete Sheet",
    uploaded: "Uploaded",
    pleaseFixThisIssueInTheFileAndUploadItAgain: "Please fix this issue in the file and upload it again",
    uploadValidationSheet: "Upload Validation Sheet",
    beforeValidationValue: "Before validation value",
    afterValidationValue: "After validation value",
    productStats: "Product Stats",
    promotionName: "Promotion Name",
    totalCampaigns: "Total Campaigns",
    campaignApplicationsStats: "Campaign Applications",
    totalCampaignApplications: "Total Campaign Applications",
    couponsRequests: "Coupons Requests",
    totalCouponsRequests: "Total Coupons Requests",
    announcements: "Announcements",
    campaignPromotions: "Promotions",
    customActions: "Actions",
    offerTypes: "Offer Types",
    createNewPromotion: "Create New Promotion",
    promotionInfo: "Promotion Info",
    noData: "No data",
    uploadCreatives: "تحميل تصميمات",
    uploadCreative: "Upload Creative",
    changeCreative: "Change Creative",
    noPromotions: "No Promotions",
    uploadMore: "Upload More",
    imagesMaxSize: "Images Max Size",
    videosMaxSize: "Videos Max Size",
    supportedFormats: "Supported Formats",
    promotionDetails: "Promotion Details",
    addNewPromotion: "Add New Promotion",
    categoriesList: "Categories",
    addCountry: "Add Country",
    addCategory: "Add Category",
    categorySet: "Category Set",
    specific: "Specific",
    vatRegistered: "VAT Registered",
    isVatRegistered: "VAT Registered",
    vatDocument: "VAT Document",
    allProductsOrCategories: "All Products/Categories",
    specificProductsOrCategories: "Specific Products/Categories",
    flat: "Flat",
    upTo: "Up To",
    range: "Range",
    delayed: "مؤجلة",
    addOffer: "Add Offer",
    promotion: "Promotion",
    proofOfPayment: "Proof Of Payment",
    noDataToDisplay: "No data to display :(",
    deletePromotion: "Delete Promotion",
    deleteAccount: "Delete Account",
    reason: "Reason",
    optional: "Optional",
    required: "مطلوب",
    otp: "OTP",
    sendAnnouncement: "Send the announcement right now",
    chooseAnnouncementDate: "Choose date & time for your announcement to be published",
    chooseDateAndTime: "Choose Date and Time",
    cairoTimeZone: "(Cairo time zone)",
    selectDateAndTime: "Select Date and Time",
    cairoZoneEquivalentTo: "Current time zone in Cairo is equivalent to",
    uae: "UAE",
    sau: "SAU",

    verificationID: "Verification ID",
    accountDeletionPending: "Account Deletion Pending",
    yourAccountIsUnderDeletionProcess: "your account is currently undergoing a deletion process!",
    requestedForAccountDeletion: "Requested for Account deletion",
    deleteRequest: "Delete Request",
    thisAccoutIsRequestingForDeletion: "This accout is requesting for deletion",
    didntGetCode: "Didn't get a code?",
    unableToResendFor30Seconds: "Unable to resend new otp For 30 seconds",
    deleteMyAccount: "Delete my account",
    editPromotion: "Edit Promotion",
    uploadedOffline: "Uploaded Offline",
    phoneNumberCountryCode: "Phone Number Country Code",

    entity: "الجهة",
    selectedMacros: "Selected Macros",
    entityId: "Entity Id",
    entities: "Entities",
    users: "Users",
    userId: "User Id",
    logUserName: "User Name",
    batch: "Batch",

    logDetails: "Log Details",
    shareReferral: "شارك رمز الإحالة الخاص بك واكسب",
    sendCode: "أرسل الرمز",
    register: " يسجلون",
    earn: "أنت تكسب",
    referralsMakeConversation: "عندما تقوم إحالاتك بإجراء تحويلهم الأول",
    iconnect: "iConnect",
    iConnect: "iConnect",
    viewProfile: "عرض الحساب",
    resetPassword: "Reset Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    newPasswordConfirmation: "Confirm New Password",
    passwords: "Passwords",
    upload: "Upload",
    download: "تحميل",
    showDetails: "Show details",
    currenciesReference: "Currencies Reference",
    countriesReference: "Countries Reference",
    conversion: "تحويل",
    capPoints: "Cap points",
    referralsNumber: '<p class="text-primary-500 p-base-medium  m-r-6">{referralNumber} Referrals</p>',
    registred: "مسجل",
    referalPrize: "جائزة الإحالة",
    registredReason: "لم يجر الناشر تحويله الأول حتى الآن",
    rejectedReason: "رفض الناشر لعدم توافقه مع برنامج Boostiny Publisher",
    getPrize: `<p class="text-info-700">
    ستحصل على <span class="p-base-bold">{reward}</span>
     دولارات عندما يقوم الناشر المدعو بإجراء معاملته الأولى، يُرجى الملاحظة ايضًا أن تحويلات الإحالة قد تستغرق ما يصل إلى 24 ساعة لتنعكس في النظام.
    </p>
    `,
    partnerCode: "Partner Code",
    publishersPerformance: "Publishers Performance",
    redeemHistory: "Redeem History",
    prize: "الجائزة",
    rewardType: "Reward Type",
    physicalReward: "Physical Reward",
    extraPayment: "Extra Payment",
    delivered: "delivered",
    redeemed: "redeemed",
    havePrize: "Have Prize",
    none: "None",
    redeem: "Redeem",
    currentPrize: "Current Prize",
    redeemConfirmation: "Redeem Confirmation",
    remainingDays: "days remaining",
    conversionReward: "<p class='p-small-medium'>{conversionFactor} conversion = {rewardPoints} points </p>",
    referred: "مشار إليه",
    redeemedPoints: "النقاط المستردة:",
    loyaltyProgramDesc: "المزيد من التحويلات والمزيد من النقاط والمزيد من الجوائز",
    collectPoints: "اكسب النقاط",
    collectPointsDesc: "كسب نقاط من خلال إجراء تحويلات على الحملات ،",
    upgradeTiers: "ترقية المستويات",
    upgradeTiersDesc: "مع المزيد من النقاط ، تأتي المستويات الأعلى بمكافآت أكبر",
    winPrizes: "اكسب مكافأة",
    winPrizesDesc: "حصل على مكافآت بأموال وهدايا إضافية",
    points: "نقاط",
    reached: "وصل",
    remainingPoints: "النقاط المتبقية",
    nextTier: "المستوى التالي",
    1: "st",
    2: "nd",
    3: "rd",
    th: "th",
    whatsappNumberCountryCode: "whatsapp number country code",
    accessedAt: "Accessed At",
    accessedBy: "Accessed By",
    provideCampaign: "Provide Campaign access to",
    partnerNameOrId: "Partner Name or Id",

    trackingLink: "Tracking Link",
    originalTrackingLink: "Original Tracking Link",
    longLink: "Long link",
    customizedTrackingLinks: "Customized Tracking Links",
    link: "Link",
    publisherSource: "Publisher Source",
    publisherClickId: "Publisher Click ID",
    postback: "Server Postback",
    pixel: "Pixel Tracking",
    attributionWindow: "Attribution Window",
    cookieLifeTime: "Cookie Life Time",
    offerUrl: "Offer Url",
    boostinyMacrosDescription: "Boostiny macros description",
    boostinyMacro: "Boostiny Macros",
    selectMacro: "Select Macro",
    campaignModel: "Campaign Type",
    clicks: "Clicks",
    urlLog: "URL log",
    hasUpdatedUrl: "has updated the URL.",
    campaignType: "نوع الحملة",
    group: "Group",
    perSalesAmount: "Per Sales Amount",
    commissionType: "Commission Type",
    cap: "Cap",
    addDimension: "Add Dimension",
    "customer-type": "User",
    "less-than": "Less than",
    "greater-than": "Greater than",
    between: "Between",
    new: "New",
    returning: "Returning",
    contains: "Contains",
    not: "Not",
    equals: "Equals",
    user: "User",
    localCurrency: "Local Currency",
    commissionGroups: "Commission Groups",
    groups: "مجموعات",
    viewDetails: "عرض التفاصيل",
    moreGroup: "More Group | More Groups",
    usd: "usd ($)",
    AOV: "AOV",
    noOfRecords: "No. of records",
    in: "In",
    extrasAndDeductionsFiles: "Extras and Deductions Files",
    undo: "Undo",
    contain: "Contain",
    "not-equals": "Not Equals",
    "not-contains": "Not Contains",
    cps: "CPS",
    cpc: "CPC",
    termsConditions: "Terms and conditions",
    totalReferralsNumber: "Referrals",
    registered: "Registered",
    referralPrizeReceived: "You have received your prize check the notifications",
    "AA-LABS": "AA LABS",
    "aa-labs": "AA LABS",
    searchMessages: "Search Messages",
    assignBu: "Business Unit",
    assignTeamLeader: "Team Leader",
    assignTeamMember: "Team Member",
    assignPartner: "Partner",
    assignTrafficSource: "Traffic Source",
    tipDate: "التاريخ",
    // black friday
    yesterdayPerformance: "أداء الأمس",
    monthToDatePerformance: "أداء الشهر حتى تاريخ اليوم",
    hurryUp: "Hurry up",
    makeMoreConversions: "make more conversions",

    TLTMName: "TL/TM Name",
    TMName: "TM Name",
    updatedLink: "Updated Link",
    selectMacros: "Select Macros",
    macros: "Macros",
    reset: "Reset",
    addAsMacro: "Add as macro",
    notMacro: "Not a macro",
    sub1: "Sub ID 1",
    sub2: "Sub ID 2",
    sub3: "Sub ID 3",
    sub4: "Sub ID 4",
    sub5: "Sub ID 5",
    customizedLinksHistory: "Customized Links history will show here",
    updatedTrackingLink: "Updated tracking link",
    userSource: "User Source",
    clickToCopy: "انقر للنسخ!",
    copied: "تم النسخ!",
    clickAnalysis: "Click analysis",
    clicksVsConversions: "Clicks vs. Conversions history",
    clicksCount: "Clicks",
    mbTeam: "MB Team",
    clickId: "Click ID",
    userName: "Partners/MB Team",
    partnerAndTeam: "Partners/MB Team",
    linkStatsMsg: "Link stats over the selected time.",
    clickConversionRate: "CCR",
    earningPerClick: "EPC",

    conversionLinkDocument: "Conversion link document",
    clickIds: "Click IDs",
    NA: "NA",
    selectAnotherCampaignType: "another campaign type",
    wefoundA: "We found a",
    missingPeriod: "Missing Period",
    restOfmissingMessage: "that has no revenue or payout. This time period will be missing if you click Continue.",
    asANewCategory: "as a new category",
    pleaseEnterValue: "Please Enter Value",
    noConditions: "No conditions",
    titleEn: "العنوان بالإنجلزية",
    titleAr: "العنوان بالعربية",
    descriptionEn: "الوصف بالإنجلزية",
    descriptionAr: "الوصف بالعربية",
    activeCoupons: "الكوبونات النشطة",
    undoValidation: "Undo Validation",
    customizeLink: "Customize Link",
    title: "Title",
    url: "URL",
    backofficeUsers: "Backoffice Users",
    agencyUsers: "Agency Users",
    deepLink: "Deep Link",
    linksList: "Links List",
    defaultDeepLinkURL: "Default Deeplink URL",
    en: "English",
    ar: "Arabic",
    agencyName: "Agency Name",
    websiteURL: "Website URL",
    lastYearRevenue: "Last year revenue USD",
    ready: "Ready",
    agencyAdmin: "Admin",
    agencyUser: "Campaign Manager",
    "agency-campaign-manager": "Campaign Manager",
    "agency-account-manager": "Account Manager",
    "agency-admin": "Agency Admin",
    approvalData: "Approval Info",
    year: "عام",
    quarter: "ربع عام",
    youCanSelect: "يمكنك اختيار",
    q1JanMar: "الربع الأول",
    q2AprJun: "الربع الثاني",
    q3JulSep: "الربع الثالت",
    q4OctDec: "الربع الرابع",
    boostAndEarnStayTuned: "Boost & Earn will return again! Stay Tuned for more rewards soon!",
    week: "أسبوع",
    day: "يوم",
    campaignSource: "Campaign Source",
    backoffice: "Backoffice",
    campaign_source: "Campaign Source",
    businessUnit: "Business Unit",
    source: "Source",
    teamLeaderId: "Team Leader ID",
    teamMemberId: "Team Member ID",
    campaignCouponId: "Campaign Coupon ID",
    nonPerformingCoupons: "Non Performing Coupons",
    requestCouponsSuccessMessage: "Coupons request sent successfully",
    accountManager: "Account Manager",
    campaignManger: "Campaign Manger",
    agencyCampaignManager: "Campaign Manager",
    agencyAccountManager: "Account Manager",
    publisherSupport: "Publisher Support",
    APIConfiguration: "API Configuration",
    accountSettings: "إعدادات الحساب",
    generateAPIkey: "Generate API key",
    deleteAgreement: "Delete Agreement",
    revgate: "Revgate",
    restore: "Restore",
    capValue: "CAP value",
    currentCap: "Current CAP",
    futureCap: "Future CAP",
    showCapLog: "Show CAP log",
    capHistory: "CAP history",
    systemCurrency: "System Currency",
    postBackTracking: "Postback Tracking",
    requestsError: "Requests Error",
    back: "Back",
    brands: "Brands",
    brand: "Brand",
    brandId: "Brand",
    targetedCountries: "Targeted Countries",
    allowedCountries: "Allowed Countries",
    blockedCountries: "Blocked Countries",
    addNewURL: "Add New URL",
    editURL: "Edit URL",
    requiredMacro: "Required Macro",
    optionalMacro: "Optional Macro",
    allCampaigns: "All Campaigns",
    sea: "SEA",
    heads: "Heads",
    reportsTo: "Reports To",
    "customer-returning-days": "User Returning Days",
    "action-type": "Action Type",
    myNumbers: "أرقامي",
    maximum: "Maximum",
    paymentValidation: "Payment Validation",
    resolved: "Resolved",
    resolve: "Resolve",
    unResolve: "Un Resolve",
    delete: "Delete",
    vatUnregistered: "Vat Unregistered",
    close: "Close",
    "bank-transfer": "Bank Transfer",
    "mobile-cash": "Mobile Cash",
    cashId: "Cash Id",
    cash: "Cash",
    notAvailable: "Not Available",
    pendingVatUpload: "Pending VAT invoice",
    accepted: "Accepted",
    invoice: "Invoice",
    validationPending: "Validation Pending",
    financeApproved: "Finance Approved",
    processing: "قيد التنفيذ",
    rejectedByBank: "مرفوضة من قبل البنك",
    bounced: "Bounced",
    CEOApproved: "CEO Approved",
    estimated: "Estimated",
    partnerStatus: "Partner Status",
    paymentStatus: "Payment Status",
    vatStatus: "Vat Status",
    bouncedInvoices: "Bounced Invoices",
    unresolvedComments: "Unresolved Comments",
    viewLog: "View activity log",
    invoiceIssueDate: "Issue date",
    delay: "Delay",
    validationApproved: "Validation Approved",
    delayAndHide: "Delay and hide",
    approveAsFinance: "Approve as finance",
    approveAsManagement: "Approve as CEO",
    viewRejectionReason: "View rejection reason",
    "delayed-and-hidden": "approve & delay",
    "finance-approved": "finance approved",
    ApproveAndDelay: "Approve & Delay",
    day_s: "day(s)",
    left: "left",
    partnerGlobalPayouts: "Partner Global Payouts",
    partnerCommission: "Partner Commission",
    appliedOn: "Applied on",
    percentageOfBoostinyCommission: "Percentage of Boostiny Commission",

    invoiceId: "رقم الفاتورة",
    invoiceDate: "Invoice Date",
    amount: "القيمة",
    pendingVatInvoice: "في انتظار الفاتورة الضريبية",
    pendingAcceptance: "في انتظار الموافقة",
    pendingInvoice: "Pending Invoice",
    underReview: "قيد المراجعة",
    acceptInvoice: "قبول الفاتوره",
    rejectInvoice: "رفض الفاتوره",

    clickToUpload: "اضغط لرفع",
    orDragAndDrop: "أو إدراج الفاتورة",
    pdfFilesOnly: "ملفات PDF فقط",

    myInvoices: "فواتيري",

    vatInvoice: "الفاتورة الضريبية",
    downloadInvoice: "تحميل الفاتوره",
    downloadProofOfPayment: "تحميل اثبات الدفع",

    reasonOfDelay: "سبب التأجيل",
    rejectedByTheBank: "مرفوضة من قبل البنك",

    delayToNextMonthPayment: "اريد تأجيل الفاتوره للشهر المقبل",
    requestReview: "اريد اعادة مراجعة الفاتوره",
    contactSupport: "اريد التواصل مع الدعم الفني",
    other: "اخرى",
    savedFilters: "Saved Filters",
    saveFilters: "Save Filters",
    addFilterName: "Add name to filter",
    saveDateFilter: "Save date period",
    "sales-amount-usd": "Sales amount USD",
    fileSize: "File size",
    imageFileSize: "Image file size",
    gifFileSize: "GIF file size",

    campaignsScreen: "Campaigns Screen",
    campaignsScreenDimensions: "Dimensions 1256px * 280px",
    overviewScreenDimensions: "Dimensions 408px * 225px",
    uploadEnCreative: "Upload En Creative",
    uploadArCreative: "Upload Ar Creative",
    mobileScreen: "Mobile Screen",
    mobileScreenDimensions: "Dimensions 343px * 148px",

    systemNotifications: "System Notifications",
    pushNotifications: "Push Notifications",
    clicksWithConversions: "Display only clicks with conversions",
    referenceFiles: "Reference Files",
    sampleFiles: "Sample Files",
    customCampaignsSampleFiles: "Custom Campaigns Sample Files",
    clickToSelectFile: {
      click: "Click",
      toSelect: "to select file",
    },
    adminName: "Admin name",
    approvedBy: "Approved by",
    noCommentAdded: "No Comment Added",
    noTeamAdded: "No Team Added Yet",
    rejectedBy: "Rejected By",
    log: "Log",
    campaignEntity: "the campaign entity",
    flagged: "Flagged",
    accept: "Accept",
    validate: "Validate",
    flag: "Flag",
    chooseReasonsOfRejection: "Choose reasons of rejection",
    reupload: "Reupload",
    theValidationSheet: "the validation sheet",
    "pending-cg": "Pending CG",
    "pending-bi": "Pending BI",
    legalEntity: "Legal entity",

    paymentId: "Payment ID",
    paymentMonth: "شهر الدفع",
    issuingDate: "تاريخ الإصدار",
    partiallyPaid: "مدفوعة جزئيًا",
    paidAt: "تاريخ الدفع",
    tagRegistrationNumber: "رقم التسجيل الضريبي",
    company: "الشركه",
    copyDetails: "نسخ",
    viewYourInvoice: "مشاهدة الفاتورة",
    draft: "Draft",
    changeToBounced: "Change to bounced",
    payInvoice: "Pay invoice",
    processPayment: "Process payment",
    mixed: "Mixed",
    updatedStatusTo: "updated status to",
    "partially-paid": "مدفوعة جزئيًا",
    viewInvoice: "مشاهدة الفاتورة",
    uploadYourInvoice: "رفع الفاتورة",
    reUpload: "إعادة رفع الفاتورة",
    nextPayment: "Next Payment",
    roleHolder: "Role Holder",
    roleDisplayName: "Role Name",
    fullAccess: "Full Access",
    rolePermissions: "Role Permissions",
    teamManagement: "Team Management",
    numOfHolders: "No. of holders",
    userPermissions: "User Permissions",
    editRole: "Edit Role",
    numOfCoupons: "No of Coupons",
    inPrivate: "inPrivate",
    couponsPending: "Coupons pending",
    order_id: "Order ID",
    urlShortener: "URL shortener",
    generateAndCopy: "Generate & Copy",
    linkCustomization: "Link Customization",
    attribution: "Attribution",
    deepLinkSupported: "Deep Link Supported",
    onlyDeepLinkSupported: "Only Deep Link Supported",
    noCustomizedLinksAvailable: "No Customized Links Available",
    assigned: "Assigned",
    rejectPartner: "Reject Partner",
    releaseNotes: "Release Notes",
    scheduled: "Scheduled",
    releaseNote: "Release Note",
    releaseType: "Release Type",
    releasePublishTime: "Release Publish Time (GMT)",
    releaseVersion: "Release Version",
    major: "Major",
    minor: "Minor",
    patch: "Patch",
    item: "Item",
    mainInfo: "Main Info",
    releaseScope: "Release Scope",
    releaseAnnouncements: "Release Announcements",
    createReleaseNote: "Create Release Note",
    version: "Version",
    publishedAt: "Published At",
    unarchive: "Unarchive",
    localTime: "Local Time",
    selectArchiveDate: "Select Archive Date",
    updateArchivingDate: "Update Archiving Date",
    invoiceStatus: "Invoice Status",
    headId: "head Id",
    migrateUserCampaigns: "Migrate user campaigns",
    changeReportingLine: "Change reporting line",
    migrationStartDate: "Migration start date",
    allocatedCouponsFull: "allocated coupons",
    validationError: "Validation error",
    allocationError: "Allocation error",
    accessError: "Access error",
    allSelectedCampaigns: "All selected campaigns",
    newAccountManager: "New Account manager",
    newTeamLeader: "New Team leader",
    newUser: "New User",
    currentManager: "Current Manager",
    reportingStartDate: "Reporting start date",
    reportingLineChange: "Reporting line change",
    till: "Till",
    allocationSource: "Allocation Source",
    oldUser: "Old user",
    affectedCampaigns: "Affected Campaigns",
    migrateFrom: "Migrate From",
    migrateTo: "Migrate To",
    migrationInfo: "Migration Info",
    migrationCampaigns: "Migration Campaigns",
    reportingLineChangeInfo: "Reporting Line Change Info",
    newManager: "New manager",
    oldManager: "Old manager",
    onlyWithIntegrationErrors: "Show only campaigns with integration error",
    lastEventDateTime: "Last Event Date Time",
    maximumHoursBetweenEvents: "Maximum Hours Between Events",
    LastFileProcessedDate: "Last File Processed Date",
    uploadedAt: "Uploaded At",
    sheet: "Sheet",
    bonuses: "Bonuses",
    numOfFollowers: "No. Followers",
    audienceGender: "Audience Gender",
    scope: "Scope",
    audienceLanguages: "Audience Languages",
    audienceInterests: "Audience Interests",
    socialFilters: "Social Filters",
    audienceAge: "Audience Age",
    sendToFinance: "Send to Finance",
    chooseTemplate: "Choose template",
    emailSent: "Email Sent",
    sendToFinanceOn: "Sent to Finance on",
    futureAllocations: "With Future allocations",
    partnersReadyForApproval: "Partner’s validation ready for approval",
    partnersFailedToBeApproved: "Partner’s validation failed to be approved",
    partnersReadyToChangeToPending: "Partner's validation ready to change to Pending",
    partnerReadyToChangeToPending: "{name}'s validation ready to change to Pending",
    partnerReadyForApproval: "{name}’s validation ready for approval",
    failedToChangeToPending: "Partner's validation failed to change to pending",
    outOf: "out of",
    partnerNameAndId: "Partner name/ID",
    partnersWithPendingInvoices: "Partners with pending invoices",
    partnersWithPendingValidation: "Partners with pending validation",
    cgm: "CGM",
    cgd: "CGD",
    cgmId: "cgm",
    cgTeam: "CG Team",
    CGM: "CGM",
    CGD: "CGD",
    emailNotifications: "Email Notifications",
    xDaysOfZeroConversions: "X-days of zero conversions",
    requiredDimension: "Required Dimension",
    payoneer: "Payoneer",
    payoneerAccount: "Payoneer Account",
    clientPresence: "Client Presence",
    locationCountry: "Client Location",
    global: "Global",
    regional: "Regional",
  },
  campaignValidationSheetLog: {
    uploadedTheValidationSheet: "uploaded the validation sheet",
    acceptedTheValidationSheet: "accepted the validation sheet",
    rejectedTheValidationSheet: "rejected the validation sheet",
    validatedTheValidationSheet: "validated the validation sheet",
    undoTheValidationSheet: "undo the validation sheet",
    flaggedTheValidationSheet: "flagged the validation sheet",
    sentValidationSheetToFinance: "sent the validation sheet to finance",
  },
  comparisonReport: {
    orders: "Orders",
    revenue: "Gross Revenue",
    totalCost: "Gross Cost",
    profit: "Gross Profit",
    profitPercentage: "Gross Profit (%)",
    growthRate: "Growth Rate (%)",
  },
  bankInfo: {
    bankName: "إسم البنك",
    branch: "الفرع",
    accountName: "اسم الحساب",
    iban: "Iban",
    swift: "Swift",
  },
  validationStatus: {
    "not-validated": "Validation in Progress",
    "not-provided": "Not shared yet",
    approved: "Approved",
    pending: "Validation in Progress",
    rejected: "Rejected",
  },
  header: {
    email: "البريد الإلكتروني",
    phone: "رقم الهاتف",
    signOut: "تسجيل الخروج",
  },

  dashboardChart: {
    title: "متابعة الأداء اليومي",
    legends: {
      revenue: "ربح",
      salesAmount: "المبيعات",
      conversion: "تحويلات",
    },
  },
  newCampaigns: {
    title: "حملات جديدة",
    apply: "طلب",
  },

  team: {
    title: "المستخدمين",
    filter: {
      roles: {
        title: "المهام",
      },
      bus: {
        title: "القسم",
      },
    },
  },
};
