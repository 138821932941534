<script>
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      content() {
        return this.notification.content;
      },
      cycle() {
        return this.content.cycle.slice(0, 7);
      },
    },

    methods: {
      setFilters() {
        const filtersData = this.storageFiltersData;

        if (this.$router.currentRoute.name === "payments-and-invoices") {
          this.$store.dispatch("Globals/setFilter", {
            key: "cycle",
            value: this.content.cycle,
          });
          this.$store.dispatch("Globals/setFilter", {
            key: "businessUnit",
            value: this.content.bu.id,
          });
          this.$store.dispatch("Globals/setFilter", {
            key: "vatStatus",
            value: 1,
          });
          this.$store.dispatch("Globals/setFilter", {
            key: "invoiceStatus",
            value: "uploaded",
          });

          return;
        }

        filtersData["payments-and-invoices"] = {
          cycle: this.content.cycle,
          businessUnit: this.content.bu.id,
          vatStatus: 1,
          invoiceStatus: "uploaded",
        };
        this.setLocalStorage("filters", filtersData);
        this.viewPaymentValidations();
      },
    },
  };
</script>
<template>
  <div @click="setFilters">
    <p
      class="p-base-medium text-neutral-400 m-b-9"
      :class="{ 'cursor-pointer': canViewPaymentValidation }"
    >
      <span class="text-neutral-900 m-r-3">{{ content.count }}</span>

      <span v-if="content.bu.name === 'AN'" class="m-r-3">{{
        $t(`notifications.${notification.type}.number-of-publishers`)
      }}</span>
      <span v-else class="m-r-3">{{
        $t(`notifications.${notification.type}.number-of-influencers`)
      }}</span>
      <span class="text-neutral-900 m-r-3">{{ cycle }}.</span>
      <span class="m-r-3">{{
        $t(`notifications.${notification.type}.please-review`)
      }}</span>
    </p>
  </div>
</template>
<style scoped lang='sass'></style>
