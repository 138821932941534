<script>
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      announcement() {
        return this.notification.content.announcement;
      },
      subject() {
        return this.$root.lang === "en"
          ? this.announcement.subject
          : this.announcement.subject_ar;
      },
    },

    methods: {},
  };
</script>
<template>
  <div>
    <p
      class="p-base-medium text-neutral-400 m-b-9"
      :class="{ 'cursor-pointer': canViewAnnouncements }"
      @click="viewAnnouncements"
    >
      <span class="text-neutral-900">{{ subject }}</span>
    </p>
  </div>
</template>
<style scoped lang='sass'></style>
