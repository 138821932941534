export default {
  fetchAdvancedFilters: ({ isAgency }) => `${isAgency ? "agency/" : ""}config/advancedFilterCriteria`,
  //  if (isAgency) return "agency/countries" => to be enabled once the backend prepare the API
  getCountriesLookups: () => "countries",

  exportData: ({
    url,
    token,
    filters,
  }) => `${process.env.VUE_APP_API_URL}v${process.env.VUE_APP_VERSION}/${url}?token=${token}&${filters}`,
}
