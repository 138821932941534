export default {
  errors: {
    somethingWentWrong: "Something went wrong",
    unauthorized: "You are not allowed to access this page",
    notFound: "This page is not found",
    notValid: "Please add a valid {field}",
    existEmail: "{field} is already taken",
    referralCode: "Code doesn't exist or wrong",
    requiredField: "The {field} is required",
    minAndMaxLength: "The {field} has to be {min} minimum and {max} maximum",
    fieldIsRequired: "The Field is required",
    pleaseAddValidPercentage: "please add valid percentage",
    allowedHeight: "Please upload an image with the required height",
    wrongData: "Something wrong with the data please recheck",
    invalidCapValue: "Please enter a valid Cap value",
    invalidNumberOfDays: "Please pick a range of at least 14 days",
    capMustBeBiggerThanCurrent: "The cap must be bigger than the current value ({current})",
    noFileChosen: "please add an CSV file",
    bigFile: "Please select an image less than 1 MB",
    invalidCodes: "codes must be alpha or numbers without any spaces, tabs or commas or valid countries!",
    pageNotFound: "Page Not Found",
    notFoundMessage: "The page you are looking for might have been removed, had it’s name changed or is temporarily unavailable",
    cycleNotValid: "this cycle is not valid",
    noneSelectedForm: "Please fill at least one section",
    fillSection: "Please fill {section} section",
    failedToDownload: "Failed to download",
    verificationIDFailed: "Upload failed. Please contact the Support team",
    mobile_app: "mobile app",
    media_buying: "media buying",
    social: "social",
    website: "website",
    email: "email",
    selectAllSocialInputs: "Please fill all social inputs",
    fillSpecificInput: "Please fill {sectionKey} in {formName} section",
    atLeastOneInput: "please fill at least one input from {sectionKey} section",
    enterValidEmail: "Please enter a valid email",
    enterValidIban: "Please enter a valid Iban",
    selectAtLeastOneTrafficSource: "Please select at least one traffic source",
    fillTheRequiredFields: "please fill the required inputs for the selected traffic sources",
    notMatch: "{field} is not match {secondField}",
    notValidPassword: "{field} should be min 6 characters & contains small, capital letters and numbers",
    addValidCouponRequestNumber: "Please add a number between 1 to 20.",

    //

    maxChar: "{field} should be not greater than {num} characters",
    maxCharLength: "{field} should be not greater than {length} characters",
    notValidExtension: "Allowed extensions ( png, jpg, jpeg )",
    allowedExtensions: "Allowed extensions ( {extensions} )",
    notAllowedBigFiles: "Please select an image less than {size} MB",
    notAllowedBigFilesKilobytes: "Please select an image less than {size} kilobytes",
    notAllowedBigFilesMB: "Please select a file less than {size} MB",

    enterValidURL: "Please enter a valid URL",
    enterValidName: "Please enter a valid URL name",
    addEstimatedCancellationRate: "Please add value between 0.00 and 100",
    validationDays: "Please add value between 0 and 120",
    estimatedCancellationRateRightValue: "Please enter value with 2 decimal numbers",
    invalidArabyAdsMail: "Make sure you login using ArabyAds account, to sign in to Boostiny",
    specificChar: "{field} should be {number} characters",
    notMatchMsg: "Please make sure the 2 {field} are matching",
    dupliactePassword: "Please choose a password you haven't used before",
    decimalsNotAllowed: "Decimals numbers are not allowed",
    actionIsRequired: "This action is required",
    updatedLink: "The Updated Link is required",
    clickIdRequired: `In order to generate the Offer URL, one selected macro should
    be Boostiny Click ID macro. Please map Boostiny Click ID macro to the correct parameter.`,
    duplicateMacrosKeys: "Some of the Macros keys are duplicated",
    urlHasNoParams: "Please add at least one param",
    enterValidParams: "Please enter valid params",
    enterMax50Chars: "Please enter maximum 50 characters",
    nameNotUnique: "Please enter a unique name",
    lessThan: "The {field} should be less than {second}",
    biggerThan: "The {field} should be bigger than {second}",
    campaignCodeNotAllowed: "Spaces, Capital letters and special characters except '-' are not allowed for campaign code.",
    addOfferUrl: "Add at least one offer URL",
    lessThanOrEqual: "The {field} should be less than or equal {value}",
    deepLinkNotSameDefaultDeepLink: "Make sure you only add deeplink URLs that belong to this domain.",
    biggerThanAndEqual: "The {field} should be bigger than and equal {value}",
    requiredMacros: "Please add required macros",
    enterValidDelayReason: "Please enter a valid delay reason",
    enterValidReason: "Please enter a valid reason",
    selectPDFOnly: "Please select PDF file only",
    requiredDate: "Please select a Date",
    selectAtLeastOneFileForScreen: "Please select at least one file for selected screens",
    invalidDimensions: "Please enter a valid dimensions",
    pleaseSelectFile: "Please select a file",
    campaignDoesnotHaveLegalEntity: "This campaign doesn’t have legal entity, you must add the correct legal entity in the campaign first",
    addAtLeastOneFeature: "Please add at least one feature",
    addAtLeastOneFeatureOrEnhancement: "Please add at least one feature or enhancement",
    addAtLeastOneBugFixOrEnhancement: "Please add at least one bug fix or enhancement",
    linkDomainMismatch: "The domain of the link does not match {domain}",
    numberBetween: "Put a number between {from} and {to}",
    enterValidSwift: "Invalid Swift Code, please contact your bank",
    enterValidInstagramUrl: "Please enter valid Instagram account URL",
    enterValidInstagramUsername: "Please enter valid Instagram account username",
  },
}
