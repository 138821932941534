<script>
  import Links from "./Links.vue"

  export default {
    components: { Links },
  }
</script>

<template>
  <div class="desktop-view-sidebar">
    <Links :show-titles="false" />
  </div>
</template>

<style lang="sass">
.desktop-view-sidebar
  height: calc(100vh - 80px)
  position: fixed
  top: 80px
  left: 0
  z-index: 3
  padding-block: 21px
  background-color: var(--base-white)
  padding-inline: 20px
  transition: all .2s ease-in-out
  width: 80px
  border-right: 1px solid var(--neutral-200)

  [dir=rtl] &
    left: auto
    right: 0

  .link
    .title
      display: none

  &:hover
    width: 300px
    box-shadow: 0 24px 24px 12px var(--neutral-300-05)

    .link
      .title
        display: block

</style>
