<script>
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      content() {
        return this.notification.content;
      },
      formattedReportingDate() {
        return new Intl.DateTimeFormat("default", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }).format(new Date(this.content.reporting_date));
      },
    },

    methods: {},
  };
</script>
<template>
  <div>
    <p
      class="p-base-medium text-neutral-400 m-b-9"
    >
      <span>{{ $t(`notifications.${notification.type}.yourReportingLineChangedTo`) }} </span>
      <span class="text-neutral-900">{{ content.manager.name }} </span>
      <span>{{ $t(`notifications.${notification.type}.startedFrom`) }} </span>
      <span class="text-neutral-900">{{ formattedReportingDate }} </span>
    </p>
  </div>
</template>
<style scoped lang='sass'></style>
