<script>
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      campaign() {
        return this.notification.content.campaign;
      },
      agency() {
        return this.notification.content.sender;
      },
    },

    methods: {},
  };
</script>
<template>
  <div>
    <p
      class="p-base-medium text-neutral-400 m-b-9"
      :class="{ 'cursor-pointer': canViewCampaigns }"
      @click="viewCampaignDetails"
    >
      <span class="m-r-3">{{
        $t(`notifications.${notification.type}.${"agency"}`)
      }}</span>
      <span class="text-neutral-900 m-r-3">{{ agency.name }}</span>
      <span>{{ $t(`notifications.${notification.type}.${"created"}`) }}</span>
      <span>
        {{ $t(`notifications.${notification.type}.${"campaign"}`) }}
      </span>
      <span class="text-neutral-900 m-r-3"> {{ campaign.name }}</span>
      <span class="m-r-3">{{
        $t(`notifications.${notification.type}.${"available-on-boostiny"}`)
      }}</span>
    </p>
  </div>
</template>
  <style scoped lang='sass'></style>
