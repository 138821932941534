<script>
  import DotsLoader from "./DotsLoader.vue";

  export default {
    components: {
      DotsLoader,
    },
    props: {},
    data() {
      return {};
    },

    computed: {},

    methods: {},
  };
</script>

<template>
  <div
    class="loader-container d-flex align-items-center justify-content-center"
  >
    <div class="overlay d-flex align-items-center justify-content-center">
      <DotsLoader />
    </div>
  </div>
</template>

<style scoped lang='sass'>
.loader-container
    width: 100%
    height: 100vh
    position: fixed
    top: 0
    bottom: 0
    right: 0
    left: 0
    z-index: 11
.overlay
    position: absolute
    height: 100%
    width: 100%
    top: 0
    left: 0
    background: var(--base-white-03)
    z-index: 2

</style>
