export default [
  {
    path: "/loyalty-program",
    title: "loyaltyProgram",
    redirect: "/loyalty-program/performance",
    name: "loyaltyProgram",
    component: () => import(/* webpackChunkName: "loyaltyProgram" */ "../views/loyalty-program/Index.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "performance",
        name: "loyalty-publishers-performance",
        component: () => import(/* webpackChunkName: "PublisherPerformance" */ "../views/loyalty-program/PublisherPerformance.vue"),
        meta: {
          title: "publishersPerformance",
          entityName: "loyalty-program-publishers-performance",
          filtersScheme: {
            advancedFilters: [],
            bu: "",
            hasPrizes: "",
            sortMetrics: "name",
            sortDirection: "asc",
          },
        },
      },
      {
        path: "redeem-history",
        name: "loyalty-redeem-history",
        component: () => import(/* webpackChunkName: "RedeemHistory" */ "../views/loyalty-program/RedeemHistory.vue"),
        meta: {
          title: "redeemHistory",
          entityName: "loyalty-program-redeem-history",
          requireAuth: true,
          filtersScheme: {
            bu: "",
            rewardType: "",
            advancedFilters: [],
            sortMetrics: "name",
            sortDirection: "asc",
          },
        },
      },
    ],
  },
  {
    path: "/loyalty-program-details",
    redirect: "/dashboard",
    title: "Boost & Earn details",
    name: "loyalty-program-details",
    component: () => import(/* webpackChunkName: "loyaltyProgramDetails" */ "../views/LoyaltyProgramDetails.vue"),
    meta: {
      requireAuth: true,
      entityName: "loyalty-program-details",
    },
  },
]
