const avatarColors = [
  "#CD0B19",
  "#8C54FF",
  "#9C27B0",
  "#F7C137",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#00C1D4",
  "#4CAF50",
  "#D6367E",
  "#FF9800",
  "#097FA4",
];

function getRandomColor() {
  const randomIndex = Math.floor(Math.random() * avatarColors.length);
  return avatarColors[randomIndex];
}

export default getRandomColor
