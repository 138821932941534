import Vue from "vue";
import Vuex from "vuex";

import Loader from "./loader"
import Globals from "./globals"
import ResponseHandling from "./handleResponse"
import Authentication from "./authentication"
import Campaign from "./campaign"
import Team from "./team"
import Notifications from "./notifications"
import Partners from "./partners"
import Chat from "./chat"
import Lookups from "./lookups"
import ReferralAndLoyaltyProgram from "./referral-and-loyaltyProgram"
import Common from "./common/Index"
import Advertisers from "./advertisers"
import Authorization from "./authorization"
import User from "./user"

Vue.use(Vuex);

export default new Vuex.Store({

  modules: {
    Loader,
    Globals,
    Authentication,
    Authorization,
    ResponseHandling,
    Campaign,
    Team,
    Notifications,
    Partners,
    Chat,
    Lookups,
    ReferralAndLoyaltyProgram,
    Common,
    Advertisers,
    User,
  },
});
