<script>
  import Cookies from "js-cookie";
  import { mapGetters } from "vuex";

  export default {
    components: {
      BaseToast: () => import(
        /* webpackChunkName: "BaseToast" */ "@/components/partials/base-toast/Index.vue"
      ),
      AnnouncementPopup: () => import(
        /* webpackChunkName: "AnnouncementPopup" */ "@/components/release-notes/announcement/Index.vue"
      ),
      ReferFriendModal: () => import(
        /* webpackChunkName: "ReferFriendModal" */ "@/components/referral-and-loyalty-program/referral-modal/Index.vue"
      ),
      BaseChatNotifications: () => import(
        /* webpackChunkName: "BaseChatNotifications" */ "@/components/chat/notifications/Index.vue"
      ),
      DailyTips: () => import(
        /* webpackChunkName: "DailyTips" */ "@/components/daily-tips/Index.vue"
      ),
    },
    props: {},
    data() {
      return {
        showAnnouncementPopup: false,
        displayContent: false,
      };
    },

    computed: {
    ...mapGetters("Authorization", [
      "isLoginAsActivated",
      "isPartner",
      "isAgency",
      "hasOnlyMediaBuying",
    ]),

    isLoading() {
      return this.$store.state.Loader.isLoading;
    },

    user() {
      return this.$store.state.User.user;
    },

    showToast() {
      return this.$store.state.ResponseHandling.showToast;
    },

    toastContent() {
      return this.$store.state.ResponseHandling.toastContent;
    },

    announcement() {
      return this.$store.state.Common.announcement;
    },

    hasAnnouncement() {
      return !!Object.keys(this.announcement).length;
    },

    referModal() {
      return this.$store.state.ReferralAndLoyaltyProgram.displayedReferModal;
    },
    },

    watch: {
      hasAnnouncement() {
        if (this.hasAnnouncement) {
          this.showAnnouncementPopup = true;
        }
      },

      $route: {
        handler(newValue) {
          if (Object.keys(newValue.meta).length && !newValue.meta.requireAuth) {
            this.displayContent = true;
          } else {
            this.displayContent = !!Object.keys(this.user).length;
          }
        },
        immediate: true,
      },

      user() {
        /**
         * there is another watcher on the user on the root
         * we need to check and merge both of them if possible
         */
        if (!this.$route.meta.requireAuth) {
          this.displayContent = true;
        } else {
          this.displayContent = !!Object.keys(this.user).length;
        }

        let subscribe;

        if (this.user?.id && !this.isLoginAsActivated) {
          const that = this;
          subscribe = setInterval(() => {
            that.$store.dispatch("Chat/changeFirebaseUserOnlineStatus", true);
          }, 300000);
        } else {
          clearInterval(subscribe);
        }
      },
    },

    created() {
      let params = [];
      if (window.location.search) {
        params = window.location.search.split("&");
      }

      if (params && params.length && params.find((el) => el.includes("token"))) {
        const token = params.find((el) => el.includes("token")).split("=")[1];
        const expirationDate = params
          .find((el) => el.includes("expires"))
          .split("=")[1];

        Cookies.set(process.env.VUE_APP_TOKEN_KEY, token, {
          expires: +expirationDate,
          path: "/",
        });

        this.$router.replace({ name: "overview" });
      }
    },

    mounted() {
      if (this.user?.id && !this.isLoginAsActivated) {
        this.$store.dispatch("Chat/changeFirebaseUserOnlineStatus", true);
      }
    },

    methods: {
      close() {
        this.showAnnouncementPopup = false;
        localStorage.clear();
        localStorage.setItem("lang", this.$i18n.locale);
      },

      view({ cardID }) {
        const content = this.announcement?.content.map((el, index) => {
          if (cardID === index) {
            return {
              ...el,
              clicked: true,
            };
          }

          return el;
        });

        const modifiedAnnouncement = {
        ...this.announcement,
        content,
        };

        this.$store.dispatch("Common/updateAnnouncement", modifiedAnnouncement);
      },
    },
  };
</script>
<template>
  <div class="w-100">
    <Loader v-if="isLoading" />
    <transition name="fadeInDown">
      <base-toast v-if="showToast" :content="toastContent" />
    </transition>

    <BaseChatNotifications v-if="$route.name !== 'chat'" />

    <transition name="fadeUp">
      <AnnouncementPopup
        v-if="
          showAnnouncementPopup &&
            hasAnnouncement &&
            announcement.content.length &&
            !isLoginAsActivated
        "
        :announcement="announcement"
        @cancel="close"
        @view="view"
      />
    </transition>

    <DailyTips />

    <transition name="fadeUp">
      <ReferFriendModal v-if="referModal" />
    </transition>
    <router-view v-if="displayContent" />
  </div>
</template>
<style lang="sass" scoped></style>
