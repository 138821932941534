export default {
  addAdvertiser: ({ isAgency }) => `${isAgency ? "agency/" : ""}advertisers`,
  updateAdvertiser: ({ advertiserId, isAgency }) => `${isAgency ? "agency/" : ""}advertisers/${advertiserId}/update`,
  advertiserPOCs: ({ advertiserId, isAgency }) => `${isAgency ? "agency/" : ""}advertisers/${advertiserId}/point-of-contacts`,
  advertiserDetails: ({ advertiserId, isAgency }) => `${isAgency ? "agency/" : ""}advertisers/${advertiserId}`,
  advertiserCampaigns: ({ isAgency }) => `${isAgency ? "agency/" : ""}campaigns/list`,
  createBrand: ({ isAgency }) => `${isAgency ? "agency/" : ""}brands`,
  getBrands: ({ advertiserId, isAgency }) => `${isAgency ? "agency/" : ""}brands/${advertiserId}`,
  updateBrand: ({ brandId, isAgency }) => `${isAgency ? "agency/" : ""}brands/${brandId}`,
  deleteBrand: ({ brandId, isAgency }) => `${isAgency ? "agency/" : ""}brands/${brandId}`,
}
