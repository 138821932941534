export default {
  extrasAndDeductions: {
    extraOrDeduction: "extra or deduction",
    debitOrCredit: "debit or credit",
    create: "Create {type}",
    edit: "Edit {type}",
    totalDeductionValue: "Total value of deductions",
    deductionCount: "# Deductions",
    extraCount: "# Extras",
    totalExtraValue: "Total value of Extras",
    debitCount: "# Debits note",
    totalDebitValue: "Total value of Debits",
    creditCount: "# Credits note",
    totalCreditValue: "Total value of Credits",

    // finance renaming
    paidToBeCollected: "Paid to be collected",
    debitAndCredit: "Debit note & Credit note",
    debit: "Debit",
    credit: "Credit",
    debitNote: "Debit Note",
    creditNote: "Credit  Note",

  },

  finance: {
    extraCount: "# Debits note",
    totalExtraValue: "Total value of Debits",
    deductionCount: "# Credits note",
    totalDeductionValue: "Total value of Credits",
  },
}
