<script>
  export default {
    props: {
      color: {
        type: String,
        default: "var(--neutral-500)",
      },
    },
  }
</script>

<template>
  <svg
    width="14" height="14"
    viewBox="0 0 14 14" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00066 6.99988H3.66732C3.49051 6.99988 3.32094 7.07012 3.19592 7.19515C3.07089 7.32017 3.00066 7.48974 3.00066
      7.66655C3.00066 7.84336 3.07089 8.01293 3.19592 8.13795C3.32094 8.26298 3.49051 8.33322 3.66732 8.33322H5.00066C5.17747
      8.33322 5.34704 8.26298 5.47206 8.13795C5.59708 8.01293 5.66732 7.84336 5.66732 7.66655C5.66732 7.48974 5.59708 7.32017
      5.47206 7.19515C5.34704 7.07012 5.17747 6.99988 5.00066 6.99988ZM4.33399 5.66655H7.00066C7.17747 5.66655 7.34704 5.59631
      7.47206 5.47129C7.59708 5.34626 7.66732 5.17669 7.66732 4.99988C7.66732 4.82307 7.59708 4.6535 7.47206 4.52848C7.34704
      4.40345 7.17747 4.33322 7.00066 4.33322H4.33399C4.15718 4.33322 3.98761 4.40345 3.86259 4.52848C3.73756 4.6535 3.66732
      4.82307 3.66732 4.99988C3.66732 5.17669 3.73756 5.34626 3.86259 5.47129C3.98761 5.59631 4.15718 5.66655 4.33399 5.66655ZM5.00066
      9.66655H3.66732C3.49051 9.66655 3.32094 9.73679 3.19592 9.86181C3.07089 9.98684 3.00066 10.1564 3.00066 10.3332C3.00066 10.51
      3.07089 10.6796 3.19592 10.8046C3.32094 10.9296 3.49051 10.9999 3.66732 10.9999H5.00066C5.17747 10.9999 5.34704 10.9296 5.47206
      10.8046C5.59708 10.6796 5.66732 10.51 5.66732 10.3332C5.66732 10.1564 5.59708 9.98684 5.47206 9.86181C5.34704 9.73679 5.17747
      9.66655 5.00066 9.66655ZM13.0007 6.99988H11.0007V0.999883C11.0011 0.882409 10.9705 0.766899 10.912 0.665047C10.8535 0.563195
      10.7691 0.478619 10.6673 0.419883C10.566 0.361371 10.451 0.330566 10.334 0.330566C10.217 0.330566 10.102 0.361371 10.0007
      0.419883L8.00066 1.56655L6.00066 0.419883C5.89931 0.361371 5.78435 0.330566 5.66732 0.330566C5.5503 0.330566 5.43534 0.361371
      5.33399 0.419883L3.33399 1.56655L1.33399 0.419883C1.23264 0.361371 1.11768 0.330566 1.00066 0.330566C0.883632 0.330566 0.768669
      0.361371 0.667323 0.419883C0.565587 0.478619 0.481177 0.563195 0.422642 0.665047C0.364106 0.766899 0.333523 0.882409 0.33399
      0.999883V11.6665C0.33399 12.197 0.544703 12.7057 0.919776 13.0808C1.29485 13.4558 1.80356 13.6665 2.33399 13.6665H11.6673C12.1978
      13.6665 12.7065 13.4558 13.0815 13.0808C13.4566 12.7057 13.6673 12.197 13.6673 11.6665V7.66655C13.6673 7.48974 13.5971 7.32017
      13.4721 7.19515C13.347 7.07012 13.1775 6.99988 13.0007 6.99988ZM2.33399 12.3332C2.15718 12.3332 1.98761 12.263 1.86259
      12.138C1.73756 12.0129 1.66732 11.8434 1.66732 11.6665V2.15322L3.00066 2.91322C3.10355 2.96696 3.21791 2.99502 3.33399
      2.99502C3.45007 2.99502 3.56443 2.96696 3.66732 2.91322L5.66732 1.76655L7.66732 2.91322C7.77021 2.96696 7.88457 2.99502 8.00066
      2.99502C8.11674 2.99502 8.2311 2.96696 8.33399 2.91322L9.66732 2.15322V11.6665C9.66913 11.894 9.70971 12.1194 9.78732
      12.3332H2.33399ZM12.334 11.6665C12.334 11.8434 12.2638 12.0129 12.1387 12.138C12.0137 12.263 11.8441 12.3332 11.6673
      12.3332C11.4905 12.3332 11.3209 12.263 11.1959 12.138C11.0709 12.0129 11.0007 11.8434 11.0007 11.6665V8.33322H12.334V11.6665ZM8.04066
      9.77988C8.00349 9.75465 7.96314 9.73448 7.92066 9.71988C7.88096 9.69958 7.83815 9.68606 7.79399 9.67988C7.68689 9.65844 7.57615
      9.66364 7.47153 9.69502C7.36691 9.72641 7.2716 9.78302 7.19399 9.85988C7.07217 9.98764 7.00311 10.1567 7.00066 10.3332C6.99959
      10.4199 7.01545 10.5059 7.04732 10.5865C7.08356 10.6676 7.13314 10.7419 7.19399 10.8065C7.25879 10.8654 7.3332 10.9128 7.41399
      10.9466C7.49379 10.9818 7.58008 11 7.66732 11C7.75457 11 7.84086 10.9818 7.92066 10.9466C8.00145 10.9128 8.07586 10.8654 8.14066
      10.8065C8.20244 10.7443 8.25133 10.6704 8.2845 10.5892C8.31768 10.5079 8.3345 10.421 8.33399 10.3332C8.33153 10.1567 8.26248 9.98764
      8.14066 9.85988C8.10898 9.83121 8.07558 9.8045 8.04066 9.77988ZM8.13399 7.19322C8.05614 7.11741 7.96095 7.06178 7.85669
      7.03116C7.75243 7.00055 7.64228 6.99589 7.5358 7.01758C7.42933 7.03926 7.32977 7.08665 7.2458 7.1556C7.16182 7.22456 7.09598 7.313
      7.05399 7.41322C7.0125 7.51422 6.99644 7.62386 7.00725 7.73251C7.01805 7.84117 7.05538 7.94551 7.11594 8.03636C7.17651 8.12721
      7.25847 8.20179 7.35461 8.25356C7.45075 8.30532 7.55813 8.33267 7.66732 8.33322C7.84413 8.33322 8.0137 8.26298 8.13873
      8.13795C8.26375 8.01293 8.33399 7.84336 8.33399 7.66655C8.33294 7.57943 8.31482 7.49336 8.28066 7.41322C8.24854 7.32999 8.19846
      7.25487 8.13399 7.19322Z" :fill="color"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
