<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="20" height="20"
    viewBox="0 0 20 20" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5499 0H11.8999C11.648 0 11.4063 0.100092 11.2282 0.278214C11.05 0.456336 10.95 0.69808 10.95 0.949998V10.45C10.95 10.7019
      11.05 10.9436 11.2282 11.1218C11.4063 11.2999 11.648 11.4 11.8999 11.4H18.5499C18.8019 11.4 19.0436 11.2999 19.2217 11.1218C19.3998
      10.9436 19.4999 10.7019 19.4999 10.45V0.949998C19.4999 0.69808 19.3998 0.456336 19.2217 0.278214C19.0436 0.100092 18.8019 0 18.5499
      0ZM18 9.9H12.5V1.5H18V9.9Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
    />
    <path
      d="M8.09999 8.55078H1.45C1.19808 8.55078 0.956336 8.65087 0.778214 8.82899C0.600092 9.00712 0.5 9.24886 0.5 9.50078V19.0008C0.5
      19.2527 0.600092 19.4944 0.778214 19.6725C0.956336 19.8507 1.19808 19.9508 1.45 19.9508H8.09999C8.3519 19.9508 8.59365 19.8507
      8.77177 19.6725C8.94989 19.4944 9.04998 19.2527 9.04998 19.0008V9.50078C9.04998 9.24886 8.94989 9.00712 8.77177 8.82899C8.59365
      8.65087 8.3519 8.55078 8.09999 8.55078ZM7.5 18.5H2V10H7.5V18.5Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
    />
    <path
      d="M8.09999 0H1.45C1.19808 0 0.956336 0.100092 0.778214 0.278214C0.600092 0.456336 0.5 0.69808 0.5 0.949998V6.64999C0.5 6.90191
      0.600092 7.14365 0.778214 7.32177C0.956336 7.49989 1.19808 7.59999 1.45 7.59999H8.09999C8.3519 7.59999 8.59365 7.49989 8.77177
      7.32177C8.94989 7.14365 9.04998 6.90191 9.04998 6.64999V0.949998C9.04998 0.69808 8.94989 0.456336 8.77177 0.278214C8.59365
      0.100092 8.3519 0 8.09999 0ZM7.5 6.1H2V1.5H7.5V6.1Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
    />
    <path
      d="M18.5499 12.3496H11.8999C11.648 12.3496 11.4063 12.4497 11.2282 12.6278C11.05 12.8059 10.95 13.0477 10.95 13.2996V18.9996C10.95
      19.2515 11.05 19.4933 11.2282 19.6714C11.4063 19.8495 11.648 19.9496 11.8999 19.9496H18.5499C18.8019 19.9496 19.0436 19.8495 19.2217
      19.6714C19.3998 19.4933 19.4999 19.2515 19.4999 18.9996V13.2996C19.4999 13.0477 19.3998 12.8059 19.2217 12.6278C19.0436 12.4497
      18.8019 12.3496 18.5499 12.3496ZM18 18.5H12.5V13.8H18V18.5Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
