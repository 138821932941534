<script>
  export default {
    name: "ArrowLeftIcon",
    props: {
      color: {
        type: String,
        default: "var(--neutral-500)",
      },
      width: {
        type: String,
        default: "16",
      },
      height: {
        type: String,
        default: "16",
      },
    },
  };
</script>

<template>
  <svg
    class="w-6 h-6"
    :stroke="color"
    viewBox="0 0 24 24"
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M14 5l7 7m0 0l-7 7m7-7H3"
    />
  </svg>
</template>
<style scoped>
</style>
