import moment from "moment";
import API from "@/utils/input-output/api/index";
import formatDate from "@/utils/date/format/index";
import constants from "./constants"

export default {
  namespaced: true,
  state: {
    dailyTips: {
      list: [],
      loading: false,
      showDailyTipsModal: false,
    },

    announcement: {},
  },

  mutations: {
    /* Daily Tips */
    SET_DAILY_TIPS_MODAL: (state, value) => {
      state.dailyTips.showDailyTipsModal = value
    },
    SET_DAILY_TIPS: (state, value) => {
      state.dailyTips.list = value
    },
    SET_DAILY_TIPS_LOADING: (state, value) => {
      state.dailyTips.loading = value
    },
    /* Daily Tips */

    /* Announcement */
    SET_ANNOUNCEMENT: (state, data) => {
      state.announcement = data;
    },
    /* Announcement */
  },

  actions: {
    /* Daily Tips */
    toggleDailyTipsModal: ({ commit, dispatch }, value) => {
      if (!value) {
        dispatch("Loader/cancelRequests", {
          requests: [{ id: "fetchDailyTips" }, { id: "uploadCreatives" }],
        }, { root: true })
      }
      commit("SET_DAILY_TIPS_MODAL", value)
    },

    fetchDailyTips({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_DAILY_TIPS_LOADING", true)
        const currentDate = new Date()
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }
        const from = formatDate({
          date: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
          locale: "fr-CA",
          options,
        })
        const to = formatDate({
          date: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
          locale: "fr-CA",
          options,
        })

        API({
          url: constants.dailyTips(),
          method: "GET",
          requestId: "fetchDailyTips",
          params: { from, to, limit: 50 },
        })
          .then(({ data }) => {
            commit("SET_DAILY_TIPS_LOADING", false)
            commit("SET_DAILY_TIPS", data.payload.data)
            resolve()
          })
          .catch(() => {
            commit("SET_DAILY_TIPS_LOADING", false)
            commit("SET_DAILY_TIPS_MODAL", false)
            reject()
          })
      })
    },

    checkLastVisitDate({ commit, state }) {
      API({
        url: constants.checkLastVisitDate(),
        method: "GET",
        requestId: "checkLastVisitDate",
      })
        .then(({ data }) => {
          const lastVisitedAt = data.payload.data.lastVisitedAt ? moment.utc(data.payload.data.lastVisitedAt).local() : null
          const today = moment()
          const isHavingTipToday = state.dailyTips.list.some((tip) => moment(tip.date).isSame(moment(), "day"))
          if (
            (!lastVisitedAt || lastVisitedAt?.isBefore(today, "day"))
            && state.dailyTips.list.length
            && isHavingTipToday
          ) {
            commit("SET_DAILY_TIPS_MODAL", true)
            API({
              url: constants.checkLastVisitDate(),
              method: "POST",
              requestId: "updateVisitDate",
            })
          }
        })
    },
    /* Daily Tips */

    /* Announcements */
    updateAnnouncement({ commit }, data) {
      commit("SET_ANNOUNCEMENT", data);
    },

    fetchUserAnnouncements({ rootGetters, dispatch }) {
      API({
        url: constants.fetchLatestAnnouncements({ isAgency: rootGetters["Authorization/isAgency"] }),
        method: "GET",
        requestId: "fetchUserAnnouncements",
      })
        .then((response) => {
          if (response.data.payload.data) {
            dispatch("updateAnnouncement", response.data.payload.data)
            dispatch("seenAnnouncements", { id: response.data.payload.data.id });
          } else {
            dispatch("updateAnnouncement", {})
          }
        });
    },

    seenAnnouncements({ rootGetters }, { id }) {
      API({
        url: constants.seenAnnouncements({ id, isAgency: rootGetters["Authorization/isAgency"] }),
        data: {
          announcement_id: id,
        },
        method: "POST",
        requestId: "seenAnnouncements",
      });
    },
    /* Announcements */
  },
}
