<script>
  import { Portal } from "@linusborg/vue-simple-portal";
  import trackResponse from "@/utils/miscellaneous/track-response/index";
  import BaseImage from "@/components/partials/BaseImage.vue";
  import API from "@/utils/input-output/api/index";
  import ActionsContainer from "../../actions/Index.vue";
  import ActionBtn from "../../actions/ActionBtn.vue";
  import RejectApplicationModal from "../modals/reject-application/Index.vue";
  import mixin from "../mixin";
  import constants from "../../../constants";

  export default {
    components: {
      ActionsContainer,
      ActionBtn,
      RejectApplicationModal,
      BaseImage,
      Portal,
    },

    mixins: [mixin],

    data() {
      return {
        status: "",
      };
    },

    computed: {
      isCurrentRouteNotifications() {
        return (
          this.$route.name === "coupon-requests"
          || this.$route.name === "all-notifications"
        );
      },
      campaign() {
        return this.notification.content.campaign;
      },
      sender() {
        return this.notification.content.sender.name;
      },
      partner() {
        return this.notification.content.partner;
      },
      campaignApplicationID() {
        return this.notification.content.campaignApplicationId;
      },
      userPersona() {
        return this.$store.getters["User/userPersona"];
      },
    },

    mounted() {
      this.status = this.notification.status;
    },

    methods: {
      viewDetails() {
        this.setFilters();
      },
      setFilters() {
        const filtersData = this.storageFiltersData;

        if (this.$router.currentRoute.name === "campaign-applications") {
          this.$store.dispatch("Globals/setAdvancedFilters", {
            field: "partner_name",
            key: "textContains",
            requires: ["internal_id"],
            value: this.partner.name,
          });

          this.$store.dispatch("Globals/setFilter", {
            key: "status",
            value: "PENDING",
          });

          return;
        }

        filtersData["campaign-applications"] = {
          [this.campaign.id]: {
            status: "PENDING",
            advancedFilters: [
              {
                field: "partner_name",
                key: "textContains",
                requires: ["internal_id"],
                value: this.partner.name,
              },
            ],
          },
        };
        this.setLocalStorage("filters", filtersData);
        this.viewCampaignDetails();
      },

      actionHandler(action) {
        this[action.toLowerCase()]();
      },

      approve() {
        this.setNotificationAsRead();

        API({
          url: constants.approve({
            id: this.campaignApplicationID,
            persona: this.userPersona,
          }),
          method: "PUT",
          requestId: "applicationRequestApprove",
        }).then(() => {
          trackResponse({
            type: "success",
            msg: this.$t("messages.recordUpdatedSuccessfully"),
          });
          this.actionTaken(this.notification.id).then(() => {
            this.status = "action-taken";
          });
        });
      },

      reject() {
        this.showDialog = true;
        this.$emit("on-popup-state-update", "opened");
      },

      hideApplicationRequestDialog() {
        this.showDialog = false;
        this.$emit("on-popup-state-update", "closed");
      },

      rejectApplication(value) {
        this.isSubmitting = true;
        this.setNotificationAsRead();

        API({
          url: constants.rejectApplication({
            campaignApplicationID: this.campaignApplicationID,
            persona: this.userPersona,
          }),
          params: { reason: value },
          method: "PUT",
          requestId: "rejectApplication",
        }).then(() => {
          trackResponse({
            type: "success",
            msg: this.$t("messages.recordUpdatedSuccessfully"),
          });
          this.hideApplicationRequestDialog();
          this.isSubmitting = false;
          this.actionTaken(this.notification.id).then(() => {
            this.status = "action-taken";
          });
        }).catch(() => {
          this.isSubmitting = false;
        })
      },
    },
  };
</script>
<template>
  <div
    :class="[
      isCurrentRouteNotifications &&
        'd-flex justify-content-between align-items-center',
    ]"
  >
    <portal>
      <transition name="fadeUp">
        <RejectApplicationModal
          v-if="showDialog"
          :is-submitting="isSubmitting"
          @cancel="hideApplicationRequestDialog"
          @confirm="rejectApplication"
        />
      </transition>
    </portal>
    <div class="d-flex justify-content-start align-items-center">
      <div class="img-container m-r-12">
        <BaseImage
          :src="campaign.logo"
          width="40"
          height="40"
          class="radius-full cursor-pointer fitted-img"
          :name="`notification-img-${notification.id}`"
        />
      </div>
      <div class="m-r-15">
        <p
          class="cursor-pointer p-base-medium text-neutral-400 m-b-9"
          @click="setFilters"
        >
          <span class="text-neutral-900 m-r-3">{{ sender }}</span>
          <span class="m-r-3">{{
            $t(`notifications.${notification.type}.${"has-applied"}`)
          }}</span>
          <span class="text-neutral-900 m-r-3">{{ campaign.name }}</span>
          <span>{{
            $t(`notifications.${notification.type}.${"campaign"}`)
          }}</span>
        </p>
      </div>
    </div>
    <ActionsContainer
      v-if="status !== 'action-taken' && !isLoginAsActivated"
      :is-in-dropdown="!isCurrentRouteNotifications"
    >
      <ActionBtn
        v-for="(action, i) in notification.actions"
        :key="`action-${action}-${i}`"
        :action="action"
        :is-small="!isCurrentRouteNotifications"
        @action-handler="actionHandler(action)"
      />
    </ActionsContainer>
  </div>
</template>
<style scoped lang='sass'></style>
