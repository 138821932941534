const themes = {
  white: {
    textClass: "text-neutral-900",
    bgClass: "bg-base-white",
    labelClass: "text-neutral-400",
    borderClass: "border-base-white",
    iconColor: "",
  },

  secondary: {
    textClass: "text-secondary-500",
    bgClass: "bg-base-white",
    labelClass: "text-neutral-900",
    borderClass: "border-secondary-500",
    iconColor: "var(--secondary-500)",
  },

  error: {
    bgClass: "bg-error-50",
    textClass: "text-error-500",
    labelClass: "text-neutral-900",
    borderClass: "border-error-500",
    iconColor: "var(--error-500)",
  },

  neutral: {
    bgClass: "bg-base-white",
    textClass: "text-neutral-900",
    labelClass: "text-neutral-900",
    borderClass: "",
    iconColor: "",
  },

  "light-neutral": {
    bgClass: "bg-base-white",
    textClass: "text-neutral-900",
    labelClass: "text-neutral-900",
    borderClass: "border-neutral-400",
    iconColor: "",
  },
  "dark-neutral": {
    bgClass: "bg-neutral-200",
    textClass: "text-neutral-900",
    labelClass: "text-neutral-900",
    borderClass: "border-neutral-600",
    iconColor: "var(--neutral-900)",
  },
  accent: {
    bgClass: "bg-neutral-100",
    textClass: "text-neutral-900",
    labelClass: "text-neutral-900",
    iconColor: "var(--neutral-400)",
    borderClass: "border-transparent",
  },
}

export default themes
