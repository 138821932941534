<script>
  import i18n from "@/lang";
  import ModelHeader from "./Header.vue";
  import ModelBody from "./Body.vue";
  import ModelFooter from "./Footer.vue";

  export default {
    components: { ModelHeader, ModelBody, ModelFooter },
    props: {
      label: {
        required: false,
        default: "",
        type: String,
      },
      isSubmitting: {
        required: false,
        default: false,
        type: Boolean,
      },
      value: {
        required: false,
        type: String,
        default: "",
      },
    },

    data() {
      return {
        inputValue: "",
        inputError: "",
        disableBtn: false,
      };
    },

    watch: {
      isSubmitting() {
        this.disableBtn = this.isSubmitting;
      },
    },
    mounted() {
      if (this.value) {
        this.inputValue = this.value;
      }
    },

    methods: {
      cancel() {
        this.$emit("cancel");
      },

      confirm() {
        if (!this.inputValue) {
          this.inputError = this.label
            ? i18n.t("errors.requiredField", {
              field: i18n.t(`labels.${this.label}`),
            })
            : i18n.t("errors.fieldIsRequired");
          return;
        }
        this.$emit("confirm", this.inputValue);
        this.disableBtn = true;
      },

      updateValue(newVal) {
        this.inputValue = newVal;
        this.inputError = "";
      },

      reset() {
        this.inputError = "";
      },
    },
  };
</script>

<template>
  <div
    class="
      w-100
      d-flex
      align-items-center
      justify-content-center
      modal-container
    "
  >
    <div
      class="
        bg-base-white
        shadow-1
        radius-8
        p-y-12
        d-flex
        flex-column
        justify-content-between
        modal
      "
    >
      <ModelHeader />

      <ModelBody
        :theme="inputError ? 'error' : 'neutral'"
        :error-message="inputError"
        :label="label"
        :value="inputValue"
        @update-value="updateValue"
        @reset="reset"
        @submit="confirm"
      />

      <ModelFooter
        :disable-btn="disableBtn"
        @cancel="cancel"
        @confirm="confirm"
      />
    </div>
  </div>
</template>

<style scoped lang='sass'>
.modal-container
  width: 100%
  height: 100vh
  position: fixed
  top: 0
  bottom: 0
  right: 0
  left: 0
  z-index: 10
  background: var(--base-white-05)
.modal
  width: 400px
  @media (max-width: 470px)
    width: 300px
</style>
