<script>
  export default {
    props: {
      color: {
        type: String,
        default: "var(--neutral-500)",
      },
    },
  }
</script>

<template>
  <svg
    width="12" height="14"
    viewBox="0 0 12 14" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.666016 6.99992C0.666016 7.17673 0.736254 7.3463 0.861278 7.47132C0.986302 7.59635 1.15587 7.66659 1.33268
      7.66659H6.39268L4.85935 9.19325C4.79686 9.25523 4.74727 9.32896 4.71342 9.4102C4.67958 9.49144 4.66215 9.57858 4.66215
      9.66659C4.66215 9.75459 4.67958 9.84173 4.71342 9.92297C4.74727 10.0042 4.79686 10.0779 4.85935 10.1399C4.92132 10.2024 4.99506
      10.252 5.0763 10.2858C5.15754 10.3197 5.24467 10.3371 5.33268 10.3371C5.42069 10.3371 5.50783 10.3197 5.58907 10.2858C5.67031
      10.252 5.74404 10.2024 5.80602 10.1399L8.47268 7.47325C8.53338 7.40985 8.58095 7.33509 8.61268 7.25325C8.67936 7.09094 8.67936
      6.90889 8.61268 6.74659C8.58095 6.66475 8.53338 6.58999 8.47268 6.52659L5.80602 3.85992C5.74386 3.79776 5.67006 3.74845 5.58885
      3.71481C5.50763 3.68117 5.42059 3.66386 5.33268 3.66386C5.24478 3.66386 5.15773 3.68117 5.07652 3.71481C4.9953 3.74845 4.92151
      3.79776 4.85935 3.85992C4.79719 3.92208 4.74788 3.99587 4.71424 4.07709C4.6806 4.1583 4.66329 4.24535 4.66329 4.33325C4.66329
      4.42116 4.6806 4.5082 4.71424 4.58942C4.74788 4.67063 4.79719 4.74443 4.85935 4.80659L6.39268 6.33325H1.33268C1.15587 6.33325
      0.986302 6.40349 0.861278 6.52851C0.736254 6.65354 0.666016 6.82311 0.666016 6.99992ZM9.33268 0.333252H2.66602C2.13558 0.333252
      1.62687 0.543966 1.2518 0.919038C0.876729 1.29411 0.666016 1.80282 0.666016 2.33325V4.33325C0.666016 4.51006 0.736254 4.67963
      0.861278 4.80466C0.986302 4.92968 1.15587 4.99992 1.33268 4.99992C1.50949 4.99992 1.67906 4.92968 1.80409 4.80466C1.92911 4.67963
      1.99935 4.51006 1.99935 4.33325V2.33325C1.99935 2.15644 2.06959 1.98687 2.19461 1.86185C2.31964 1.73682 2.4892 1.66659 2.66602
      1.66659H9.33268C9.50949 1.66659 9.67906 1.73682 9.80409 1.86185C9.92911 1.98687 9.99935 2.15644 9.99935 2.33325V11.6666C9.99935
      11.8434 9.92911 12.013 9.80409 12.138C9.67906 12.263 9.50949 12.3333 9.33268 12.3333H2.66602C2.4892 12.3333 2.31964 12.263 2.19461
      12.138C2.06959 12.013 1.99935 11.8434 1.99935 11.6666V9.66659C1.99935 9.48977 1.92911 9.32021 1.80409 9.19518C1.67906 9.07016
      1.50949 8.99992 1.33268 8.99992C1.15587 8.99992 0.986302 9.07016 0.861278 9.19518C0.736254 9.32021 0.666016 9.48977 0.666016
      9.66659V11.6666C0.666016 12.197 0.876729 12.7057 1.2518 13.0808C1.62687 13.4559 2.13558 13.6666 2.66602 13.6666H9.33268C9.86311
      13.6666 10.3718 13.4559 10.7469 13.0808C11.122 12.7057 11.3327 12.197 11.3327 11.6666V2.33325C11.3327 1.80282 11.122 1.29411
      10.7469 0.919038C10.3718 0.543966 9.86311 0.333252 9.33268 0.333252Z" :fill="color"
    />
  </svg>
</template>
