import stringToCamelCase from "@/utils/string/string-to-camel-case/index"
import snakeToCamelCase from "@/utils/string/snake-to-camel-case/index"
import API from "@/utils/input-output/api/index"
import constants from "./constants"

export default {
  namespaced: true,
  state: {
    countries: [],
    offerTypes: [],
    categories: [],
    currencies: [],
    macros: [],
    bus: [],
  },

  mutations: {
    SET_COUNTRIES: (state, data) => {
      state.countries = data
    },

    SET_OFFER_TYPES: (state, data) => {
      state.offerTypes = data
    },

    SET_CATEGORIES: (state, data) => {
      state.categories = data
    },

    SET_CURRENCIES: (state, data) => {
      state.currencies = data
    },

    SET_MACROS: (state, data) => {
      state.macros = data
    },

    SET_BUS: (state, data) => {
      state.bus = data
    },
  },

  /**
   * no need for the use of getters here, we are not doing any computation
  */
  getters: {
    macros(state) {
      return state.macros
    },

    BULookups(state, _, rootState) {
      const data = rootState.User.user.bus && rootState.User.user.bus.length
        ? rootState.User.user.bus
        : state.bus;
      return data.map((el) => ({
        ...el,
        value: el.id,
        label: el.name,
      }));
    },
  },

  actions: {
    fetchSystemBUs({ commit }) {
      API({
        url: constants.fetchSystemBUs(),
        method: "get",
        params: {
          limit: 20,
          page: 1,
        },
        requestId: "getSystemBUs",
        initiator: "global",
        requiresAuth: true,
      })
        .then(({ data }) => {
          commit("SET_BUS", data.payload.data)
        })
    },

    fetchCountries({ commit }) {
      API({
        url: constants.fetchCountries(),
        method: "get",
        requestId: "fetchCountries",
        params: {
          limit: 500,
          page: 1,
        },
      }).then(({ data }) => {
        commit("SET_COUNTRIES", data.payload.data.map((el) => ({
          id: el.alpha_3_code,
          name: stringToCamelCase(el.name),
          value: el.alpha_3_code,
        })))
      })
    },

    fetchOfferTypes({ commit }) {
      API({
        url: constants.fetchOfferTypes(),
        method: "get",
        params: { limit: 20, page: 1 },
        requestId: "fetchOfferTypes",
      }).then(({ data }) => {
        commit("SET_OFFER_TYPES", data.payload.data.map((el) => ({
          value: el.type,
          name: snakeToCamelCase(el.type),
        })))
      });
    },

    fetchCategories({ commit }) {
      API({
        url: constants.fetchCategories(),
        method: "get",
        params: { limit: 100, page: 1 },
        requestId: "fetchCategories",
      }).then(({ data }) => {
        commit("SET_CATEGORIES", data.payload.data.map((el) => ({
          value: el.id,
          name: el.name,
        })))
      });
    },

    fetchCurrencies({ commit }) {
      API({
        url: constants.fetchCurrencies(),
        method: "get",
        params: { limit: 500, page: 1 },
        requestId: "fetchCurrencies",
      }).then(({ data }) => {
        commit("SET_CURRENCIES", data.payload.data.map((el) => ({
          value: el.code,
          name: el.name,
        })))
      });
    },

    fetchMacros({ commit, rootGetters }) {
      const isAgency = rootGetters["Authorization/isAgency"]
      API({
        url: constants.fetchMacros({ isAgency }),
        method: "get",
        params: { page: 1, limit: 500 },
        requestId: "fetchMacros",
      }).then(({ data }) => {
        commit("SET_MACROS", data.payload.data?.map((el) => ({
          id: el.id,
          value: el.id,
          name: `{${el.id}}`,
          description: el.description,
        })))
      });
    },
  },
}
