<script>
  import BaseImage from "@/components/partials/BaseImage.vue";

  import mixin from "../mixin";

  export default {
    components: { BaseImage },

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      sender() {
        return this.notification.content.sender;
      },
      campaign() {
        return this.notification.content.campaign;
      },
      conversionCycle() {
        return this.notification.content.cycle;
      },
    },

    methods: {},
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div class="img-container m-r-12">
      <BaseImage
        :src="campaign.logo"
        width="40"
        height="40"
        class="radius-full fitted-img"
      />
    </div>
    <div>
      <p
        class="p-base-medium text-neutral-400 m-b-9"
        :class="{ 'cursor-pointer': canViewCampaignValidation }"
        @click="setValidationsFilters"
      >
        <span class="text-neutral-900 m-r-3">{{ sender.name }}</span>

        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.accepted`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ campaign.name }}</span>
        <span class="text-neutral-900 m-r-3">{{
          formatCycle(conversionCycle)
        }}</span>
        <span>{{ $t(`notifications.${notification.type}.cycle`) }}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
