<script>
  import ModelHeader from "./Header.vue";
  import ModelBody from "./Body.vue";
  import ModelFooter from "./Footer.vue";

  export default {
    components: { ModelHeader, ModelBody, ModelFooter },
    props: {
      partner: {
        type: Object,
        required: true,
      },
      isSubmitting: {
        required: false,
        default: false,
        type: Boolean,
      },
    },

    data() {
      return {
        disableBtn: false,
      };
    },
    computed: {},

    watch: {
      isSubmitting() {
        this.disableBtn = this.isSubmitting;
      },
    },

    methods: {
      cancel() {
        this.$emit("cancel");
      },

      confirm() {
        this.$emit("confirm");
        this.disableBtn = true;
      },
    },
  };
</script>

<template>
  <div
    class="
      w-100
      d-flex
      align-items-center
      justify-content-center
      modal-container
    "
  >
    <div
      class="
        bg-base-white
        shadow-1
        radius-8
        p-y-12
        d-flex
        flex-column
        justify-content-between
        modal
      "
    >
      <ModelHeader />

      <ModelBody
        :partner="partner"
      />

      <ModelFooter
        :disable-btn="disableBtn"
        @cancel="cancel"
        @confirm="confirm"
      />
    </div>
  </div>
</template>

<style scoped lang='sass'>
.modal-container
    width: 100%
    height: 100vh
    position: fixed
    top: 0
    bottom: 0
    right: 0
    left: 0
    z-index: 10
    background: var(--base-white-05)
.modal
    width: 400px
    @media (max-width: 470px)
        width: 300px
</style>
