import Dashboard from "../icons/Dashboard.vue"
import Campaigns from "../icons/Campaigns.vue"
import Partners from "../icons/Partners.vue"
import Advertisers from "../icons/Advertisers.vue"
import Agencies from "../icons/Agencies.vue"
import Reports from "../icons/Reports.vue"
import Validations from "../icons/Validations.vue"
import Payments from "../icons/Payments.vue"
import ExtraAndDeduction from "../icons/ExtraAndDeduction.vue"
import Team from "../icons/Team.vue"
import PartnerCoupons from "../icons/PartnerCoupons.vue"
import PartnersSearch from "../icons/PartnersSearch.vue"
import ActivityLogs from "../icons/ActivityLogs.vue"
import Link from "../icons/Link.vue";

// import CampaignsCommissions from "../icons/CampaignsCommissions.vue"

const routes = [
  {
    name: "dashboard",
    icon: Dashboard,
    route: "overview",
    personas: ["backoffice", "partner", "advertiser", "agency"],
    children: [],
    permissions: [
      "VIEW_OVERVIEW_STATS",
      "VIEW_LINK_STATS",
      "VIEW_TOP_CAMPAIGNS",
      "VIEW_TOP_PARTNERS",
      "VIEW_TOP_COUPONS",
      "VIEW_TOP_TRAFFIC_SOURCES",
      "VIEW_TOP_CAMPAIGNS_CAPS",
      "VIEW_COUPONS_STATS",
      "VIEW_PERFORMANCE_STATS",
      "VIEW_EARNING_STATS",
      "VIEW_RECOMMENDED_CAMPAIGNS",
      "VIEW_NEW_CAMPAIGNS",
      "VIEW_MY_CAMPAIGNS",
      "VIEW_EXTRA_STATS",
      "VIEW_VALIDATION_STATS",
    ],
  },

  {
    name: "campaigns",
    icon: Campaigns,
    route: "campaigns",
    personas: ["backoffice", "partner", "advertiser", "agency"],
    children: [],
    permissions: ["VIEW_CAMPAIGNS"],
  },

  {
    name: "partners",
    icon: Partners,
    route: "partners",
    personas: ["backoffice", "agency"],
    children: ["partner-details"],
    permissions: ["VIEW_PARTNERS"],
  },

  {
    name: "advertisers",
    icon: Advertisers,
    route: "advertisers-list",
    personas: ["backoffice", "agency"],
    children: ["advertiser-campaigns", "advertiser-brands"],
    permissions: ["VIEW_ADVERTISERS"],
  },

  {
    name: "agencies",
    icon: Agencies,
    route: "agencies",
    personas: ["backoffice"],
    children: [],
    permissions: ["VIEW_AGENCIES"],
  },

  {
    name: "partners-search",
    icon: PartnersSearch,
    route: "partners-search",
    personas: ["advertiser"],
    children: [],
    permissions: ["CAN_SEARCH_PARTNERS"],
  },

  {
    name: "partnerCoupons",
    icon: PartnerCoupons,
    route: "partner-coupons",
    personas: ["partner"],
    children: [],
    permissions: ["VIEW_COUPONS"],
  },

  {
    name: "links",
    icon: Link,
    route: "links",
    personas: ["backoffice", "partner"],
    children: [],
    permissions: ["VIEW_LINK_TRACKING"],
  },

  {
    name: "reports",
    icon: Reports,
    route: "reports",
    personas: ["backoffice", "partner", "advertiser", "agency"],
    children: ["performance-report",
      "performance-comparison-report",
      "click-analysis-report", "financial-summary-report",
      "cost-summary-report", "partners-report", "campaigns-report",
      "partner-payment", "product-stats"],
    permissions: [
      "VIEW_PERFORMANCE_REPORT",
      "VIEW_FINANCIAL_SUMMARY_REPORT",
      "VIEW_COST_SUMMARY_REPORT",
      "VIEW_PARTNER_PAYMENT_REPORT",
      "VIEW_CLICK_ANALYSIS_REPORT",
      "VIEW_PARTNER_ANALYSIS_REPORT",
      "VIEW_CAMPAIGN_ANALYSIS_REPORT",
      "VIEW_TOTAL_CAMPAIGNS", // deal with it as a primary permission for the product stats report
    ],
  },
  {
    name: "validations",
    icon: Validations,
    route: "validations",
    personas: ["backoffice"],
    children: ["campaigns-summary", "campaign-validation",
      "partners-validation", "partner-data",
      "payment-cycles-validation", "validation-sheet"],
    permissions: [
      "VIEW_PARTNER_VALIDATION",
      "VIEW_CAMPAIGNS_VALIDATION_SUMMARY",
      "VIEW_CAMPAIGN_VALIDATION",
      "VIEW_CAMPAIGNS_VALIDATION_SHEETS",
      "VIEW_PAYMENT_CYCLES",
    ],
  },
  {
    name: "payments",
    icon: Payments,
    route: "payments",
    personas: ["backoffice"],
    children: ["payments-and-invoices"],
    permissions: ["VIEW_PARTNERS_PAYMENTS"],
  },
  {
    name: "extraAndDeduction",
    icon: ExtraAndDeduction,
    route: "extras-and-deductions",
    personas: ["backoffice"],
    children: ["extra-and-deduction-revenue", "extra-and-deduction-payout"],
    permissions: ["VIEW_EXTRA_AND_DEDUCTION_REVENUES", "VIEW_EXTRA_AND_DEDUCTION_PAYOUTS"],
  },
  {
    name: "team",
    icon: Team,
    route: "team-management",
    personas: ["backoffice", "agency"],
    children: ["team", "user-details", "roles"],
    permissions: ["VIEW_TEAM", "VIEW_ROLES"],
  },
  {
    name: "activityLogs",
    icon: ActivityLogs,
    route: "activity-logs",
    personas: ["backoffice"],
    children: [],
    permissions: ["VIEW_LOGS"],
  },
  // {
  //   name: "campaignsCommissions",
  //   icon: CampaignsCommissions,
  //   route: "campaigns-commissions",
  //   hideFrom: ["head", "team-leader", "team-member", "finance", "sales-manager", "power-user",
  //     "admin", "bi", "advertiser", "poc", "agency-admin", "agency-campaign-manager", "agency-account-manager"],
  //   children: [],
  // },
]

export default {
  routes,
}
