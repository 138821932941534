<script>
  import Banner from "../icons/Banner.vue";

  export default {
    components: { Banner },
  }
</script>

<template>
  <router-link
    :to="{ name: 'banners' }"
    class="base-tooltip cursor-pointer d-flex justify-content-center align-items-center"
    :data-tooltip="$t('titles.banners')"
  >
    <Banner />
  </router-link>
</template>

<style scoped lang="sass">
</style>
