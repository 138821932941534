import trackResponse from "@/utils/miscellaneous/track-response/index";

export default [
  {
    path: "coupons",
    component: () => import(/* webpackChunkName: "CampaignCoupons" */ "@/views/campaigns/details/coupons/Index.vue"),
    children: [
      {
        path: "",
        name: "campaign-coupons",
        query: { type: "", couponRequestsList: "" },
        component: () => import(/* webpackChunkName: "CampaignCouponsList" */ "@/views/campaigns/details/coupons/List.vue"),
        props: true,
        meta: {
          hasFilters: true,
          title: "campaignCoupons",
          entityName: "campaign-coupons",
          showTabs: true,
          filtersScheme: {
            type: "allocated",
            status: "active",
            advancedFilters: [],
            sortMetrics: "code",
            sortDirection: "asc",
            countries: [],
            trafficSources: [],
            buId: "all",
            team: "",
            nonPerformingCoupons: 0,
            recentAllocations: 0,
          },
        },
      },
      {
        path: "request-allocation",
        name: "coupons-request-allocation",
        component:
          () => import(/* webpackChunkName: "CouponsRequestAllocation" */ "@/views/campaigns/details/coupons/RequestAllocation.vue"),
        meta: {
          requireAuth: true,
          hasFilters: true,
          title: "campaignCouponsRequestsAllocation",
          entityName: "campaign-coupons-requests-allocation",
          showTabs: false,
          filtersScheme: {
            advancedFilters: [],
            sortMetrics: "code",
            sortDirection: "asc",
          },
        },
        props: true,
        beforeEnter: (to, from, next) => {
          if (to.params.requestInfo) {
            next()
          } else {
            trackResponse({
              type: "error",
              msg: "The request data is missing please check",
            });
            next({
              name: "campaign-coupons",
              params: {
                id: to.params.id,
              },
            })
          }
        },

      },
      {
        path: "allocation",
        name: "coupons-allocation",
        component: () => import(/* webpackChunkName: "CouponsAllocation" */ "@/views/campaigns/details/coupons/Allocation.vue"),
        meta: {
          requireAuth: true,
          title: "campaignCouponsAllocation",
          entityName: "campaign-coupons-allocation",
          showTabs: false,
        },
      },
      {
        path: ":couponId",
        component: () => import(/* webpackChunkName: "CouponDetails" */ "@/views/campaigns/details/coupons/Details.vue"),
        name: "coupon-details",
        meta: {
          requireAuth: true,
          title: "campaignCouponDetails",
          entityName: "campaign-coupon-details",
          showTabs: false,
        },
      },
    ],
  },
]
