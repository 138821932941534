<script>
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      partner() {
        return this.notification.content.partner;
      },
      sender() {
        return this.notification.content.sender;
      },
    },

    methods: {},
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div>
      <p
        class="m-b-9 p-base-medium text-neutral-400 m-b-9 cursor-pointer"
        @click="viewPartnerDetails"
      >
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"please-note"}`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ sender.name }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"comments-tag"}`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ partner.name }}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
