<script>
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      campaign() {
        return this.notification.content.campaign;
      },
    },

    methods: {},
  };
</script>
<template>
  <div>
    <p
      class="p-base-medium text-neutral-400 m-b-9"
      :class="{ 'cursor-pointer': canViewCampaigns }"
      @click="viewCampaignDetails"
    >
      <span class="m-r-3">{{ $t(`notifications.${notification.type}`) }}</span>
      <span class="text-neutral-900">{{ campaign.name }}</span>
    </p>
  </div>
</template>
  <style scoped lang='sass'></style>
