<script>
  export default {
    props: {
      color: {
        type: String,
        required: false,
        default: "var(--neutral-500)",
      },
    },
  };
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M13.2997 0.710994C13.1129 0.523741 12.8592 0.418508 12.5947 0.418508C12.3302 0.418508
      12.0766 0.523741 11.8897 0.710994L6.99973 5.59099L2.10973 0.700994C1.9229 0.513741
      1.66924 0.408508 1.40473 0.408508C1.14021 0.408508 0.886558 0.513741 0.699727
      0.700994C0.309727 1.09099 0.309727 1.72099 0.699727 2.11099L5.58973 7.00099L0.699727
      11.891C0.309727 12.281 0.309727 12.911 0.699727 13.301C1.08973 13.691 1.71973 13.691
      2.10973 13.301L6.99973 8.41099L11.8897 13.301C12.2797 13.691 12.9097 13.691 13.2997
      13.301C13.6897 12.911 13.6897 12.281 13.2997 11.891L8.40973 7.00099L13.2997
      2.11099C13.6797 1.73099 13.6797 1.09099 13.2997 0.710994Z"
      :fill="color"
    />
  </svg>
</template>
<style scoped lang='sass'>
</style>
