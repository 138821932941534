import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import { trackPageView } from "@/services/TrackingManagement"
import store from "../store";
import isTokenValid from "../utils/validation/is-token-valid/index";
import authentication from "./authentication";
import shallowRoutes from "./shallowRoutes";
import errors from "./errors";
import campaigns from "./campaigns/index";
import validations from "./validations";
import reports from "./reports";
import partners from "./partners";
import notifications from "./notifications";
import teamManagement from "./teamManagement";
import i18n from "../lang"
import advertisers from "./advertisers";
import loyaltyProgram from "./loyaltyProgram";
import agencies from "./agencies";
import accountSettings from "./accountSettings";
import payments from "./payments";
import extrasAndDeductions from "./extrasAndDeductions";
import releaseNotes from "./releaseNotes"

/**
 * why this is here?!!!
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location)
    .catch((err) => err)
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    meta: {
      title: "homePage",
    },
    beforeEnter: (to, from, next) => {
      next({ name: "overview" })
    },
  },
  ...authentication,
  ...shallowRoutes,
  ...campaigns,
  ...validations,
  ...reports,
  ...partners,
  ...notifications,
  ...teamManagement,
  ...advertisers,
  ...agencies,
  ...loyaltyProgram,
  ...accountSettings,
  ...payments,
  ...extrasAndDeductions,
  ...errors,
  ...releaseNotes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/**
 * global route guard
 */

router.afterEach(() => {
  const tokenValid = isTokenValid();
  const isLoginAsActivated = store.getters["Authorization/isLoginAsActivated"]

  if (tokenValid && Object.keys(store.state.User.user).length && !isLoginAsActivated) {
    store.dispatch("Notifications/setUnseenNotificationCount")
  }
})

const authRoutes = authentication.map((el) => el.name)
router.beforeEach((to, from, next) => {
  to.matched.some((record) => {
    const tokenValid = isTokenValid()
    if (record.meta.requireAuth && !tokenValid) {
      const finalDestinationExpiration = 30 / (24 * 60) // 30 min
      Cookies.set("finalDestination", to.fullPath, { expires: finalDestinationExpiration, path: "/" });

      next({
        name: "login",
      });
    } else if (authRoutes.includes(record.name) && tokenValid) {
      next({
        name: "overview",
      });
    } else {
      next();
    }
    return true
  });
});

// Router navigation guard to cancel pending requests on route change
router.beforeEach((to, from, next) => {
  const { pendingRequests } = store.state.Loader;

  // Cancel previous route pending requests
  pendingRequests.forEach((request) => {
    if (from.name === request.initiator && request.cancelWhenLeaveInitiator) {
      request.cancel({
        requestId: request.id,
      });
    }
  });

  next();
});
/**
 * setting tap title
 */

const isRevGatePartner = process.env.VUE_APP_NAME === "revgate"
const title = isRevGatePartner ? "RevGate" : "Boostiny"

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = `${title} - ${i18n.messages[i18n.locale].titles[to.meta.title] || to.name}`;
  } else {
    document.title = `${title} - ${to.name}`;
  }
  next();
});

/**
 * scroll to the top with each new route navigation
 */
router.beforeEach((to, from, next) => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  next();
});

/**
 * bypass maintenance mode
 */
router.beforeEach((to, from, next) => {
  const queryParams = window.location.search.replace("?", "")
    .split("&")
  const bypassMaintenanceModeToken = queryParams.find((el) => el.startsWith("qcpass"))
    ?.split("=")[1] || null

  if (bypassMaintenanceModeToken) {
    Cookies.set("qcpass", bypassMaintenanceModeToken, { expires: 1 })
  }

  next()
});

router.afterEach((to) => {
  const requireAuth = to.matched.map((el) => !!el.meta.requireAuth).some((el) => el === true)

  if (store.getters["Authorization/isLoginAsActivated"] || (requireAuth && !Object.keys(store.state.User.user).length) || !to.meta.entityName) return
  trackPageView({ page_screen_name: to.meta.entityName })
})

export default router;
