<script>
  import themes from "./config";

  export default {
    props: {
      theme: {
        type: String,
        required: false,
        default: "warning",
        validator: (value) => ["warning", "info", "error"].includes(value),
      },
      content: {
        type: String,
        default: "",
      },
      position: {
        type: String,
        required: false,
        default: "center",
        validator: (value) => ["start", "end", "center"].includes(value),
      },

      showIcon: {
        default: true,
        type: Boolean,
      },
    },
    data() {
      return {};
    },
    computed: {
      activeTheme() {
        return themes[this.theme];
      },
    },
    methods: {},
  };
</script>
<template>
  <div
    :class="[
      activeTheme.bgClass,
      activeTheme.textClass,
      activeTheme.borderClass,
      `align-items-${position}`
    ]"
    class="d-flex p-6 radius-4"
  >
    <component
      :is="activeTheme.icon"
      v-if="activeTheme.icon && showIcon"
      :color="activeTheme.iconColor"
      class="m-r-12 flex-shrink-0"
    />
    <slot>
      <p class="p-base-light">
        {{ content }}
      </p>
    </slot>
  </div>
</template>
