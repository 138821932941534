<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="24" height="24"
    viewBox="0 0 24 24" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.93 4.93L9.17 9.17M14.83 14.83L19.07 19.07M19.07 4.93L14.83 9.17L18.36 5.64M4.93 19.07L9.17 14.83M22 12C22 17.5228 17.5228 22
      12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16
      8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
      :stroke="isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
