export default {
  filters: {
    top: "الاعلى",
    less: "الأقل",
    topPerforming: "أفضل أداءً",
    lessPerforming: "أقل أداء",
    revenues: "الإيرادات",
    revenue: "الإيرادات",
    salesAmount: "المبيعات",
    totalCost: "المصاريف",
    showing: "عرض",
    conversions: "التحويلات",
    salesAmountUsd: "المبيعات بالدولار",
    profit: "الربح",
    margin: "هامش الربح",
    cairo: "القاهرة",
    amman: "عمان",
    dubai: "دبي",
    cost: "Cost",

    publisherLevel: "Publisher Level",

    nano: "Nano",
    micro: "Micro",
    macro: "Macro",
    mega: "Mega",

    assetOwners: "Asset Owners",
    socialAssets: "Social Assets",
    loyaltyPrograms: "Boost & Earn",
    organicActivities: "Organic Activities",
    mediaBuying: "Media Buying",

    network: "Network",
    individual: "Individual",

    fixed: "Fixed",
    revShare: "RevShare",
    "fixed&RevShare": "Fixed&RevShare",
    yes: "نعم",
    no: "لا",
    managementUnit: "Partner Management Unit",
    topCampaigns: "أهم الحملات",
    recommendedCampaigns: "الحملات الموصى بها",
    newCampaigns: "حملات جديدة",
    myCampaigns: "حملاتي",
    iconnect: "iConnect",
    iConnect: "iConnect",
    sea: "SEA",

  },
}
