import trackResponse from "@/utils/miscellaneous/track-response/index"
import Errors from "@/services/Errors";
import router from "../../router"

export default {
  namespaced: true,
  state: {
    errorCode: null,
    showToast: false,
    toastContent: {},
    errors: new Errors({}),
  },

  mutations: {
    SET_ERROR_CODE: (state, data) => {
      state.errorCode = data
    },

    SET_ERRORS: (state, data) => {
      state.errors = data
    },

    SET_SHOW_TOAST: (state, data) => {
      state.showToast = data
    },

    SET_TOAST_CONTENT: (state, data) => {
      state.toastContent = data
    },
  },

  actions: {

    handleErrors({ commit, dispatch }, {
      statusCode, errors, showGlobalAlert, disableDefaultErrorHandling = false,
    }) {
      commit("SET_ERROR_CODE", statusCode)

      if (disableDefaultErrorHandling) {
        return;
      }
      /**
       * Bad Request
       */
      if (statusCode === 400) {
        if (showGlobalAlert) {
          trackResponse({ type: "error", msg: errors.message })
        }

        /**
        * invalid tokens,
        * clear user data, and log out
        */
      } else if (statusCode === 401) {
        dispatch("Authentication/handleInvalidTokens", null, { root: true })

        /**
        * forbidden, when user tries to access unauthorized routes
        */
      } else if (statusCode === 403) {
        trackResponse({ type: "error", msg: errors.message })

        router.push("/")
        /**
         * not found
         */
      } else if (statusCode === 404) {
        trackResponse({ type: "error", msg: errors.message })

        router.push({ name: "not-found" })

        /**
        * server error
        */
      } else if (statusCode === 500) {
        trackResponse({ type: "error", msg: errors.message })

        /**
        * maintenance mode
        */
      } else if (statusCode === 503) {
        router.push({ name: "system-down" })

        /**
         * user already registered
         */
      } else if (statusCode === 409) {
        trackResponse({ type: "error", msg: errors.message })

        router.push({ name: "login" })
        /**
         * validation error
         */
      } else if (statusCode === 422 || statusCode === 406) {
        if (showGlobalAlert) {
          trackResponse({ type: "error", msg: errors.message })
        }

        const errorsObject = new Errors()

        Object.entries(errors.validations).forEach(([key, val]) => {
          errorsObject.recordField({
            [key]: val[0],
          });
        });

        commit("SET_ERRORS", errorsObject)
      } else if (statusCode === 451) {
        /**
         * Suspended Partner
         */

        if (errors.reason === "suspended") {
          router.push({ name: "suspended-partner" })
        } else if (errors.reason === "request_delete") {
          router.push({ name: "deleteRequest" })
        }

        commit("SET_ERRORS", errors)
      } else if (statusCode === 412) {
        trackResponse({ type: "error", msg: errors.message })

        /**
         * No available payouts and revenue
         */
      } else {
        /**
         * any other unhandled codes
         */
        trackResponse({ type: "error", msg: errors.message })
      }
    },

    resetErrors({ commit }) {
      commit("SET_ERRORS", new Errors({}))
    },

    toggleShowToast({ commit, state }) {
      commit("SET_SHOW_TOAST", !state.showToast)
    },

    setToastContent({ commit }, data) {
      commit("SET_TOAST_CONTENT", data)
    },

  },
}
