<script>
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {},

    mounted() {
      this.setNotificationAsRead()
    },
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div class="m-r-15">
      <p class="cursor-pointer p-base-medium text-neutral-400 m-b-9">
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"congratulations"}`)
        }}</span>
        <span v-if="notification.content.type === 'extra'"> $ </span>
        <span class="text-neutral-900">{{ notification.content.prize }} </span>
        <span>{{
          $t(`notifications.${notification.type}.${notification.content.type}`)
        }}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
