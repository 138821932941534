<script>
  export default {
    props: {
      disableBtn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {};
    },

    methods: {
      cancel() {
        this.$emit("cancel");
      },

      confirm() {
        this.$emit("confirm");
      },
    },
  };
</script>

<template>
  <div
    class="p-x-18 m-t-12 d-flex justify-content-end align-items-center modal-footer"
  >
    <button
      :disabled="disableBtn"
      class="btn btn-neutral-bordered btn-md m-r-15"
      @click="cancel"
    >
      <span>{{ $t("buttons.cancel") }}</span>
    </button>
    <button
      :disabled="disableBtn"
      class="btn btn-primary-filled btn-md"
      @click="confirm"
    >
      <span name="primaryBtnText">{{ $t("buttons.submit") }}</span>
    </button>
  </div>
</template>

<style scoped lang='sass'>
.modal-footer
  height: 56px
</style>
