<script>
  export default {
    props: {
      color: {
        type: String,
        default: "var(--neutral-500)",
      },
    },
  }
</script>

<template>
  <svg
    width="14" height="14"
    viewBox="0 0 14 14" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.52671 6.52634C4.46601 6.58974 4.41844 6.66451 4.38671 6.74634C4.32003 6.90865 4.32003 7.0907 4.38671 7.25301C4.41844 7.33484
        4.46601 7.40961 4.52671 7.47301L6.52671 9.47301C6.65224 9.59854 6.82251 9.66907 7.00004 9.66907C7.17758 9.66907 7.34784 9.59854
        7.47337 9.47301C7.59891 9.34747 7.66944 9.17721 7.66944 8.99967C7.66944 8.82214 7.59891 8.65188 7.47337 8.52634L6.60671
        7.66634H9.00004C9.17685 7.66634 9.34642 7.5961 9.47145 7.47108C9.59647 7.34606 9.66671 7.17649 9.66671 6.99967C9.66671 6.82286
        9.59647 6.65329 9.47145 6.52827C9.34642 6.40325 9.17685 6.33301 9.00004 6.33301H6.60671L7.47337 5.47301C7.53586 5.41103 7.58546
        5.3373 7.6193 5.25606C7.65315 5.17482 7.67057 5.08768 7.67057 4.99967C7.67057 4.91167 7.65315 4.82453 7.6193 4.74329C7.58546
        4.66205 7.53586 4.58832 7.47337 4.52634C7.4114 4.46386 7.33767 4.41426 7.25643 4.38041C7.17519 4.34657 7.08805 4.32914 7.00004
        4.32914C6.91203 4.32914 6.8249 4.34657 6.74366 4.38041C6.66242 4.41426 6.58868 4.46386 6.52671 4.52634L4.52671 6.52634ZM0.333374
        6.99967C0.333374 8.31822 0.724367 9.60715 1.45691 10.7035C2.18945 11.7998 3.23064 12.6543 4.44882 13.1589C5.66699 13.6635 7.00744
        13.7955 8.30064 13.5382C9.59385 13.281 10.7817 12.6461 11.7141 11.7137C12.6464 10.7814 13.2814 9.59348 13.5386 8.30028C13.7958
        7.00707 13.6638 5.66663 13.1592 4.44845C12.6547 3.23028 11.8002 2.18909 10.7038 1.45654C9.60751 0.724001 8.31858 0.333008 7.00004
        0.333008C6.12456 0.333008 5.25766 0.505446 4.44882 0.840478C3.63998 1.17551 2.90505 1.66657 2.286 2.28563C1.03575 3.53587 0.333374
        5.23156 0.333374 6.99967ZM12.3334 6.99967C12.3334 8.05451 12.0206 9.08565 11.4345 9.96272C10.8485 10.8398 10.0156 11.5234 9.04102
        11.927C8.06648 12.3307 6.99412 12.4363 5.95956 12.2305C4.92499 12.0247 3.97468 11.5168 3.2288 10.7709C2.48292 10.025 1.97497
        9.07472 1.76919 8.04016C1.5634 7.00559 1.66902 5.93324 2.07268 4.9587C2.47635 3.98416 3.15994 3.1512 4.037 2.56517C4.91406
        1.97914 5.94521 1.66634 7.00004 1.66634C8.41453 1.66634 9.77108 2.22824 10.7713 3.22844C11.7715 4.22863 12.3334 5.58519
        12.3334 6.99967Z" :fill="color"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
