export default {
  sideBar: {
    dashboard: "متابعة الاداء",
    campaigns: "الحملات",
    partners: "الشركاء",
    reports: "التقارير",
    validations: "التحقيق",
    team: "الفريق",
    bonusAndFines: "المكافئات والخصومات",
    settings: "الاعدادات",
    help: "المساعدة و الاسئلة",
    idea: "فكره",
    advertisers: "Advertisers",
    "partners-search": "الشركاء",
    messages: "الرسائل",
    activityLogs: "Activity Logs",
    creatives: "Creatives",
    partnerCoupons: "الكوبونات",
    agencies: "Agencies",
    agencyPartners: "الشركاء",
    payments: "Payments",
    campaignsCommissions: "Campaigns Commissions",
    links: "Links",

  },
}
