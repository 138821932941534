<script>
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      reward() {
        return this.notification.content.reward;
      },
    },

    mounted() {
      this.setNotificationAsRead();
    },
  };
</script>
<template>
  <div>
    <p class="cursor-pointer p-base-medium text-neutral-400 m-b-9">
      <span class="m-r-3">{{
        $t(`notifications.${notification.type}.congratulations`)
      }}</span>
      <span class="text-neutral-900 m-r-3">{{ reward }}</span>
      <span>{{ $t(`notifications.${notification.type}.points-added`) }}</span>
    </p>
  </div>
</template>
<style scoped lang='sass'></style>
