export default {

  fetchNotifications: ({ persona }) => {
    if (persona === "agency") return "agency/notifications"
    return "notifications"
  },

  fetchNotificationSettings: ({ persona }) => {
    if (persona === "agency") return "agency/notifications/configuration"
    return "notifications/configuration"
  },
  changeNotificationSettings: ({ persona }) => {
    if (persona === "agency") return "agency/notifications/settings"
    return "notifications/settings"
  },
  setNotificationToBeRead: ({ id, persona }) => {
    if (persona === "agency") return `agency/notifications/${id}?status=read`
    return `notifications/${id}?status=read`
  },

  actionTaken: ({ id, persona }) => {
    if (persona === "agency") return `agency/notifications/${id}?status=action-taken`
    return `notifications/${id}?status=action-taken`
  },
  approve: ({ id, persona }) => {
    if (persona === "agency") return `agency/applications/${id}/update?status=approved`
    return `applications/${id}/update?status=approved`
  },
  rejectRequest: ({ campaignID, couponRequestID, persona }) => {
    if (persona === "agency") return `agency/campaigns/${campaignID}/coupon-requests/${couponRequestID}/reject`
    return `campaigns/${campaignID}/coupon-requests/${couponRequestID}/reject`
  },
  rejectApplication: ({ campaignApplicationID, persona }) => {
    if (persona === "agency") return `agency/applications/${campaignApplicationID}/update?status=rejected`
    return `applications/${campaignApplicationID}/update?status=rejected`
  },

  fetchNotificationsTags: ({ persona }) => {
    if (persona === "agency") {
      return "agency/notifications/lookups/scoped-tags"
    }

    return "notifications/lookups/scoped-tags"
  },

  pushNotifications: ({ persona }) => {
    if (persona === "agency") return "agency/users/settings/push_notification"
    return "users/settings/push_notification"
  },
}
