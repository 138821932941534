import moment from "moment"
import Cookies from "js-cookie";
import API from "../../utils/input-output/api/index"
import constants from "./constants"

export default {
  namespaced: true,
  state: {
    dateRange: {
      from: moment()
        .locale("en")
        .subtract(6, "days")
        .format("YYYY-MM-DD"),
      to: moment()
        .locale("en")
        .format("YYYY-MM-DD"),
    },
    mineFilter: true,
    tablesRowsLimits: 20,
    filters: {},
    filtersCriteria: {},
    countries: [],
  },

  mutations: {
    SET_DATE_RANGE: (state, value) => {
      state.dateRange = value
    },

    SET_TABLE_ROWS: (state, value) => {
      state.tablesRowsLimits = value
    },

    SET_FILTERS: (state, value) => {
      state.filters = value
    },

    UPDATE_FILTERS: (state, value) => {
      Object.entries(value)
        .forEach(([key, val]) => {
          state.filters[key] = val
        })
    },
    SET_FILTER: (state, filter) => {
      state.filters[filter.key] = filter.value
    },

    SET_FILTERS_CRITERIA: (state, value) => {
      state.filtersCriteria = value
    },

    SET_COUNTRIES(state, value) {
      state.countries = value
    },

    SET_MINE_FILTER: (state, value) => {
      state.mineFilter = value
    },
  },

  actions: {
    setDateRange({ commit }, val) {
      const newValue = {
        from: moment(val.startDate)
          .locale("en")
          .format("YYYY-MM-DD"),
        to: moment(val.endDate)
          .locale("en")
          .format("YYYY-MM-DD"),
      }
      commit("SET_DATE_RANGE", newValue)
      localStorage.setItem("dateRange", JSON.stringify(newValue))
    },

    setRowsEntries({ commit }, val) {
      commit("SET_TABLE_ROWS", val)
      localStorage.setItem("rowsEntries", JSON.stringify(val))
    },

    getRouteSharedFilters({
      commit,
      state,
    }) {
      const date = JSON.parse(localStorage.getItem("dateRange") || JSON.stringify(state.dateRange))
      const mineFilter = JSON.parse(localStorage.getItem("mine") || JSON.stringify(state.mineFilter))
      const rowsEntries = [20, 50, 100].includes(+JSON.parse(localStorage.getItem("rowsEntries")))
        ? +JSON.parse(localStorage.getItem("rowsEntries")) : 20
      commit("SET_DATE_RANGE", date)
      commit("SET_MINE_FILTER", mineFilter)
      commit("SET_TABLE_ROWS", rowsEntries)
    },

    setFilter({ commit }, filter) {
      commit("SET_FILTER", filter)
    },

    setFilters({ commit }, filters) {
      commit("SET_FILTERS", filters)
    },

    updateFilters({ commit }, filters) {
      commit("UPDATE_FILTERS", filters)
    },

    resetAllFilters({ commit }, { value = {} }) {
      commit("SET_FILTERS", value)
    },

    setAdvancedFilters({
      commit,
      state,
    }, newFilter) {
      let filters = state.filters.advancedFilters
      const isFilterExists = state.filters.advancedFilters.find((filter) => filter.field === newFilter.field) !== undefined

      if (isFilterExists) {
        filters = state.filters.advancedFilters.map((filter) => {
          let updated = filter
          if (filter.field === newFilter.field) {
            updated = newFilter
          }
          return updated
        })
      } else {
        filters = [...filters, newFilter]
      }
      commit("SET_FILTER", {
        key: "advancedFilters",
        value: filters,
      })
    },

    removeAdvancedFilter({
      state,
      commit,
    }, field) {
      const filters = state.filters.advancedFilters.filter((filter) => filter.field !== field)
      commit("SET_FILTER", {
        key: "advancedFilters",
        value: filters,
      })
    },

    restAdvancedFilters({ commit }) {
      commit("SET_FILTER", {
        key: "advancedFilters",
        value: [],
      })
    },

    updateSorting({
      state,
      commit,
    }, val) {
      let sortDir = "desc";
      if (state.filters.sortMetrics === val) {
        sortDir = state.filters.sortDirection === "asc" ? "desc" : "asc";
      }

      commit("SET_FILTER", {
        key: "sortMetrics",
        value: val,
      })
      commit("SET_FILTER", {
        key: "sortDirection",
        value: sortDir,
      })
    },

    getRouteFilters({
      commit,
      dispatch,
    }, data) {
      dispatch("getRouteSharedFilters")
      const filtersData = getFiltersFromLocalStorage(data.route)

      let filters = data.activeCampaign ? data.scheme[data.activeCampaign] : data.scheme
      if (Object.keys(filtersData).length) {
        filters = data.activeCampaign ? filtersData[data.activeCampaign] || data.scheme[data.activeCampaign] : filtersData
      }

      commit("SET_FILTERS", filters)
    },

    saveFilters({ state }, {
      route,
      activeCampaign,
    }) {
      setFiltersInLocalStorage({
        data: state.filters,
        route,
        activeCampaign,
      })
    },

    fetchFiltersCriteria({ commit }, params) {
      const url = constants.fetchAdvancedFilters({ isAgency: params.isAgency })
      API({
        url,
        method: "get",
        initiator: "global",
        requestId: "filtersCriteria",
      })
        .then(({ data }) => {
          const dataObject = {
            string: {
              keys: data.payload.string,
              defaultKey: "textContains",
            },
            number: {
              keys: data.payload.number,
              defaultKey: "numberIsEqual",
            },
            date: {
              keys: data.payload.date.filter((el) => !el.includes("Between")), // between option is not handled
              defaultKey: "dateIs",
            },
          }

          commit("SET_FILTERS_CRITERIA", dataObject)
        })
    },

    fetchCountries({ commit }, params) {
      API({
        url: constants.getCountriesLookups({ isAgency: params.isAgency }),
        method: "get",
        params: { limit: 500 },
        initiator: "global",
        requestId: "fetchCountries",
      })
        .then(({ data }) => {
          commit("SET_COUNTRIES", data.payload.data);
        });
    },

    setMineFilter({ commit }, value) {
      commit("SET_MINE_FILTER", value)
      localStorage.setItem("mine", JSON.stringify(value))
    },

    exportData(_, {
      url,
      filters,
    }) {
      /* eslint-disable */
      const qs = require("qs");
      /* eslint-enable */

      const token = Cookies.get(process.env.VUE_APP_TOKEN_KEY)
      const link = constants.exportData({
        url,
        token,
        filters: qs.stringify(filters, {
          encode: true,
        }),
      })

      window.open(link);
    },
  },
}

function getFiltersFromLocalStorage(route) {
  const filtersData = JSON.parse(localStorage.getItem("filters") || "{}")
  let filters = {}

  if (filtersData[route]) {
    filters = filtersData[route]
  }

  return filters
}

function setFiltersInLocalStorage({
  data,
  route,
  activeCampaign,
}) {
  const filtersData = JSON.parse(localStorage.getItem("filters") || "{}")

  if (activeCampaign) {
    filtersData[route] = {
      ...filtersData[route],
      [activeCampaign]: data,
    }
  } else {
    filtersData[route] = data
  }
  localStorage.setItem("filters", JSON.stringify(filtersData))
}
