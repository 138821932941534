<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
<template>
  <svg
    width="24" height="16"
    viewBox="0 0 24 16" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2668 2.5999H19.6371L17.652 0.614828C17.5148 0.476835 17.3285 0.399902 17.1334 0.399902H12.0001C11.805 0.399902 11.6187
        0.476835 11.4817 0.614828L8.11865 3.97786C7.26056 4.83594 7.26056 6.23074 8.11865 7.08862L8.24485 7.21482C9.10293 8.07291 10.4977
        8.07291 11.3556 7.21482L13.9853 4.58513C14.2721 4.29834 14.2721 3.83495 13.9853 3.54815C13.6985 3.26135 13.2351 3.26136 12.9483
        3.54815L10.3186 6.17785C10.0436 6.45137 9.55664 6.45137 9.28166 6.17785L9.15545 6.05164C9.01828 5.91381 8.94282 5.73048 8.94282
        5.53324C8.94282 5.33599 9.0191 5.15183 9.15545 5.01483L12.3037 1.86657H16.8299L18.8149 3.85164C18.9519 3.98963 19.1382 4.06656
        19.3333 4.06656H21.5333V9.93324H20.3333C19.9279 9.93324 19.6 10.2611 19.6 10.6666C19.6 11.072 19.9279 11.3999 20.3333
        11.3999H22.2667C22.6721 11.3999 23 11.072 23 10.6666V3.33323C23 2.92777 22.6722 2.5999 22.2668 2.5999Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.5"
    />

    <path
      d="M15 6.5C14.7132 6.21321 14.2498 6.21321 13.963 6.5C13.6762 6.78679 13.6762 7.25018 13.963 7.53698L16.3141 10.1511C16.5305 10.3675
        16.6148 10.5999 16.5722 10.8625C16.4945 11.3465 16.0098 11.8759 15.3388 12.2111C14.4676 12.6475 13.3523 12.8499 12.8206
        12.7839C12.5896 12.7531 12.3593 12.8395 12.1994 13.009C11.5811 13.6654 10.7026 14.0635 9.84911 14.0759C9.35413 14.081
        8.98679 13.9497 8.85173 13.8156L5.18573 10.1496C5.11829 10.0814 5.03694 10.0279 4.94675 9.99053C4.85737 9.95223 4.76194 9.93324
        4.66667 9.93324H2.46667V4.06657H4.66667C4.86179 4.06657 5.04807 3.98963 5.18508 3.85164L7.17032 1.86657H8.06668C8.47214 1.86657
        8.80001 1.5387 8.80001 1.13324C8.80001 0.727774 8.47214 0.399902 8.06668 0.399902H6.86667C6.67156 0.399902 6.48527 0.476836
        6.34827 0.614828L4.36303 2.5999H1.73333C1.32787 2.5999 1 2.92777 1 3.33323V10.6666C1 11.072 1.32787 11.3999 1.73333
        11.3999H4.36303L7.81477 14.8516C8.25544 15.2923 8.9801 15.5418 9.81099 15.5418C9.8308 15.5418 9.85126 15.5418 9.87106
        15.5411C11.018 15.5249 12.1473 15.0623 13.017 14.2614H13.0354C13.9154 14.2614 15.0908 13.974 15.9944 13.5222C17.1062
        12.9662 17.8637 12.0591 18.0199 11.0962C18.1379 10.3673 17.9004 9.66263 17.351 9.11331L15 6.5Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.5"
    />
  </svg>
</template>
