export default {
  sideBar: {
    dashboard: "Dashboard",
    campaigns: "Campaigns",
    partners: "Partners",
    reports: "Reports",
    validations: "Validations",
    extraAndDeduction: "Extras and Deductions",
    bonusAndFines: "Extras and Deductions",
    debitAndCredit: "Debits and Credits",
    team: "Team",
    settings: "Settings",
    help: "Help & FAQs",
    idea: "Idea",
    advertisers: "Advertisers",
    "partners-search": "Partners",
    messages: "Messages",
    activityLogs: "Activity Logs",
    creatives: "Creatives",
    partnerCoupons: "Coupons",
    agencies: "Agencies",
    agencyPartners: "Partners",
    payments: "Payments",
    campaignsCommissions: "Campaigns Commissions",
    links: "Links",
  },
}
