import Cookies from "js-cookie";

export default {
  namespaced: true,
  state: {
    loginAsActivated: false,
  },

  mutations: {
    SET_LOGIN_AS_ACTIVATED(state, payload) {
      state.loginAsActivated = payload
    },
  },

  getters: {
    isLoginAsActivated(state) {
      return state.loginAsActivated
    },

    hasAffiliateAccess(_, getters, rootState, rootGetters) {
      return !rootGetters["User/userBUIds"].length || rootGetters["User/userBUIds"].includes(1)
    },

    hasInfluencerAccess(_, getters, rootState, rootGetters) {
      return !rootGetters["User/userBUIds"].length || rootGetters["User/userBUIds"].includes(2)
    },

    hasMBAccess(_, getters, rootState, rootGetters) {
      return !rootGetters["User/userBUIds"].length || rootGetters["User/userBUIds"].includes(3)
    },

    hasOnlyMediaBuying(_, getters, rootState, rootGetters) {
      return rootGetters["User/userBUIds"].length === 1 && rootGetters["User/userBUIds"].includes(3)
    },

    hasOnlyAALabsAccess(_, getters, rootState, rootGetters) {
      return rootGetters["User/userBUIds"].length === 1 && rootGetters["User/userBUIds"].includes(4)
    },

    hasAffAndInfAccess(_, getters) {
      return getters.hasAffiliateAccess && getters.hasInfluencerAccess
    },

    isHead(state, getters, rootState) {
      return rootState.User.user.roleName === "head";
    },

    isTeamLeader(state, getters, rootState) {
      return rootState.User.user.roleName === "team-leader"
    },

    isTeamMember(state, getters, rootState) {
      return rootState.User.user.roleName === "team-member"
    },

    isSuperAdmin(state, getters, rootState) {
      return rootState.User.user.roleName === "admin"
    },

    isPowerUser(state, getters, rootState) {
      return rootState.User.user.roleName === "power-user";
    },

    isBI(state, getters, rootState) {
      return rootState.User.user.roleName === "bi"
    },

    isFinanceUser(state, getters, rootState) {
      return rootState.User.user.roleName === "finance"
    },

    isSalesManager(state, getters, rootState) {
      return rootState.User.user.roleName === "sales-manager";
    },

    isAgencyAdmin(state, getters, rootState) {
      return rootState.User.user.roleName === "agency-admin"
    },

    isAgencyCampaignManager(state, getters, rootState) {
      return rootState.User.user.roleName === "agency-campaign-manager"
    },

    isAgency(state, getters, rootState) {
      return rootState.User.user.persona === "agency"
    },

    isPartner(state, getters, rootState) {
      return rootState.User.user.persona === "partner"
    },

    isAdvertiser(state, getters, rootState) {
      return rootState.User.user.persona === "advertiser"
    },

    isBackoffice(state, getters, rootState) {
      return rootState.User.user.persona === "backoffice"
    },
  },

  actions: {
    setIsLoginAsActivated({ commit }) {
      const loginAsToken = Cookies.get(process.env.VUE_APP_LOGIN_AS_TOKEN_KEY)
      commit("SET_LOGIN_AS_ACTIVATED", !!loginAsToken)
    },
  },
}
