export default [
  {
    path: "/account-settings",
    name: "account-settings",
    component: () => import(/* webpackChunkName: "AccountSettings" */ "../views/account-settings/Index.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "system-currency",
        name: "system-currency",
        component: () => import(/* webpackChunkName: "SystemCurrency" */ "../views/account-settings/SystemCurrency.vue"),
        meta: {
          requireAuth: true,
          title: "systemCurrency",
          entityName: "system-currency",
        },
      },
      {
        path: "api-configuration",
        name: "api-configuration",
        component: () => import(/* webpackChunkName: "APIConfiguration" */ "../views/account-settings/APIConfiguration.vue"),
        meta: {
          requireAuth: true,
          title: "APIConfiguration",
          entityName: "API-configuration",
        },
      },

      {
        path: "postback-tracking",
        name: "postback-tracking",
        component: () => import(/* webpackChunkName: "PostBackTracking" */ "../views/account-settings/PostBackTracking.vue"),
        meta: {
          requireAuth: true,
          title: "postBackTracking",
          entityName: "postback-tracking",
        },
      },

    ],
  },
]
