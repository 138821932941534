<script>
  export default {
    props: {
      color: {
        default: "var(--neutral-500)",
        type: String,
      },
      width: {
        type: String,
        default: "14",
      },
      height: {
        type: String,
        default: "14",
      },
    },
  };
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00016 0.333496C3.32016 0.333496 0.333496 3.32016 0.333496 7.00016C0.333496 10.6802
      3.32016 13.6668 7.00016 13.6668C10.6802 13.6668 13.6668 10.6802 13.6668 7.00016C13.6668
      3.32016 10.6802 0.333496 7.00016 0.333496ZM7.00016 10.3335C6.6335 10.3335 6.3335 10.0335
      6.3335 9.66683V7.00016C6.3335 6.6335 6.6335 6.3335 7.00016 6.3335C7.36683 6.3335 7.66683
      6.6335 7.66683 7.00016V9.66683C7.66683 10.0335 7.36683 10.3335 7.00016 10.3335ZM6.3335
      5.00016H7.66683V3.66683H6.3335V5.00016Z"
      :fill="color"
    />
  </svg>
</template>
<style scoped lang='sass'></style>
