<script>
  import { mapGetters } from "vuex";
  import BaseImage from "@/components/partials/BaseImage.vue";
  import mixin from "../mixin";

  export default {
    components: {
      BaseImage,
    },

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
    ...mapGetters("Authorization", ["isHead", "isTeamLeader"]),
    sender() {
      return this.notification.content.sender;
    },
    campaign() {
      return this.notification.content.campaign;
    },
    conversionCycle() {
      return this.notification.content.cycle;
    },
    paymentCycle() {
      return this.notification.content?.payment_cycle;
    },
    userID() {
      return this.$store.state.User.user.id;
    },
    canRedirectToCampaignValidation() {
      if (this.isHead || this.isTeamLeader) {
        return (
          this.canViewCampaignValidation
          && this.notification.content?.CG_team_users?.includes(this.userID)
        );
      }

      return this.canViewCampaignValidation;
    },
    },

    methods: {},
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div class="img-container m-r-12">
      <BaseImage
        :src="campaign.logo"
        width="40"
        height="40"
        class="radius-full fitted-img"
      />
    </div>
    <div>
      <p
        class="p-base-medium text-neutral-400 m-b-9"
        :class="{ 'cursor-pointer': canRedirectToCampaignValidation }"
        @click="canRedirectToCampaignValidation && setValidationsFilters()"
      >
        <span class="text-neutral-900 m-r-3">{{ sender.name }}</span>

        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.validated`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ campaign.name }}</span>
        <span class="text-neutral-900 m-r-3">{{
          formatCycle(conversionCycle)
        }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.cycle`)
        }}</span>
        <span v-if="paymentCycle">
          <span class="m-r-3">{{
            $t(`notifications.${notification.type}.on`)
          }}</span>
          <span class="text-neutral-900 m-r-3">{{
            formatCycle(paymentCycle)
          }}</span>
        </span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
