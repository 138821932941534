export default function setSelectedFiltersOptionsV2({
  route, name, data, activeCampaign,
}) {
  const selectedFiltersOptions = JSON.parse(localStorage.getItem("selectedFiltersOptionsV2")) || {}

  if (activeCampaign) {
    selectedFiltersOptions[route] = {
      ...(selectedFiltersOptions[route]),
      [activeCampaign]: {
        ...(selectedFiltersOptions?.[route]?.[activeCampaign] || {}),
        [name]: data,
      },
    }
  } else {
    selectedFiltersOptions[route] = {
      ...(selectedFiltersOptions?.[route] || {}),
      [name]: [
        ...data,
      ],
    }
  }

  localStorage.setItem("selectedFiltersOptionsV2", JSON.stringify(selectedFiltersOptions))
}
