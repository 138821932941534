export default [
  {
    path: "/release-notes",
    component: () => import(/* webpackChunkName: "ReleaseNotes" */ "../views/release-notes/Index.vue"),
    meta: {
      title: "releaseNotes",
      requireAuth: true,
    },
    children: [
      {
        path: "",
        name: "release-notes-list",
        component: () => import(/* webpackChunkName: "ReleaseNotesList" */ "../views/release-notes/List.vue"),
        meta: {
          requireAuth: true,
          title: "releaseNotes",
          entityName: "release-notes",
        },
      },
      {
        path: "create",
        name: "create-release-notes",
        component: () => import(/* webpackChunkName: "CreateReleaseNotes" */ "../views/release-notes/Create.vue"),
        meta: {
          requireAuth: true,
          title: "createReleaseNotes",
          entityName: "create-release-notes",
        },
      },
      {
        path: ":id",
        name: "release-note-details",
        component: () => import(/* webpackChunkName: "ReleaseNoteDetails" */ "../views/release-notes/details/Index.vue"),
        redirect: { name: "edit-release-note" },
        meta: {
          title: "releaseNoteDetails",
        },
        children: [
          {
            path: "edit",
            name: "edit-release-note",
            component: () => import(/* webpackChunkName: "EditReleaseNote" */ "../views/release-notes/details/Edit.vue"),
            meta: {
              requireAuth: true,
              title: "editReleaseNote",
              entityName: "edit-release-note",
            },
          },
        ],
      },
    ],
  },

]
