export default {
  latestRelease: () => "releases/latest",

  // Agency
  agencyLatestRelease: () => "agency/releases/latest",

  loginByRefreshToken: ({ userPersona }) => {
    if (userPersona === "agency") {
      return "agency/auth/refresh"
    }
    return "auth/refresh"
  },
}
