<script>
  import BaseImage from "@/components/partials/BaseImage.vue";
  import mixin from "../mixin";

  export default {
    components: {
      BaseImage,
    },

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      type() {
        if (
          new Date().getTime()
          < new Date(this.notification.content.expirationDate).getTime()
        ) {
          return "coupon-allocation-will-be-expired";
        }
        return "coupon-allocation-expired";
      },
      campaign() {
        return this.notification.content.campaign;
      },
      codes() {
        return this.notification.content.codes.map((el) => el.code);
      },
      expirationDate() {
        return this.notification.content.expirationDate;
      },
    },

    methods: {
      displayCodes() {
        if (this.codes.length > 3) {
          const [firstCode, secondCode, ...restOfCodes] = this.codes;
          return `${firstCode}, ${secondCode}${`, +${restOfCodes.length}`}`;
        }
        return this.codes.join(", ");
      },
      setFilters() {
        const filtersData = this.storageFiltersData;

        if (this.$router.currentRoute.name === "campaign-coupons") {
          this.$store.dispatch("Globals/setFilter", {
            key: "advancedFilters",
            value: [
              {
                field: "code",
                key: "match",
                requires: [],
                value: this.codes.join(", ").toLowerCase(),
              },
            ],
          });
        }

        filtersData["campaign-coupons"] = {
          [this.campaign.id]: {
            bu: "",
            allocationStatus: "allocated",
            status: "inactive",
            sortMetrics: "code",
            sortDirection: "asc",
            couponType: "",
            countries: [],
            trafficSources: [],
            advancedFilters: [
              {
                field: "code",
                key: "match",
                requires: [],
                value: this.codes.join(", ").toLowerCase(),
              },
            ],
          },
        };

        this.setLocalStorage("filters", filtersData);
        this.viewCampaignCoupons();
      },
    },
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div class="img-container m-r-12">
      <BaseImage
        :src="campaign.logo"
        width="40"
        height="40"
        class="radius-full cursor-pointer fitted-img"
        :name="`notification-img-${notification.id}`"
      />
    </div>
    <div class="m-r-15">
      <p
        class="p-base-medium text-neutral-400 m-b-9"
        :class="{ 'cursor-pointer': canViewCoupons }"
        @click="setFilters"
      >
        <span class="m-r-3">{{
          $t(`notifications.coupon-allocation-expired.${"coupon-code"}`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ displayCodes() }}</span>
        <span class="m-r-3">{{
          $t(`notifications.coupon-allocation-expired.${"under"}`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ campaign.name }}</span>
        <span v-if="type === 'coupon-allocation-expired'">{{
          $t(`notifications.coupon-allocation-expired.campaign-got-expired`)
        }}</span>
        <span v-if="type === 'coupon-allocation-will-be-expired'">
          <span class="m-r-3">
            {{ $t("notifications.coupon-allocation-will-be-expired") }}</span>
          <span class="text-neutral-900">{{ expirationDate }}</span>
        </span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
