export default [
  {
    path: "/team-management",
    name: "team-management",
    component: () => import(/* webpackChunkName: "TeamManagement" */ "@/views/team-management/Index.vue"),
    meta: {
      requireAuth: true,
      title: "team",
    },
    children: [
      {
        path: "team",
        component: () => import(/* webpackChunkName: "Team" */ "@/views/team-management/team/Index.vue"),
        children: [
          {
            path: "",
            name: "team",
            component: () => import(/* webpackChunkName: "TeamList" */ "@/views/team-management/team/List.vue"),
            meta: {
              hasFilters: true,
              filtersScheme: {
                deleted: false,
                roleId: "",
                advancedFilters: [],
                buId: "",
                teamId: "",
                sortDirection: "asc",
                sortMetrics: "name",
              },
              title: "team",
              entityName: "team-list",
            },
          },
          {
            path: ":id",
            component: () => import(/* webpackChunkName: "TeamDetails" */ "@/views/team-management/team/details/Index.vue"),
            meta: {
              title: "userDetails",

            },
            children: [
              {
                path: "",
                name: "user-details",
                component: () => import(/* webpackChunkName: "UserDetails" */ "@/views/team-management/team/details/UserDetails.vue"),
                meta: {
                  title: "userDetails",
                  entityName: "user-details",
                },
              },
            ],
          },
        ],
      },
      {
        path: "roles",
        component: () => import(/* webpackChunkName: "Roles" */ "@/views/team-management/roles/Index.vue"),
        children: [
          {
            path: "",
            name: "roles",
            component: () => import(/* webpackChunkName: "RolesList" */ "@/views/team-management/roles/List.vue"),
            meta: {
              filtersScheme: {
                roleId: "",
              },
              title: "roles",
              entityName: "roles-list",
            },
          },
          {
            path: ":id",
            component: () => import(/* webpackChunkName: "RoleDetailsIndex" */ "@/views/team-management/roles/details/Index.vue"),
            meta: {
              title: "roleDetails",
              entityName: "role-details",
            },
            children: [
              {
                path: "",
                name: "role-details",
                component: () => import(/* webpackChunkName: "RoleDetails" */ "@/views/team-management/roles/details/RoleDetails.vue"),
                meta: {
                  title: "roleDetails",
                  entityName: "role-details",
                },
              },
              {
                path: "edit",
                component: () => import(/* webpackChunkName: "RoleEdit" */ "@/views/team-management/roles/details/Edit.vue"),
                name: "role-edit",
                meta: {
                  title: "roleEdit",
                  entityName: "role-edit",
                },
              },
            ],
          },
        ],
      },
    ],
  },
]
