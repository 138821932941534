<script>
  export default {
    components: {},
    props: {},
    computed: {
      isLoggedIn() {
        return !!Object.keys(this.$store.state.User.user).length;
      },

      getLang() {
        return this.$i18n.locale;
      },

      isRevGatePartner() {
        return process.env.VUE_APP_NAME === "revgate"
      },

      logoPath() {
        if (this.isRevGatePartner) return require("@/assets/icons/revgateLogos/main-logo.svg")

        return require("@/assets/icons/boostinyLogos/boostinyWordLogo.svg")
      },
    },
    methods: {
      goToSignIn() {
        this.$router.push({ name: "login" });
      },

      toggleLang() {
        window.toggleLang();
      },
    },
  };
</script>

<template>
  <div
    class="
      w-100
      d-flex
      justify-content-between
      align-items-center
      h-100
      bg-base-white
    "
  >
    <div class="header-logo d-flex align-items-center">
      <router-link class="m-t-6" to="/">
        <img
          class="boostiny-logo"
          :src="logoPath"
          alt=""
        >
      </router-link>
    </div>
    <div
      v-if="!isLoggedIn"
      class="sign-in-error d-flex justify-content-center align-items-center"
    >
      <p class="heading-6-bold system-down-sign">
        <span>{{ $t('titles.alreadyMember') }}</span>
        <span
          class="text-primary-500 m-x-6 cursor-pointer"
          @click="goToSignIn"
        >{{ $t('titles.signIn') }}</span>
      </p>
      <div class="separator" />
      <div
        class="
          align-items-center
          choose-lang
          d-flex
          p-2 p-base-medium
          radius-6
          cursor-pointer
        "
        :class="getLang !== 'ar' ? 'p-b-3' : ''"
        @click="toggleLang"
      >
        <img
          src="@/assets/icons/translate.svg"
          alt="translate"
          width="20"
          height="20"
        >
        <p class="lang-shortname m-l-3 m-r-3">
          {{ getLang === "ar" ? "EN" : "AR" }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.header-logo
    height: 20px

    img
      height: 100%
.choose-lang
      width: 32px
      height: 32px
      background-color: var(--secondary-500-01)
      color: var(--secondary-500)
      cursor: pointer
      border-radius: 6px
      text-transform: capitalize
.system-down-sign
    @media (max-width: 450px)
        display: flex
        align-items: flex-end
        justify-content: center
        flex-direction: column

.separator
    display: block
    width: 1px
    height: 30px
    background-color: var(--neutral-200)
    margin: 0 5px
</style>
