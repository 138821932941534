export default [
  {
    path: "/login",
    component: () => import(/* webpackChunkName: "Login" */ "../views/authentication/Login.vue"),
    name: "login",
    query: "",
    meta: {
      title: "signIn",
      entityName: "login",
    },
  },
  {
    path: "/forgot-password",
    component: () => import(/* webpackChunkName: "ForgotPassword" */ "../views/authentication/ForgotPassword.vue"),
    name: "forgot-password",
    meta: {
      title: "forgotPassword",
      entityName: "forgot-password",
    },
  },
  {
    path: "/reset-password/:token",
    component: () => import(/* webpackChunkName: "RestPassword" */ "../views/authentication/RestPassword.vue"),
    name: "reset-password",
    meta: {
      title: "resetPassword",
      entityName: "reset-password",
    },
  },
  /**
   * this has to be sign up instead of resister
   */
  {
    path: "/register/:token",
    component: () => import(/* webpackChunkName: "Registration" */ "../views/authentication/Registration.vue"),
    name: "register",
    meta: {
      title: "register",
      entityName: "register",
    },
  },
  {
    path: "/influencer-register",
    component: () => import(/* webpackChunkName: "InfluencerRegistration" */ "../views/authentication/InfluencerRegistration.vue"),
    name: "influencer-register",
    meta: {
      title: "influencerRegister",
      entityName: "influencer-register",
    },
  },
  {
    path: "/publisher-register",
    component: () => import(/* webpackChunkName: "PublisherRegistration" */ "../views/authentication/PublisherRegistration.vue"),
    name: "publisher-register",
    meta: {
      title: "publisherRegister",
      entityName: "publisher-register",
    },
  },
  {
    path: "/AAlabs-register",
    component: () => import(/* webpackChunkName: "AALabsRegistration" */ "../views/authentication/AALabsRegistration.vue"),
    name: "AAlabs-register",
    meta: {
      title: "AAlabsRegister",
      entityName: "AAlabs-register",
    },
  },
  /**
   * we need to only have one register route
   * and the corresponding content should be rendered according to the domain
   */
  {
    path: "/agency-register",
    component: () => import(/* webpackChunkName: "AgencyRegistration" */ "../views/authentication/AgencyRegistration.vue"),
    name: "agency-register",
    query: { lang: "" },
    meta: {
      title: "agencyRegister",
      entityName: "agency-register",
    },
  },
];
