<script>
  import { mapGetters } from "vuex";
  import Cookies from "js-cookie";
  import Help from "../icons/Help.vue";
  import Tips from "../icons/Tips.vue";
  import Banner from "../icons/Banner.vue";
  import Ideas from "../icons/Ideas.vue";
  import BoostAndEarn from "../shared/BoostAndEarn.vue";
  import ReferButton from "../shared/ReferButton.vue";
  import metaData from "./data";

  export default {
    components: {
      Ideas,
      Help,
      BoostAndEarn,
      Banner,
      Tips,
      ReferButton,
    },

    props: {
      showTitles: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        hoveredRoute: {},
      };
    },

    computed: {
      ...mapGetters("User", ["userPersona", "userBUIds"]),
      ...mapGetters("Authorization", [
        "isPartner",
        "isAdvertiser",
        "isAgency",
        "isBackoffice",
        "isFinanceUser",
        "isHead",
        "isTeamLeader",
        "hasOnlyMediaBuying",
        "hasAffAndInfAccess",
      ]),

      routes() {
        let { routes } = metaData;
        if (!this.hasAffAndInfAccess && (this.isHead || this.isTeamLeader)) {
          routes = routes.filter(
            (route) => !["validations"].includes(
                route.name,
              ),
          );
        }

        if (this.isBackoffice && !this.userBUIds.includes(3)) {
          routes = routes.filter((route) => route.name !== "links")
        }

        if (this.hasOnlyMediaBuying) {
          routes = routes.filter(
            (route) => !["partners", "extraAndDeduction"].includes(
                route.name,
              ),
          );
        }
        routes = routes
          .filter(
            (route) => route.permissions.some((permission) => this.$root.checkPermission({
                  permission: this.$permissions[permission],
                })) && route.personas.includes(this.userPersona),
          )
          .map((route) => ({
            ...route,
            name:
              this.isFinanceUser && route.name === "extraAndDeduction"
                ? "debitAndCredit"
                : route.name,
          }));
        return routes;
      },

      canViewTips() {
        return this.$root.checkPermission({
          permission: this.$permissions.VIEW_TIPS,
        });
      },

      canViewBanners() {
        return this.$root.checkPermission({
          permission: this.$permissions.VIEW_BANNERS,
        });
      },

      showDailyTipsButton() {
        if (this.isPartner) {
          return (
            this.canViewTips
            && this.$store.state.Common.dailyTips.list.length > 0
            && !this.$store.state.Common.dailyTips.loading
          );
        }

        return this.canViewTips;
      },

      helpPortal() {
        if (process.env.VUE_APP_NAME === "revgate") {
          return "https://help.revgate.net/portal/";
        }

        if (this.isPartner && process.env.VUE_APP_NAME === "boostiny") {
          return "https://supportdesk.boostiny.com/portal/";
        }
        return this.isAgency
          ? "https://agency-support.boostiny.com"
          : "https://help.boostiny.com/portal/";
      },

      goToAhaURL() {
        const env = process.env.VUE_APP_ENV;
        let envText = "";

        if (env === "testing" || env === "development") {
          envText = "-testing-sq1";
        }

        if (env === "staging") {
          envText = "-staging";
        }
        const token = Cookies.get(process.env.VUE_APP_TOKEN_KEY);
        return `https://api${envText}.boostiny.com/v2/integrations/aha/login/?token=${token}`;
      },

      canAccessIdea() {
        return (
          !this.isAdvertiser
          && !this.isAgency
          && process.env.VUE_APP_NAME !== "revgate"
        );
      },

      isUserSuspended() {
        return this.$store.getters["User/isUserSuspended"];
      },

      isUserHasDeleteRequest() {
        return this.$store.getters["User/isUserHasDeleteRequest"];
      },

      canSeeReferButton() {
        return this.$root.checkPermission({ permission: this.$permissions.CREATE_REFERRAL_INVITATION_LINK })
          && this.isPartner && !this.isUserSuspended && !this.isUserHasDeleteRequest
      },
    },

    methods: {
      onMouseOver(route) {
        this.hoveredRoute = route;
      },

      close() {
        this.$emit("close");
      },

      onMouseLeave() {
        this.hoveredRoute = {};
      },

      showDailyTipsModal() {
        this.close();
        this.$store.dispatch("Common/toggleDailyTipsModal", true);
      },
    },
  };
</script>

<template>
  <div class="links h-100">
    <div class="w-100">
      <router-link
        v-for="item in routes"
        :key="item.route"
        :class="{
          'is-active':
            $route.name === item.route || item.children.includes($route.name),
        }"
        :to="{ name: item.route }"
        class="link text-decoration-none"
        @mouseover.native="onMouseOver(item)"
        @mouseleave.native="onMouseLeave"
      >
        <component
          :is="item.icon"
          :is-active="
            $route.name === item.route || item.children.includes($route.name)
          "
          :is-hovered-on="hoveredRoute.route === item.route"
        />
        <p :class="[showTitles ? 'show' : 'hide']" class="title p-base-medium">
          {{ $t(`sideBar.${item.name}`) }}
        </p>
      </router-link>
    </div>

    <div class="w-100 p-t-12" :class="{ 'more-links': !isAdvertiser }">
      <template v-if="$root.windowWidth <= 991">
        <div
          v-if="showDailyTipsButton"
          class="link bg-base-white cursor-pointer"
          @click="showDailyTipsModal"
          @mouseleave="onMouseLeave"
          @mouseover="onMouseOver({ route: 'tips' })"
        >
          <Tips :is-hovered-on="hoveredRoute.route === 'tips'" />
          <p
            :class="[showTitles ? 'show' : 'hide']"
            class="title p-base-medium"
          >
            {{ $t("titles.dailyTips") }}
          </p>
        </div>

        <router-link
          v-if="canViewBanners"
          :to="{ name: 'banners' }"
          class="link bg-base-white text-decoration-none cursor-pointer"
          @mouseleave.native="onMouseLeave"
          @mouseover.native="onMouseOver({ route: 'banner' })"
        >
          <Banner :is-hovered-on="hoveredRoute.route === 'banner'" />
          <p
            :class="[showTitles ? 'show' : 'hide']"
            class="title p-base-medium"
          >
            {{ $t("titles.uploadBanners") }}
          </p>
        </router-link>

        <BoostAndEarn v-if="$root.checkPermission({permission: $permissions.VIEW_LOYALTY_PROGRAM}) && !isPartner" class="m-b-9" />
        <ReferButton
          v-if="canSeeReferButton" class="m-b-9"
          @close="close"
        />
      </template>

      <a
        v-if="isPartner"
        class="link text-decoration-none cursor-pointer"
        href="https://drive.google.com/drive/folders/1hk83io9WNZQRwxFbkSzBGeFUqNBfRu2D"
        target="_blank"
        @mouseleave="onMouseLeave"
        @mouseover="onMouseOver({ route: 'creatives' })"
      >
        <Banner :is-hovered-on="hoveredRoute.route === 'creatives'" />
        <p :class="[showTitles ? 'show' : 'hide']" class="title p-base-medium">
          {{ $t("sideBar.creatives") }}
        </p>
      </a>

      <a
        v-if="canAccessIdea"
        class="link text-decoration-none cursor-pointer"
        :href="goToAhaURL"
        target="_blank"
        @mouseleave="onMouseLeave"
        @mouseover="onMouseOver({ route: 'ideas' })"
      >
        <Ideas :is-hovered-on="hoveredRoute.route === 'ideas'" />
        <p :class="[showTitles ? 'show' : 'hide']" class="title p-base-medium">
          {{ $t(`sideBar.idea`) }}
        </p>
      </a>
      <a
        v-if="!isAdvertiser"
        class="link text-decoration-none cursor-pointer"
        :href="helpPortal"
        target="_blank"
        @mouseleave="onMouseLeave"
        @mouseover="onMouseOver({ route: 'help' })"
      >
        <Help :is-hovered-on="hoveredRoute.route === 'help'" />
        <p :class="[showTitles ? 'show' : 'hide']" class="title p-base-medium">
          {{ $t(`sideBar.help`) }}
        </p>
      </a>
    </div>
  </div>
</template>

<style lang="sass">
.links
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-start

  .more-links
    border-top: 1px solid var(--neutral-200)

  .link
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 10px
    width: 100%
    height: 40px
    padding: 10px
    border-radius: 6px
    margin-bottom: 4px
    transition: all .3s ease-in-out

    svg
      flex-shrink: 0

      path
        transition: all .3s ease-in-out

    .title
      transition: all .3s ease-in-out
      flex-shrink: 0
      color: var(--neutral-700)

      &.hide
        display: none

      &.show
        display: block

    &:hover, &.is-active
      background-color: var(--primary-25)

      .title
        color: var(--primary-700)
</style>
