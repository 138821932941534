import Alert from "@/components/partials/icons/Alert.vue"
import Info from "@/components/partials/icons/Info.vue"

/**
 * Available themes:
 * Info
 * Warning
 * Error
 */

const themes = {
  warning: {
    icon: Alert,
    bgClass: "bg-warning-50",
    textClass: "text-warning-700",
    borderClass: "border-warning-700",
    iconColor: "var(--warning-700)",
  },
  info: {
    icon: Info,
    bgClass: "bg-info-50",
    textClass: "text-info-700",
    borderClass: "border-info-700",
    iconColor: "var(--info-700)",
  },
  error: {
    bgClass: "bg-error-50",
    textClass: "text-error-500",
    borderClass: "border-error-500",
    iconColor: "var(--error-500)",
  },
}

export default themes
