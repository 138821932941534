<script>
  import { mapGetters } from "vuex";
  import Activator from "./icons/Activator.vue";
  import BaseModal from "./zoho-modal/Index.vue";

  export default {
    components: {
      BaseModal,
      Activator,
    },

    data() {
      return {
        showModal: false,
      };
    },

    computed: {
      ...mapGetters("Authorization", ["isPartner", "isAgency"]),
      ...mapGetters("User", ["userBUIds"]),

      user() {
        return this.$store.state.User.user;
      },
      isLoggedIn() {
        return !!Object.keys(this.user);
      },

      getZohoFormUrl() {
        if (process.env.VUE_APP_NAME === "revgate") return this.getRevGatePartnerZohoFormURL
        if (this.isPartner) return this.getPartnerZohoFormURL;
        if (this.isAgency) return this.getAgencyZohoFormURL;
        return this.getBackOfficeZohoFormURL;
      },

      getRevGatePartnerZohoFormURL() {
        return `/revgatePartnerZohoForm.html?email=${this.user.email}&name=${this.user.name}&partnerId=${this.user.internalId}&bu=${this.partnerBU}`;
      },

      getPartnerZohoFormURL() {
        return `/partnerZohoForm.html?email=${this.user.email}&name=${this.user.name}&partnerId=${this.user.internalId}&bu=${this.partnerBU}`;
      },

      getBackOfficeZohoFormURL() {
        return `/zohoForm.html?email=${this.user.email}&name=${this.user.name}`;
      },

      getAgencyZohoFormURL() {
        return `/agencyZohoForm.html?email=${this.user.email}&name=${this.user.name}`;
      },

      partnerBU() {
        if (this.userBUIds.includes(1)) {
          return "Affiliates";
        }
        if (this.userBUIds.includes(2)) {
          return "Influencers";
        }

        return "";
      },
    },

    methods: {
      openModal() {
        this.showModal = true;
      },

      hideModal() {
        this.showModal = false;
      },
    },
  };
</script>

<template>
  <div>
    <div @click="openModal">
      <slot name="trigger">
        <div class="zoho-btn">
          <Activator />
        </div>
      </slot>
    </div>
    <transition name="fade">
      <base-modal v-if="showModal" @cancel="hideModal">
        <iframe
          sandbox="allow-same-origin allow-top-navigation allow-scripts allow-forms allow-downloads allow-modals"
          :src="getZohoFormUrl"
          border="0"
          class="overflow-y-scroll custom-scroll"
          style="border: none"
        />
      </base-modal>
    </transition>
  </div>
</template>

<style lang="sass" scoped>
.zoho-btn
  width: 50px
  height: 50px
  background-color: var(--primary-500)
  border-radius: 50%
  display: grid
  place-items: center
  cursor: pointer
  position: fixed
  bottom: 15px
  right: 15px
  z-index: 2

  [dir=rtl] &
    right: auto
    left: 15px

iframe
  width: 100% !important
  min-height: calc( 100vh - 80px )
</style>
