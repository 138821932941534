<script>
  import BaseImage from "@/components/partials/BaseImage.vue";
  import mixin from "../mixin";

  export default {
    components: { BaseImage },

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      sender() {
        return this.notification.content.sender.name;
      },
      campaign() {
        return this.notification.content.campaign;
      },
      previousNumberOfDays() {
        return this.notification.content.validation_days.old;
      },
      newNumberOfDays() {
        return this.notification.content.validation_days.new;
      },
    },

    mounted() {
      this.setNotificationAsRead();
    },
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div class="img-container m-r-12">
      <BaseImage
        :src="campaign.logo"
        width="40"
        height="40"
        class="radius-full fitted-img"
      />
    </div>
    <div>
      <p class="p-base-medium text-neutral-400 m-b-9">
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.validation`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ campaign.name }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.changed`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ previousNumberOfDays }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.to`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ newNumberOfDays }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.by`)
        }}</span>
        <span class="text-neutral-900">{{ sender }}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
