<script>
  import formatDate from "@/utils/date/format";
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      cycle() {
        return this.notification.content.cycle;
      },
    },

    methods: {
      formatCycle() {
        return formatDate({ date: this.cycle, options: { month: "long" } });
      },
      setFilters() {
        const filtersData = this.storageFiltersData;

        if (this.$router.currentRoute.name === "partner-payment") {
          this.$store.dispatch("Globals/setFilter", {
            key: "cycle",
            value: this.cycle,
          });
          return;
        }

        filtersData["partner-payment"] = {
          buIds: [],
          cycle: this.cycle,
          dimensions: ["campaign_id", "campaign_name"],
          metrics: [
            "validated_orders",
            "validated_sales_amount_usd",
            "validated_revenue",
            "campaign_id",
            "partner_id",
            "bonus",
            "campaign_id",
            "partner_id",
            "fine",
          ],
          partner: "",
          sortDirection: "desc",
          sortMetrics: "",
        };
        this.setLocalStorage("filters", filtersData);
        this.viewPartnerPayment();
      },
    },
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div>
      <p
        class="p-base-medium text-neutral-400 m-b-9"
        :class="{ 'cursor-pointer ': canViewPartnerPaymentReport }"
        @click="setFilters"
      >
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"payment-report"}`)
        }}</span>
        <span class="m-r-3 text-neutral-900">{{ formatCycle() }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"ready"}`)
        }}</span>
        <span>{{
          $t(`notifications.${notification.type}.${"please-check"}`)
        }}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
