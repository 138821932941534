export default {
  markAllAsRead: ({ persona }) => {
    if (persona === "agency") return "agency/notifications/mark-all-read"
    return "notifications/mark-all-read"
  },
  unseenCount: ({ persona }) => {
    if (persona === "agency") return "agency/notifications/unseen-counter"
    return "notifications/unseen-counter"
  },
  unreadCount: ({ persona }) => {
    if (persona === "agency") return "agency/notifications/unread-counter"
    return "notifications/unread-counter"
  },
  setNotificationsToBeSeen: ({ id, persona }) => {
    if (persona === "agency") return `agency/notifications/${id}?status=seen`
    return `notifications/${id}?status=seen`
  },
}
