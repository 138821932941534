import mixpanel from "mixpanel-browser";

const SUPPORTED_PLATFORMS = {
  mixpanel: mixpanelTracking,
  gtag: gtagTracking,
}

function mixpanelTracking({
  name,
  superProperties,
  properties,
}) {
  mixpanel.track(name, {
    ...superProperties,
    ...properties,
  })
}

function gtagTracking({
  name,
  properties,
}) {
  window.gtag("event", name, properties)
}

function getPlatforms(platforms) {
  if (platforms.length && platforms.some((platform) => !Object.keys(SUPPORTED_PLATFORMS)
    .includes(platform))) {
    throw new Error("Invalid platform")
  }

  return platforms.length ? platforms : Object.keys(SUPPORTED_PLATFORMS)
}

function registerEvent({
  platforms = [],
  name,
  properties = {},
  superProperties = {},
}) {
  getPlatforms(platforms)
    .forEach((platform) => {
      SUPPORTED_PLATFORMS[platform]({
        name,
        properties,
        superProperties,
      })
    })
}

function resetTracking() {
  mixpanel.reset()
}

function setUserTrackingProfile({ data }) {
  mixpanel.identify(data.id)
  mixpanel.people.set({
    $name: data.name,
    $email: data.email,
    user_role_name: data.roleName,
    user_persona: data.persona,
    business_unit: data.bus ? data.bus.map((el) => el.name)
      .join(", ") : "AN, INF, MB, AA-LABS",
  })
}

function trackPageView(properties = {}) {
  mixpanel.track("page_screen_viewed", properties)
}

export {
  registerEvent, resetTracking, setUserTrackingProfile, trackPageView,
}
