export default {
  partnerDashboard: {
    myCampaigns: "حملاتي",
    myCoupons: "الكوبونات آلخاصة بي",
    activeCampaign: "نشط انضم الحملات",
    activeCoupons: "الكوبونات المعينة النشطة",
    dataNumbers: '<span class="text-secondary-500 heading-3-light">{activeData}</span> من أصل <span class="heading-3-light">{totalData}</span>',
    viewAll: "مشاهدة الكل",
    conversions: "التحويلات",
    revenues: "الإيرادات",
    salesAmount: "مبلغ المبيعات",
    outOf: "من أصل",

  },
}
