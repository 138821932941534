import store from "@/store";

export default [
  {
    path: "/notifications",
    component: () => import(/* webpackChunkName: "NotificationsIndex" */ "../views/notifications/Index.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        name: "notifications",
        component: () => import(/* webpackChunkName: "NotificationsList" */ "../views/notifications/List.vue"),
        meta: {
          title: "systemNotifications",
          entityName: "notifications",
          filtersScheme: {
            tag: "",
          },
        },
      },
      {
        path: "settings",
        name: "notifications-settings",
        component: () => import(/* webpackChunkName: "NotificationSettings" */ "../views/notifications/Settings.vue"),
        meta: {
          title: "notificationSettings",
          entityName: "notifications-settings",
        },
        beforeEnter(to, from, next) {
          const isLoginAsActivated = store.getters["Authorization/isLoginAsActivated"]
          if (isLoginAsActivated) {
            next({ name: "overview" })
          } else {
            next()
          }
        },
      },

    ],
  },

]
