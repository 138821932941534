<script>
  import BellDropDown from "@/components/notifications/bell-dropdown/Index.vue";
  import { mapGetters } from "vuex";
  import ErrorView from "./ErrorView.vue";
  import ProfileDetailsPopup from "./ProfileDetailsPopup.vue";
  import GoToChat from "./GoToChat.vue";
  import Tips from "./Tips.vue";
  import Banners from "./Banners.vue";
  import ReferButton from "../shared/ReferButton.vue";
  import BoostAndEarn from "../shared/BoostAndEarn.vue";
  import Menu from "../icons/Menu.vue";

  export default {
    components: {
      ErrorView,
      ProfileDetailsPopup,
      GoToChat,
      BellDropDown,
      Tips,
      Banners,
      BoostAndEarn,
      Menu,
      ReferButton,
    },

    computed: {
      ...mapGetters("Authorization", [
        "isBackoffice",
        "isPartner",
      ]),

      isUserSuspended() {
        return this.$store.getters["User/isUserSuspended"];
      },

      isUserHasDeleteRequest() {
        return this.$store.getters["User/isUserHasDeleteRequest"];
      },

      isLoginAsActivated() {
        return this.$store.getters["Authorization/isLoginAsActivated"]
      },

      canSeeNotificationsDropdown() {
        return (this.$root.checkPermission({ permission: this.$permissions.ACCESS_NOTIFICATIONS })
        && !this.isUserSuspended
        && !this.isUserHasDeleteRequest)
      },

      isRevGatePartner() {
        return process.env.VUE_APP_NAME === "revgate"
      },

      canSeeReferButton() {
        return this.$root.checkPermission({ permission: this.$permissions.CREATE_REFERRAL_INVITATION_LINK })
          && this.isPartner && !this.isUserSuspended && !this.isUserHasDeleteRequest
      },
    },

    methods: {
      toggleLang() {
        window.toggleLang()
      },
    },
  }
</script>

<template>
  <div class="base-new-header d-flex justify-content-between align-items-center">
    <ErrorView
      v-if="
        $router.currentRoute.name === 'not-found' ||
          !Object.keys($store.state.User.user).length
      "
    />
    <template v-else>
      <div>
        <div class="logo d-flex justify-content-between align-items-center gap-15">
          <Menu
            v-if="$root.windowWidth <= 991" class="cursor-pointer"
            @click.native="$emit('close')"
          />

          <router-link class="m-t-6" :to="{ name: 'overview' }">
            <img
              v-if="$root.windowWidth > 991"
              :src="require(`@/assets/icons/${isRevGatePartner ? 'revgateLogos/main-logo.svg' : 'boostinyLogos/boostinyWordLogo.svg'}`)"
              width="120"
            >
            <img
              v-else
              :src="require(`@/assets/icons/${isRevGatePartner ? 'revgateLogos/icon-logo.svg' : 'boostinyLogos/boostinyRedLogo.svg'}`)"
            >
          </router-link>

          <div class="lang-btn cursor-pointer" @click="toggleLang">
            <span class="heading-6-bold text-uppercase">{{ $i18n.locale === 'ar' ? 'en' : 'ar' }}</span>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-center gap-18">
        <template v-if="$root.windowWidth > 991">
          <ReferButton v-if="canSeeReferButton" />
          <BoostAndEarn v-if="$root.checkPermission({permission: $permissions.VIEW_LOYALTY_PROGRAM}) && !isPartner" />
          <Banners v-if="$root.checkPermission({permission: $permissions.VIEW_BANNERS}) && !isPartner" />
          <Tips />
          <div class="separator" />
        </template>
        <GoToChat class="m-t-3" />
        <BellDropDown v-if="canSeeNotificationsDropdown" class="m-t-6" />
        <ProfileDetailsPopup />
      </div>
    </template>
  </div>
</template>

<style scoped lang="sass">
.base-new-header
  position: sticky
  top: 0
  left: 0
  width: 100vw
  height: 80px
  background-color: var(--base-white)
  z-index: 5
  border-bottom: 1px solid var(--neutral-200)
  padding-inline: 30px
  padding-block: 15px

  .lang-btn
    width: 40px
    height: 40px
    display: grid
    place-items: center
    border-radius: 8px
    border: 1px solid var(--neutral-300)
    transition: all .2s ease-in-out

    @media (max-width: 767px)
      width: 35px
      height: 35px

    &:hover
      background-color: var(--primary-50)
      border-color: var(--primary-50)
      span
        color: var(--primary-500)

    span
      transition: all .2s ease-in-out
      color: var(--neutral-500)

  .separator
    display: block
    width: 1px
    height: 25px
    background-color: var(--neutral-200)
</style>
