<script>
  import Close from "@/components/partials/icons/Close.vue";

  export default {
    components: { Close },
    data() {
      return {};
    },

    computed: {},

    methods: {
      cancel() {
        this.$emit("cancel");
      },
    },
  };
</script>

<template>
  <div
    class="d-flex justify-content-between"
    :class="
      'w-100 d-flex align-items-center p-x-30 p-y-9 radius-8 container '
    "
  >
    <div class="title d-flex justify-content-start align-items-start">
      <p class="heading-5-bold p-x-6">
        Tickets Form
      </p>
    </div>

    <Close
      color="var(--primary-700)"
      class="cursor-pointer  hover "
      @click.native="cancel"
    />
  </div>
</template>

<style scoped lang='sass'>
.container
  .title
      width: 100%
      .result-num
          background-color: var(--error-500)
          color: var(--base-white)
</style>
