<script>

  export default {
    props: {
      isSmall: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  };
</script>

<template>
  <div
    class="d-flex justify-content-start align-items-center w-100"
    :class="{'is-small': isSmall}"
  >
    <div class="img-container m-r-12">
      <div

        class="radius-full shimmer-loader img-notif-loader"
        alt=""
      />
    </div>
    <div class="details m-t-9 m-r-9 w-100">
      <p class="m-b-9 p-9 shimmer-loader w-100" />
      <p class="p-base-light p-6 shimmer-loader date-shimmer" />
    </div>
  </div>
</template>

<style scoped lang="sass">
.is-small
    .img-notif-loader
        width: 40px !important
        height: 40px !important

.img-notif-loader
    width: 70px
    height: 70px

.date-shimmer
    width:50%
</style>
