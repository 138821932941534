<script>
  import BaseImage from "@/components/partials/BaseImage.vue";
  import mixin from "../mixin";

  export default {
    components: {
      BaseImage,
    },

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      campaign() {
        return this.notification.content.campaign;
      },
      isPartner() {
        return this.$store.getters["Authorization/isPartner"];
      },
    },

    methods: {},
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div class="img-container m-r-12">
      <BaseImage
        :src="campaign.logo_url"
        width="40"
        height="40"
        class="radius-full cursor-pointer fitted-img"
      />
    </div>
    <div class="m-r-15">
      <p
        class="p-base-medium text-neutral-400 d-flex flex-wrap m-b-9"
        :class="{ 'cursor-pointer': canViewCampaigns }"
        @click="viewCampaignDetails"
      >
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.campaign`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ campaign.name }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.consumed`)
        }}</span>
        <span v-if="isPartner" class="m-r-3">{{
          $t(`notifications.${notification.type}.pleaseCheck`)
        }}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
