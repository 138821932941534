<script>
  export default {
    data() {
      return {};
    },

    methods: {},
  };
</script>

<template>
  <div class="w-100 d-flex align-items-center p-x-18 container">
    <img src="@/assets/icons/alert-icon.svg" alt="alert-icon">
    <span class="heading-5-bold m-l-6">{{ $t("labels.rejectionReason") }}</span>
  </div>
</template>

<style scoped lang='sass'>
.container
  height: 56px
</style>
