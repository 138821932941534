<script>
  import mixin from "../mixin";

  export default {
    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      payout() {
        return this.notification.content.payout;
      },
      bu() {
        return this.notification.content.bu;
      },
      payoutType() {
        const { type } = this.payout;
        return type.slice(0, 1).toUpperCase() + type.slice(1);
      },
      campaign() {
        return this.notification.content.campaign;
      },

      getContent() {
        let value = [];
        if (this.payout.type === "partner") {
          value = this.notification.content.partners.map(
            (el) => `${el.name} - ${el.internalId}`,
          );
        }

        if (this.payout.type === "coupon") {
          value = this.notification.content.coupons.map((el) => `${el.code}`);
        }

        return value.join(", ");
      },
    },

    methods: {
      viewCampaignPayouts() {
        if (
          ["team-leader", "team-member", "head"].includes(
            this.$store.state.User.user.roleName,
          )
        ) {
          this.$router.push({
            name: "campaign-payouts",
            params: {
              id: this.campaign.id,
            },
          });
        }
        this.setNotificationAsRead();
      },
    },
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div>
      <p
        class="cursor-pointer p-base-medium text-neutral-400 m-b-9"
        @click="viewCampaignPayouts()"
      >
        <span class="text-neutral-900 m-r-3">{{ payoutType }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"payout-condition"}`)
        }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"for"}`)
        }}</span>
        <span v-if="getContent" class="m-r-3 text-neutral-900">({{ getContent }})</span>
        <span class="m-r-3 text-neutral-900">{{
          $t(`labels.${bu.name}`) + " BU"
        }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"due"}`)
        }}</span>
        <span class="m-r-6 text-neutral-900">{{ payout.endDate }}.</span>
        <span>{{
          $t(`notifications.${notification.type}.${"update-condition"}`)
        }}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
