<script>
  import TextareaField from "@/components/partials/inputs/TextareaField.vue";

  export default {
    components: {
      TextareaField,
    },
    props: {
      errorMessage: {
        type: String,
        required: false,
        default: "",
      },
      theme: {
        required: false,
        default: "neutral",
        type: String,
      },
      value: {
        type: String,
        required: true,
      },
      label: {
        required: true,
        type: String,
      },
    },
    data() {
      return {};
    },

    methods: {
      setUpdatedValue({ value }) {
        this.$emit("update-value", value);
      },

      submit() {
        this.$emit("submit");
      },

      reset() {
        this.$emit("reset");
      },
    },
  };
</script>

<template>
  <div class="bg-neutral-100 modal-body p-18">
    <div class="col-12">
      <TextareaField
        :value="value"
        input-id="application"
        :theme="theme"
        :message="errorMessage"
        placeholder="rejectionReason"
        @update-value="setUpdatedValue"
        @reset="reset"
        @submit="submit"
      />
    </div>
  </div>
</template>

<style scoped lang='sass'>
</style>
