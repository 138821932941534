<script>
  import setSelectedFiltersOptionsV2 from "@/utils/input-output/set-selected-filters-options-v2/index";
  import BaseImage from "@/components/partials/BaseImage.vue";
  import mixin from "../mixin";

  export default {
    components: { BaseImage },

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      content() {
        return this.notification.content;
      },
      cycle() {
        return this.content.campaign_validation.cycle.slice(0, 7);
      },
    },

    methods: {
      setFilters() {
        const filtersData = this.storageFiltersData;

        setSelectedFiltersOptionsV2({
          route: "campaign-validation",
          name: "campaign",
          data: [
            {
              id: this.content.campaign.id,
              value: this.content.campaign.id,
              name: this.content.campaign.name,
            },
          ],
        });

        if (this.$router.currentRoute.name === "campaign-validation") {
          this.$store.dispatch("Globals/setFilter", {
            key: "cycle",
            value: this.content.campaign_validation.cycle,
          });
          this.$store.dispatch("Globals/setFilter", {
            key: "campaign",
            value: this.content.campaign.id,
          });

          return;
        }

        filtersData["campaign-validation"] = {
          cycle: this.content.campaign_validation.cycle,
          campaign: this.content.campaign.id,
          dimensions: [],
          metrics: [],
        };
        this.setLocalStorage("filters", filtersData);
        this.viewValidations();
      },
    },
  };
</script>
<template>
  <div class="d-flex align-items-center" @click="setFilters">
    <div class="m-r-12">
      <BaseImage
        :src="content.sender.avatar"
        width="40"
        height="40"
        class="radius-full fitted-img"
      />
    </div>
    <div>
      <p class="p-base-medium text-neutral-400 m-b-9 cursor-pointer">
        <span class="text-neutral-900 m-r-3">{{ content.sender.name }}</span>

        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.mention`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ content.campaign.name }}</span>
        <span class="text-neutral-900 m-r-3">{{ cycle }}</span>
        <span>{{ $t(`notifications.${notification.type}.validation`) }}.</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
