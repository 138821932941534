<script>
  export default {
    props: {
      color: {
        type: String,
        required: false,
        default: "var(--neutral-500)",
      },
      width: {
        type: Number,
        default: 20,
      },
      height: {
        type: Number,
        default: 21,
      },
    },
  };
</script>

<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_941_33504)">
      <path
        d="M9.9999 6.76335V10.0967M9.9999 13.43H10.0082M8.5749 2.48002L1.51656
        14.2634C1.37104 14.5154 1.29403 14.8011 1.29322 15.0921C1.2924 15.3831 1.3678
        15.6693 1.51192 15.9221C1.65603 16.175 1.86383 16.3856 2.11465 16.5332C2.36547 16.6808
        2.65056 16.7602 2.94156 16.7634H17.0582C17.3492 16.7602 17.6343 16.6808 17.8851
        16.5332C18.136 16.3856 18.3438 16.175 18.4879 15.9221C18.632 15.6693 18.7074
        15.3831 18.7066 15.0921C18.7058 14.8011 18.6288 14.5154 18.4832 14.2634L11.4249
        2.48002C11.2763 2.23511 11.0672 2.03262 10.8176 1.89209C10.568 1.75156 10.2863
        1.67773 9.9999 1.67773C9.71345 1.67773 9.43184 1.75156 9.18223 1.89209C8.93263
        2.03262 8.72345 2.23511 8.5749 2.48002Z"
        :stroke="color"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_941_33504">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.0966797)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<style scoped lang='sass'></style>
