<script>
  import Layout from "../../layouts/Errors.vue";
  import PageNotFound from "../../components/errors/PageNotFound[404].vue"

  export default {
    components: {
      Layout,
      PageNotFound,
    },
    props: {},
    data() {
      return {};
    },

    computed: {},

    methods: {},
  };
</script>

<template>
  <Layout>
    <PageNotFound />
  </Layout>
</template>

<style scoped lang='sass'></style>
