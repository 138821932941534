<script>
  import setSelectedFiltersOptionsV2 from "@/utils/input-output/set-selected-filters-options-v2/index";
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      content() {
        return this.notification.content;
      },
      cycle() {
        return this.content.payment_validation.cycle.slice(0, 7);
      },
    },

    methods: {
      setFilters() {
        const filtersData = this.storageFiltersData;

        setSelectedFiltersOptionsV2({
          route: "payments-and-invoices",
          name: "partners",
          data: [
            {
              id: this.content.partner.id,
              value: this.content.partner.id,
              name: this.content.partner.name,
            },
          ],
        });

        if (this.$router.currentRoute.name === "payments-and-invoices") {
          this.$store.dispatch("Globals/setFilter", {
            key: "cycle",
            value: this.content.payment_validation.cycle,
          });
          this.$store.dispatch("Globals/setFilter", {
            key: "partners",
            value: [this.content.partner.id],
          });
          this.$store.dispatch("Globals/setFilter", {
            key: "businessUnit",
            value: this.content.bu.id,
          });

          return;
        }

        filtersData["payments-and-invoices"] = {
          cycle: this.content.payment_validation.cycle,
          partners: [this.content.partner.id],
          businessUnit: this.content.bu.id,
        };
        this.setLocalStorage("filters", filtersData);
        this.viewPaymentValidations();
      },
    },
  };
</script>
<template>
  <div @click="setFilters">
    <p
      class="p-base-medium text-neutral-400 m-b-9"
      :class="{ 'cursor-pointer': canViewPaymentValidation }"
    >
      <span class="text-neutral-900 m-r-3">{{ content.sender.name }}</span>

      <span class="m-r-3">{{
        $t(`notifications.${notification.type}.comment-mention`)
      }}</span>
      <span class="text-neutral-900 m-r-3">{{ content.partner.name }}</span>

      <span class="m-r-3">{{
        $t(`notifications.${notification.type}.in`)
      }}</span>
      <span class="text-neutral-900 m-r-3">{{ cycle }}</span>

      <span>{{ $t(`notifications.${notification.type}.payment`) }}</span>
    </p>
  </div>
</template>
<style scoped lang='sass'></style>
