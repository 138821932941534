import Vue from "vue";
import VueI18n from "vue-i18n";

import ar from "./locales/ar/index";
import en from "./locales/en/index";

Vue.use(VueI18n);

const locale = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";

const messages = {
  ar,
  en,
};

const i18n = new VueI18n({
  locale,
  fallbackLocale: "en",
  silentFallbackWarn: true,
  messages,
});

export default i18n;
