<script>
  export default {
    props: {
      src: {
        type: String,
        required: false,
        default: "",
      },

      customFullback: {
        type: String,
        required: false,
        default: "",
      },

      alt: {
        type: String,
        required: false,
        default: "",
      },

      width: {
        type: [Number, String],
        default: "auto",
      },
    },

    computed: {},
  };
</script>

<template>
  <img
    :src="src || ''"
    :alt="alt"
    :onerror="`this.onerror=null;this.src='${
      customFullback || require('@/assets/images/shared/any-missing-icon.svg')
    }';`"
    :width="width"
    :height="width"
  >
</template>
