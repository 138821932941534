import formatDate from "@/utils/date/format"

/**
 * to be modified
 */
export default [
  {
    path: "/validations",
    name: "validations",
    title: "validations",
    component: () => import(
      /* webpackChunkName: "Validations" */ "../views/validations/Index.vue"
    ),
    meta: {
      requireAuth: true,
      title: "validations",
    },
    children: [
      {
        path: "campaigns-summary",
        name: "campaigns-summary",
        meta: {
          hasFilters: true,
          apiRouteKey: "payments/campaigns-summary", // the api route name
          filtersScheme: {
            cycle: formatDate({ date: new Date(new Date().getFullYear(), new Date().getMonth(), 1), locale: "fr-CA" }),
            campaigns: [],
            coupons: [],
            teamLeaders: [],
            teamMembers: [],
            buIds: [],
            dimensions: [],
            metrics: [],
            sortMetrics: "",
            sortDirection: "desc",
            campaignModel: "all",
            heads: [],
            accountabilityStatus: "",
            validationStatus: [],
          },
          title: "campaignSummary",
          entityName: "campaigns-summary",
        },
        component: () => import(
          /* webpackChunkName: "CampaignSummary" */ "../views/validations/CampaignsSummary.vue"
        ),
      },
      {
        path: "campaign",
        name: "campaign-validation",
        meta: {
          hasFilters: true,
          apiRouteKey: "payments/campaigns/validations", // the api route name
          filtersScheme: {
            cycle: formatDate({ date: new Date(new Date().getFullYear(), new Date().getMonth(), 1), locale: "fr-CA" }),
            buIds: [],
            campaign: "",
            coupons: [],
            maxCancellationRatio: "",
            validationStatus: [],
            dimensions: [],
            metrics: [],
            sortMetrics: "",
            sortDirection: "desc",
          },
          title: "campaignValidation",
          entityName: "campaign-validation",
        },
        component: () => import(
          /* webpackChunkName: "CampaignValidation" */ "../views/validations/Campaign.vue"
        ),
      },
      {
        path: "partners",
        name: "partners-validation",
        meta: {
          hasFilters: true,
          apiRouteKey: "payments/partners-validation",
          filtersScheme: {
            dimensions: [],
            metrics: [],
            cycle: formatDate({ date: new Date(new Date().getFullYear(), new Date().getMonth(), 1), locale: "fr-CA" }),
            partners: [],
            isSuspended: "",
            bu: "",
            status: "",
            sortMetrics: "partner_name",
            sortDirection: "desc",
          },
          title: "partnersValidation",
          entityName: "partners-validation",
        },
        component: () => import(
          /* webpackChunkName: "PartnersValidation" */ "../views/validations/Partners.vue"
        ),
      },
      {
        path: "payment-cycles",
        name: "payment-cycles-validation",
        meta: {
          title: "paymentCyclesValidation",
          entityName: "payment-cycles-validation",
        },
        component: () => import(
          /* webpackChunkName: "PaymentCycles" */ "../views/validations/PaymentCycles.vue"
        ),
      },
      {
        path: "validation-sheet",
        name: "validation-sheet",
        meta: {
          hasFilters: true,
          filtersScheme: {
            campaignId: "",
            cycle: formatDate({ date: new Date(new Date().getFullYear(), new Date().getMonth(), 1), locale: "fr-CA" }),
          },
          title: "ValidationSheet",
          entityName: "validation-sheet",
        },
        component: () => import(/* webpackChunkName: "ValidationSheet" */ "../views/validations/ValidationSheet.vue"),
      },

    ],
  },
];
