<script>
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      partner() {
        return this.notification.content.partner;
      },
      role() {
        return this.notification.content.role;
      },
    },

    methods: {},
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div>
      <p
        class="p-base-medium text-neutral-400 m-b-9 cursor-pointer"
        @click="viewPartnerDetails()"
      >
        <span class="m-r-3 text-neutral-900">{{ partner.name }}'s</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}`)
        }}</span>
        <span class="text-neutral-900">{{ role.name }}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
