<script>
  import formatDate from "@/utils/date/format";
  import Type from "./Type.vue";

  export default {
    components: { Type },
    props: {
      date: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      isDropdownView: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {};
    },
    computed: {
      isPartner() {
        return this.$store.getters["Authorization/isPartner"];
      },
    },
    methods: {
      formatDate(date) {
        const now = new Date();
        const diff = now - new Date(date);
        const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));

        const timeOptions = {
          hour: "numeric",
          minute: "numeric",
        };

        const dateOptions = {
          year: "numeric",
          month: "short",
          day: "numeric",
        };

        const isoFormattedDate = new Date(
          `${new Date(date).toString().split("GMT")[0]} UTC`,
        ).toISOString();

        if (diffInDays === 0) {
          return `Today at ${formatDate({
            date: isoFormattedDate,
            options: timeOptions,
          })}`;
        } if (diffInDays === -1) {
          return `Yesterday at ${formatDate({
            date: isoFormattedDate,
            options: timeOptions,
          })}`;
        }
        return formatDate({ date: isoFormattedDate, options: dateOptions });
      },
    },
  };
</script>
<template>
  <div class="d-flex gap-6">
    <Type v-if="isPartner && isDropdownView" :type="type" />
    <p class="p-base-light">
      {{ formatDate(date) }}
    </p>
  </div>
</template>

<style lang="sass" scoped>
</style>
