function trimStartWhitespace(value) {
  if (value.startsWith(" ")) {
    return value.trimStart();
  }
  return value;
}

function trimEndWhitespace(value) {
  if (value.endsWith(" ")) {
    return value.trimEnd();
  }
  return value;
}

export default function trimSpaces(value) {
  return trimStartWhitespace(trimEndWhitespace(value));
}
