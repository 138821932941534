export default {
  partnerDashboard: {
    myCampaigns: "My Campaigns",
    myCoupons: "My Coupons",
    activeCampaign: "Active joined campaigns",
    activeCoupons: "Active assigned coupons",
    dataNumbers: '<span class="text-secondary-500 heading-3-light">{activeData}</span> out of <span class="heading-3-light">{totalData}</span>',
    viewAll: "View All",
    conversions: "Conversions",
    revenues: "Revenues",
    salesAmount: "Sales Amount",
    "campaign-application-approved": "Request to join {campaignName} campaign got approved",
    "coupon-allocation-assigned": "{codeLength}  Coupon codes for {campaignName}",
    "coupon-request-allocated": "Allocate {couponRequestAllocatedCount} coupon code(s) request for campaign {campaignName}",
    outOf: "out of",
  },
}
