<script>
  import DesktopView from "./DesktopView.vue"
  import MobileView from "./MobileView.vue"

  export default {
    components: { DesktopView, MobileView },

    props: {
      showSidebar: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      close() {
        this.$emit("close")
      },
    },

  }
</script>

<template>
  <div>
    <DesktopView v-if="$root.windowWidth > 991" />
    <MobileView
      v-else :show-sidebar="showSidebar"
      @close="close"
    />
  </div>
</template>

<style lang="sass">

</style>
