import Cookies from "js-cookie";
import { signOut } from "firebase/auth"
import { auth } from "@/config/firebase"
import { resetTracking } from "@/services/TrackingManagement";
import router from "@/router";
import API from "../../utils/input-output/api/index";
import constants from "./constants"

export default {
  namespaced: true,

  state: {
    isUserLoggedInFirebase: false,
    isGoogleClientInitialized: false,
  },

  mutations: {
    SET_IS_USER_LOGGED_IN_FIREBASE(state, data) {
      state.isUserLoggedInFirebase = data;
    },
    SET_IS_GOOGLE_CLIENT_INITIALIZED(state, data) {
      state.isGoogleClientInitialized = data;
    },
  },

  actions: {
    handleInvalidTokens() {
      Cookies.remove(process.env.VUE_APP_TOKEN_KEY);
      Cookies.remove(process.env.VUE_APP_REFRESH_TOKEN_KEY);
      Cookies.remove(process.env.VUE_APP_LOGIN_AS_TOKEN_KEY);
      Cookies.remove(process.env.VUE_APP_FIREBASE_TOKEN_KEY);
      Cookies.remove("hideGoToAppPopup");
      resetTracking()

      const finalDestinationExpiration = 30 / (24 * 60) // 30 min
      Cookies.set("finalDestination", router.currentRoute.fullPath, { expires: finalDestinationExpiration, path: "/" });

      window.location.reload()
    },

    logout({ dispatch, rootGetters }) {
      const token = Cookies.get(process.env.VUE_APP_TOKEN_KEY);
      const isLoginAsActivated = rootGetters["Authorization/isLoginAsActivated"]

      // set the user online status offline if the real logged-in user presses logout
      if (!isLoginAsActivated) {
        dispatch("Chat/changeFirebaseUserOnlineStatus", false, { root: true });
      }

      if (!token) {
        dispatch("clearUserData");
        return;
      }

      API({
        url: constants.logout(),
        method: "POST",
        initiator: "global",
        requestId: "logout",
        blockScreen: true,
      })
        .then(() => {
          signOut(auth)
          dispatch("clearUserData");
        })
        .catch(() => {
          /**
           * we need to end the user session in case of any error returned from the logout
           */

          if (!isLoginAsActivated) {
            signOut(auth)
          }
          dispatch("clearUserData");
        });
    },

    clearUserData() {
      const lang = localStorage.getItem("lang") || "en";
      Cookies.remove(process.env.VUE_APP_TOKEN_KEY);
      Cookies.remove(process.env.VUE_APP_REFRESH_TOKEN_KEY);
      Cookies.remove(process.env.VUE_APP_LOGIN_AS_TOKEN_KEY);
      Cookies.remove(process.env.VUE_APP_FIREBASE_TOKEN_KEY);
      Cookies.remove("hideGoToAppPopup");
      resetTracking()
      localStorage.clear();
      localStorage.setItem("lang", lang);
      window.location.reload();
    },

    setIsUserLoggedInFirebase({ commit }, value) {
      commit("SET_IS_USER_LOGGED_IN_FIREBASE", value)
    },
    setIsGoogleClientInitialized({ commit }, value) {
      commit("SET_IS_GOOGLE_CLIENT_INITIALIZED", value)
    },
  },
};
