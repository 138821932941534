<script>
  import TimeLineItemShimmer from "../../partials/base-timeline/TimeLineItemShimmer.vue";

  export default {
    components: { TimeLineItemShimmer },
    data() {
      return {};
    },
    computed: {},
    methods: {},
  };
</script>
<template>
  <div>
    <div
      v-for="n in 3" :key="n"
      class="p-y-18 p-x-21"
    >
      <TimeLineItemShimmer :is-small="true" />
    </div>
  </div>
</template>

<style lang="sass" scoped>
</style>
