<script>
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      lang() {
        return this.$root.lang;
      },
      cycle() {
        return this.notification.content.cycle.slice(0, 7);
      },

      legalEntity() {
        return this.notification.content?.legalEntity?.name || "";
      },
    },

    methods: {},
  };
</script>
<template>
  <div @click="viewInvoice">
    <p
      class="p-base-medium text-neutral-400 m-b-9"
      :class="{ 'cursor-pointer': canViewInvoices }"
    >
      <span v-if="lang === 'en'" class="m-r-3 text-neutral-900">{{
        legalEntity
      }}</span>
      <span class="m-r-3">{{
        $t(`notifications.${notification.type}.invoice-for`)
      }}</span>
      <span v-if="lang === 'ar'">
        <span class="m-r-3 text-neutral-900">{{ legalEntity }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.month`)
        }}</span>
      </span>
      <span class="text-neutral-900 m-r-3">{{ cycle }}</span>

      <span class="m-r-3">{{
        $t(`notifications.${notification.type}.paid`)
      }}</span>
    </p>
  </div>
</template>
<style scoped lang='sass'></style>
