<script>
  import { mapGetters } from "vuex";
  import Tips from "../icons/Tips.vue";

  export default {
    components: { Tips },

    computed: {
      ...mapGetters("Authorization", [
        "isPartner",
      ]),

      showDailyTipsButton() {
        if (this.isPartner) {
          return (
            this.$store.state.Common.dailyTips.list.length > 0
            && !this.$store.state.Common.dailyTips.loading
            && this.$root.checkPermission({ permission: this.$permissions.VIEW_TIPS })
          );
        }
        return this.$root.checkPermission({
          permission: this.$permissions.VIEW_TIPS,
        });
      },
    },

    methods: {
      showDailyTipsModal() {
        this.$store.dispatch("Common/toggleDailyTipsModal", true);
      },
    },
  }
</script>

<template>
  <div
    v-if="showDailyTipsButton"
    class="base-tooltip cursor-pointer d-flex justify-content-center align-items-center"
    :data-tooltip="$t('titles.dailyTips')"
    @click="showDailyTipsModal"
  >
    <Tips />
  </div>
</template>

<style scoped lang="sass">

</style>
