/**
 * Coupons
 */
const permissions = {

  VIEW_COUPONS: {
    value: "view-coupons",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  EXPORT_COUPONS: {
    value: "export-coupons",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  UPLOAD_COUPONS: {
    value: "upload-coupons",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "agency"],
  },
  EDIT_COUPONS_ADSET: {
    value: "edit-coupons-adset",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "agency"],
  },
  EDIT_COUPONS_COUNTRY: {
    value: "edit-coupons-country",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "agency"],
  },
  ALLOCATE_COUPONS: {
    value: "allocate-coupons",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "agency"],
  },
  VIEW_ARCHIVED_COUPONS: {
    value: "view-archived-coupons",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "agency"],
  },
  ARCHIVE_COUPONS: {
    value: "archive-coupons",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "agency"],
  },
  UNARCHIVE_COUPONS: {
    value: "unarchive-coupons",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "agency"],
  },
  VIEW_COUPON_DETAILS: {
    value: "view-coupon-details",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "agency"],
  },
  DELETE_COUPON_ALLOCATION: {
    value: "delete-coupon-allocation",
    module: "coupons",
    subModule: "existing",
    personas: ["backoffice", "agency"],
  },
  VIEW_MISSING_COUPONS: {
    value: "view-missing-coupons",
    module: "coupons",
    subModule: "missing",
    personas: ["backoffice"],
  },
  MANAGE_MISSING_COUPONS: {
    value: "manage-missing-coupons",
    module: "coupons",
    subModule: "missing",
    personas: ["backoffice"],
  },
  VIEW_COUPON_REQUESTS: {
    value: "view-coupon-requests",
    module: "coupons",
    subModule: "requests",
    personas: ["backoffice", "partner", "agency"],
  },
  MANAGE_COUPON_REQUESTS: {
    value: "manage-coupon-requests",
    module: "coupons",
    subModule: "requests",
    personas: ["backoffice", "agency"],
  },
  CREATE_COUPON_REQUEST: {
    value: "create-coupon-request",
    module: "coupons",
    subModule: "requests",
    personas: ["partner"],
  },

  /**
   * Insights
   */

  VIEW_OVERVIEW_STATS: {
    value: "view-overview-stats",
    module: "insights",
    subModule: "main",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  VIEW_LINK_STATS: {
    value: "view-link-stats",
    module: "insights",
    subModule: "main",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  VIEW_TOP_CAMPAIGNS: {
    value: "view-top-campaigns",
    module: "insights",
    subModule: "main",
    personas: ["backoffice", "partner", "advertiser"],
  },
  VIEW_TOP_PARTNERS: {
    value: "view-top-partners",
    module: "insights",
    subModule: "main",
    personas: ["backoffice", "agency", "advertiser"],
  },
  VIEW_TOP_COUPONS: {
    value: "view-top-coupons",
    module: "insights",
    subModule: "main",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  VIEW_TOP_TRAFFIC_SOURCES: {
    value: "view-top-traffic-sources",
    module: "insights",
    subModule: "main",
    personas: ["backoffice"],
  },
  VIEW_TOP_CAMPAIGNS_CAPS: {
    value: "view-top-campaigns-caps",
    module: "insights",
    subModule: "main",
    personas: ["backoffice", "advertiser"],
  },
  VIEW_COUPONS_STATS: {
    value: "view-coupons-stats",
    module: "insights",
    subModule: "main",
    personas: ["partner"],
  },
  VIEW_PERFORMANCE_STATS: {
    value: "view-performance-stats",
    module: "insights",
    subModule: "main",
    personas: ["partner"],
  },
  VIEW_EARNING_STATS: {
    value: "view-earning-stats",
    module: "insights",
    subModule: "main",
    personas: ["partner"],
  },
  VIEW_RECOMMENDED_CAMPAIGNS: {
    value: "view-recommended-campaigns",
    module: "insights",
    subModule: "main",
    personas: ["partner"],
  },
  VIEW_NEW_CAMPAIGNS: {
    value: "view-new-campaigns",
    module: "insights",
    subModule: "main",
    personas: ["partner"],
  },
  VIEW_MY_CAMPAIGNS: {
    value: "view-my-campaigns",
    module: "insights",
    subModule: "main",
    personas: ["partner"],
  },
  VIEW_EXTRA_STATS: {
    value: "view-extra-stats",
    module: "insights",
    subModule: "main",
    personas: ["advertiser", "agency"],
  },
  VIEW_VALIDATION_STATS: {
    value: "view-validation-stats",
    module: "insights",
    subModule: "main",
    personas: ["advertiser", "agency"],
  },
  VIEW_TOTAL_CAMPAIGNS: {
    value: "view-total-campaigns",
    module: "insights",
    subModule: "product_stats",
    personas: ["backoffice", "agency"],
  },
  VIEW_PARTNERS_REGISTRATION_STATS: {
    value: "view-partners-registration-stats",
    module: "insights",
    subModule: "product_stats",
    personas: ["backoffice"],
  },
  VIEW_ANNOUNCEMENTS_STATS: {
    value: "view-announcements-stats",
    module: "insights",
    subModule: "product_stats",
    personas: ["backoffice", "agency"],
  },
  VIEW_COUPONS_REQUESTS_STATS: {
    value: "view-coupons-requests-stats",
    module: "insights",
    subModule: "product_stats",
    personas: ["backoffice", "agency"],
  },
  VIEW_CAMPAIGNS_APPLICATIONS_STATS: {
    value: "view-campaigns-applications-stats",
    module: "insights",
    subModule: "product_stats",
    personas: ["backoffice", "agency"],
  },
  VIEW_CAMPAIGNS_DETAILS_STATS: {
    value: "view-campaigns-details-stats",
    module: "insights",
    subModule: "product_stats",
    personas: ["backoffice", "agency"],
  },
  EXPORT_CAMPAIGNS_DETAILS_STATS: {
    value: "export-campaigns-details-stats",
    module: "insights",
    subModule: "product_stats",
    personas: [],
  },

  /**
   * Team
   */

  VIEW_TEAM: {
    value: "view-team",
    module: "team_management",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  EXPORT_TEAM: {
    value: "export-team",
    module: "team_management",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  CREATE_TEAM: {
    value: "create-team",
    module: "team_management",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  LOGIN_AS_TEAM: {
    value: "login-as-team",
    module: "team_management",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  EDIT_TEAM: {
    value: "edit-team",
    module: "team_management",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  DELETE_TEAM: {
    value: "delete-team",
    module: "team_management",
    subModule: "main",
    personas: ["backoffice"],
  },
  MIGRATE_USER_CAMPAIGNS: {
    value: "migrate-user-campaigns",
    module: "team_management",
    subModule: "main",
    personas: ["backoffice"],
  },
  CHANGE_REPORTING_LINE: {
    value: "change-reporting-line",
    module: "team_management",
    subModule: "main",
    personas: ["backoffice"],
  },
  VIEW_ROLES: {
    value: "view-roles",
    module: "team_management",
    subModule: "roles",
    personas: ["backoffice"],
  },
  MANAGE_ROLES: {
    value: "manage-roles",
    module: "team_management",
    subModule: "roles",
    personas: ["backoffice"],
  },

  /**
   * Extras & Deductions
   */

  CLOSE_EXTRA_AND_DEDUCTION_CYCLES: {
    value: "close-extra-and-deduction-cycles",
    module: "extras_deductions",
    subModule: "main",
    personas: ["backoffice"],
  },
  VIEW_EXTRA_AND_DEDUCTION_REVENUES: {
    value: "view-extra-and-deduction-revenues",
    module: "extras_deductions",
    subModule: "revenue",
    personas: ["backoffice"],
  },
  EXPORT_EXTRA_AND_DEDUCTION_REVENUES: {
    value: "export-extra-and-deduction-revenues",
    module: "extras_deductions",
    subModule: "revenue",
    personas: ["backoffice"],
  },
  MANAGE_EXTRA_AND_DEDUCTION_REVENUES: {
    value: "manage-extra-and-deduction-revenues",
    module: "extras_deductions",
    subModule: "revenue",
    personas: ["backoffice"],
  },
  UPLOAD_EXTRA_AND_DEDUCTION_REVENUES: {
    value: "upload-extra-and-deduction-revenues",
    module: "extras_deductions",
    subModule: "revenue",
    personas: ["backoffice"],
  },
  VIEW_EXTRA_AND_DEDUCTION_UPLOADED_LOGS_REVENUES: {
    value: "view-extra-and-deduction-uploaded-logs-revenues",
    module: "extras_deductions",
    subModule: "revenue",
    personas: ["backoffice"],
  },
  UNDO_EXTRA_AND_DEDUCTION_UPLOADED_REVENUES: {
    value: "undo-extra-and-deduction-uploaded-revenues",
    module: "extras_deductions",
    subModule: "revenue",
    personas: ["backoffice"],
  },
  VIEW_EXTRA_AND_DEDUCTION_PAYOUTS: {
    value: "view-extra-and-deduction-payouts",
    module: "extras_deductions",
    subModule: "payout",
    personas: ["backoffice"],
  },
  EXPORT_EXTRA_AND_DEDUCTION_PAYOUTS: {
    value: "export-extra-and-deduction-payouts",
    module: "extras_deductions",
    subModule: "payout",
    personas: ["backoffice"],
  },
  MANAGE_EXTRA_AND_DEDUCTION_PAYOUTS: {
    value: "manage-extra-and-deduction-payouts",
    module: "extras_deductions",
    subModule: "payout",
    personas: ["backoffice"],
  },
  UPLOAD_EXTRA_AND_DEDUCTION_PAYOUTS: {
    value: "upload-extra-and-deduction-payouts",
    module: "extras_deductions",
    subModule: "payout",
    personas: ["backoffice"],
  },
  VIEW_EXTRA_AND_DEDUCTION_UPLOADED_LOGS_PAYOUTS: {
    value: "view-extra-and-deduction-uploaded-logs-payouts",
    module: "extras_deductions",
    subModule: "payout",
    personas: ["backoffice"],
  },
  UNDO_EXTRA_AND_DEDUCTION_UPLOADED_PAYOUTS: {
    value: "undo-extra-and-deduction-uploaded-payouts",
    module: "extras_deductions",
    subModule: "payout",
    personas: ["backoffice"],
  },

  /**
   * Logs
   */
  VIEW_LOGS: {
    value: "view-logs",
    module: "logs",
    subModule: "main",
    personas: ["backoffice"],
  },

  /**
   * Account
   */
  REQUEST_EDIT_PROFILE: {
    value: "request-edit-profile",
    module: "account",
    subModule: "settings",
    personas: ["partner"],
  },
  REQUEST_DELETE_ACCOUNT: {
    value: "request-delete-account",
    module: "account",
    subModule: "settings",
    personas: ["partner"],
  },
  VIEW_USER_SETTINGS: {
    value: "view-user-settings",
    module: "account",
    subModule: "settings",
    personas: ["backoffice", "partner", "advertiser"],
  },
  MANAGE_USER_SETTINGS: {
    value: "manage-user-settings",
    module: "account",
    subModule: "settings",
    personas: ["backoffice", "partner", "advertiser"],
  },
  MANAGE_PUBLISHER_API: {
    value: "manage-publisher-api",
    module: "account",
    subModule: "settings",
    personas: ["partner"],
  },
  MANAGE_PUBLISHER_POSTBACK_LINK: {
    value: "manage-publisher-postback-link",
    module: "account",
    subModule: "settings",
    personas: ["partner"],
  },

  /**
   * Tips
   */

  VIEW_TIPS: {
    value: "view-tips",
    module: "tips",
    subModule: "main",
    personas: ["backoffice", "partner"],
  },
  MANAGE_TIPS: {
    value: "manage-tips",
    module: "tips",
    subModule: "main",
    personas: ["backoffice"],
  },

  /**
   * Notifications
   */

  ACCESS_NOTIFICATIONS: {
    value: "access-notifications",
    module: "notifications",
    subModule: "main",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  /**
   * Campaigns
   */
  VIEW_CAMPAIGNS: {
    value: "view-campaigns",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  VIEW_INPRIVATE_CAMPAIGNS: {
    value: "view-inprivate-campaigns",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGN_PERFORMANCE_CHART: {
    value: "view-campaign-performance-chart",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "advertiser", "agency"],
  },
  VIEW_CAMPAIGN_ERRORS: {
    value: "view-campaign-errors",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "advertiser"],
  },
  VIEW_UNJOINED_CAMPAIGNS: {
    value: "view-unjoined-campaigns",
    module: "campaigns",
    subModule: "main",
    personas: ["partner"],
  },
  RATE_CAMPAIGN: {
    value: "rate-campaign",
    module: "campaigns",
    subModule: "main",
    personas: ["partner"],
  },
  REQUEST_CAMPAIGN_APPLICATION: {
    value: "request-campaign-application",
    module: "campaigns",
    subModule: "main",
    personas: ["partner"],
  },
  EXPORT_CAMPAIGNS: {
    value: "export-campaigns",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  CREATE_CAMPAIGN: {
    value: "create-campaign",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  EDIT_CAMPAIGN_DESCRIPTION: {
    value: "edit-campaign-description",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "advertiser", "agency"],
  },
  EDIT_CAMPAIGN_BASIC_INFO: {
    value: "edit-campaign-basic-info",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  VIEW_CAMPAIGN_STATUS_HISTORY: {
    value: "view-campaign-status-history",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  VIEW_CAMPAIGN_POC: {
    value: "view-campaign-poc",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGN_TEAM: {
    value: "view-campaign-team",
    module: "campaigns",
    subModule: "main",
    personas: ["advertiser"],
  },

  VIEW_CAMPAIGN_TEAM_ACCESS: {
    value: "view-campaign-team-access",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  GIVE_CAMPAIGN_TEAM_ACCESS: {
    value: "give-campaign-team-access",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  VIEW_LINK_CAMPAIGN_OFFER_URL_LOGS: {
    value: "view-link-campaign-offer-url-logs",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  VIEW_LINK_TRACKING: {
    value: "view-link-tracking",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "partner"],
  },
  CREATE_CUSTOM_LINK_TRACKING: {
    value: "create-custom-link-tracking",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "partner"],
  },
  VIEW_CAMPAIGN_PARTNERS: {
    value: "view-campaign-partners",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  VIEW_CAMPAIGN_PARTNER_TRACKING_LINKS: {
    value: "view-campaign-partner-tracking-links",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  GIVE_CAMPAIGN_PARTNER_ACCESS: {
    value: "give-campaign-partner-access",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  CAN_DEACTIVATE_CAMPAIGN_PARTNER_ACCESS: {
    value: "can-deactivate-campaign-partner-access",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice"],
  },
  CAN_DEACTIVATE_CAMPAIGN_TEAM_ACCESS: {
    value: "can-deactivate-campaign-team-access",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice"],
  },
  CAN_MANAGE_CAMPAIGN: {
    value: "can-manage-campaign",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  CAN_CONFIGURE_CAMPAIGN_PERFORMANCE_NOTIFICATIONS: {
    value: "can-configure-campaign-performance-notifications",
    module: "campaigns",
    subModule: "main",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGN_APPLICATIONS: {
    value: "view-campaign-applications",
    module: "campaigns",
    subModule: "applications",
    personas: ["backoffice", "agency"],
  },
  EXPORT_CAMPAIGN_APPLICATIONS: {
    value: "export-campaign-applications",
    module: "campaigns",
    subModule: "applications",
    personas: ["backoffice", "agency"],
  },
  MANAGE_CAMPAIGN_APPLICATIONS: {
    value: "manage-campaign-applications",
    module: "campaigns",
    subModule: "applications",
    personas: ["backoffice", "agency"],
  },
  VIEW_CAMPAIGN_PROMOTIONS: {
    value: "view-campaign-promotions",
    module: "campaigns",
    subModule: "promotions",
    personas: ["backoffice", "partner"],
  },
  MANAGE_CAMPAIGN_PROMOTIONS: {
    value: "manage-campaign-promotions",
    module: "campaigns",
    subModule: "promotions",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGN_CAPS: {
    value: "view-campaign-caps",
    module: "campaigns",
    subModule: "capping",
    personas: ["backoffice", "partner"],
  },
  VIEW_CAMPAIGN_CAPS_LOG: {
    value: "view-campaign-caps-log",
    module: "campaigns",
    subModule: "capping",
    personas: ["backoffice"],
  },
  MANAGE_CAMPAIGN_CAPS: {
    value: "manage-campaign-caps",
    module: "campaigns",
    subModule: "capping",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGN_LEGAL_ENTITIES: {
    value: "view-campaign-legal-entities",
    module: "campaigns",
    subModule: "legal_entities",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGN_LEGAL_ENTITIES_LOG: {
    value: "view-campaign-legal-entities-log",
    module: "campaigns",
    subModule: "legal_entities",
    personas: ["backoffice"],
  },
  MANAGE_CAMPAIGN_LEGAL_ENTITIES: {
    value: "manage-campaign-legal-entities",
    module: "campaigns",
    subModule: "legal_entities",
    personas: ["backoffice"],
  },

  /**
   * Payments
   */
  VIEW_PARTNERS_PAYMENTS: {
    value: "view-partners-payments",
    module: "payments",
    subModule: "partners",
    personas: ["backoffice"],
  },
  EXPORT_PARTNERS_PAYMENTS: {
    value: "export-partners-payments",
    module: "payments",
    subModule: "partners",
    personas: ["backoffice"],
  },
  MANAGE_PARTNERS_PAYMENTS: {
    value: "manage-partners-payments",
    module: "payments",
    subModule: "partners",
    personas: ["backoffice"],
  },
  MANAGE_PARTNERS_PAYMENTS_SETTINGS: {
    value: "manage-partners-payments-settings",
    module: "payments",
    subModule: "partners",
    personas: ["backoffice"],
  },
  VIEW_PAYMENT_COMMENTS: {
    value: "view-payment-comments",
    module: "payments",
    subModule: "partners",
    personas: ["backoffice"],
  },
  MANAGE_PAYMENT_COMMENTS: {
    value: "manage-payment-comments",
    module: "payments",
    subModule: "partners",
    personas: ["backoffice"],
  },
  UPLOAD_PROOF_OF_PAYMENTS: {
    value: "upload-proof-of-payments",
    module: "payments",
    subModule: "partners",
    personas: ["backoffice"],
  },
  VIEW_INVOICES: {
    value: "view-invoices",
    module: "payments",
    subModule: "partners",
    personas: ["partner"],
  },
  MANAGE_INVOICES: {
    value: "manage-invoices",
    module: "payments",
    subModule: "partners",
    personas: ["partner"],
  },
  VIEW_PAYMENT_CYCLES: {
    value: "view-payment-cycles",
    module: "payments",
    subModule: "cycles",
    personas: ["backoffice"],
  },
  CLOSE_PAYMENT_CYCLES: {
    value: "close-payment-cycles",
    module: "payments",
    subModule: "cycles",
    personas: ["backoffice"],
  },

  /**
   * Agency
   */
  VIEW_AGENCIES: {
    value: "view-agencies",
    module: "agency",
    subModule: "main",
    personas: ["backoffice"],
  },
  EXPORT_AGENCIES: {
    value: "export-agencies",
    module: "agency",
    subModule: "main",
    personas: ["backoffice"],
  },
  MANAGE_AGENCIES: {
    value: "manage-agencies",
    module: "agency",
    subModule: "main",
    personas: ["backoffice"],
  },

  /**
   * Advertiser
   */
  VIEW_ADVERTISERS: {
    value: "view-advertisers",
    module: "advertiser",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  EXPORT_ADVERTISERS: {
    value: "export-advertisers",
    module: "advertiser",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  MANAGE_ADVERTISERS: {
    value: "manage-advertisers",
    module: "advertiser",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  LOGIN_AS_POC: {
    value: "login-as-poc",
    module: "advertiser",
    subModule: "main",
    personas: ["backoffice"],
  },

  /**
   * Announcements
   */
  VIEW_ANNOUNCEMENTS: {
    value: "view-announcements",
    module: "announcements",
    subModule: "main",
    personas: ["backoffice", "partner", "agency"],
  },
  MANAGE_ANNOUNCEMENTS: {
    value: "manage-announcements",
    module: "announcements",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  DELETE_ANNOUNCEMENTS: {
    value: "delete-announcements",
    module: "announcements",
    subModule: "main",
    personas: ["backoffice"],
  },
  /**
   * Banners
   */
  VIEW_BANNERS: {
    value: "view-banners",
    module: "banners",
    subModule: "main",
    personas: ["backoffice", "partner"],
  },
  MANAGE_CURRENT_BANNERS: {
    value: "manage-current-banners",
    module: "banners",
    subModule: "main",
    personas: ["backoffice"],
  },
  MANAGE_FUTURE_BANNERS: {
    value: "manage-future-banners",
    module: "banners",
    subModule: "main",
    personas: ["backoffice"],
  },
  CREATE_BANNERS: {
    value: "create-banners",
    module: "banners",
    subModule: "main",
    personas: ["backoffice"],
  },

  /**
   * Chat
   */
  ACCESS_CHAT: {
    value: "access-chat",
    module: "chat",
    subModule: "main",
    personas: ["backoffice", "partner", "agency"],
  },

  /**
   * Loyalty Program
   */
  VIEW_LOYALTY_PROGRAM: {
    value: "view-loyalty-program",
    module: "loyalty_program",
    subModule: "main",
    personas: ["backoffice", "partner"],
  },
  MANAGE_LOYALTY_PROGRAM: {
    value: "manage-loyalty-program",
    module: "loyalty_program",
    subModule: "main",
    personas: ["backoffice"],
  },
  CREATE_REFERRAL_INVITATION_LINK: {
    value: "create-referral-invitation-link",
    module: "loyalty_program",
    subModule: "main",
    personas: ["partner"],
  },

  /**
   * Partners Permissions
   */
  VIEW_PARTNERS: {
    value: "view-partners",
    module: "partners",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  EXPORT_PARTNERS: {
    value: "export-partners",
    module: "partners",
    subModule: "main",
    personas: ["backoffice"],
  },
  MANAGE_PARTNERS: {
    value: "manage-partners",
    module: "partners",
    subModule: "main",
    personas: ["backoffice"],
  },
  REQUEST_EDIT_PARTNER: {
    value: "request-edit-partner",
    module: "partners",
    subModule: "main",
    personas: ["backoffice"],
  },
  VIEW_PARTNERS_COMMISSIONS: {
    value: "view-partners-commissions",
    module: "partners",
    subModule: "main",
    personas: ["backoffice"],
  },
  VIEW_PARTNERS_GLOBAL_PAYOUTS: {
    value: "view-partners-global-payouts",
    module: "partners",
    subModule: "main",
    personas: ["backoffice"],
  },
  MANAGE_PARTNERS_GLOBAL_PAYOUTS: {
    value: "manage-partners-global-payouts",
    module: "partners",
    subModule: "main",
    personas: ["backoffice"],
  },
  LOGIN_AS_PARTNER: {
    value: "login-as-partner",
    module: "partners",
    subModule: "main",
    personas: ["backoffice"],
  },
  CAN_SEARCH_PARTNERS: {
    value: "can-search-partners",
    module: "partners",
    subModule: "main",
    personas: ["advertiser"],
  },
  VIEW_PARTNER_COMMENTS: {
    value: "view-partner-comments",
    module: "partners",
    subModule: "comment",
    personas: ["backoffice"],
  },
  MANAGE_PARTNER_COMMENTS: {
    value: "manage-partner-comments",
    module: "partners",
    subModule: "comment",
    personas: ["backoffice"],
  },

  /**
   * Reports Permissions
   */

  VIEW_PERFORMANCE_REPORT: {
    value: "view-performance-report",
    module: "reports",
    subModule: "performance",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  EXPORT_PERFORMANCE_REPORT: {
    value: "export-performance-report",
    module: "reports",
    subModule: "performance",
    personas: ["backoffice", "partner", "advertiser", "agency"],
  },
  VIEW_PERFORMANCE_COMPARISON_REPORT: {
    value: "view-performance-comparison-report",
    module: "reports",
    subModule: "performance",
    personas: ["backoffice"],
  },
  VIEW_FINANCIAL_SUMMARY_REPORT: {
    value: "view-financial-summary-report",
    module: "reports",
    subModule: "financial",
    personas: ["backoffice"],
  },
  EXPORT_FINANCIAL_SUMMARY_REPORT: {
    value: "export-financial-summary-report",
    module: "reports",
    subModule: "financial",
    personas: ["backoffice"],
  },
  VIEW_COST_SUMMARY_REPORT: {
    value: "view-cost-summary-report",
    module: "reports",
    subModule: "financial",
    personas: ["backoffice"],
  },
  EXPORT_COST_SUMMARY_REPORT: {
    value: "export-cost-summary-report",
    module: "reports",
    subModule: "financial",
    personas: ["backoffice"],
  },
  VIEW_PARTNER_PAYMENT_REPORT: {
    value: "view-partner-payment-report",
    module: "reports",
    subModule: "financial",
    personas: ["backoffice", "partner"],
  },
  EXPORT_PARTNER_PAYMENT_REPORT: {
    value: "export-partner-payment-report",
    module: "reports",
    subModule: "financial",
    personas: ["backoffice", "partner"],
  },
  VIEW_CLICK_ANALYSIS_REPORT: {
    value: "view-click-analysis-report",
    module: "reports",
    subModule: "analysis",
    personas: ["backoffice", "partner", "agency"],
  },
  EXPORT_CLICK_ANALYSIS_REPORT: {
    value: "export-click-analysis-report",
    module: "reports",
    subModule: "analysis",
    personas: ["backoffice", "partner", "agency"],
  },
  VIEW_PARTNER_ANALYSIS_REPORT: {
    value: "view-partner-analysis-report",
    module: "reports",
    subModule: "analysis",
    personas: ["backoffice"],
  },
  EXPORT_PARTNER_ANALYSIS_REPORT: {
    value: "export-partner-analysis-report",
    module: "reports",
    subModule: "analysis",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGN_ANALYSIS_REPORT: {
    value: "view-campaign-analysis-report",
    module: "reports",
    subModule: "analysis",
    personas: ["backoffice", "agency"],
  },
  EXPORT_CAMPAIGN_ANALYSIS_REPORT: {
    value: "export-campaign-analysis-report",
    module: "reports",
    subModule: "analysis",
    personas: ["backoffice"],
  },

  /**
   * Validation Permissions
   */

  VIEW_PARTNER_VALIDATION: {
    value: "view-partner-validation",
    module: "validations",
    subModule: "partners",
    personas: ["backoffice"],
  },
  EXPORT_PARTNER_VALIDATION: {
    value: "export-partner-validation",
    module: "validations",
    subModule: "partners",
    personas: ["backoffice"],
  },
  MANAGE_PARTNER_VALIDATION: {
    value: "manage-partner-validation",
    module: "validations",
    subModule: "partners",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGNS_VALIDATION_SUMMARY: {
    value: "view-campaigns-validation-summary",
    module: "validations",
    subModule: "campaigns",
    personas: ["backoffice"],
  },
  EXPORT_CAMPAIGNS_VALIDATION_SUMMARY: {
    value: "export-campaigns-validation-summary",
    module: "validations",
    subModule: "campaigns",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGN_VALIDATION: {
    value: "view-campaign-validation",
    module: "validations",
    subModule: "campaigns",
    personas: ["backoffice"],
  },
  EXPORT_CAMPAIGN_VALIDATION: {
    value: "export-campaign-validation",
    module: "validations",
    subModule: "campaigns",
    personas: ["backoffice"],
  },
  UPLOAD_CAMPAIGN_VALIDATION_SHEET: {
    value: "upload-campaign-validation-sheet",
    module: "validations",
    subModule: "campaigns",
    personas: ["backoffice"],
  },
  MANAGE_CAMPAIGN_VALIDATION: {
    value: "manage-campaign-validation",
    module: "validations",
    subModule: "campaigns",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGN_VALIDATION_LOG: {
    value: "view-campaign-validation-log",
    module: "validations",
    subModule: "campaigns",
    personas: ["backoffice"],
  },
  VIEW_CAMPAIGNS_VALIDATION_SHEETS: {
    value: "view-campaigns-validation-sheets",
    module: "validations",
    subModule: "campaigns",
    personas: ["backoffice"],
  },
  SEND_CAMPAIGN_VALIDATION_SUMMARY_EMAIL: {
    value: "send-campaign-validation-summary-email",
    module: "validations",
    subModule: "campaigns",
    personas: ["backoffice"],
  },

  /**
   * Payouts Permissions
   */

  VIEW_PAYOUTS: {
    value: "view-payouts",
    module: "payouts",
    subModule: "main",
    personas: ["backoffice", "partner", "agency"],
  },
  EXPORT_PAYOUTS: {
    value: "export-payouts",
    module: "payouts",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  MANAGE_DEFAULT_PAYOUTS: {
    value: "manage-default-payouts",
    module: "payouts",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  MANAGE_PARTNER_PAYOUTS: {
    value: "manage-partner-payouts",
    module: "payouts",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  MANAGE_COUPON_PAYOUTS: {
    value: "manage-coupon-payouts",
    module: "payouts",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },
  EDIT_GLOBAL_PAYOUTS: {
    value: "edit-global-payouts",
    module: "payouts",
    subModule: "main",
    personas: ["backoffice", "agency"],
  },

  /**
   * Revenues Permissions
   */

  VIEW_REVENUES: {
    value: "view-revenues",
    module: "revenues",
    subModule: "main",
    personas: ["backoffice", "advertiser"],
  },
  EXPORT_REVENUES: {
    value: "export-revenues",
    module: "revenues",
    subModule: "main",
    personas: ["backoffice", "advertiser"],
  },
  MANAGE_DEFAULT_REVENUES: {
    value: "manage-default-revenues",
    module: "revenues",
    subModule: "main",
    personas: ["backoffice"],
  },
  MANAGE_COUPON_REVENUES: {
    value: "manage-coupon-revenues",
    module: "revenues",
    subModule: "main",
    personas: ["backoffice"],
  },
  EDIT_GLOBAL_REVENUES: {
    value: "edit-global-revenues",
    module: "revenues",
    subModule: "main",
    personas: ["backoffice"],
  },

  /**
   * Release notes Permissions
   */

  VIEW_RELEASE_NOTES: {
    value: "view-release-notes",
    module: "release_notes",
    subModule: "main",
    personas: ["backoffice"],
  },
  MANAGE_RELEASE_NOTES: {
    value: "manage-release-notes",
    module: "release_notes",
    subModule: "main",
    personas: ["backoffice"],
  },
}
export default permissions
