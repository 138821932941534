export default {
  errors: {
    somethingWentWrong: "حدث خطأ",
    unauthorized: "لا يمكنك الدخول لهذه الصفحه",
    notFound: "هذه الصفحه غير موجوده",
    notValid: "هذه البيانات غير صالحه",
    existEmail: "البريد الاكتروني موجود بالفعل",
    referralCode: "Code doesn't exist or wrong",
    requiredField: " هذا الحقل مطلوب",
    pleaseAddValidPercentage: "من فضلك اضف نسبه مئويه صحيحه",
    wrongData: "هنام خطاء في البيانات من فضلك قم بالمراجعه",
    noFileChosen: "please add an CSV file",
    bigFile: "Please select an image less than 1 MB",
    verificationIDFailed: "Upload failed. Please contact the Support team",
    invalidCapValue: "Please enter a valid Cap value",
    capMustBeBiggerThanCurrent: "The cap must be bigger than the current value ({current})",
    invalidNumberOfDays: "Please pick a range of at least 14 days",
    invalidCodes: "codes must be alpha or numbers without any spaces, tabs or commas or valid countries!",
    pageNotFound: "الصفحة غير موجودة",
    notFoundMessage: "ربما تمت إزالة الصفحة التي تبحث عنها ، أو تم تغيير اسمها أو عدم توفرها مؤقتًا",
    cycleNotValid: "this cycle is not valid",
    noneSelectedForm: "يرجى ملء قسم واحد على الأقل",
    fillSection: "من فضلك قم ب ملء بيانات قسم ال {section}",
    allowedHeight: "Please upload an image with the required height",
    mobile_app: "تطبيق الهاتف المحمول",
    media_buying: "شراء إعلانات",
    failedToDownload: "Failed to download",
    social: "شبكات التواصل الاجتماعي",
    website: "موقع إلكتروني",
    email: "بريد إلكتروني",
    selectAllSocialInputs: "Please fill all social inputs",
    fillSpecificInput: "Please fill {sectionKey} in {formName} section",
    atLeastOneInput: "يرجى ملء إدخال واحد على الأقل من قسم {sectionKey} ",
    enterValidEmail: "من فضلك قم بإدخال بريد الكتروني صحيح",
    enterValidIban: "من فضلك قم بإدخال أيبان صحيح",

    notMatch: "{field} غير متطابق مع {secondField}",
    notValidPassword: "{field} لا يصلح اقل من 6 احرف , يجب ان يحتوي علي حروف كبيرة وصغيرة وارقام",
    addValidCouponRequestNumber: "من فضلك قم بإدخال رقم بين 1 الي 20.",

    //

    maxChar: "{field} should be not greater than {num} characters",
    maxCharLength: "{field} should be not greater than {length} characters",
    notValidExtension: "Allowed extensions ( png, jpg, jpeg )",
    allowedExtensions: "Allowed extensions ( {extensions} )",
    notAllowedBigFiles: "Please select an image less than {size} MB",
    notAllowedBigFilesKilobytes: "Please select an image less than {size} kilobytes",
    notAllowedBigFilesMB: "Please select a file less than {size} MB",

    enterValidURL: "Please enter a valid URL",
    enterValidName: "Please enter a valid URL name",
    addEstimatedCancellationRate: "Please add value between 0.00 and 100",
    validationDays: "Please add value between 0 and 120",
    estimatedCancellationRateRightValue: "Please enter value with 2 decimal numbers",
    minAndMaxLength: "length has to b {min} chars minimum and {max} maximum",
    specificChar: "{field} should be {number} characters",
    notMatchMsg: "Please make sure the 2 {field} are matching",
    dupliactePassword: "Please choose a password you haven't used before",
    decimalsNotAllowed: "Decimals numbers are not allowed",
    actionIsRequired: "This action is required",
    updatedLink: "The Updated Link is required",
    clickIdRequired: `In order to generate the Offer URL, one selected macro should
    be Boostiny Click ID macro. Please map Boostiny Click ID macro to the correct parameter.`,
    duplicateMacrosKeys: "Some of the Macros keys are duplicated",
    urlHasNoParams: "Please add at least one param",
    enterMax50Chars: "Please enter maximum 50 characters",
    nameNotUnique: "Please enter a unique name",
    enterValidParams: "Please enter valid params",
    lessThan: "The {field} should be less than {second}",
    biggerThan: "The {field} should be bigger than {second}",
    campaignCodeNotAllowed: "Spaces, Capital letters and special characters except '-' are not allowed for campaign code.",
    addOfferUrl: "Add at least one offer URL",
    lessThanOrEqual: "The {field} should be less than or equal {value}",
    deepLinkNotSameDefaultDeepLink: "Make sure you only add deeplink URLs that belong to this domain.",
    biggerThanAndEqual: "The {field} should be bigger than and equal {value}",
    requiredMacros: "Please add required macros",
    enterValidDelayReason: "Please enter a valid delay reason",
    enterValidReason: "Please enter a valid reason",
    selectPDFOnly: "Please select PDF file only",
    requiredDate: "Please select a Date",
    selectAtLeastOneFileForScreen: "Please select at least one file for selected screens",
    invalidDimensions: "Please enter a valid dimensions",
    pleaseSelectFile: "Please select a file",
    campaignDoesnotHaveLegalEntity: "This campaign doesn’t have legal entity, you must add the correct legal entity in the campaign first",
    addAtLeastOneFeature: "Please add at least one feature",
    addAtLeastOneFeatureOrEnhancement: "Please add at least one feature or enhancement",
    addAtLeastOneBugFixOrEnhancement: "Please add at least one bug fix or enhancement",
    linkDomainMismatch: "The domain of the link does not match {domain}",
    numberBetween: "Put a number between {from} and {to}",
    enterValidSwift: "Invalid Swift Code, please contact your bank",
    enterValidInstagramUrl: "Please enter valid Instagram account URL",
    enterValidInstagramUsername: "Please enter valid Instagram account username",
  },
}
