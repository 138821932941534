import formatDate from "@/utils/date/format"

const performanceSharedFilters = {
  sortMetrics: "",
  type: "gross",
  sortDirection: "desc",
  advancedFilters: [],
  salesManagers: [],
  campaign_start_year: [],
  dimensions: [],
  metrics: [],
  categories: [],
  buIds: [],
  campaigns: [],
  advertiser: "",
  brands: [],
  heads: [],
  selectedFiltersGroup: null,
}
export default [
  {
    path: "/reports",
    name: "reports",
    component: () => import(/* webpackChunkName: "Reports" */ "../views/reports/Index.vue"),
    meta: {
      requireAuth: true,
      title: "reports",
    },
    children: [
      {
        path: "performance",
        component: () => import(/* webpackChunkName: "Reports" */ "../views/reports/performance/Index.vue"),
        meta: {
          requireAuth: true,
        },
        children: [
          {
            path: "",
            name: "performance-report",
            component: () => import(/* webpackChunkName: "PerformanceReport" */ "../views/reports/performance/Default.vue"),
            meta: {
              hasFilters: true,
              apiRouteKey: "reports/performance", // the api route name
              couponAPIRouteKey: "reports/performance",
              linkAPIRouteKey: "reports/link-performance",
              entityName: "performance",
              // need to get rid of multiple schemas approach
              filtersScheme: {
                ...performanceSharedFilters,
                campaignType: "coupon",
                teamLeaders: [],
                teamMembers: [],
                trafficSourcesIds: [],
                team: "",
                campaign_source: "",
                teams: [],
                clickIds: [],
                clientPresence: "",
                locationCountry: "",
              },

              couponFiltersScheme: {
                ...performanceSharedFilters,
                campaignType: "coupon",
                teamLeaders: [],
                teamMembers: [],
                trafficSourcesIds: [],
                team: "",
                clientPresence: "",
                locationCountry: "",
              },

              linkFiltersScheme: {
                ...performanceSharedFilters,
                campaignType: "link",
                teams: [],
                clickIds: [],
              },
              title: "reportPerformance",
            },
          },
          {
            path: "comparison",
            name: "performance-comparison-report",
            component: () => import(/* webpackChunkName: "PerformanceComparison" */ "../views/reports/performance/Comparison.vue"),
            meta: {
              hasFilters: true,
              entityName: "performance-comparison",
              filtersScheme: {
                buIds: [],
                campaigns: [],
                advertisers: [],
                partners: [],
                teamLeaders: [],
                teamMembers: [],
                categories: [],
                countries: [],
                campaignType: [],
                customerType: [],
                period: {
                  from: "",
                  to: "",
                },
                periodType: "month",
              },
              title: "performanceComparison",
            },
          },
        ],
      },
      {
        path: "click-analysis",
        name: "click-analysis-report",
        component: () => import(/* webpackChunkName: "ClickAnalysis" */ "../views/reports/ClickAnalysis.vue"),
        meta: {
          hasFilters: true,
          entityName: "click-analysis",
          apiRouteKey: "reports/click-analysis", // click-analysis the api route name
          filtersScheme: {
            partners: [],
            teams: [],
            buIds: [],
            campaigns: [],
            clickIds: [],
            dimensions: [],
            metrics: [],
            sortMetrics: "",
            campaign_source: "",
            sortDirection: "desc",
            advancedFilters: [],
            heads: [],
            selectedFiltersGroup: null,
            hasConversion: 0,
          },
          title: "clickAnalysis",
        },
      },
      {
        path: "financial-summary",
        name: "financial-summary-report",
        component: () => import(/* webpackChunkName: "FinancialSummary" */ "../views/reports/FinancialSummary.vue"),
        meta: {
          hasFilters: true,
          entityName: "financial-summary",
          apiRouteKey: "payments/reports/financial-summary", // the api route name
          filtersScheme: {
            dimensions: [],
            metrics: [],
            cycles: [],
            campaigns: [],
            buIds: [],
            teamLeaders: [],
            teamMembers: [],
            advancedFilters: [],
            sortMetrics: "",
            sortDirection: "desc",
            team: "",
            legal_entity: "",
          },
          title: "reportFinance",
        },
      },
      {
        path: "cost-summary",
        name: "cost-summary-report",
        component: () => import(/* webpackChunkName: "CostSummary" */ "../views/reports/CostSummary.vue"),
        meta: {
          hasFilters: true,
          entityName: "cost-summary",
          apiRouteKey: "payments/reports/cost-summary", // the api route name
          filtersScheme: {
            dimensions: [],
            metrics: [],
            cycle: formatDate({ date: new Date(new Date().getFullYear(), new Date().getMonth(), 1), locale: "fr-CA" }),
            cost_type: "",
            legal_entity: "",
            campaigns: [],
            bu: [],
            teamLeaders: [],
            teamMembers: [],
            advancedFilters: [],
          },
          title: "reportCost",
        },
      },
      {
        path: "partners",
        name: "partners-report",
        component: () => import(/* webpackChunkName: "PartnerReport" */ "../views/reports/Partners.vue"),
        meta: {
          hasFilters: true,
          entityName: "partner-analysis",
          apiRouteKey: "reports/partner-analysis",
          filtersScheme: {
            partners: [],
            buIds: [],
            teamLeaders: [],
            campaigns: [],
            dimensions: [],
            metrics: [],
            sortMetrics: "",
            campaign_source: "",
            sortDirection: "desc",
            advancedFilters: [],
            team: "",
            campaignModel: "all",
            advertiser: "",
            selectedFiltersGroup: null,
            clientPresence: "",
            locationCountry: "",
          },
          title: "partnerAnalysis",
        },
      },
      {
        path: "campaigns",
        name: "campaigns-report",
        component: () => import(/* webpackChunkName: "CampaignReport" */ "../views/reports/Campaigns.vue"),
        meta: {
          hasFilters: true,
          entityName: "campaign-analysis",
          apiRouteKey: "reports/campaign-analysis",
          filtersScheme: {
            campaigns: [],
            buIds: [],
            teamLeaders: [],
            dimensions: [],
            metrics: [],
            sortMetrics: "",
            campaign_source: "",
            sortDirection: "desc",
            advancedFilters: [],
            brands: [],
            team: "",
            campaignModel: "all",
            advertiser: "",
            heads: [],
            salesManagers: [],
            campaign_start_year: "",
            selectedFiltersGroup: null,
            clientPresence: "",
            locationCountry: "",
          },
          title: "reportCampaigns",
        },
      },
      {
        path: "partner-payment",
        name: "partner-payment",
        component: () => import(/* webpackChunkName: "PartnerPaymentReport" */ "../views/reports/PartnerPayment.vue"),
        meta: {
          hasFilters: true,
          entityName: "partners-payment",
          apiRouteKey: "payments/partners-payment-report",
          filtersScheme: {
            partner: "",
            buIds: [],
            cycle: formatDate({ date: new Date(new Date().getFullYear(), new Date().getMonth(), 1), locale: "fr-CA" }),
            dimensions: [],
            metrics: [],
            sortMetrics: "",
            sortDirection: "desc",
            advancedFilters: [],
          },
          title: "partnerPayment",
        },
      },
      {
        path: "product-stats",
        name: "product-stats",
        component: () => import(/* webpackChunkName: "PartnerPaymentReport" */ "../views/reports/ProductStats.vue"),
        meta: {
          hasFilters: true,
          entityName: "product-stats",
          apiRouteKey: "",
          filtersScheme: {
            totalsRange: {
              from: formatDate({ date: new Date(new Date().getFullYear(), new Date().getMonth(), 1), locale: "fr-CA" }),
              to: formatDate({ date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), locale: "fr-CA" }),
            },
            team: "",
            campaign: "",
          },
          title: "productStats",
        },
      },
    ],
  },
]
