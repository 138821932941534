import trackResponse from "@/utils/miscellaneous/track-response/index";
import router from "@/router";

export const initiatorWhitelisted = ["global"]

export function generateInitiator({ initiator }) {
  if (process.env.NODE_ENV === "development" && initiator && !initiatorWhitelisted.includes(initiator)) {
    trackResponse({
      type: "error",
      msg: "Please add valid initiator",
    });
    return
  }

  return `${initiator || router.currentRoute.name}`
}

export function getRequestId({ initiator, id }) {
  return `${initiator}/${id}`
}
