export default {
  buttons: {
    closePopup: "Close",
    cancel: "الغاء",
    submit: "إرسال",
    done: "تم",
    forgetPassword: "هل نسيت كلمة المرور الخاصه بك؟",
    login: "تسجيل دخول",
    viewMore: "مشاهدة المزيد",
    viewLess: "مشاهدة أقل",
    confirm: "تأكيد",
    apply: "قدم",
    search: "بحث",
    view: "اظهر",
    clearFilters: "مسح",
    download: "تحميل",
    configure: "Configure",
    discard: "Discard",
    saveEdits: "Save Edits",
    showFilters: " عرض الفلاتر",
    hideFilters: "اخفاء الفلاتر",
    applyFor: "قدم على",
    allocate: "تخصيص",
    export: " CSV تحميل ",
    deactivate: "Deactivate",
    addEntity: "Add Entity",
    addAnotherLink: "Add Another Link",
    newBanner: "New Banner",
    createNewBanner: "Create New Banner",
    proceedToEdit: "Proceed To Edit",
    saveLink: "Save Link",
    delete: "حذف",
    deselect: "الفاء الاختيار",
    allocateCoupons: "تعيين الكوبونات",
    viewAll: "مشاهدة الكل",
    runReport: "نفذ",
    newPayout: "عمولة جديدة",
    newRevenue: "ايراد جديد",
    newExtraDeduction: "إضافة جديدة أو خصم",
    bulkUploadLog: "Bulk Upload Log",
    approve: "Approve",
    reject: "رفض",
    send: "ارسال",
    add: "Add",
    edit: "تعديل",
    Edit: "تعديل",
    ignore: "Ignore",
    requestVerificationID: "Request Verification ID",
    requestVATDocument: "Request VAT Document",
    update: "تحديث",
    updateAdset: "تحديث مجموعة الإعلانات",
    createNew: "أنشئ جديد",
    sendSummary: "ملخص",
    goToUserProfile: "عرض",
    bulkUpload: "إضافة كوبونات",
    downloadSample: "Download Sample File",
    validate: "Validate",
    next: "التالي",
    run: "Run",
    register: "Register",
    save: "حفظ",
    sendRestPasswordLink: "أرسل رابط إعادة تعيين كلمة المرور",
    backToLogin: "العودة إلى تسجيل الدخول",
    resetPassword: "Rest password",
    comment: "Comment",
    markAllAsRead: "Mark All As Read",
    details: "تفاصيل",
    goToDashboard: "إذهب إلي متابعة الأداء",
    closeCycle: "اغلاق الشهر",
    influencer: "مؤثر",
    publisher: "ناشر",
    addClientContact: "Add New Client Contact",
    addAnotherClientContact: "Add Another Client Contact",
    finish: "إنهاء",
    back: "السابق",
    goBack: "السابق",
    addComment: "إضافة تعليق",
    skipThisStep: "تخطي هذه الخطوه",
    revoke: "Revoke",
    loadMoreComments: "المزيد",
    loadMoreNotifications: "المزيد",

    requestCoupons: "طلب كوبونات",
    on: "تشغيل",
    off: "إيقاف",

    couponRequests: "طلبات الكوبونات",

    new: "New",
    newAnnouncement: "New Announcement",

    downloadCountries: "Download Countries list for reference",
    createCampaign: "Create New Campaign",
    paymentSettings: "Payment Settings",
    createGlobalPayout: "Create Global Payout",

    closed: "مغلق",
    upcoming: "القادمة",
    close: "إغلاق الدورة",
    pending: "Pending",
    setPaid: "Change to Paid",
    sendAgreementEsign: "إرسال التوقيع الإلكتروني",
    upload: "تحميل",
    yes: "Yes",
    no: "No",
    uploadNew: "Upload New",
    newAdvertiser: "New Advertisers",
    newUser: "مستخدم جديد",
    loginWithGoogle: "Login With Google",
    continue: "Continue",
    allEdits: "All Edits",
    approveAll: "Approve all",
    rejectAll: "Reject all",
    closeChangeRequestsBox: "close",
    deleteUser: "Delete user",
    archive: "Archive",
    updateCountry: "Update Country",
    addPayoutRevenue: "Add Payout / Revenue",
    contactSupport: "التواصل مع خدمة العملاء",
    verify: "Verify",
    clickToResend: "Click to resend",
    redeem: "Redeem",
    exploreProgram: "Explore program",
    getStarted: "Get started",
    viewDetails: "View Details",
    changePassword: "Change Password",
    copy: "Copy",
    addNewTip: "اضف نصيحة جديدة",
    editTip: "تعديل النصيحة",
    newAccess: "New access",
    giveAccess: "Give access",
    shortenLink: "Shorten Link",
    updateLink: "Update Link",
    newLink: "New Link",
    reset: "Reset",
    newGroup: "New Group",
    saveChanges: "Save Changes",
    continueAddLater: "Continue & add later",
    saveAddNew: "Save & add new",
    addNewLink: "Add New Link",
    seeAllCustomizedLinks: "See All Customized Links",
    viewCommission: "عرض العمولة",
    undo: "Undo",
    addLink: "Add Link",
    compare: "Compare",
    createBrand: "Create Brand",
    "delayed-and-hidden": "Approve and Delay",
    "finance-approved": "Approve as Finance",
    delayed: "Delay",
    "management-approved": "Approve as CEO",
    paid: "Paid",
    bounced: "Bounced",
    "approved-and-delayed": "Approve and Delay",
    reply: "Reply",
    processing: "Processing",

    accept: "قبول",
    sampleSheets: "Sample Sheets",
    extraDeductionBulkUpload: "Bulk Upload",
    viewTeam: "View team",
    closeModal: "Close",
    approveAsFinance: "Approve as finance",
    approveAsManagement: "Approve as CEO",
    approveAndDelay: "Approve and Delay",
    viewInvoices: "View invoices",
    history: "History",
    pendingRequests: "Pending Requests",
    addNew: "Add New",
    unarchive: "Unarchive",
    updateArchivingDate: "Update Archiving Date",
    migrate: "Migrate",
    backTo: "Back",
    reportingLineHistory: "Reporting Line History",
    showSocialDataFilters: "Show Social Data Filters",
    hideSocialDataFilters: "Hide Social Data Filters",
  },
}
