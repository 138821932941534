export default {
  buttons: {
    closePopup: "Close",
    cancel: "Cancel",
    done: "Done",
    forgetPassword: "Forgot your password ?",
    login: "Sign in",
    viewMore: "View more",
    viewLess: "View Less",
    confirm: "Confirm",
    apply: "Apply",
    search: "Search",
    clearFilters: "Clear",
    showFilters: "Show Filters",
    hideFilters: "Hide Filters",
    configure: "Configure",
    discard: "Discard",
    saveEdits: "Save Edits",
    applyFor: "Apply",
    allocate: "Allocate",
    export: "Export CSV",
    addEntity: "Add Entity",
    proceedToEdit: "Proceed To Edit",
    delete: "Delete",
    deactivate: "Deactivate",
    addAnotherLink: "Add Another Link",
    deselect: "Deselect",
    allocateCoupons: "Allocate Coupons",
    viewAll: "View All",
    newBanner: "New Banner",
    createNewBanner: "Create New Banner",
    runReport: "Run Report",
    newPayout: "New Payout",
    newRevenue: "New Revenue",
    newUser: "New User",
    submit: "Submit",
    newExtraDeduction: "New Extra or Deduction",
    newDebitCredit: "New Debit or Credit",
    bulkUploadLog: "Bulk Upload Log",
    requestVerificationID: "Request Verification ID",
    requestVATDocument: "Request VAT Document",
    edit: "edit",
    Edit: "Edit",
    send: "Send",
    approve: "Approve",
    reject: "Reject",
    markAllAsRead: "Mark All As Read",
    add: "Add",
    ignore: "Ignore",
    update: "Update",
    updateAdset: "Update Adset",
    createNew: "Create new",
    goToUserProfile: "View",
    loginAs: "Login As",
    sendSummary: "Summary",
    bulkUpload: "Bulk Upload",
    downloadSample: "Download Sample File",
    validate: "Validate",
    next: "Next",
    upload: "Upload",
    run: "Run",
    register: "Register",
    save: "Save",
    saveLink: "Save Link",
    addNewLink: "Add New Link",
    seeAllCustomizedLinks: "See All Customized Links",
    sendRestPasswordLink: "Send reset password link",
    backToLogin: "Back to login",
    resetPassword: "Rest password",
    comment: "Comment",
    details: "Details",
    goToDashboard: "Go To Dashboard",
    closeCycle: "Close cycle",
    influencer: "Influencer",
    publisher: "Publisher",
    cancelAgreement: "Cancel Agreement",
    finish: "Finish",
    back: "Previous",
    goBack: "Back",
    addComment: "Add Comment",
    skipThisStep: "Skip this step",
    revoke: "Revoke",
    viewDetails: "View Details",
    loadMoreComments: "Load More",
    loadMoreNotifications: "Load More",
    requestCoupons: "Request coupons",
    on: "On",
    off: "Off",

    couponRequests: "Coupons Requests",

    new: "New",
    newAnnouncement: "New Announcement",
    view: "View",
    downloadCountries: "Download Countries list for reference",
    createCampaign: "Create New Campaign",
    download: "Download",
    closed: "Closed",
    upcoming: "Upcoming",
    close: "Close Cycle",
    pending: "Pending",
    setPaid: "Change to Paid",
    sendAgreementEsign: "Send Agreement E-sign",
    sendAgreementEsignAgain: "Send Agreement again",

    yes: "Yes",
    no: "No",
    uploadNew: "Upload New",
    newAdvertiser: "New Advertisers",
    loginWithGoogle: "Login With Google",
    continue: "Continue",
    allEdits: "All Edits",
    approveAll: "Approve all",
    rejectAll: "Reject all",
    closeChangeRequestsBox: "close",
    addClientContact: "Add New Client Contact",
    addAnotherClientContact: "Add Another Client Contact",
    deleteUser: "Delete user",
    archive: "Archive",
    updateCountry: "Update Country",
    addPayoutRevenue: "Add Payout / Revenue",
    contactSupport: "Contact Support",
    verify: "Verify",
    clickToResend: "Click to resend",
    redeem: "Redeem",
    exploreCampaigns: "Explore campaigns",
    changePassword: "Change Password",
    newAccess: "New access",
    giveAccess: "Give access",
    shortenLink: "Shorten Link",
    referFriend: "Refer a Friend",
    copyInvitation: "Copy Invitation Link",
    linkCopied: "Link Copied",
    exploreProgram: "Explore program",
    getStarted: "Get started",
    copy: "Copy",
    newGroup: "New Group",
    saveChanges: "Save Changes",
    continueAddLater: "Continue & add later",
    saveAddNew: "Save & add new",
    viewCommission: "View commission",

    updateLink: "Update Link",
    newLink: "New Link",
    reset: "Reset",
    paymentSettings: "Payment Settings",
    createGlobalPayout: "Create Global Payout",

    addNewTip: "Add New Tip",
    editTip: "Edit Daily Tip",
    undo: "Undo",
    addLink: "Add Link",
    compare: "Compare",
    createBrand: "Create Brand",
    "delayed-and-hidden": "Approve and Delay",
    "finance-approved": "Approve as Finance",
    delayed: "Delay",
    "management-approved": "Approve as CEO",
    paid: "Paid",
    bounced: "Bounced",
    "approved-and-delayed": "Approve and Delay",
    reply: "Reply",
    processing: "Processing",
    accept: "Accept",
    extraDeductionBulkUpload: "Bulk Upload",
    sampleSheets: "Sample Sheets",
    viewTeam: "View team",
    closeModal: "Close",
    approveAsFinance: "Approve as finance",
    approveAsManagement: "Approve as CEO",
    approveAndDelay: "Approve and Delay",
    viewInvoices: "View invoices",
    history: "History",
    pendingRequests: "Pending Requests",
    unarchive: "Unarchive",
    addNew: "Add New",
    updateArchivingDate: "Update Archiving Date",
    migrate: "Migrate",
    backTo: "Back",
    reportingLineHistory: "Reporting Line History",
    showSocialDataFilters: "Show Social Data Filters",
    hideSocialDataFilters: "Hide Social Data Filters",
  },
}
