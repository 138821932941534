import {
  collection, getDocs, query, where, documentId, onSnapshot, Timestamp, getDoc, doc, setDoc, serverTimestamp,
} from "firebase/firestore";

import { db } from "@/config/firebase";
import decryptUser from "@/utils/data-manipulation/decrypt-user/index";
import hashWithMd5 from "@/utils/string/hash-with-md5/index";
import uniqBy from "@/utils/data-manipulation/uniq-by/index";
import md5 from "md5";

export default {
  namespaced: true,
  state: {
    activeChat: null,
    currentUserChats: [],
    currentUserParticipantsIds: [],
    chatUsersList: [], // [NIT] is this the users available to chat with?
    usersNumOfUnreadMsgs: [],
    searchTargetMessageId: null,
    notifications: [],
    fetchChatsSnapshot: null,
    activeUserData: {},
    publishersSupportUser: null,
  },

  mutations: {
    SET_ACTIVE_CHAT: (state, value) => {
      state.activeChat = value
    },
    SET_UNREAD_MESSAGES: (state, value) => {
      state.usersNumOfUnreadMsgs = value;
    },
    SET_CURRENT_USER_CHAT: (state, value) => {
      state.currentUserChats = value;
    },
    SET_CHAT_USERS_LIST: (state, value) => {
      state.chatUsersList = value;
    },
    SET_CURRENT_USER_PARTICIPANTS_IDS: (state, value) => {
      state.currentUserParticipantsIds = value;
    },

    SET_SEARCH_TARGET_MESSAGE_ID: (state, value) => {
      state.searchTargetMessageId = value;
    },

    SET_NOTIFICATIONS: (state, value) => {
      state.notifications = value;
    },

    SET_FETCH_CHATS_SNAPSHOT: (state, value) => {
      state.fetchChatsSnapshot = value;
    },

    SET_FETCH_ACTIVE_USER_DATA: (state, value) => {
      state.activeUserData = value;
    },

    SET_PUBLISHERS_SUPPORT_USER: (state, value) => {
      state.publishersSupportUser = value;
    },
  },

  getters: {
    hashedActiveUserId(state) {
      return hashWithMd5(state.activeChat?.userId)
    },

    hashedCurrentUserId(state, getters, rootState) {
      return hashWithMd5(rootState.User?.user?.id)
    },

    chatDocId(state, getters) {
      return [getters.hashedActiveUserId, getters.hashedCurrentUserId].sort().toString();
    },

    hashedChatDocId(state, getters) {
      return hashWithMd5(getters.chatDocId)
    },
  },

  actions: {
    setActiveChat({ commit }, value) {
      commit("SET_ACTIVE_CHAT", value)
    },

    setSearchTargetMessageId({ commit }, value) {
      commit("SET_SEARCH_TARGET_MESSAGE_ID", value)
    },

    async setUserData({ dispatch }, id) {
      const userInfoQuery = query(collection(db, "Users"), where(documentId(), "==", hashWithMd5(id)));
      const userInfoDoc = await getDocs(userInfoQuery);
      let userData = {};
      userInfoDoc.forEach((docEl) => {
        const docData = docEl.data()
        const decryptedUserData = JSON.parse(decryptUser(docData.user))
        userData = { ...docData, ...decryptedUserData }
      });
      dispatch("setActiveChat", userData);
    },

    async fetchUsers({
      commit, state, getters, dispatch,
    }) {
      const currentUserChats = query(
        collection(db, "Chats"),
        where("participantIds", "array-contains", getters.hashedCurrentUserId),
      );
      const snapshot = onSnapshot(currentUserChats, (querySnapshot) => {
        commit("SET_CURRENT_USER_CHAT", []);
        commit("SET_CHAT_USERS_LIST", uniqBy({ array: [...state.chatUsersList], property: "userId" }))
        commit("SET_CURRENT_USER_PARTICIPANTS_IDS", [])
        commit("SET_UNREAD_MESSAGES", [])

        querySnapshot.forEach((docEl) => {
          commit("SET_CURRENT_USER_CHAT", [...state.currentUserChats, docEl.data()]);
        });
        state.currentUserChats.forEach((user) => {
          const participantId = user.participantIds.filter(
            (el) => el !== getters.hashedCurrentUserId,
          )[0];
          commit("SET_CURRENT_USER_PARTICIPANTS_IDS", [...state.currentUserParticipantsIds, participantId])
          dispatch("handleUnreadMsgs", { user, participantId })

          if (user.lastMessage && user.lastMessage?.createdAt && user.lastMessage.participantId !== getters.hashedCurrentUserId) {
            /**
             * [IMP] as we are spreading any way , why do not we use spread operator instead of push
             */

            const createdAt = new Timestamp(user.lastMessage?.createdAt.seconds, user.lastMessage?.createdAt.nanoseconds).toDate().getTime()
            const lastMessageTime = window.moment(createdAt)
            const now = window.moment(new Date().getTime()).subtract(700, "ms")

            if (window.moment(lastMessageTime).isSameOrAfter(now)) {
              dispatch("addNotifications", { user, createdAt })
            }
          }
        });
      });

      commit("SET_FETCH_CHATS_SNAPSHOT", snapshot)
    },

    addNotifications({ commit, state, getters }, { user, createdAt }) {
      const userDetails = user.participantes[Object.keys(user.participantes).find((el) => el !== getters.hashedCurrentUserId)]
      const notifications = uniqBy({ array: [...state.notifications, { ...user.lastMessage, createdAt, user: userDetails }], property: "createdAt" })

      commit("SET_NOTIFICATIONS", notifications)
    },

    removeNotification({ commit, state }, value) {
      const filterdArray = state.notifications.filter((el) => el.createdAt !== value)
      commit("SET_NOTIFICATIONS", filterdArray)
    },

    resetNotifications({ commit }) {
      commit("SET_NOTIFICATIONS", [])
    },

    handleUnreadMsgs({ commit, state, getters }, { user, participantId }) {
      if (
        user.numberOfMessages
        && user.seen
        && user.seen[getters.hashedCurrentUserId]
      ) {
        const difference = user.numberOfMessages
          - user.seen[getters.hashedCurrentUserId].numberOfMessages;

        commit("SET_UNREAD_MESSAGES", [...state.usersNumOfUnreadMsgs, {
          unreadMsgs: difference,
          participantId,
        }])
      } else {
        commit("SET_UNREAD_MESSAGES", [...state.usersNumOfUnreadMsgs, {
          unreadMsgs: user.numberOfMessages,
          participantId,
        }])
      }
    },

    unsubscribeFetchChats({ state, commit }) {
      if (state.fetchChatsSnapshot) {
        state.fetchChatsSnapshot()
        commit("SET_FETCH_CHATS_SNAPSHOT", null)
      }
    },

    setActiveUserData({ commit }, value) {
      commit("SET_FETCH_ACTIVE_USER_DATA", value)
    },

    async fetchPublishersSupportUser({ commit }) {
      const docRef = doc(db, "Users", hashWithMd5(process.env.VUE_APP_PUBLISHERS_SUPPORT_USER_ID));
      const docSnap = await getDoc(docRef);

      commit("SET_PUBLISHERS_SUPPORT_USER", JSON.parse(decryptUser(docSnap.data().user)))
    },

    changeFirebaseUserOnlineStatus({ rootState }, online) {
      const docRef = doc(db, "Users", md5(`${rootState.User.user?.id}`));
      setDoc(docRef, {
        onlineModificationDate: serverTimestamp(),
        online,
      }, { merge: true })
    },
  },

}
