<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="23" height="23"
    viewBox="0 0 23 23" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.29503 4.3709L3.16509 5.24091C3.44102 5.51682 3.44102 5.96412 3.1651 6.24004C3.16509 6.24004 3.16509 6.24004 3.16509
      6.24004L3.10053 6.17548C2.86026 6.41577 2.4707 6.41573 2.23047 6.17548L2.29503 4.3709ZM2.29503 4.3709C2.29503 4.3709 2.29503
      4.3709 2.29503 4.3709C2.01914 4.09499 1.57177 4.095 1.29585 4.3709C1.01992 4.64681 1.01992 5.09412 1.29585 5.37003L2.16591
      6.24004L2.29503 4.3709ZM2.16591 11.7532C2.4418 11.4773 2.88917 11.4773 3.16509 11.7532C3.44102 12.0292 3.44102 12.4765 3.16509
      12.7524L2.29503 13.6224C2.29503 13.6224 2.29503 13.6224 2.29503 13.6224C2.0191 13.8983 1.57173 13.8983 1.29585 13.6224C1.29584
      13.6224 1.29584 13.6224 1.29584 13.6224L1.3604 13.5578L2.16591 11.7532ZM2.16591 11.7532C2.16591 11.7532 2.16591 11.7532 2.16591
      11.7532L2.16591 11.7532ZM4.19833 6.75545L4.28765 6.77439L4.19833 6.75545C3.69168 9.14486 4.31607 11.5455 5.91243 13.34L5.98065
      13.2793L5.91244 13.34C6.66873 14.1901 7.10206 15.3123 7.10206 16.42V17.6504C7.10206 18.4904 7.63959 19.2064 8.38821 19.4743C8.65995
      20.9269 9.93061 22.089 11.5 22.089C13.069 22.089 14.34 20.9273 14.6118 19.4743C15.3604 19.2064 15.8979 18.4904 15.8979
      17.6503V16.42C15.8979 15.3109 16.3321 14.2021 17.1239 13.2988C18.3168 11.9384 18.9744 10.1907 18.9741 8.38143C18.9741 3.66591
      14.6574 0.0806523 9.89546 1.07543L9.91413 1.1648L9.89546 1.07543C7.08054 1.66358 4.79891 3.92319 4.19833 6.75545ZM16.0613
      12.3675L16.0613 12.3675C15.2106 13.3379 14.679 14.5049 14.5288 15.7135H8.47136C8.32143 14.5045 7.79209 13.327 6.96832
      12.4009L6.9001 12.4616L6.96831 12.4009C5.67402 10.946 5.16775 8.99582 5.58069 7.04856C6.06205 4.77846 7.91285 2.93322 10.1845
      2.4586L10.1658 2.36923L10.1845 2.4586C14.0663 1.64745 17.561 4.56166 17.561 8.38145C17.5612 9.84806 17.0282 11.2647 16.0613
      12.3675ZM11.5 20.6761C10.7957 20.6761 10.1686 20.219 9.89333 19.5832H13.1066C12.8314 20.219 12.2043 20.6761 11.5 20.6761ZM14.4849
      17.6504C14.4849 17.9392 14.2498 18.1743 13.9609 18.1743H9.03906C8.75024 18.1743 8.51513 17.9392 8.51513
      17.6504V17.1265H14.4849V17.6504ZM1.61523 8.29014C1.22504 8.29014 0.908699 8.60646 0.908699 8.99664C0.908699 9.38682 1.22504
      9.70314 1.61523 9.70314H2.8457C3.2359 9.70314 3.55224 9.38682 3.55224 8.99664C3.55224 8.60646 3.2359 8.29014 2.8457
      8.29014H1.61523ZM21.3848 8.29014H20.1543C19.7641 8.29014 19.4478 8.60646 19.4478 8.99664C19.4478 9.38682 19.7641 9.70314 20.1543
      9.70314H21.3848C21.775 9.70314 22.0913 9.38682 22.0913 8.99664C22.0913 8.60646 21.775 8.29014 21.3848 8.29014ZM21.7042
      4.3709C21.4283 4.09499 20.9809 4.095 20.705 4.3709L19.8349 5.24091C19.559 5.51682 19.559 5.96413 19.8349 6.24004C20.1108
      6.5159 20.5582 6.51595 20.8341 6.24004L21.7042 5.37003C21.9801 5.09412 21.9801 4.64681 21.7042 4.3709ZM21.7042 12.6232L20.8341
      11.7532C20.5582 11.4773 20.1108 11.4773 19.8349 11.7532C19.559 12.0292 19.559 12.4765 19.8349 12.7524L20.705 13.6224C20.9809
      13.8982 21.4282 13.8983 21.7042 13.6224C21.9801 13.3465 21.9801 12.8992 21.7042 12.6232Z"
      :fill="isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.182603"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
