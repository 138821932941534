import Vue from "vue";
import VueTelInput from "vue-tel-input";
import mixpanel from "mixpanel-browser";
import GoogleAuth from "@/config/google"
import { mapGetters } from "vuex";
import { registerEvent, trackPageView } from "@/services/TrackingManagement";
import formatNumber from "@/utils/numbers/format-number/index";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang";
import MainLayout from "./layouts/Main.vue";
import Loader from "./components/partials/BaseLoader.vue";
import isTokenValid from "./utils/validation/is-token-valid/index";
import permissions from "./config/permissions";
import clickOutside from "./directives/v-click-outside";

import "./assets/sass/border.sass" // to fix style overriding issue

if (process.env.VUE_APP_NAME === "revgate") {
  require("./assets/sass/colors/revgate/index.sass")
} else {
  require("./assets/sass/colors/boostiny/index.sass")
}

/* eslint-disable */
import "./assets/sass/main.sass";
/* eslint-enable */

Vue.config.productionTip = false;

window.moment = require("moment");

Vue.component("Layout", MainLayout);
Vue.component("Loader", Loader);

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: "profile email",
  prompt: "select_account",
  ux_mode: "popup",
}

Vue.directive("click-outside", clickOutside)

Vue.use(GoogleAuth, gauthOption)
Vue.use(VueTelInput);

Vue.prototype.$gtag = window.gtag;
Vue.prototype.$permissions = permissions

/* MixPanel Registration  */

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  debug: false, // enable it while development only
  track_pageview: false,
  persistence: "localStorage",
});
/* MixPanel Registration  */

window.onbeforeunload = () => {
  if (router.currentRoute.meta.hasFilters) {
    store.dispatch("Globals/saveFilters", {
      route: router.currentRoute.name,
    });
  }
};

new Vue({
  router,
  store,
  i18n,
  data: {
    windowWidth: window.innerWidth,
  },

  computed: {
    ...mapGetters("Authorization", ["isLoginAsActivated"]),

    lang() {
      return i18n.locale;
    },

    user() {
      return this.$store.state.User.user;
    },
  },

  watch: {
    user: {
      deep: true,
      handler() {
        if (
          !!Object.keys(this.user).length
          && isTokenValid()
          && !this.isLoginAsActivated
        ) {
          store.dispatch("Notifications/setUnseenNotificationCount");

          if (this.$router.currentRoute.meta.entityName) {
            trackPageView({ page_screen_name: this.$router.currentRoute.meta.entityName })
          }
        }
      },
    },
  },

  created() {
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();

    store.dispatch("Authorization/setIsLoginAsActivated")
  },

  updated() {
    this.handleWindowResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
  },

  mounted() {
    this.setLang();

    if (isTokenValid()) {
      store.dispatch("User/fetchUser", { fetchDependencies: true })
    }

    store.dispatch("Globals/getRouteSharedFilters");
  },

  beforeDestroy() {
    store.dispatch("Chat/changeFirebaseUserOnlineStatus", false);
  },

  methods: {
    checkPermission({ permission }) {
      return this.user.permissions?.[permission.module]?.[permission.subModule]?.includes(permission.value)
    },

    convertCurrencyRate({ value }) {
      return value * this.$store.state.User.currencyRate
    },

    formatNumber({
      number,
      style = "decimal",
      currency, // either USD or the user currency, only required when the style == currency
      notation = "standard",
      minDecimals = 0,
      maxDecimals = 2,
    }) {
      return formatNumber({
        number,
        style,
        currency: currency || this.user.settings.currency.code,
        locale: this.lang === "ar" && "ar-SA",
        notation,
        minDecimals,
        maxDecimals,
      })
    },

    handleWindowResize() {
      this.windowWidth = window.innerWidth;
    },

    /**
     * todo: refactor
     */
    setLang() {
      if (localStorage.getItem("lang")) {
        if (localStorage.getItem("lang") === "ar") {
          document.documentElement.setAttribute("dir", "rtl")
          i18n.locale = "ar";
          window.moment.locale("ar");
        }
      }
    },

    scrollUp() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },

    sendTrackingEvent({
      name,
      properties = {},
      platforms = [],
    }) {
      if (this.isLoginAsActivated) return

      const requireAuth = this.$router.currentRoute.matched.map((el) => !!el.meta.requireAuth)
        .some((el) => el === true)
      if (requireAuth && Object.keys(this.user).length === 0) return

      const { entityName } = this.$router.currentRoute.meta
      const superProperties = {
        ...(entityName ? { page_screen_name: entityName } : {}),
      }

      registerEvent(({
        platforms,
        name,
        properties,
        superProperties,
      }))
    },
  },
  render: (h) => h(App),
}).$mount("#app");

/**
 * todo: remove it
 */
window.toggleLang = () => {
  if (localStorage.getItem("lang") === "ar") {
    document.documentElement.setAttribute("dir", "ltr")
    window.moment.locale("en");
    i18n.locale = "en";
    localStorage.setItem("lang", i18n.locale);
    return;
  }

  document.documentElement.setAttribute("dir", "rtl")

  i18n.locale = "ar";
  window.moment.locale("ar");
  localStorage.setItem("lang", i18n.locale);
};
