import setSelectedFiltersOptionsV2 from "@/utils/input-output/set-selected-filters-options-v2/index";
import API from "@/utils/input-output/api/index";
import constants from "../../constants"

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      isSubmitting: false,
    }
  },
  computed: {
    storageFiltersData() {
      return JSON.parse(localStorage.getItem("filters") || "{}");
    },
    userPersona() {
      return this.$store.getters["User/userPersona"];
    },
    isFinanceUser() {
      return this.$store.getters["Authorization/isFinanceUser"]
    },

    campaign() {
      return this.notification.content.campaign;
    },

    partner() {
      return this.notification.content.partner;
    },

    canViewCampaigns() {
      return this.$root.checkPermission({ permission: this.$permissions.VIEW_CAMPAIGNS })
    },

    canViewPartners() {
      return this.$root.checkPermission({ permission: this.$permissions.VIEW_PARTNERS })
    },

    canViewCoupons() {
      return this.$root.checkPermission({ permission: this.$permissions.VIEW_COUPONS })
    },

    canViewPaymentValidation() {
      return this.$root.checkPermission({ permission: this.$permissions.VIEW_PARTNERS_PAYMENTS })
    },

    canViewPartnerPaymentReport() {
      return this.$root.checkPermission({ permission: this.$permissions.VIEW_PARTNER_PAYMENT_REPORT })
    },

    canViewAnnouncements() {
      return this.$root.checkPermission({ permission: this.$permissions.VIEW_ANNOUNCEMENTS })
    },

    canViewInvoices() {
      return this.$root.checkPermission({ permission: this.$permissions.VIEW_INVOICES })
    },

    canViewCampaignValidation() {
      return this.$root.checkPermission({ permission: this.$permissions.VIEW_CAMPAIGN_VALIDATION })
    },

    isLoginAsActivated() {
      return this.$store.getters["Authorization/isLoginAsActivated"];
    },
    isPartner() {
      return this.$store.getters["Authorization/isPartner"];
    },
  },

  methods: {
    viewCampaignDetails() {
      this.setNotificationAsRead()
      if (!this.canViewCampaigns) return;
      this.$router.push({
        name: "campaign-details",
        params: {
          id: this.campaign.id,
        },
      });
    },

    viewPartnerDetails() {
      this.setNotificationAsRead()
      if (this.isPartner) {
        this.$router.push({ name: "profile" })
        return;
      }
      this.$router.push({
        name: "partner-details",
        params: {
          id: this.partner.id,
        },
      });
    },

    viewCampaignCoupons() {
      this.setNotificationAsRead()
      if (!this.canViewCoupons) return;
      this.$router.push({
        name: "campaign-coupons",
        params: {
          id: this.campaign.id,
        },
      });
    },

    viewPaymentValidations() {
      this.setNotificationAsRead()
      if (!this.canViewPaymentValidation) return;
      this.$router.push({ name: "payments-and-invoices" });
    },

    viewPartnerPayment() {
      this.setNotificationAsRead()
      if (!this.canViewPartnerPaymentReport) return;
      this.$router.push({ name: "partner-payment" });
    },

    viewAnnouncements() {
      this.setNotificationAsRead()
      if (!this.canViewAnnouncements) return;
      const link = this.$root.lang === "en" ? this.notification.content.announcement.content : this.notification.content.announcement.content_ar
      if (link) {
        window.open(link, "_blank")
      }
    },

    viewInvoice() {
      this.setNotificationAsRead()
      if (!this.canViewInvoices) return;
      this.$router.push({ name: "payments-and-invoices" })
    },

    viewValidations() {
      this.setNotificationAsRead()
      if (!this.canViewCampaignValidation) return;
      this.$router.push({ name: "campaign-validation" })
    },

    setValidationsFilters() {
      if (this.isFinanceUser) return;

      const filtersData = this.storageFiltersData;

      setSelectedFiltersOptionsV2({
        route: "campaign-validation",
        name: "campaign",
        data: [{
          id: this.campaign.id,
          value: this.campaign.id,
          name: this.campaign.name,
        }],
      })

      if (this.$router.currentRoute.name === "campaign-validation") {
        this.$store.dispatch("Globals/setFilter", {
          key: "cycle",
          value: this.conversionCycle,
        });
        this.$store.dispatch("Globals/setFilter", {
          key: "campaign",
          value: this.campaign.id,
        });
        return;
      }

      filtersData["campaign-validation"] = {
        cycle: this.conversionCycle,
        campaign: this.campaign.id,
        dimensions: [],
        metrics: [],
      };
      this.setLocalStorage("filters", filtersData);
      this.viewValidations();
    },

    formatCycle(cycle) {
      return cycle.slice(0, 7)
    },

    setNotificationAsRead() {
      this.sendGtagEvent()
      if (this.isLoginAsActivated || this.notification.status === "read") return

      this.$emit("set-notification-as-read")

      API({
        url: constants.setNotificationToBeRead({ id: this.notification.id, persona: this.userPersona }),
        method: "put",
        requestId: "setNotificationToBeRead",
      }).then(() => {
        this.$store.dispatch("Notifications/decrementUnreadNotifications")
      })
    },

    setLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },

    actionTaken(id) {
      return API({
        url: constants.actionTaken({
          id,
          persona: this.userPersona,
        }),
        method: "PUT",
        requestId: "actionTaken",
      })
    },

    sendGtagEvent() {
      this.$gtag("event", "click_system_notification", {
        screen: this.$route.meta.entityName,
        component: this.$route.meta.entityName === "overview" ? "Today’s updates" : "System",
        role: this.$store.state.User.user.roleName,
        notification_type: this.notification.tag,
      })
    },
  },
}
