<script>
  import Boost from "../icons/Boost.vue"

  export default {
    components: { Boost },
  }
</script>

<template>
  <router-link
    class="text-decoration-none text-base-white p-x-12 p-y-9 radius-8
    d-flex justify-content-center align-items-center gap-10 cursor-pointer bg-primary-500"
    :to="{ name: 'loyaltyProgram' }"
  >
    <Boost />
    <span class="p-base-medium">{{ $t("labels.loyaltyProgram") }}</span>
  </router-link>
</template>

<style scoped lang="sass">

</style>
