<script>
  import trimSpaces from "@/utils/string/trim-spaces/index";
  import InfoIcon from "@/components/partials/icons/Info.vue";
  import InputMessage from "./InputMessage.vue";
  import themes from "./config"

  export default {
    components: { InputMessage, InfoIcon },
    props: {
      theme: {
        required: false,
        default: "neutral",
        type: String,
        validator: (value) => Object.keys(themes).includes(value),
      },
      label: {
        required: false,
        type: String,
        default: "",
      },

      value: {
        required: true,
        type: String,
      },

      message: {
        required: false,
        type: String,
        default: null,
      },

      readonly: {
        required: false,
        type: Boolean,
        default: false,
      },

      disabled: {
        default: false,
        type: Boolean,
      },

      placeholder: {
        required: false,
        type: String,
        default: "",
      },

      rows: {
        required: false,
        type: Number,
        default: 8,
      },
      inputId: {
        type: [String, Number],
        required: true,
      },

      isRequired: {
        type: Boolean,
        required: false,
        default: false,
      },
      showInfoIcon: {
        type: Boolean,
        required: false,
        default: false,
      },
      maxLength: {
        type: Number,
        default: 300,
      },
    },
    data() {
      return {};
    },
    computed: {
      activeTheme() {
        return themes[this.theme]
      },
    },

    methods: {
      trimValueSpaces(inputValue) {
        return trimSpaces(inputValue);
      },
      clickOnInfoIcon() {
        this.$emit("info-icon-clicked", { id: this.inputId });
      },
    },
  };
</script>

<template>
  <div class="w-100">
    <label
      v-if="label"
      class="p-base-medium text-neutral-900 m-l-12"
      :for="label"
      :class="{ 'm-l-0': showInfoIcon }"
    >
      <span v-if="showInfoIcon" class="radius-full m-r-3 m-t-3 cursor-pointer">
        <InfoIcon
          color="var(--accent-4-400)"
          name="has-update-btn"
          @click.native.stop="clickOnInfoIcon"
        />
      </span>
      <span>{{ $t(`labels.${label}`) }}</span>
      <span v-if="isRequired" class="text-error-500 m-l-3">*</span>
    </label>
    <textarea
      :id="inputId"
      :rows="rows"
      class="
        w-100
        m-y-6
        p-12
        radius-8
        p-base-medium
        overflow-y-scroll
        primary-scroll
      "
      :class="[activeTheme.bgClass, activeTheme.textClass, activeTheme.borderClass]"
      :readonly="readonly"
      autocomplete="off"
      :value="value"
      :placeholder="placeholder ? $t(`placeholders.${placeholder}`) : ''"
      :disabled="disabled"
      :maxlength="maxLength"
      @input="
        $emit('update-value', {
          value: trimValueSpaces($event.target.value),
          id: inputId,
        }),
        $emit('reset'),
        $emit('update:value', trimValueSpaces($event.target.value))
      "
      @keyup.enter="$emit('submit')"
    />
    <InputMessage
      v-if="message" :message="message"
    />
  </div>
</template>

<style scoped lang='sass'>
textarea
  overflow: auto
  resize: none
textarea:focus
  box-shadow: none !important
  outline: none !important
label
  text-transform: capitalize
</style>
