import store from "../store"
import DownPage from "../views/errors/SystemDown[503].vue"
import NotFound from "../views/errors/NotFound[404].vue"

export default [
  {
    path: "/system-down",
    name: "system-down",
    component: DownPage,
    meta: {
      title: "systemDown",
      entityName: "system-down",
    },
    beforeEnter: ((to, from, next) => {
      const err = store.state.ResponseHandling.errorCode
      if (err === 503) {
        next()
      } else {
        next("/")
      }
    }),
  },
  {
    path: "/suspended-partner",
    name: "suspended-partner",
    component: () => import(/* webpackChunkName: "PartnerSuspended" */ "../views/errors/PartnerSuspended[451].vue"),
    meta: {
      title: "suspendPartner",
      entityName: "suspended-partner",
    },
    beforeEnter: ((to, from, next) => {
      const err = store.state.ResponseHandling.errorCode
      if (err === 451) {
        next()
      } else {
        next("/")
      }
    }),
  },
  {
    path: "/request-to-delete-partner",
    title: "deleteRequest",
    name: "deleteRequest",
    component: () => import(/* webpackChunkName: "RequestedToDeletedPartner" */ "../views/errors/RequestedToDeletedPartner[451].vue"),
    meta: {
      requireAuth: true,
      entityName: "delete-partner-request",
    },
    beforeEnter: ((to, from, next) => {
      const err = store.state.ResponseHandling.errorCode
      if (err === 451) {
        next()
      } else {
        next("/")
      }
    }),
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
    meta: {
      title: "notFound",
      entityName: "not-found",
    },
  },
]
