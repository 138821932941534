<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="20" height="20"
    viewBox="0 0 20 20" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 14.5L10 19L19 14.5M1 10L10 14.5L19 10M10 1L1 5.5L10 10L19 5.5L10 1Z"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
