export default [
  {
    path: "/agencies",
    name: "agencies",
    component: () => import(/* webpackChunkName: "AgenciesList" */ "../views/agencies/List.vue"),
    meta: {
      requireAuth: true,
      title: "agencies",
      entityName: "agencies-list",
      filtersScheme: {
        advancedFilters: [],
        type: "ready",
        status: "approved",
        name: "",
        countries: [],
        dateRange: { from: "", to: "" },
      },
    },
  },
]
