<script>
  import mixin from "../../mixin";

  export default {
    components: {
      Backoffice: () => import("./Backoffice.vue"),
      Partner: () => import("./Partner.vue"),
    },
    mixins: [mixin],
    data() {
      return {};
    },
    computed: {
      userPersona() {
        return this.$store.getters["User/userPersona"];
      },
    },
    methods: {},
  };
</script>
<template>
  <div>
    <Backoffice
      v-if="userPersona === 'backoffice'"
      :notification="notification"
    />
    <Partner
      v-if="userPersona === 'partner'"
      :notification="notification"
      @view-campaign-details="viewCampaignDetails"
    />
  </div>
</template>

<style lang="sass" scoped>
</style>
