export default [
  {
    path: "/extras-and-deductions",
    component: () => import(/* webpackChunkName: "ExtrasAndDeductions" */ "../views/extras-and-deductions/Index.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        name: "extras-and-deductions",
        component: () => import(
          /* webpackChunkName: "ExtrasAndDeductionList" */ "../views/extras-and-deductions/List.vue"
        ),
        meta: {
          hasFilters: true,
          filtersScheme: {
            advancedFilters: [],
            campaigns: [],
            cycle: "",
            sortDirection: "desc",
            sortMetrics: "type",
            type: "all",
            category: "",
            buIds: [],
          },
          title: "extrasAndDeductions",
          entityName: "extras-and-deductions-list",

        },
      },
      {
        path: "bulk-upload-logs",
        name: "bulk-upload-logs",
        component: () => import(/* webpackChunkName: "BulkUploadExtrasAndDeductionLogs" */ "../views/extras-and-deductions/BulkUploadLogs.vue"),
        meta: {
          hasFilters: true,
          filtersScheme: {
            type: "",
          },
          title: "bulkUploadLogs",
          entityName: "bulk-upload-logs",

        },
      },
    ],
  },
]
