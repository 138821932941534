export default {
  notificationSettings: {
    "coupon-allocation-expired": "Coupon code expiration",
    "coupon-allocation-assigned": "Coupon allocation assigned",
    "coupon-code-request": "Coupon code request",
    "coupon-request-created": "Coupon code request",
    "coupon-request-rejected": "Coupon request rejection",
    "coupon-request-allocated": "Coupon Request code allocation",
    "campaign-paused": "Campaign paused",
    "campaign-stopped": "Campaign stopped",
    "campaign-rejected": "Campaign rejected",
    "campaign-resumed": "Campaign resumed",
    "campaign-application-approved": "Campaign application approved",
    "campaign-application-rejected": "Campaign application rejected",
    "campaign-status-update": "Campaign status update (paused/stopped/resumed)",
    "campaign-application-request": "Campaign application request",
    "partner-validation-approved-for-partners": "Payment Report ready",
    "partner-validation-approved-for-team": "Partner payment ready",
    "payout-expiration": "Payout condition expiration",
    "revenue-expiration": "Revenue condition expiration",
    "campaign-updated-by-advertiser": "Campaign updated by advertiser",
    "campaign-status-changed": "Campaign status change",
    "miscellaneous-request-update-partner-profile-action": "Profile edit approval",
    "miscellaneous-request-update-partner-profile": "Profile edits",
    "miscellaneous-user-tagged-in-partner-comment": "Tagging in partner profile comments",
    "announcements-boostiny": "Boostiny",
    "announcements-promotions": "Promotions",
    "announcements-insights": "Insights",
    "announcements-updates": "Updates",
    "link-campaign-access": "Link Campaign access",
    "campaign-access-agency-given": "Giving campaign access",
    "campaign-access-agency-received": "Receiving campaign access",
    "campaign-created-by-agency": "Campaign created by agency",
    "partner-payment-validation-management-approved": "Payment Actions",
    "partner-payment-validation-bounced": "Payment Failed",
    "partner-payment-validation-paid": "Payment Completed",
    "partners-uploaded-vat-invoices": "Partner Uploaded VAT Invoices",
    "partners-approved-invoices": "Partner Approved Invoices",
    "pending-payment-actions": "Payment Pending Actions",
    "campaign-cap-deleted": "Campaign Cap Deletion",
    "campaign-cap-consumed": "Campaign Cap Consumption",
    "campaign-cap-usage-alert": "Campaign Cap Usage Alert",
    "campaign-cap-increased": "Campaign Cap Increase",
    "campaign-cap-extended": "Campaign Cap Extension",
    "campaign-validation-days-changed": "Campaign Validation Days Change",
    "user-tagged-in-partner-payment-validation-comment": "Tagging in payment comments",
    "campaign-validation-sheet-uploaded": "Validation Sheet Upload",
    "campaign-validation-sheet-accepted": "Validation Sheet Acceptance",
    "undo-campaign-validation-approval": "Validation Undo",
    "campaign-validation-flagged": "Validation Flagged",
    "campaign-validation-sheet-rejected": "Validation Sheet Rejection",
    "campaign-validation-approved": "Validation Done",
    "campaign-access-revoked": "Campaign Access Revoked",

  },
}
