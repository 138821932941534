export default [
  {
    path: "tracking-link",
    component: () => import(/* webpackChunkName: "TrackingLink" */
      "@/views/campaigns/details/tracking-link/Index.vue"
    ),
    meta: {
      showTabs: true,
    },
    children: [
      {
        path: "",
        name: "tracking-link",
        component: () => import(/* webpackChunkName: "TrackingLinksList" */
          "@/views/campaigns/details/tracking-link/List.vue"
        ),
        meta: {
          title: "trackingLink",
          entityName: "tracking-links",
          requireAuth: true,
          showTabs: true,
        },
      },
      {
        path: ":offerUrlId",
        name: "tracking-link-customization",
        component: () => import(/* webpackChunkName: "TrackingLinkCustomization" */
          "@/views/campaigns/details/tracking-link/Customization.vue"
        ),
        meta: {
          title: "trackingLinkCustomization",
          entityName: "tracking-link-customization",
          requireAuth: true,
          showTabs: true,
        },
      },
    ],
  },
]
