export default {
  notifications: {
    /* Campaign */
    "campaign-application-approved": {
      "application-for": "Application for",
      "campaign-got-approved": "campaign got approved.",
    },
    "campaign-application-rejected": {
      "application-for": "Application for",
      "campaign-got-rejected": "campaign got rejected.",
    },
    "campaign-paused": {
      campaign: "Campaign",
      paused: "is paused.",
    },
    "campaign-resumed": {
      campaign: "Campaign",
      resumed: "is resumed.",
    },
    "campaign-stopped": {
      campaign: "Campaign",
      stopped: "is stopped.",
    },

    "campaign-application-request": {
      "has-applied": "has applied for",
      campaign: "campaign",
    },
    "campaign-status-changed": {
      "campaign-status": "Campaign status of",
      "has-been-changed": "has been changed from",
      to: "to",
    },
    "link-campaign-access": "Please note that you have been given access to the Link based campaign",
    "campaign-access-agency-received": "Please note that you have been given access to Campaign",
    "campaign-access-agency-given": "have been given access to Campaign",

    "campaign-created-by-agency": {
      created: "created",
      agency: "Agency",
      campaign: "campaign",
      "available-on-boostiny": "and now it's available on Boostiny",
    },

    "campaign-cap-usage-alert": {
      campaign: "Campaign",
      budgetIs: "budget is",
      consumed: "consumed,",
      daysRemaining: "day remaining. | days remaining.",
      campaignMightBeStopped: "Campaign might be stopped if the budget is reached.",
    },

    "campaign-cap-consumed": {
      campaign: "Campaign",
      consumed: "budget is consumed, campaign and links are stopped.",
      pleaseCheck: "Please check with the account manager.",
    },

    "campaign-cap-deleted": {
      campaign: "Campaign",
      budgetRemoved: "budget is removed.",
      promoteCampaign: "Now you can promote the campaign without any restriction on the budget!",
    },

    "campaign-cap-increased": {
      campaign: "Campaign",
      budgetIncreased: "budget increased and",
      consumed: "is consumed,",
      daysRemaining: "day remaining. | days remaining.",
      campaignMightBeStopped: "Campaign might be stopped if the budget is reached.",
    },

    "campaign-cap-extended": {
      campaign: "Campaign",
      budgetExtended: "budget date extended and",
      consumed: "is consumed,",
      daysRemaining: "day remaining. | days remaining.",
      campaignMightBeStopped: "Campaign might be stopped if the budget is reached.",
    },

    "campaign-access-revoked": {
      backoffice: {
        note: "Please note that your access on",
        revoked: "has been revoked starting from",
      },
      partner: {
        links: "Your links on campaign",
        deactivated: "have been deactivated because",
        from: "from",
      },
    },
    /* Partner  */
    "partner-validation-approved-for-partners": {
      "payment-report": "Payment report for the month of",
      ready: "is ready.",
      "please-check": "Please check.",
    },

    "partner-validation-approved-for-team": {
      "partner-payment": "Partner payment of",
      "for-the-month": "for the month of",
      ready: "is ready.",
    },

    "partner-referring-publisher": {
      congratulations: "Congratulations",
      "payment-added": "has been added to your payment as your referral has made his first conversion",
    },
    "partner-referred-publisher": {
      congratulations: "Congratulation you have earned",
      "points-added": "points for completing your first conversion",
    },
    "partner-loyalty-reward": {
      congratulations: "Congratulations your points have been redeemed with",
      extra: "as an extra payment in this month",
      physical: "and an Account Manager will contact you to ship it",
    },

    /* Coupon */

    "coupon-request-created": {
      "has-requested": "has requested",
      "coupons-for": "coupons for",
      campaign: "campaign.",
    },

    "coupon-request-rejected": {
      "coupon-code": "Coupon code request for",
      "campaign-got-rejected": "campaign got rejected.",
    },
    "coupon-request-allocated": {
      "coupon-code": "coupon code(s) request for",
      "got-allocated": " campaign got allocated.",
    },
    "coupon-allocation-expired": {
      "coupon-code": "Coupon code",
      under: "under",
      "campaign-got-expired": "campaign got expired.",
    },
    "coupon-allocation-will-be-expired": "campaign will be expiring on",
    "coupon-allocation-assigned": "Coupon code(s) has been allocated to campaign",

    // ---------
    /* payout and revenue notifications types  */

    "revenue-expiration": {
      "revenue-condition": "Revenue condition",
      for: "for",
      due: "is due on",
      "update-condition": "Please update or create new condition.",
    },

    "payout-expiration": {
      "payout-condition": "Payout condition",
      for: "for",
      due: "is due on",
      "update-condition": "Please update or create new condition.",
    },

    /* advertiser notification types */
    "campaign-updated-by-advertiser": {
      "please-note": "Please note that advertiser has updated the",
      of: "of",
      campaign: "campaign",
    },

    noNotifications: "No Notifications",
    /* Miscellaneous */
    "miscellaneous-request-update-partner-profile":
      "profile has some update requests from",
    "miscellaneous-request-update-partner-profile-action": {
      "arabyads-team-has": "ArabyAds team has",
      "request-edits": "your request edits on",
    },

    "miscellaneous-user-tagged-in-partner-comment": {
      "please-note": "Please note that",
      "comments-tag": "has tagged you in the comments section of",
    },

    /* Payment Validation */
    "partner-payment-validation-management-approved": {
      "final-payment": "Final payment invoice for",
      "vat-registered": "is pending. Please add your VAT invoice in order to proceed with the payment",
      "vat-unregistered": `is in progress. Please review it and either accept/reject 
      it within 7 days as it will be auto-approved after 7 days if no action is taken from your side.`,
    },

    "partner-payment-validation-paid": {
      "invoice-for": "invoice for",
      paid: "is paid, click to review the invoice.",
    },

    "partner-payment-validation-bounced": {
      "payment-for": "payment for",
      "payment-failed": " was rejected by your bank, please contact partner support to review the issue.",
    },

    "partners-uploaded-vat-invoices": {
      "number-of-publishers": "publishers uploaded VAT invoices for",
      "number-of-influencers": "influencers uploaded VAT invoices for",
      "please-review": "Please review and proceed with the payment.",
    },

    "partners-approved-invoices": {
      "number-of-publishers": "publishers approved invoices for",
      "number-of-influencers": "influencers approved invoices for",
      "please-review": "Please review and proceed with the payment.",
    },

    "pending-payment-actions": {
      "number-of-partners": "partners have pending payment actions for",
      "please-review": "Please review and proceed with the payment.",
    },

    "user-tagged-in-partner-payment-validation-comment": {
      "comment-mention": "mentioned you in a comment related to",
      in: "in",
      payment: "payment.",
    },
    /** Validation */
    "campaign-validation-days-changed": {
      validation: "Validation period for campaign",
      changed: "has been changed from",
      to: "days to",
      by: "days by",
    },

    "campaign-validation-sheet-accepted": {
      accepted: "has accepted the validation file for",
      cycle: "cycle.",
    },

    "campaign-validation-flagged": {
      flagged: "has flagged the validation file for",
      cycle: "cycle.",
    },

    "campaign-validation-sheet-rejected": {
      rejected: "has rejected the validation file for",
      cycle: "cycle.",
      review: "Review the reasons and re-upload the file.",
    },

    "campaign-validation-sheet-uploaded": {
      uploaded: "has uploaded the validation file and is pending on BI review for",
      cycle: "cycle.",
    },

    "undo-campaign-validation-approval": {
      undone: "has undone validation for",
      cycle: "cycle on",
      "payment-cycle": "payment cycle",
    },

    "campaign-validation-approved": {
      validated: "has validated",
      cycle: "cycle",
      on: "on",
      "payment-cycle": "payment cycle.",
    },

    "user-created-campaign-validation-comment": {
      comment: "commented on",
      validation: "validation",
    },

    "user-mentioned-in-campaign-validation-comment": {
      mention: "mentioned you in",
      validation: "validation",
    },

    "user-replied-campaign-validation-comment": {
      reply: "replied to your comment",
    },

    /** Change reporting line */
    "reporting-line-changed-for-employee": {
      yourReportingLineChangedTo: "Please Note that your reporting line has been changed, and you are now reporting to",
      startedFrom: "starting from",
    },
    "reporting-line-moved-from-manager": {
      pleaseNoteThat: "Please Note that",
      movedFromYourTeam: "has been moved from your team starting from",
    },
    "reporting-line-added-to-manager": {
      pleaseNoteThat: "Please Note that",
      addedToYourTeam: "has been added to your team starting from",
    },

    /** Migrate user campaigns */
    "migrate-campaigns-from-user": {
      pleaseNoteThat: "Please Note that",
      startingFrom: "campaign(s) have been migrated from you starting from",
      forMoreDeatilsCheckEmail: "for more details please check your email",
    },
    "migrate-campaigns-to-user": {
      pleaseNoteThat: "Please Note that",
      startingFrom: "campaign(s) have been migrated to you starting from",
      forMoreDeatilsCheckEmail: "for more details please check your email",
    },
    "migrate-campaigns-for-head": {
      pleaseNoteThat: "Please Note that",
      migratedFrom: "campaign(s) have been migrated from",
      to: "to",
      startingFrom: "starting from",
      forMoreDeatilsCheckEmail: "for more details please check your email",
    },
    "migrate-campaigns-for-team": {
      pleaseNoteThat: "Please Note that",
      startingFrom: "campaign(s) have been migrated from you starting from",
      forMoreDeatilsCheckEmail: "for more details please check your email",
    },
    "cg-team-unassigned-from-campaign": {
      pleaseNoteThatYouHaveBeenUnassignedFrom: "Please note that you have been unassigned from",
      cgTeam: "'s CG team",
    },
    "cgm-team-assigned-to-campaign": {
      pleaseNoteThatYouHaveBeenAssignedAs: "Please note that you have been assigned as",
      cgm: "'s CGM",
    },
    "cgd-team-assigned-to-campaign": {
      pleaseNoteThatYouHaveBeenAssignedAs: "Please note that you have been assigned as",
      cgdAlongWith: "'s CGD, along with",
      asCgm: "as CGM",
    },
  },
}
