<script>
  export default {
    props: {
      message: {
        type: String,
        required: false,
        default: "",
      },
    },

    methods: {},
  };
</script>
<template>
  <div
    class="
      align-items-center
      d-flex
      flex-column
      p-12 p-base-medium
      text-neutral-400
    "
  >
    {{ message }}
  </div>
</template>
<style scoped lang='sass'></style>
