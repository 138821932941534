<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="22" height="20"
    viewBox="0 0 22 20" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.46 4.00002L10 3.41002V7.00002C10 7.26523 10.1054 7.51959 10.2929 7.70713C10.4804 7.89466 10.7348 8.00002 11 8.00002C11.2652
      8.00002 11.5196 7.89466 11.7071 7.70713C11.8946 7.51959 12 7.26523 12 7.00002V3.41002L12.54 3.96002C12.6308 4.06254 12.7416
      4.14533 12.8657 4.20325C12.9898 4.26117 13.1244 4.29299 13.2613 4.29675C13.3982 4.3005 13.5344 4.27609 13.6615 4.22505C13.7885
      4.17401 13.9038 4.09742 14 4.00002C14.0937 3.90706 14.1681 3.79645 14.2189 3.6746C14.2697 3.55274 14.2958 3.42203 14.2958
      3.29002C14.2958 3.15801 14.2697 3.0273 14.2189 2.90544C14.1681 2.78358 14.0937 2.67298 14 2.58002L11.71 0.290018C11.6149 0.198978
      11.5028 0.127613 11.38 0.0800184C11.1365 -0.0199996 10.8635 -0.0199996 10.62 0.0800184C10.4972 0.127613 10.3851 0.198978 10.29
      0.290018L8 2.54002C7.80639 2.73363 7.69762 2.99622 7.69762 3.27002C7.69762 3.54382 7.80639 3.80641 8 4.00002C8.19361 4.19363
      8.4562 4.30239 8.73 4.30239C9.0038 4.30239 9.26639 4.19363 9.46 4.00002ZM11 10C10.4067 10 9.82664 10.176 9.33329 10.5056C8.83994
      10.8353 8.45542 11.3038 8.22836 11.852C8.0013 12.4001 7.94189 13.0033 8.05764 13.5853C8.1734 14.1672 8.45912 14.7018 8.87868
      15.1213C9.29824 15.5409 9.83279 15.8266 10.4147 15.9424C10.9967 16.0581 11.5999 15.9987 12.1481 15.7717C12.6962 15.5446 13.1648
      15.1601 13.4944 14.6667C13.8241 14.1734 14 13.5934 14 13C14 12.2044 13.6839 11.4413 13.1213 10.8787C12.5587 10.3161 11.7956 10
      11 10ZM11 14C10.8022 14 10.6089 13.9414 10.4444 13.8315C10.28 13.7216 10.1518 13.5654 10.0761 13.3827C10.0004 13.2 9.98063 12.9989
      10.0192 12.8049C10.0578 12.6109 10.153 12.4328 10.2929 12.2929C10.4327 12.1531 10.6109 12.0578 10.8049 12.0192C10.9989 11.9806
      11.2 12.0005 11.3827 12.0761C11.5654 12.1518 11.7216 12.28 11.8315 12.4444C11.9414 12.6089 12 12.8022 12 13C12 13.2652 11.8946
      13.5196 11.7071 13.7071C11.5196 13.8947 11.2652 14 11 14ZM4 13C4 13.1978 4.05865 13.3911 4.16853 13.5556C4.27841 13.72 4.43459
      13.8482 4.61732 13.9239C4.80004 13.9996 5.00111 14.0194 5.19509 13.9808C5.38907 13.9422 5.56725 13.847 5.70711 13.7071C5.84696
      13.5673 5.9422 13.3891 5.98079 13.1951C6.01937 13.0011 5.99957 12.8001 5.92388 12.6173C5.84819 12.4346 5.72002 12.2784 5.55557
      12.1685C5.39112 12.0587 5.19778 12 5 12C4.73478 12 4.48043 12.1054 4.29289 12.2929C4.10536 12.4804 4 12.7348 4 13ZM18 13C18
      12.8022 17.9414 12.6089 17.8315 12.4444C17.7216 12.28 17.5654 12.1518 17.3827 12.0761C17.2 12.0005 16.9989 11.9806 16.8049
      12.0192C16.6109 12.0578 16.4327 12.1531 16.2929 12.2929C16.153 12.4328 16.0578 12.6109 16.0192 12.8049C15.9806 12.9989 16.0004
      13.2 16.0761 13.3827C16.1518 13.5654 16.28 13.7216 16.4444 13.8315C16.6089 13.9414 16.8022 14 17 14C17.2652 14 17.5196 13.8947
      17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13ZM19 6.00002H15C14.7348 6.00002 14.4804 6.10538 14.2929 6.29291C14.1054 6.48045
      14 6.7348 14 7.00002C14 7.26523 14.1054 7.51959 14.2929 7.70713C14.4804 7.89466 14.7348 8.00002 15 8.00002H19C19.2652 8.00002
      19.5196 8.10538 19.7071 8.29291C19.8946 8.48045 20 8.7348 20 9.00002V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196
      17.8947 19.2652 18 19 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V9.00002C2 8.7348 2.10536
      8.48045 2.29289 8.29291C2.48043 8.10538 2.73478 8.00002 3 8.00002H7C7.26522 8.00002 7.51957 7.89466 7.70711 7.70713C7.89464
      7.51959 8 7.26523 8 7.00002C8 6.7348 7.89464 6.48045 7.70711 6.29291C7.51957 6.10538 7.26522 6.00002 7 6.00002H3C2.20435
      6.00002 1.44129 6.31609 0.87868 6.8787C0.31607 7.44131 0 8.20437 0 9.00002V17C0 17.7957 0.31607 18.5587 0.87868 19.1213C1.44129
      19.6839 2.20435 20 3 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7957 22 17V9.00002C22 8.20437 21.6839
      7.44131 21.1213 6.8787C20.5587 6.31609 19.7956 6.00002 19 6.00002Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
