<script>
  export default {
    methods: {
      showReferModal() {
        this.$emit("close");
        this.$store.dispatch(
          "ReferralAndLoyaltyProgram/setDisplayedReferModal",
          true,
        );
      },
    },
  }
</script>

<template>
  <div
    class="text-base-white p-x-12 p-y-9 radius-8
    d-flex justify-content-center align-items-center gap-10 cursor-pointer bg-primary-500"
    @click="showReferModal"
  >
    <span class="p-base-medium">{{ $t("buttons.referFriend") }}</span>
  </div>
</template>

<style scoped lang="sass">

</style>
