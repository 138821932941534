<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="22" height="22"
    viewBox="0 0 22 22" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3717 8.40622C19.6308 7.78457 18.5223 7.88184 17.9018 8.62129L16.234 10.5948V4.87203C16.234 4.39239 16.0346 3.92789
      15.6868 3.59762L13.4611 1.48336C13.1331 1.17168 12.7031 1 12.2506 1H2.75777C1.78856 1 1 1.78856 1 2.75777V19.2418C1 20.211
      1.78856 20.9996 2.75777 20.9996H14.4763C15.4455 20.9996 16.2341 20.211 16.2341 19.2418V16.0402L20.5883 10.8826C21.2128 10.1384
      21.116 9.03075 20.3717 8.40622ZM12.7185 2.39414C15.0628 4.62102 14.8963 4.45824 14.9402 4.51559H12.7185V2.39414ZM15.0622
      19.2418C15.0622 19.5649 14.7993 19.8277 14.4763 19.8277H2.75777C2.43469 19.8277 2.17184 19.5649 2.17184 19.2418V2.75777C2.17184
      2.43469 2.43469 2.17184 2.75777 2.17184H11.5466V5.10145C11.5466 5.42504 11.809 5.68739 12.1326 5.68739H15.0622V11.9815L12.5712
      14.9291C12.396 15.1384 12.2718 15.3853 12.2081 15.6506L11.6635 17.9302C11.6382 18.036 11.6429 18.1468 11.677 18.2501C11.7112
      18.3535 11.7734 18.4452 11.8568 18.5152C11.9401 18.5851 12.0413 18.6305 12.149 18.6462C12.2567 18.6618 12.3666 18.6472 12.4665
      18.6039L14.6168 17.6718C14.7784 17.6014 14.9285 17.5073 15.0622 17.3925V19.2418ZM13.6686 15.4462L14.5661 16.1993L14.3665
      16.4357C14.308 16.5053 14.2342 16.5604 14.1508 16.5967L13.0756 17.0627L13.3479 15.9229C13.3689 15.8352 13.4099 15.7534 13.4676
      15.684L13.6686 15.4462ZM15.322 15.3039L14.4249 14.5511L17.6636 10.7187L18.5588 11.4699L15.322 15.3039ZM19.6918 10.128L19.3149
      10.5745L18.42 9.82364L18.7982 9.3761C19.0048 9.12989 19.3728 9.09778 19.6184 9.30387C19.8689 9.51399 19.8965 9.88403 19.6918
      10.128Z" :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.3"
    />

    <path
      d="M9.78895 4.51562H3.92969C3.60609 4.51562 3.34375 4.77797 3.34375 5.10156C3.34375 5.42516 3.60609 5.6875 3.92969
      5.6875H9.78895C10.1125 5.6875 10.3749 5.42516 10.3749 5.10156C10.3749 4.77797 10.1125 4.51562 9.78895 4.51562ZM13.3045
      8.07024H3.92969C3.60609 8.07024 3.34375 8.33258 3.34375 8.65618C3.34375 8.97977 3.60609 9.24211 3.92969 9.24211H13.3045C13.6281
      9.24211 13.8904 8.97977 13.8904 8.65618C13.8904 8.33258 13.6281 8.07024 13.3045 8.07024ZM13.3045 11.5858H3.92969C3.60609 11.5858
      3.34375 11.8482 3.34375 12.1718C3.34375 12.4954 3.60609 12.7577 3.92969 12.7577H13.3045C13.6281 12.7577 13.8904 12.4954 13.8904
      12.1718C13.8904 11.8481 13.6281 11.5858 13.3045 11.5858ZM9.78895 15.1014H3.92969C3.60609 15.1014 3.34375 15.3637 3.34375
      15.6873C3.34375 16.0109 3.60609 16.2732 3.92969 16.2732H9.78895C10.1125 16.2732 10.3749 16.0109 10.3749 15.6873C10.3749
      15.3637 10.1125 15.1014 9.78895 15.1014Z" :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.2"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
