export default {
  placeholders: {
    searchAdset: "بحث عن مجموعه",
    startTyping: "إبدأ الكتابة",
    selectDate: "Select date",
    descriptionEn: "وصف الحملة باللغة الانجليزيه",
    descriptionAr: "وصف الحملة باللغة العربيه",
    doAndDontEn: "قواعد وشروط باللغة الانجليزيه",
    doAndDontAr: "قواعد وشروط باللغة العربيه",
    websiteURLEn: "رابط الموقع باللغة الإنجليزية",
    websiteURLAr: "رابط الموقع باللغة العربية",
    creativesURLEn: "رابط الاعلانات باللغة الإنجليزية",
    creativesURLAr: "رابط الاعلانات باللغة العربية",
    doEn: "Do English",
    doAr: "Do Arabic",
    dontEn: "Don`t English",
    dontAr: "Don`t Arabic",
    editOrAddNewNotes: "قم بالتعديل أو أضف ملاحظات جديدة",
    validationComment: "Validation comment",
    comment: "Comment",
    howYouWillPromote: "How you will promote",
    rejectionReason: "Reason of rejection",
    promotionDescriptionEn: "وصف العروض باللغة الإنجليزية",
    promotionDescriptionAr: "وصف العروض باللغة العربيه",
    countryOfResidence: "Country of residence",
    addReasonOfRevokement: "add reason for revokement",
    nationality: "Nationality",
    phoneNumber: "Phone number",
    verificationCode: "",
    whatsapp: "WhatsApp number",
    instagram: "حساب إنستغرام",
    twitter: "حساب تويتر",
    facebook: "حساب فيسبوك",
    snapchat: "حساب سناب شات",
    tiktok: "حساب تيك توك",
    telegram: "حساب تيليجرام",
    youtube: "حساب يوتيوب",
    commentsInput: "أكتب تعليق",
    bankTransfer: "التحويل المصرفي",
    city: "المدينة",
    branch: "الفرع",
    iban: "ايبان",
    swift: "رمز السويفت",
    paypal: "بايبال",
    mobileCash: "موبايل كاش",
    accountHolderName: "اسم صاحب الحساب",
    bankName: "اسم البنك",
    mobileCashProvider: "موبايل كاش",
    mobileCashId: "موبايل كاش ID",
    email: "البريد الاليكتروني",

    rateDetails: "ذكر تفاصيل الأسعار الخاصة بك",
    reference: "المرجع",
    agentName: "إسم الوكيل",
    agentContact: "بيانات اتصال الوكيل",
    notes: "الملاحظات",

    adress: "Adress",
    companyName: "اسم الشركة",
    websiteLink: "رابط الموقع",
    skypeAccount: "حساب سكايب",

    arabyadsReference: "Arabyads Reference",
    affiliateNetworks: "شبكات النشر",
    yearsAsAffiliate: "سنوات الخبرة كـ ناشر",
    otherAffiliateNetworks: "Other publisher networks",
    yearsOfExperience: "Years of experience",
    budgetCapLimit: "Budget Cap Limit",
    conversionCapLimit: "Conversion Cap Limit",
    firstName: "الإسم الأول",
    lastName: "الإسم الأخير",
    URLName: "Enter URL Name",
    address: "العنوان",
    linkedIn: "Linkedin username",
    pintrest: "Pintrest username",
    whatsApp: "WhatsApp username",
    tikTok: "Tiktok username",
    native: "Native",
    social: "Social",
    other: "Other",
    display: "Display",
    video: "Video",
    search: "Search",
    others: "Others",
    bankCity: "City",
    bankBranch: "Branch",
    bankHolderName: "Holder Name",
    affiliateYearsOfExperience: "Publisher years of experience",
    mobileApp: "Mobile App",
    pinterest: "Pinterest",
    youTube: "Youtube",
    website: "Website",
    enterTheEmailDataCollectionSource: "enter the email data collection source",
    specifyThePlatformYouUse: "Specify the platform you use",

    typeSubjectHere: "Type subject here",
    maxCharacter: "Max. 100 character",
    addContentLink: "Add content link",
    searchPartners: "بحث",
    writeDownYourValidationComment: "Write down your validation comment",

    accountNumber: "رقم الحساب",
    noDateSelected: "No date selected",

    addAdvertiserName: "Add advertiser name",
    addAdvertiserEmail: "Add advertiser email",
    typeSuspensionReason: "Type suspension reason",

    clientContactName: "Client Contact Name",
    clientContactEmail: "Client Contact Email",

    sendMessage: "Send a message",
    name: "Name",
    description: "Description",
    categoriesList: "Categories",
    spend: "Spend",
    off: "% Off",
    enterValue: "Enter Value",
    promotion: "Promotion",
    typeAccountDeletionReason: "Type account deletion reason",
    zero: "0",
    oldPassword: "Old Password",
    newPassword: "New Password",
    newPasswordConfirmation: "Confirm New Password",
    partnerCode: "Partner Code",
    sevenDays: "7 Days",
    thirtyDays: "30 Days",
    offerUrl: "Enter offer URL",
    userSource: "User Source",
    userClickId: "User Click ID",
    sub1: "Sub ID 1",
    sub2: "Sub ID 2",
    sub3: "Sub ID 3",
    sub4: "Sub ID 4",
    sub5: "Sub ID 5",
    commission: "Commission",
    capAmount: "Cap Amount",
    from: "From",
    to: "To",
    value: "Value",
    tipDescriptionEn: "وصف النصيحة بالإنجلزية",
    tipDescriptionAr: "وصف النصيحة بالعربية",
    deepLink: "Deep Link",
    advertiserSentLink: "Advertiser sent link",
    defaultDeepLinkURL: "Default Deeplink URL",
    agencyAdminName: "Agency admin name",
    agencyName: "Agency Name",
    websiteURL: "Website URL",
    lastYearRevenue: "Last year revenue USD",
    comments: "comments",
    enterBrandName: "Enter brand name",
    paymentCycle: "Payment Cycle",
    addComment: "Add comment...",
    addRedirectionLink: "Please add redirection link",
    addReason: "Add Reason...",
    addReasonOfRejection: "Add reason of rejection...",
    boostinyLink: "Boostiny Link",
    numOfDaysBetween: "Number of days (between 1 and 30)",
    payoneer: "Payoneer",
  },
}
