import Cookies from "js-cookie";
import store from "../store";

export default [
  {
    path: "/dashboard",
    name: "overview",
    component: () => import(/* webpackChunkName: "Overview" */ "../views/Overview.vue"),
    meta: {
      requireAuth: true,
      title: "overview",
      entityName: "overview",
    },
  },

  {
    path: "/partners-search",
    name: "partners-search",
    component: () => import(/* webpackChunkName: "PartnersSearch" */ "../views/partner-search/Index.vue"),
    meta: {
      requireAuth: true,
      title: "partnersSearch",
      entityName: "partners-search",
    },
  },
  {
    path: "/logs",
    name: "activity-logs",
    component: () => import(/* webpackChunkName: "ActivityLogs" */ "../views/ActivityLogs.vue"),
    meta: {
      requireAuth: true,
      title: "activityLogs",
      filtersScheme: {
        actions: [],
        users: [],
        dateRange: {
          from: "",
          to: "",
        },
      },
      entityName: "activity-logs",
    },
  },

  {
    path: "/announcements",
    name: "announcements",
    component: () => import(/* webpackChunkName: "Announcements" */ "../views/Announcements.vue"),
    meta: {
      requireAuth: true,
      title: "announcements",
      entityName: "announcements",
      filtersScheme: {
        sortDirection: "desc",
        sortMetrics: "created_at",
        announcementsType: "all",
      },
    },
  },

  {
    path: "/success-submit",
    name: "success-submit",
    component: () => import(/* webpackChunkName: "Settings" */ "../views/SuccessSubmit.vue"),

    meta: {
      requireAuth: true,
      title: "submitSuccess",
      entityName: "submit-success",
    },
  },

  {
    path: "/chat",
    name: "chat",
    component: () => import(/* webpackChunkName: "Chat" */ "../views/Chat.vue"),
    beforeEnter: (to, from, next) => {
      const isLoginAsActivated = store.getters["Authorization/isLoginAsActivated"]
      const firebaseTokenKey = Cookies.get(process.env.VUE_APP_FIREBASE_TOKEN_KEY)

      if (isLoginAsActivated || !firebaseTokenKey) {
        next({ name: "not-found" })
      } else {
        next()
      }
    },
    meta: {
      requireAuth: true,
      entityName: "chat",
    },
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () => import(/* webpackChunkName: "TermsAndConditions" */ "../views/terms-and-conditions/Index.vue"),
    meta: {
      entityName: "terms-and-conditions",
    },
  },
  {
    path: "/coupons",
    name: "partner-coupons",
    component: () => import(/* webpackChunkName: "PartnerCoupons" */ "../views/PartnerCoupons.vue"),
    props: true,
    meta: {
      hasFilters: true,
      title: "partnerCoupons",
      entityName: "partner-coupons",
      requireAuth: true,
      filtersScheme: {
        status: "active",
        advancedFilters: [],
        sortMetrics: "coupon",
        sortDirection: "asc",
        campaigns: [],
        countries: [],
        teamMembers: [],
      },
    },
  },
  {
    path: "/links",
    name: "links",
    component: () => import(/* webpackChunkName: "Links" */ "../views/Links.vue"),
    meta: {
      hasFilters: true,
      title: "links",
      entityName: "links",
      requireAuth: true,
      filtersScheme: {
        campaigns: [],
        categories: [],
        supportDeepLink: false,
      },
    },
  },
  {
    path: "/partners/upload-verification-id/:token",
    redirect: "/upload-verification-id/:token",
  },
  {
    path: "/upload-verification-id/:token",
    name: "partner-verification-id",
    component: () => import(/* webpackChunkName: "PartnerVerificationID" */ "../views/PartnerVerificationID.vue"),
    meta: {
      title: "partnerVerificationID",
      entityName: "partner-verification-id",
    },
  },
  {
    path: "/upload-vat-document/:token",
    name: "partner-vat-document",
    component: () => import(/* webpackChunkName: "PartnerVATDocument" */ "../views/PartnerVATDocument.vue"),
    meta: {
      title: "partnerVATDocument",
      entityName: "partner-vat-document",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import(
      /* webpackChunkName: "Profile" */ "../views/Profile.vue"
    ),
    meta: {
      requireAuth: true,
      title: "Profile",
      entityName: "profile",
    },
  },
  {
    path: "/banners",
    name: "banners",
    component: () => import(
      /* webpackChunkName: "Banners" */ "../views/Banners.vue"
    ),
    meta: {
      requireAuth: true,
      title: "banners",
      entityName: "banners",
      hasFilters: true,
      filtersScheme: {
        status: "",
      },
    },
  },
  {
    path: "/url-redirect",
    name: "url-redirect",
    component: () => import(
      /* webpackChunkName: "URLRedirect" */ "../views/URLRedirect.vue"
    ),
    query: "",
  },
  // {
  //   path: "/campaigns-commissions",
  //   name: "campaigns-commissions",
  //   component: () => import(/* webpackChunkName: "PartnerCampaignsCommissions" */ "../views/PartnerCampaignsCommissions.vue"),
  //   meta: {
  //     hasFilters: true,
  //     requireAuth: true,
  //     title: "campaignsCommissions",
  //     filtersScheme: {
  //       campaigns: [],
  //     },
  //   },
  // },
];
