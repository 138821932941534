<script>
  export default {
    props: {
      isInDropdown: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  };
</script>

<template>
  <div
    class="actions m-r-30 d-flex flex-column"
    :class="{'is-in-dropdown m-t-15': isInDropdown}"
  >
    <slot />
  </div>
</template>

<style lang="sass" scoped>
.actions
    transform: translateX(0)
    @media (max-width: 767px)
        flex-direction: row !important
        transform: translateX(82px)

        [dir=rtl] &
            transform: translateX(-82px)

    &.is-in-dropdown
        flex-direction: row !important
        justify-content: flex-end
        align-items: flex-start
        align-self: flex-end
        transform: translateX(0)
        margin-right: 0 !important
        margin-left: 0 !important

</style>
