<script>
  import formatDate from "@/utils/date/format";
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      partnerCounts() {
        return this.notification.content.partnerCounts;
      },
      bu() {
        return this.notification.content.bu.name;
      },
      cycle() {
        return this.notification.content.cycle;
      },
    },

    methods: {
      formatCycle() {
        return formatDate({ date: this.cycle, options: { month: "long" } });
      },
    },
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div>
      <p
        class="cursor-pointer p-base-medium text-neutral-400 m-b-9"
        @click="setNotificationAsRead"
      >
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"partner-payment"}`)
        }}</span>
        <span class="text-neutral-900 m-r-3">{{ partnerCounts }}</span>
        <span class="m-r-3">{{ bu }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"for-the-month"}`)
        }}</span>
        <span class="m-r-3 text-neutral-900">{{ formatCycle() }}</span>
        <span>{{ $t(`notifications.${notification.type}.${"ready"}`) }}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
