<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="22" height="23"
    viewBox="0 0 22 23" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.75 22H0.75C0.616142 22 0.5 21.8839 0.5 21.75C0.5 21.6161 0.616142 21.5 0.75 21.5H20.75C20.8839 21.5 21 21.6161 21
        21.75C21 21.8839 20.8839 22 20.75 22Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
    />
    <path
      d="M19.75 22.5H1.75C1.34 22.5 1 22.16 1 21.75V5.75C1 2.73 2.73 1 5.75 1H15.75C18.77 1 20.5 2.73 20.5 5.75V21.75C20.5 22.16
        20.16 22.5 19.75 22.5ZM2.5 21H19V5.75C19 3.53 17.97 2.5 15.75 2.5H5.75C3.53 2.5 2.5 3.53 2.5 5.75V21Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.5"
    />
    <path
      d="M8.75 17H5.75C5.34 17 5 16.66 5 16.25C5 15.84 5.34 15.5 5.75 15.5H8.75C9.16 15.5 9.5 15.84 9.5 16.25C9.5 16.66 9.16 17 8.75 17Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.5"
    />
    <path
      d="M15.75 17H12.75C12.34 17 12 16.66 12 16.25C12 15.84 12.34 15.5 12.75 15.5H15.75C16.16 15.5 16.5 15.84 16.5 16.25C16.5 16.66
      16.16 17 15.75 17Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.5"
    />
    <path
      d="M8.75 12.5H5.75C5.34 12.5 5 12.16 5 11.75C5 11.34 5.34 11 5.75 11H8.75C9.16 11 9.5 11.34 9.5 11.75C9.5 12.16 9.16 12.5 8.75 12.5Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.5"
    />
    <path
      d="M15.75 12.5H12.75C12.34 12.5 12 12.16 12 11.75C12 11.34 12.34 11 12.75 11H15.75C16.16 11 16.5 11.34 16.5 11.75C16.5 12.16 16.16
      12.5 15.75 12.5Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.5"
    />
    <path
      d="M8.75 8H5.75C5.34 8 5 7.66 5 7.25C5 6.84 5.34 6.5 5.75 6.5H8.75C9.16 6.5 9.5 6.84 9.5 7.25C9.5 7.66 9.16 8 8.75 8Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.5"
    />
    <path
      d="M15.75 8H12.75C12.34 8 12 7.66 12 7.25C12 6.84 12.34 6.5 12.75 6.5H15.75C16.16 6.5 16.5 6.84 16.5 7.25C16.5 7.66 16.16 8 15.75 8Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'" stroke-width="0.5"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
