export default {
  filters: {
    top: "Top",
    topPerforming: "Top performing",
    lessPerforming: "Less performing",
    less: "Bottom",
    revenues: "Revenues",
    salesAmount: "Sales Amount",
    totalCost: "Total Cost",
    showing: "Showing",
    conversions: "Conversions",
    salesAmountUsd: "Sales Amount USD",
    profit: "Profit",
    margin: "Margin",
    revenue: "Revenues",
    cairo: "Cairo",
    amman: "Amman",
    dubai: "Dubai",
    cost: "Cost",

    publisherLevel: "Publisher Level",

    nano: "Nano",
    micro: "Micro",
    macro: "Macro",
    mega: "Mega",

    assetOwners: "Asset Owners",
    socialAssets: "Social Assets",
    loyaltyPrograms: "Boost & Earn",
    organicActivities: "Organic Activities",
    mediaBuying: "Media Buying",

    network: "Network",
    individual: "Individual",

    fixed: "Fixed",
    revshare: "RevShare",
    "fixed&revshare": "Fixed&RevShare",

    yes: "Yes",
    no: "No",
    managementUnit: "Partner Management Unit",
    topCampaigns: "Top Campaigns",
    recommendedCampaigns: "Recommended Campaigns",
    newCampaigns: "New Campaigns",
    myCampaigns: "My Campaigns",
    iconnect: "iConnect",
    iConnect: "iConnect",
    sea: "SEA",
  },
}
