import API from "@/utils/input-output/api/index"
import constants from "./constants"

export default {
  namespaced: true,
  state: {
    hasExplored: false,
    partnerPoints: {},
    steps: [],
    displayedReferModal: false,
  },

  mutations: {
    SET_HAS_EXPLORED: (state, value) => {
      state.hasExplored = value
    },
    SET_PARTNER_POINTS: (state, value) => {
      state.partnerPoints = value
    },
    SET_STEPS: (state, value) => {
      state.steps = value
    },
    SET_DISPLAYED_REFER_MODAL: (state) => {
      state.displayedReferModal = !state.displayedReferModal
    },
  },

  actions: {
    setHasExplored({ commit }, val) {
      commit("SET_HAS_EXPLORED", val)
    },

    addVisit() {
      API({
        url: constants.loyaltyProgramVisits(),
        method: "POST",
        requestId: "addVisit",
      })
    },

    checkLastVisit({ dispatch }) {
      API({
        url: constants.loyaltyProgramVisits(),
        method: "get",
        requestId: "checkLastVisit",
      }).then((res) => {
        const { data } = res.data.payload
        if (data.lastVisitedAt) {
          dispatch("setHasExplored", true)
        } else {
          dispatch("setHasExplored", false)
        }
      })
    },

    partnerPoints({ commit }) {
      API({
        url: constants.partnerPoints(),
        method: "get",
        requestId: "partnerPoints",
      }).then((res) => {
        const { data } = res.data.payload
        commit("SET_PARTNER_POINTS", data)
      })
    },

    rewards({ commit }) {
      API({
        url: constants.rewards(),
        method: "get",
        params: { limit: 100 },
        requestId: "partnerRewards",
      }).then((res) => {
        const { data } = res.data.payload
        commit("SET_STEPS", data)
      })
    },
    setDisplayedReferModal({ commit }, value) {
      commit("SET_DISPLAYED_REFER_MODAL", value)
    },
  },
}
