<script>
  import snakeToCamelCase from "@/utils/string/snake-to-camel-case/index";
  import mixin from "../mixin";

  export default {
    components: {},

    mixins: [mixin],

    data() {
      return {};
    },

    computed: {
      partner() {
        return this.notification.content.partner;
      },

      approvedFields() {
        return this.notification.content.approvedFields;
      },

      rejectedFields() {
        return this.notification.content.rejectedFields;
      },
    },

    methods: {
      getRequestUpdatesActions() {
        let requestUpdatesActions = "";
        if (this.rejectedFields?.length) {
          requestUpdatesActions = this.rejectedFields
            .map((item) => this.$t(`labels.${snakeToCamelCase(item)}`))
            .join(", ");
        } else if (this.approvedFields) {
          requestUpdatesActions = this.approvedFields
            .map((item) => this.$t(`labels.${snakeToCamelCase(item)}`))
            .join(", ");
        }
        return requestUpdatesActions;
      },
    },
  };
</script>
<template>
  <div class="d-flex align-items-center">
    <div>
      <p
        class="m-b-9 p-base-medium text-neutral-400 m-b-9 cursor-pointer"
        @click="viewPartnerDetails"
      >
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"arabyads-team-has"}`)
        }}</span>
        <span class="m-r-3">{{
          rejectedFields.length ? "rejected" : "accepted"
        }}</span>
        <span class="m-r-3">{{
          $t(`notifications.${notification.type}.${"request-edits"}`)
        }}</span>
        <span class="text-neutral-900">{{ getRequestUpdatesActions() }}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='sass'></style>
