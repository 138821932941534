<script>
  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
    },
    data() {
      return {};
    },
    computed: {},
    methods: {},
  };
</script>
<template>
  <p v-if="type === 'Announcement'" class="p-base-bold text-secondary-500">
    {{ $t("labels.announcement") }}
  </p>
  <p v-else class="p-base-bold text-secondary-500">
    {{ $t("labels.system") }}
  </p>
</template>
