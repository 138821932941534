export default {
  requestChangeAction: ({ partnerId }) => `partners/${partnerId}/update-requests`,
  partnerDetails: ({
    partnerId,
    isPartner,
  }) => {
    if (isPartner) return "users/profile"
    return `partners/${partnerId}`
  },
  agreementESignStatus: ({ partnerId }) => `partner-profile/${partnerId}/agreement/status`,
  loginAsPartner: ({ id }) => `partners/${id}/loginAs`,
}
