<script>
  import ModelHeader from "./Header.vue";

  export default {
    components: {
      ModelHeader,
    },

    data() {
      return {
        inputValue: "",
        inputError: "",
        disableBtn: false,
      };
    },

    computed: {},

    watch: {},

    methods: {
      cancel() {
        this.$emit("cancel");
      },
    },
  };
</script>

<template>
  <div
    class="
      w-100
      d-flex
      align-items-center
      justify-content-center
      modal-container
    "
  >
    <div
      class="
        bg-base-white
        shadow-1
        radius-8
        p-t-12 p-b-24
        d-flex
        flex-column
        justify-content-between
        modal
      "
    >
      <ModelHeader
        :title="'this is title'"
        class="modal-header"
        @cancel="cancel"
      />

      <div class="bg-neutral-100 modal-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped lang='sass'>
.modal-container
    width: 100vw !important
    height: 100vh
    position: fixed
    top: 0
    bottom: 0
    right: 0
    left: 0
    z-index: 10
    background: var(--base-white-05)
    margin: auto

    .modal-header
        height: 80px
.modal
    width: 100%
    .modal-body
        min-height: calc( 100vh - 80px )
</style>
