import axios from "axios";
import Cookies from "js-cookie";
import trackResponse from "@/utils/miscellaneous/track-response/index";
import router from "../../../router";
import store from "../../../store";
import { generateInitiator, getRequestId, initiatorWhitelisted } from "./config"

const qs = require("qs");

const cancelTokens = {}
axios.interceptors.request.use(
  (params) => {
    const pendingRequests = [
      ...store.state.Loader.pendingRequests,
      {
        id: params.requestId,
        cancel: cancelTokens[params.requestId].cancel,
        initiator: params.initiator,
        cancelWhenLeaveInitiator: params.cancelWhenLeaveInitiator,
        blockScreen: params.blockScreen,
      },
    ]
    store.dispatch("Loader/setPendingRequests", pendingRequests, { root: true })

    if (params.blockScreen) {
      store.dispatch("Loader/setIsLoading", true, { root: true })
    }
    return params
  },

  (errors) => {
    const pendingRequests = store.state.Loader.pendingRequests.filter((request) => request.id !== errors.response.config.requestId)
    store.dispatch("Loader/setPendingRequests", pendingRequests, { root: true })
  },
);

axios.interceptors.response.use(
  (params) => {
    const pendingRequests = store.state.Loader.pendingRequests.filter((request) => request.id !== params.config.requestId)
    if (pendingRequests.filter((req) => req.blockScreen).length === 0) {
      store.dispatch("Loader/setIsLoading", false, { root: true })
    }
    delete cancelTokens[params.config.requestId]

    store.dispatch("Loader/setPendingRequests", pendingRequests, { root: true })

    return Promise.resolve(params);
  },

  (errors) => {
    store.dispatch("ResponseHandling/resetErrors", null, { root: true })
    const { pendingRequests } = store.state.Loader

    // to handle in case the request is canceled from the client side
    if (errors.message?.requestId) {
      const mutatedPendingRequests = pendingRequests.filter((request) => request.id !== errors.message?.requestId)
      store.dispatch("Loader/setPendingRequests", mutatedPendingRequests, { root: true })
    }

    if (errors.response && errors.response.status) {
      const mutatedPendingRequests = pendingRequests.filter((request) => request.id !== errors.response.config.requestId)
      store.dispatch("Loader/setPendingRequests", mutatedPendingRequests, { root: true })

      store.dispatch("ResponseHandling/handleErrors",
        {
          statusCode: errors.response.status,
          errors: errors.response.data.payload.errors,
          // To display trackResponse when handling the errors
          showGlobalAlert: errors.response.config.showGlobalAlert,
          disableDefaultErrorHandling: errors.response.config.disableDefaultErrorHandling,
        }, { root: true })
      /**
       * in case of 401 we need to cancel any running requests
       * the loading state gets reset via reloading the browser window
       */

      if (errors.response.status === 401) {
        Object.keys(cancelTokens)
          .forEach((el) => {
            cancelTokens[el].cancel({
              requestId: el,
              isClientError: true,
            })
          })
      }
    }

    const numOfBlockedScreenRequests = store.state.Loader.pendingRequests.filter((req) => req.blockScreen).length
    if (numOfBlockedScreenRequests === 0 && store.state.Loader.isLoading) {
      store.dispatch("Loader/setIsLoading", false, { root: true })
    }

    const error = {
      ...errors,
      isClientError: false,
    }
    return Promise.reject(error);
  },
)

function API({
  url,
  data,
  method = "get",
  params,
  requestId,
  isURLFull = false,
  requiresAuth = true,
  token,
  showGlobalAlert = true,
  cancelAllPendingRequest = false,
  version = 2,
  blockScreen = false,
  disableDefaultErrorHandling = false,
  initiator = "",
  cancelWhenLeaveInitiator,
}) {
  /**
   * requestId is required,
   * if we don't add requestId to each api the api will be canceled.
   * we avoid adding '/' symbol because this symbol we already add when generating requestId to define the route name
   * ex: overview/getChartData => routeName/requestId (for readability wise)
   */
  if (!requestId || requestId.includes("/")) {
    if (process.env.NODE_ENV === "development") {
      trackResponse({
        type: "error",
        msg: `Please add the request Id to this ( ${url} ) without any ( / ) symbol`,
      });
    }
    return false
  }
  /**
   * In most cases, we send the dynamic part of the URL through the router, and suddenly the value gets undefined, null and so on...,
   * test case: we send apiRouteKey, if the user quickly presses the back button the value will be undefined.
   */
  const isNotValidUrl = url.split("/")
    .some((el) => ["undefined", "null", "nan"].includes(el.toLowerCase()))
  if (isNotValidUrl) {
    if (process.env.NODE_ENV === "development") {
      trackResponse({
        type: "error",
        msg: `The ${url} request has an unexpected url`,
      });
    }
    return false
  }

  axios.defaults.initiator = generateInitiator({ initiator })
  // To make the request id unique to avoid canceling the same request from another route
  axios.defaults.requestId = getRequestId({
    initiator: axios.defaults.initiator,
    id: requestId,
  })

  /**
   * we need to abort in case the logout request repeated,
   * because it cannot be cancelled and at the same time it cannot be repeated
   */
  if (axios.defaults.requestId === "global/logout" && cancelTokens["global/logout"]) {
    return false
  }

  /**
   * some requests require canceling any other pending requests e.g. login as
   */
  if (cancelAllPendingRequest) {
    Object.keys(cancelTokens)
      .forEach((el) => {
        if (el && (cancelTokens[el] && el !== axios.defaults.requestId)) {
          cancelTokens[el].cancel({
            requestId: el,
            isClientError: true,
          })
        }
      })
  }

  /**
   * if the requestId already exists, cancel it before using the new one
   */
  if (cancelTokens[axios.defaults.requestId]) {
    cancelTokens[axios.defaults.requestId].cancel({
      requestId: axios.defaults.requestId,
      isClientError: true,
    })
  }

  const { CancelToken } = axios
  cancelTokens[axios.defaults.requestId] = CancelToken.source()

  if (cancelWhenLeaveInitiator === undefined) {
    axios.defaults.cancelWhenLeaveInitiator = !initiatorWhitelisted.includes(axios.defaults.initiator)
  } else {
    axios.defaults.cancelWhenLeaveInitiator = cancelWhenLeaveInitiator
  }

  axios.defaults.blockScreen = blockScreen
  axios.defaults.showGlobalAlert = showGlobalAlert
  axios.defaults.disableDefaultErrorHandling = disableDefaultErrorHandling

  const requestParams = params
  const authToken = token || Cookies.get(process.env.VUE_APP_TOKEN_KEY);

  const headers = {
    Authorization: authToken ? `Bearer ${authToken}` : "",
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-TOKEN": document.querySelector("meta[name=\"csrf-token\"]")
      ?.getAttribute("content"),
    version,
    source: "boostiny-web",
    clientRoute: router.currentRoute.fullPath,
    bypass_maintenance: Cookies.get("qcpass") || null,
    ...(!requiresAuth && { system: process.env.VUE_APP_NAME }),
  };

  if (params && params.file) {
    headers["Content-Type"] = "multipart/form-data";
  }

  if (params && (method.toLowerCase() === "get")) {
    Object.keys(requestParams)
      .forEach((key) => (requestParams[key] === "" && key !== "q" ? delete requestParams[key] : null))
  }
  return axios({
    method,
    url: isURLFull ? url : `${process.env.VUE_APP_API_URL}v${version}/${url}`,
    data,
    params: requestParams,
    paramsSerializer: (parameters) => qs.stringify(parameters, { encode: true }),
    cancelToken: cancelTokens[axios.defaults.requestId].token,
    headers,
  });
}

export default API;
