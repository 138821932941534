export default {
  titles: {
    summary: "Summary",
    businessUnit: "Business Unit",
    analyticsOverview: "نظرة عامة على التحليلات",
    campaignRanking: "ترتيب الحملات",
    partnerRanking: "ترتيب الشركاء",
    couponsRanking: "ترتيب الكوبونات",
    signIn: "تسجيل دخول",
    welcomeToBoostiny: "مرحبا بك في Boostiny",
    welcomeToRevGate: "مرحبا بك في RevGate",
    passwordRecovery: "استرداد الحساب",
    campaigns: "الحملات",
    allocationDetails: "Allocation Details",
    selectedCoupons: "الكوبونات المختاره",
    partnerVerificationID: "Partner Verification ID",
    moreConversions: "More conversions, more points, and more prizes",
    uploadBanners: "Upload Banners",
    validationDays: "Validation Days",
    deleteCap: "Delete Cap",
    discardChanges: "Discard Changes",
    uploadProofOfPayment: "Upload Proof Of Payment",
    termsConditions: "Terms and conditions",
    applicationRejection: "Application Rejection",
    validationComment: "Validation Comment",
    assignPaymentLimit: "Assign Payment Limit",
    approvalConfirmation: "Approval Confirmation",
    approvalPartner: "تأكيد الموافقة",
    banners: "Banners",
    bannerDetails: "Banner Details",
    type: "Type",
    revokeAccess: "Revoke Access",
    stopBanner: "Stop Banner",
    removeBanner: "Remove Banner",
    deleteAnnouncement: "Delete Announcement",
    deleteAllocation: "Delete Allocation",
    deletePromotion: "Delete Promotion",
    archiveCoupon: "Archive Coupon",
    deleteComment: "Delete Comment",
    closePaymentCycle: "إغلاق دورة الدفع",
    description: "وصف الحملة",
    deleteURL: "Delete URL",
    promotion: "العروض",
    revokeApi: "Revoke API",
    links: "Links",
    bulkUploadLog: "Bulk Upload Log",
    undoFileUpload: "Undo File Upload",
    campaignSpendAndBudget: "Campaigns Spend & Budget",
    countries: "Countries",
    currency: "Currency",
    campaignBudget: "Campaign Budget",
    capConfiguration: "Cap Configuration",
    editCap: "Edit Cap",
    capLog: "Cap Log",
    promotions: "العروض",
    doAndDont: "قواعد وشروط",
    idSentSuccessfully: "تم الارسال بنجاح",
    do: "Do",
    dont: "Don`t",
    creativesURL: "رابط الاعلانات",
    campaignDetails: "بيانات الحمله",
    campaignStatus: "حالة الحمله",
    partners: "الشركاء",
    partnersSearch: "الشركاء",
    coupons: "الكوبونات",
    duration: "المده",
    campaignTeam: "المستخدمين",
    commission: "عمولة",
    commissionDetails: "تفاصيل العمولة",
    acceptanceAndCancellationRatio: "Acceptance / Cancellation ratio",
    extraAndDeduction: "Extras and Deductions",
    nationalIDOrPassport: '<p class="p-base-medium text-neutral-400">National ID Or Passport</p>',
    lastPayment: " اخر عملية دفع",
    lastValidation: "Latest Validation",
    bulkUploadLogs: "Bulk Upload Logs",
    extrasAndDeductions: "Extras and Deductions",
    arabyadsEntities: "Arabyads Entities",
    category: " الفئه",
    automation: "Automation",
    reports: "التقارير",
    reportCreator: "انشاء التقارير",
    filters: "Filters",
    reportTotals: "Report Totals",
    bonusAndFines: "المكافأة والغرامات",
    deleteDeduction: "Delete Deduction",
    deleteExtra: "Delete Extra",
    deleteCredit: "Delete Credit",
    deleteDebit: "Delete Debit",
    couponsAllocation: "Coupons Allocation",
    uploadCoupons: "Upload Coupons",
    editCampaign: "Edit Campaign",
    couponDetails: "Coupon Details",
    campaignURLs: "الروابط",
    campaignDoAndDont: "قواعد وشروط",
    campaignDescription: "وصف الحملة",
    campaignPromotionDescription: "العروض",
    campaignInfo: "المعلومات",
    couponHistory: "Coupon History",
    register: "تسجيل",
    registerAs: "التسجيل ك",
    teamManagement: "المستخدمين",
    validations: "التحقيق",
    delete: "مسح {record}",
    partnerData: "Partner Data",
    revenue: "ربح",
    userProfile: "صفحة المستخدم",
    uploadVerificationID: "Please upload your Verification ID",

    websiteURL: "رابط الموقع",
    forgotPassword: "هل نسيت كلمة السر",
    resetPassword: "Reset Password",
    influencerRegister: "التسجيل ك مؤثر",
    publisherRegister: "التسجيل ك ناشر",
    AAlabsRegister: "AA Labs Publisher Registration",
    partnerDetails: "تفاصيل الشريك",
    reportPerformance: "تقرير الأداء",
    reportFinance: "Financial Summary",
    reportCost: "Cost Summary",
    reportCampaigns: "حملات الأداء",
    partnerAnalysis: "تحليل الشركاء",
    partnerPayment: "المدفوعات",
    overview: "الملخص",
    campaignValidation: "Campaign Validation",
    partnersValidation: "Partners Validation",
    paymentUpdateValidation: "Payment Update",
    basicInfo: "معلومات أساسية",
    socialMediaInfo: "معلومات وسائل التواصل الإجتماعي",
    payementDetails: "بيانات الدفع",
    preferences: "التفضيلات",
    preferencesInfo: "التفضيلات",
    influencerRegistration: "تسجيل المؤثرين",

    website: "موقع إلكتروني",
    mobileApp: "تطبيق الهاتف المحمول",
    social: "شبكات التواصل الاجتماعي",
    mediaBuying: "شراء إعلانات",
    loyalProgram: "Loyal program",
    trafficSources: "مصادر المستخدمين",
    paymentInfo: "بيانات الدفع ",
    email: "بريد إلكتروني",
    alreadyMember: "لديك حساب؟",
    notMember: "لست عضواً؟",
    campaignReadyPartners: "الشركاء",
    registeredPartners: "الشركاء",
    ExtrasAndDeductions: "المكافأت والخصومات",
    notifications: "الإشعارات",
    newAnnouncement: "New Announcement",
    editAnnouncement: "Edit Announcement",

    // notifications
    allNotifications: "كل الإشعارات",
    couponRequests: "طلبات الكوبونات",

    notificationSettings: "إعدادات الإخطارات",
    versionHistoryLog: "تاريخ النسخ",
    BoostinyWebVersion: `نسخة ويب لبوستيني<span class="text-neutral-900 m-x-3">{version}</span> في <span class="text-neutral-900">{date}</span>
       مع <span class="text-neutral-900">{time}</span>`,
    RevGateWebVersion: `نسخة ويب لريفجيت<span class="text-neutral-900 m-x-3">{version}</span> في <span class="text-neutral-900">{date}</span>
       مع <span class="text-neutral-900">{time}</span>`,

    unallocatedCoupons: "Unallocated Coupons",
    features: "الميزات",
    enhancements: "التحسينات",
    bugFixes: "اصلاحات الشوائب",
    latestVersion: "أخر نسخة",

    systemDown: "system down",
    notFound: "not found",
    homePage: "home page",
    notificationsList: "notifications list",
    submitSuccess: "submit success",
    campaignSummary: "campaign summary",
    campaignReport: "campaign report",
    campaignApplications: "campaign applications",
    campaignPartners: "partners",
    campaignTeamAccess: "team access",
    campaignCoupons: "campaign coupons",
    campaignRevenues: "campaign revenues",
    campaignPayouts: "campaign payouts",
    campaignExtrasAndDeduction: "campaign extras and deduction",
    campaignCouponsAllocation: "campaign coupons allocation",
    campaignCouponsRequestsAllocation: "campaign coupons requests allocation",
    campaignCouponDetails: "campaign coupon details",
    campaignEdit: "campaign edit",
    addRevenue: "Add Revenue",
    addPayout: "Add Payout",
    myCampaigns: "حملاتي",
    myCoupons: "الكوبونات آلخاصة بي",
    allTimePerformanceStats: "كل احصائيات الأداء",

    earnings: "الأرباح",
    announcements: "الإعلانات",
    todaysUpdates: "تحديثات اليوم",
    paymentCyclesValidation: "Payment Cycles",
    trafficSourcesRanking: "Traffic Sources Ranking",
    campaignCreate: "Create new campaign",

    profile: "الملف الشخصي",

    currentAdset: "مجموعة الإعلانات الحاليه",
    selectAdset: "حدد مجموعة الإعلانات من القائمة",
    createAdset: "إنشاء مجموعة الإعلانات",
    changeAdset: "تغير مجموعة الإعلانات",
    bulkAdset: "Bulk Adset",

    extraAndDeductionRevenue: "Extra And Deduction Revenue",
    extraAndDeductionPayout: "Extra And Deduction Payout",
    invitation: "Invitation",

    newAdvertiser: "New Advertiser",
    editAdvertiser: "Edit Advertiser",
    advertisers: "Advertisers",
    loginToBoostiny: "Login to Boostiny",
    couponsRequestsHistory: "Coupons Requests History",

    // dashboard
    campaignsStatus: "Campaigns",
    partnersStatus: "Partners",
    couponsStatus: "Coupons",
    hasSomeProfileUpdates: "Has some profile updates",
    statusesLogsTitle: "Log of Campaign statuses",
    clientContactInfo: "Client contact Info",
    suspension: "Suspension",
    generateApikey: "Generate API key",
    messages: "Messages",
    updateCountry: "Update Country",
    changeCountry: "Change Country",
    confirmation: "Confirmation",
    selectCountry: "Select Country",

    newChat: "New chat",
    users: "Users",
    usersWorkingOnCampaign: "Users Working on a campaign",
    uploadFile: "Upload File",

    deleteMyAccount: "مسح حسابي",
    identityVerification: "Identity Verification",
    deleteRequest: "Delete Request",
    cancelDeleteRequestModalTitle: "Cancel Account Deletion Confirmation",
    activityLogs: "Activity Logs",
    newAccess: "New access",
    publishersPerformance: "Publishers Performance",
    redeemHistory: "Redeem History",
    redeemPoints: "Redeemed Points",
    topPartners: "Top Partners last month",
    howItWorks: "How it works",
    campaignType: "Campaign Type",
    attribution: "Attribution",

    attributionMethod: "Attribution Method",
    offerURL: "Offer URL",
    urlLog: "URL log",
    linkCustomization: "Link Customization",
    userSource: "User Source",
    userClickId: "User Click ID",
    subIds: "Sub IDS",
    trackingLink: "Tracking Link",
    clickAnalysis: "Click analysis",
    linkStats: "Link Stats",
    conversionLink: "Conversion Link",
    groups: "Groups",
    group: "Group",
    commissions: "العمولات",
    deleteCommission: "Delete Commission Class",
    loyaltyProgram: "Boost & Earn",
    missingRevenuePeriod: "Missing Revenue Period",
    dailyTips: "نصائح يومية",
    addNewTip: "أضف نصيحة يومية",
    editTip: "تعديل النصيحة",
    deleteTip: "مسح النصيحة",
    deepLink: "Deep Link",
    trackingLinkCustomization: "Tracking Link Customization",
    defaultDeeplink: "Default Deeplink",
    agencyRegistration: "Agency Registration",
    agencies: "Agencies",
    performanceComparison: "Performance Comparison",
    dimensions: "Dimensions",
    globalCommissionRule: "Global Commission Rule",
    globalRevenues: "Global Revenues",
    globalPayouts: "Global Payouts",
    editGlobalRevenue: "Edit Global Revenue",
    editGlobalPayout: "Edit Global Payout",
    campaignsBudget: "Campaigns Spend & Budget",
    createBrand: "Create Brand",
    updateBrand: "Update Brand",
    deleteBrand: "Delete Brand",
    APIConfiguration: "API Configuration",
    publisherPostback: "Publisher Postback",
    geoFencing: "Targeting",
    systemCurrencies: "System Currencies",
    newBrand: "New Brand",
    comments: "Comments",
    payments: "Payments",
    "delayed-and-hidden": "Approve and Delay",
    "finance-approved": "Approve as Finance",
    delayed: "Delay",
    chooseReasonOfDelay: "Choose reason of delay",
    "management-approved": "Approve as CEO",
    paid: "Paid",
    bounced: "Bounced",
    "approved-and-delayed": "Approve and Delay",
    processing: "Processing",

    uploadVATDocument: "Please upload your VAT document",
    documentUploadedSuccessfully: "Document uploaded successfully",
    invoices: "الفواتير",

    addNewGlobalPayout: "Add New Global Payout",
    deleteGlobalPayout: "Delete Global payout",
    campaignsCommissions: "Campaigns Commissions",
    saveFilters: "Save Filters",
    deleteFilters: "Delete Filter",

    systemNotifications: "System Notifications",
    bulkUpload: "Bulk Upload",
    createExtraOrDeduction: "Create Extra Or Deduction",
    editExtraOrDeduction: "Edit Extra Or Deduction",
    createDebitOrCredit: "Create Debit Or Credit",
    editDebitOrCredit: "Edit Debit Or Credit",
    validationSheetsSamples: "Validation Sheets Samples",
    deactivate: "Deactivate",

    legalEntityUpdate: "Legal entity update",
    comment: "Comment",
    approveAgency: "Approve agency",

    acceptUploadedSheet: "Accept Uploaded Validation Sheet",
    undoValidationSheet: "Undo Validation sheet",
    flagUploadedSheet: "Flag uploaded validation sheet",
    paymentCycle: "Payment Cycle",
    reasonForRejection: "Reason for rejection",
    rejectUploadedSheet: "Reject Uploaded Validation Sheet",
    reasonForFlagging: "Reason for flagging",
    validationActivityLog: "Validation activity log",
    rejectionReasonTitle: "سبب الرفض",
    companyInformation: "معلومات الشركة",
    approveAsFinance: "Approve as finance",
    approveAsManagement: "Approve as CEO",
    rejectionReason: "Reason of rejection",
    paymentStatusUpdate: "Payment Status Update",

    roleEdit: "Role Edit",
    roleDetails: "Role Details",
    team: "Team",

    requestCoupons: "طلب كوبونات",
    pendingCouponsRequestsHistory: "Pending Coupons Requests History",
    releaseNotes: "Release Notes",
    deleteUser: "Delete User",
    redeemConfirmation: "Redeem Confirmation",
    howYouWillPromote: "How You Will Promote?",
    cancellationAgreement: "Cancel Agreement",
    unarchivedCoupons: "Unarchived Coupons",
    deleteReleaseNote: "Delete Release Note",
    confirmUpload: "Confirm Upload File",
    deleteAgreement: "Delete Agreement",
    ignoreCoupons: "Ignore Coupons",
    restoreCoupons: "Restore Coupons",
    migrateUserCampaigns: "Migrate User Campaigns",
    changeReportingLine: "Change Reporting Line",
    couponInfo: "Coupon Info",
    reportingLineHistory: "Reporting Line History",
    campaignErrors: "{campaign} Errors",
    partnersValidationApproval: "Partner’s validation approval",
    partnersValidationRequestToPending: "Partner's validation request to be Pending",
    sendValidationSummariesToFinance: "Send validation summaries to Finance",
    invoiceStatusUpdates: "Partner Invoice Updates",
    socialData: "Social Data",
    win: "Win",
    emailNotifications: "Email Notifications",
    revertSuspension: "Revert Suspension",
  },
}
