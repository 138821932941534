import errors from "./errors";
import labels from "./labels";
import routesTitles from "./routesTitles";
import titles from "./titles";
import sidebar from "./sidebar"
import settingsSideBar from "./settingsSidebar"
import filters from "./filters"
import buttons from "./buttons";
import messages from "./messages";
import placeholders from "./placeholders";
import validations from "./validations";
import extrasAndDeductions from "./extrasAndDeductions";
import countries from "./countries";
import nationalities from "./nationalities";
import notifications from "./notifications";
import notificationSettings from "./notificationSettings";
import partnerDashboard from "./partner-dashboard";
import chat from "./chat";

export default {
  ...partnerDashboard,
  ...routesTitles,
  ...errors,
  ...labels,
  ...titles,
  ...sidebar,
  ...settingsSideBar,
  ...filters,
  ...buttons,
  ...messages,
  ...placeholders,
  ...validations,
  ...extrasAndDeductions,
  ...countries,
  ...nationalities,
  ...notifications,
  ...notificationSettings,
  ...chat,
};
