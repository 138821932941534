import { render, staticRenderFns } from "./Paused.vue?vue&type=template&id=5091d992&scoped=true"
import script from "./Paused.vue?vue&type=script&lang=js"
export * from "./Paused.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5091d992",
  null
  
)

export default component.exports