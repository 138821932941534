<script>
  import Chat from "../icons/Chat.vue";

  export default {
    components: { Chat },

    computed: {
      isUserLoggedInFirebase() {
        return this.$store.state.Authentication.isUserLoggedInFirebase;
      },

      loginAsToken() {
        return this.$store.getters["Authorization/isLoginAsActivated"];
      },
      isPartnerSuspended() {
        return this.$store.getters["Partners/isPartnerSuspended"];
      },
      canSeeChat() {
        return (
          this.$root.checkPermission({
            permission: this.$permissions.ACCESS_CHAT,
          })
          && this.isUserLoggedInFirebase
          && !this.loginAsToken
          && !this.isPartnerSuspended
        );
      },

      numberOfUnreadMessages() {
        return this.$store.state.Chat.usersNumOfUnreadMsgs
          .map((el) => el.unreadMsgs)
          .reduce((a, b) => a + b, 0);
      },
    },
  };
</script>

<template>
  <router-link
    v-if="canSeeChat"
    :to="{ name: 'chat' }"
    class="chat-messages base-tooltip"
    :data-tooltip="$t('labels.goToChat')"
  >
    <Chat />
    <span
      v-if="numberOfUnreadMessages"
      class="num-of-messages p-small-medium"
    >{{ numberOfUnreadMessages }}</span>
  </router-link>
</template>

<style scoped lang="sass">
.num-of-messages
  padding: 0 5px
  position: absolute
  height: 17px
  left: 9px
  bottom: 16px
  background: var(--primary-500)
  border: 1px solid var(--base-white)
  box-sizing: border-box
  border-radius: 100px
  color: var(--base-white)
</style>
