export default {
  nationalities: {
    andorran: "Andorran",
    emirati: "Emirati",
    afghan: "Afghan",
    antiguan: "Antiguan",
    anguillian: "Anguillian",
    albanian: "Albanian",
    armenian: "Armenian",
    angolan: "Angolan",
    antarctica: "Antarctica",
    argentinean: "Argentinean",
    american: "American",
    austrian: "Austrian",
    australian: "Australian",
    aruban: "Aruban",
    azerbaijani: "Azerbaijani",
    bosnian: "Bosnian",
    barbadian: "Barbadian",
    bangladeshi: "Bangladeshi",
    belgian: "Belgian",
    burkinabe: "Burkinabe",
    bulgarian: "Bulgarian",
    bahraini: "Bahraini",
    burundian: "Burundian",
    beninese: "Beninese",
    saintBarthélemy: "Saint Barthélemy",
    bermudian: "Bermudian",
    bruneian: "Bruneian",
    bolivian: "Bolivian",
    brazilian: "Brazilian",
    bahamian: "Bahamian",
    bhutanese: "Bhutanese",
    motswana: "Motswana",
    belarusian: "Belarusian",
    belizean: "Belizean",
    canadian: "Canadian",
    cocos: "Cocos",
    congolese: "Congolese",
    central: "Central",
    swiss: "Swiss",
    ivorian: "Ivorian",
    cook: "Cook",
    chilean: "Chilean",
    cameroonian: "Cameroonian",
    colombian: "Colombian",
    costa: "Costa",
    cuban: "Cuban",
    cape: "Cape",
    curacaoan: "Curacaoan",
    christmas: "Christmas",
    cypriot: "Cypriot",
    czech: "Czech",
    german: "German",
    djibouti: "Djibouti",
    danish: "Danish",
    dominican: "Dominican",
    algerian: "Algerian",
    ecuadorean: "Ecuadorean",
    estonian: "Estonian",
    egyptian: "Egyptian",
    sahrawi: "Sahrawi",
    eritrean: "Eritrean",
    spanish: "Spanish",
    ethiopian: "Ethiopian",
    finnish: "Finnish",
    fijian: "Fijian",
    falkland: "Falkland",
    micronesian: "Micronesian",
    faroese: "Faroese",
    french: "French",
    gabonese: "Gabonese",
    british: "British",
    grenadian: "Grenadian",
    georgian: "Georgian",
    ghanaian: "Ghanaian",
    gibraltar: "Gibraltar",
    greenlandic: "Greenlandic",
    gambian: "Gambian",
    guinean: "Guinean",
    equatorial: "Equatorial",
    greek: "Greek",
    guatemalan: "Guatemalan",
    guamanian: "Guamanian",
    guinea: "Guinea",
    guyanese: "Guyanese",
    honduran: "Honduran",
    croatian: "Croatian",
    haitian: "Haitian",
    hungarian: "Hungarian",
    indonesian: "Indonesian",
    irish: "Irish",
    israeli: "Israeli",
    manx: "Manx",
    indian: "Indian",
    iraqi: "Iraqi",
    iranian: "Iranian",
    icelander: "Icelander",
    channel: "Channel",
    jamaican: "Jamaican",
    jordanian: "Jordanian",
    japanese: "Japanese",
    kenyan: "Kenyan",
    kirghiz: "Kirghiz",
    cambodian: "Cambodian",
    i: "Kiribati",
    comoran: "Comoran",
    kittianAndNevis: "Kittian and Nevis",
    northKorean: "North Korean",
    southKorean: "South Korean",
    kuwaiti: "Kuwaiti",
    caymanian: "Caymanian",
    kazakhstani: "Kazakhstani",
    laotian: "Laotian",
    lebanese: "Lebanese",
    saintLucia: "Saint Lucia",
    liechtensteiner: "Liechtensteiner",
    sri: "Sri Lanka",
    liberian: "Liberian",
    mosotho: "Mosotho",
    lithuanian: "Lithuanian",
    luxembourger: "Luxembourger",
    latvian: "Latvian",
    libyan: "Libyan",
    moroccan: "Moroccan",
    monegasque: "Monegasque",
    moldovan: "Moldovan",
    montenegrin: "Montenegrin",
    saintMartin: "Saint Martin",
    malagasy: "Malagasy",
    marshallese: "Marshallese",
    macedonian: "Macedonian",
    malian: "Malian",
    myanmar: "Myanmar",
    mongolian: "Mongolian",
    chinese: "Chinese",
    mauritanian: "Mauritanian",
    montserratian: "Montserratian",
    maltese: "Maltese",
    mauritian: "Mauritian",
    maldivan: "Maldivan",
    malawian: "Malawian",
    mexican: "Mexican",
    malaysian: "Malaysian",
    mozambican: "Mozambican",
    namibian: "Namibian",
    newCaledonia: "New Caledonia",
    nigerian: "Nigerian",
    nicaraguan: "Nicaraguan",
    norwegian: "Norwegian",
    nepalese: "Nepalese",
    nauruan: "Nauruan",
    niuean: "Niuean",
    newZealand: "New Zealand",
    omani: "Omani",
    panamanian: "Panamanian",
    peruvian: "Peruvian",
    papuaNewGuinea: "Papua New Guinea",
    filipino: "Filipino",
    pakistani: "Pakistani",
    polish: "Polish",
    pitcairn: "Pitcairn",
    puerto: "Puerto",
    palestinian: "Palestinian",
    portuguese: "Portuguese",
    palauan: "Palauan",
    paraguayan: "Paraguayan",
    qatari: "Qatari",
    romanian: "Romanian",
    serbian: "Serbian",
    russian: "Russian",
    rwandan: "Rwandan",
    saudi: "Saudi",
    solomon: "Solomon",
    seychellois: "Seychellois",
    swedish: "Swedish",
    singaporean: "singaporean",
    saintHelena: "Saint Helena",
    slovene: "Slovene",
    slovak: "Slovak",
    sierra: "Sierra",
    sammarinese: "Sammarinese",
    senegalese: "Senegalese",
    somali: "Somali",
    surinamer: "Surinamer",
    sudanese: "Sudanese",
    sao: "Sao",
    salvadoran: "Salvadoran",
    dutch: "Dutch",
    syrian: "Syrian",
    swazi: "Swazi",
    turksAndCaicos: "Turks and Caicos",
    chadian: "Chadian",
    togolese: "Togolese",
    thai: "Thai",
    tadzhik: "Tadzhik",
    tokelauan: "Tokelauan",
    eastTimor: "East Timor",
    turkmen: "Turkmen",
    tunisian: "Tunisian",
    tongan: "Tongan",
    turkish: "Turkish",
    trinidadian: "Trinidadian",
    tuvaluan: "Tuvaluan",
    taiwanese: "Taiwanese",
    tanzanian: "Tanzanian",
    ukrainian: "Ukrainian",
    ugandan: "Ugandan",
    uruguayan: "Uruguayan",
    uzbekistani: "Uzbekistani",
    italian: "Italian",
    saintVincentAndTheGrenadines: "Saint Vincent and the Grenadines",
    venezuelan: "Venezuelan",
    virgin: "Virgin",
    vietnamese: "Vietnamese",
    ni: "Ni",
    wallisAndFutuna: "Wallis and Futuna",
    samoan: "Samoan",
    kosovar: "Kosovar",
    yemeni: "Yemeni",
    southAfrican: "South African",
    zambian: "Zambian",
    zimbabwean: "Zimbabwean",
  },
}
