<script>
  export default {
    props: {
      partner: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {};
    },
    methods: {},
  };
</script>

<template>
  <div class="bg-neutral-100 modal-body p-x-18 p-y-18">
    <div class="col-12 p-0">
      <p class="p-base-medium">
        <span
          class="m-r-6"
          v-html="
            $t('messages.rejectCouponRequest', { partnerName: partner.name })
          "
        />
      </p>
    </div>
  </div>
</template>

<style scoped lang='sass'>
</style>
