<script>
  import InfoIcon from "@/components/partials/icons/Info.vue";

  export default {
    components: {
      InfoIcon,
    },
    props: {
      message: {
        required: true,
        type: String,
      },
    },
  };
</script>

<template>
  <div
    class="w-100 d-flex align-items-center gap-3 p-x-12 p-y-6 radius-8 message-container bg-error-50 text-error-500"
  >
    <InfoIcon color="var(--error-500)" />
    <span class="p-small-light">{{ message }}</span>
  </div>
</template>

<style scoped lang="sass">
.message-container
  min-height: 32px
  height: auto
</style>
