export default {
  dailyTips: () => "tips",
  checkLastVisitDate: () => "visits/tip",
  fetchLatestAnnouncements: ({ isAgency }) => {
    if (isAgency) return "agency/announcements/latest"
    return "announcements/latest"
  },
  seenAnnouncements: ({ id, isAgency }) => {
    if (isAgency) return `agency/announcements/${id}/seen`
    return `announcements/${id}/seen`
  },
}
