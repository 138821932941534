import store from "@/store";

export default function trackResponse({ type, msg }, time = 3000) {
  store.dispatch("ResponseHandling/toggleShowToast")
  store.dispatch("ResponseHandling/setToastContent", {
    type, // success || error
    message: msg,
  })
  setTimeout(() => {
    store.dispatch("ResponseHandling/toggleShowToast")
  }, time)
}
