export default [
  {
    path: "revenues",
    component: () => import(/* webpackChunkName: "CampaignRevenues" */
      "@/views/campaigns/details/revenues-and-payouts/revenues/Index.vue"
    ),
    children: [
      {
        path: "",
        name: "campaign-revenues",
        component: () => import(/* webpackChunkName: "CampaignRevenuesList" */
          "@/views/campaigns/details/revenues-and-payouts/revenues/List.vue"
        ),
        meta: {
          title: "campaignRevenues",
          entityName: "campaign-revenues",
          apiKey: "revenues",
          showTabs: true,
          hasFilters: true,
          filtersScheme: {
            status: "current",
            level: "all",
            paymentType: [],
            country: "",
            sortMetrics: "start_date",
            sortDirection: "desc",
            advancedFilters: [],
            bus: [],
            dateRange: {
              from: "",
              to: "",
            },
          },

        },
      },
      {
        path: "create",
        name: "create-revenue",
        props: true,
        exact: true,
        component: () => import(/* webpackChunkName: "CreateRevenue" */
          "@/views/campaigns/details/revenues-and-payouts/revenues/Create.vue"
        ),
        meta: {
          apiKey: "revenues",
          title: "createRevenue",
          entityName: "create-revenue",
          showTabs: false,
          requireAuth: true,
        },
      },
      {
        path: ":revenueId/edit",
        name: "edit-revenue",
        props: true,
        meta: {
          apiKey: "revenues",
          title: "editRevenue",
          entityName: "edit-revenue",
          showTabs: false,
          requireAuth: true,
        },
        component:
          () => import(/* webpackChunkName: "UpdateCampaignRevenue" */
            "@/views/campaigns/details/revenues-and-payouts/revenues/Edit.vue"
          ),
      },
    ],
  },
  {
    path: "payouts",
    component:
      () => import(/* webpackChunkName: "CampaignPayouts" */
        "@/views/campaigns/details/revenues-and-payouts/payouts/Index.vue"
      ),
    children: [
      {
        path: "",
        name: "campaign-payouts",
        component:
          () => import(/* webpackChunkName: "PayoutsList" */
            "@/views/campaigns/details/revenues-and-payouts/payouts/List.vue"
          ),
        meta: {
          title: "campaignPayouts",
          entityName: "campaign-payouts",
          apiKey: "payouts",
          hasFilters: true,
          showTabs: true,
          filtersScheme: {
            status: "current",
            sortMetrics: "start_date",
            sortDirection: "desc",
            advancedFilters: [],
            paymentType: [],
            country: "",
            bus: [],
            level: "all",
            dateRange: {
              from: "",
              to: "",
            },
          },
        },
      },
      {
        path: "create",
        name: "create-payout",
        meta: {
          showTabs: false,
          apiKey: "payouts",
          title: "createPayouts",
          entityName: "create-payouts",
          requireAuth: true,
        },
        props: true,
        component:
          () => import(/* webpackChunkName: "CreatePayouts" */
            "@/views/campaigns/details/revenues-and-payouts/payouts/Create.vue"
          ),
      },
      {
        path: ":payoutId/edit",
        name: "edit-payout",
        props: true,
        meta: {
          title: "editPayout",
          entityName: "edit-payout",
          showTabs: false,
          apiKey: "payouts",
          requireAuth: true,
        },
        component:
          () => import(/* webpackChunkName: "UpdateCampaignPayouts" */
            "@/views/campaigns/details/revenues-and-payouts/payouts/Edit.vue"
          ),
      },
    ],
  },
]
