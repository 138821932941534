export default {
  campaignDetails: ({ campaignId, isAgency }) => {
    let url = `campaigns/${campaignId}`
    if (isAgency) {
      url = `agency/campaigns/${campaignId}`
    }
    return url
  },
  trackingLinks: ({ isAgency, campaignId, offerUrlId }) => {
    let url = `campaigns/${campaignId}/tracking-links/custom/${offerUrlId}`
    if (isAgency) {
      url = `agency/campaigns/${campaignId}/tracking-links/custom/${offerUrlId}`
    }
    return url
  },
  offerUrlDetails: ({ isAgency, campaignId, offerUrlId }) => {
    let url = `campaigns/${campaignId}/tracking-links/${offerUrlId}`
    if (isAgency) {
      url = `agency/campaigns/${campaignId}/tracking-links/${offerUrlId}`
    }
    return url
  },
  campaignManagers: ({ campaignId, isAgency }) => {
    if (isAgency) return `agency/campaigns/${campaignId}/campaign-managers`
    return `campaigns/${campaignId}/campaign-managers`
  },
  salesManager: ({ campaignId, isAgency }) => {
    if (isAgency) return `agency/campaigns/${campaignId}/sales-manager`
    return `campaigns/${campaignId}/sales-manager`
  },
  deactivationData: ({ campaignID }) => `campaigns/${campaignID}/deactivation-reason`,
  CGTeam: ({ campaignId }) => `campaigns/${campaignId}/client-growth-team`,
}
