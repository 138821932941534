import revenuesAndPayouts from "./revenuesAndPayouts"
import linkTracking from "./linkTracking"
import coupons from "./coupons"

export default [
  {
    path: "/campaigns",
    component: () => import(/* webpackChunkName: "Campaigns" */ "@/views/campaigns/Index.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        name: "campaigns",
        component: () => import(/* webpackChunkName: "CampaignsList" */ "@/views/campaigns/List.vue"),
        query: { application: "" },
        meta: {
          requireAuth: true,
          title: "campaigns",
          entityName: "campaigns-list",
          filtersScheme: {
            campaignModel: "all",
            status: "ACTIVE",
            application: "approved",
            advancedFilters: [],
            sortMetrics: "revenue",
            sortDirection: "asc",
            categories: [],
            countries: [],
            integration: "",
            campaignSource: "",
            salesManager: "",
            campaignStartYear: "",
            hasError: false,
          },
        },
      },
      {
        path: "create",
        name: "create-campaign",
        component: () => import(/* webpackChunkName: "CampaignCreate" */ "@/views/campaigns/Create.vue"),
        meta: {
          requireAuth: true,
          title: "campaignCreate",
          entityName: "create-campaign",
        },
        props: true,
      },
      {
        path: ":id",
        component: () => import(/* webpackChunkName: "Campaign" */ "@/views/campaigns/details/Index.vue"),
        meta: {
          requireAuth: true,
          title: "campaignDetails",
        },
        children: [
          {
            path: "",
            name: "campaign-details",
            component: () => import(/* webpackChunkName: "CampaignSummary" */ "@/views/campaigns/details/Summary.vue"),
            meta: {
              title: "campaignSummary",
              entityName: "campaign-summary",
              showTabs: true,
            },
          },
          {
            path: "edit",
            name: "edit-campaign",
            component: () => import(/* webpackChunkName: "CampaignEdit" */ "@/views/campaigns/details/Edit.vue"),
            meta: {
              title: "campaignEdit",
              entityName: "edit-campaign",
              showTabs: false,
            },
          },
          {
            path: "report",
            name: "campaign-report",
            component: () => import(/* webpackChunkName: "CampaignReport" */ "@/views/campaigns/details/Report.vue"),
            meta: {
              title: "campaignReport",
              entityName: "campaign-report",
              showTabs: true,
              apiRouteKey: "reports/performance",
              couponAPIRouteKey: "reports/performance",
              linkAPIRouteKey: "reports/link-performance",
              filtersScheme: {
                couponType: "",
                partners: [],
                teamLeaders: [],
                teamMembers: [],
                buIds: [],
                categories: [],
                coupons: [],
                dimensions: [],
                metrics: [],
                trafficSourcesIds: [],
                teams: [],
                clickIds: [],
                sortMetrics: "",
                type: "gross",
                sortDirection: "desc",
                advancedFilters: [],
              },
            },
          },
          {
            path: "applications",
            name: "campaign-applications",
            component: () => import(/* webpackChunkName: "CampaignApplications" */ "@/views/campaigns/details/Applications.vue"),
            meta: {
              hasFilters: true,
              title: "campaignApplications",
              entityName: "campaign-applications",
              showTabs: true,
              filtersScheme: {
                status: "APPROVED",
                advancedFilters: [],
                sortMetrics: "created_at",
                sortDirection: "desc",
                bus: [],
              },
            },
          },
          {
            path: "partners",
            name: "campaign-partners",
            component: () => import(/* webpackChunkName: "CampaignPartners" */ "@/views/campaigns/details/Partners.vue"),
            meta: {
              hasFilters: true,
              title: "campaignPartners",
              entityName: "campaign-partners",
              showTabs: true,
              filtersScheme: {
                advancedFilters: [],
                bus: [],
                status: "active",
                userTypes: ["partner"],
              },
            },
          },
          {
            path: "promotions",
            name: "campaign-promotions",
            component: () => import(/* webpackChunkName: "CampaignPromotions" */ "@/views/campaigns/details/Promotions.vue"),
            meta: {
              hasFilters: true,
              title: "promotions",
              entityName: "campaign-promotions",
              showTabs: true,
              filtersScheme: {
                advancedFilters: [],
                countries: [],
                offerTypes: "",
                sortMetrics: "name",
                sortDirection: "desc",
                dateRange: {
                  from: "",
                  to: "",
                },
              },
            },
          },
          {
            path: "extra-and-deduction",
            name: "campaign-extra-and-deduction",
            component: () => import(/* webpackChunkName: "CampaignExtrasAndDeductions" */ "@/views/campaigns/details/ExtrasAndDeductions.vue"),
            meta: {
              hasFilters: true,
              title: "campaignExtrasAndDeduction",
              entityName: "campaign-extras-and-deductions",
              showTabs: true,
              filtersScheme: {
                type: "all",
                cycle: "",
                advancedFilters: [],
                sortMetrics: "type",
                sortDirection: "desc",
                buIds: [],
              },
            },
          },
          /**
           * we need to have only one team route with two different scopes
           * no need to have different route each time the scope changes
           */
          {
            path: "team",
            name: "campaign-team",
            component: () => import(/* webpackChunkName: "CampaignTeam" */ "@/views/campaigns/details/Team.vue"),
            meta: {
              title: "campaignTeam",
              entityName: "campaign-team",
              hasFilters: true,
              showTabs: true,
              filtersScheme: {
                roles: [],
                advancedFilters: [],
                bus: [],
                userTypes: ["team-leader", "team-member"],
                status: "active",
              },
            },
          },
          ...coupons,
          ...revenuesAndPayouts,
          ...linkTracking,
        ],
      },
    ],
  },
]
