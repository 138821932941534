<script>
  export default {
    components: {},
    props: {},
    computed: {},

    methods: { },
  };
</script>

<template>
  <div class="w-100 p-x-24 p-t-24 p-b-60">
    <div class="system-down-container w-100 d-flex flex-column align-items-center justify-content-center">
      <img
        alt="logo"
        class="boostiny-system-down m-b-33"
        src="@/assets/images/errors/system-down.svg"
      >
      <div class="w-100 text-center">
        <p class="heading-1-bold text-neutral-700 m-b-15">
          Opps, ! We’re under scheduled maintenance
        </p>
        <p class="text-neutral-500 heading-4-light">
          We’re currently upgrading our system to make it even more
        </p>
        <p class="text-neutral-500 heading-4-light">
          useful to you. Thanks for your patience
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang='sass'>
.system-down-container
  height: calc(100vh - 200px)

.boostiny-system-down
    max-height: 300px

    @media (max-width: 992px)
        width:60%
        transform: scale(.8)

@media (max-width: 768px)
  .boostiny-system-down
    width:90%
</style>
