export default {
  fetchCountries: () => "countries",
  fetchOfferTypes: () => "promotion-offer-types/lookups/default-offer-types",
  fetchCategories: () => "categories/lookups/default",
  fetchCurrencies: () => "currencies/lookups/default",
  fetchMacros: ({ isAgency }) => {
    if (isAgency) return "agency/campaigns/lookups/campaign-offer-macros"
    return "campaigns/lookups/campaign-offer-macros"
  },
  fetchSystemBUs: () => "bus/lookups/default",
}
