export default [
  {
    path: "/advertisers",
    component: () => import(/* webpackChunkName: "Advertisers" */ "../views/advertisers/Index.vue"),
    meta: {
      requireAuth: true,
      title: "advertisers",
    },
    children: [
      {
        path: "",
        name: "advertisers-list",
        component: () => import(/* webpackChunkName: "AdvertisersList" */ "../views/advertisers/List.vue"),
        meta: {
          title: "advertisersList",
          entityName: "advertisers-list",
          filtersScheme: {
            advancedFilters: [],
            campaignModel: "",
          },
        },
      },
      {
        path: ":id",
        component: () => import(/* webpackChunkName: "AdvertiserDetails" */ "../views/advertisers/details/Index.vue"),
        name: "advertiser-details",
        redirect: {
          name: "advertiser-campaigns",
        },
        meta: {
          title: "advertisersDetails",
          entityName: "advertisers-details",
        },
        children: [
          {
            path: "campaigns",
            name: "advertiser-campaigns",
            component: () => import(/* webpackChunkName: "AdvertisersCampaigns" */ "../views/advertisers/details/Campaigns.vue"),
            meta: {
              title: "advertisersCampaigns",
              entityName: "advertisers-campaigns",
            },
          },
          {
            path: "brands",
            name: "advertiser-brands",
            component: () => import(/* webpackChunkName: "AdvertisersBrands" */ "../views/advertisers/details/Brands.vue"),
            meta: {
              title: "advertisersBrands",
              entityName: "advertisers-brands",
            },
          },
        ],
      },
    ],
  },
]
