import setPagination from "@/utils/data-manipulation/set-pagination/index";
import getRandomColor from "@/utils/miscellaneous/get-random-color"
import trackResponse from "@/utils/miscellaneous/track-response/index";
import i18n from "@/lang"
import API from "@/utils/input-output/api/index";
import constants from "./constants"

export default {
  namespaced: true,
  state: {
    details: {},
    campaigns: [],
    brands: [],
    campaignPagination: {},
    brandsPagination: {},
  },
  mutations: {
    SET_DETAILS(state, payload) {
      const pointOfContacts = payload?.pointOfContacts?.map(
        (el) => ({
          ...el,
          bgColor: getRandomColor(),
        }),
      ) || []

      state.details = {
        ...payload,
        ...(payload?.pointOfContacts && { pointOfContacts }),
      }
    },
    SET_BRANDS(state, payload) {
      state.brands = [...payload]
    },
    SET_CAMPAIGN_PAGINATION(state, payload) {
      state.campaignPagination = setPagination(payload)
    },

    SET_CAMPAIGNS(state, payload) {
      state.campaigns = payload.map((campaign) => {
        const mutated = {
          ...campaign,
          campaignName: campaign.name,
          conversions: +campaign.orders,
          lastModified: campaign.lastModified || null,
          salesManagerName: campaign.salesManager?.name,
          cost: +campaign.cost,
          salesAmountUsd: +campaign.salesAmountUsd,
          coupons: campaign.totalCoupons,
          campaignModel: campaign.campaignModel,
          clicks: campaign.clicks.toString(),
          status: campaign.status,
          avatar: campaign.SalesManager?.avatar,
          pointOfContacts: state.details.pointOfContacts.filter(
            (el) => el.campaigns.includes(campaign.id),
          ),
        };
        return mutated;
      });
    },
  },
  actions: {
    resetDetails({ commit }) {
      commit("SET_DETAILS", {})
    },
    getDetails({
      rootGetters,
      commit,
    }, { id }) {
      return new Promise((resolve) => {
        const isAgency = rootGetters["Authorization/isAgency"]

        API({
          url: constants.advertiserDetails({ advertiserId: id, isAgency }),
          method: "get",
          requestId: "AdvertiserDetails",
        })
          .then((data) => {
            commit("SET_DETAILS", data.data.payload.data)
            resolve()
          });
      })
    },

    getCampaigns({
      rootGetters,
      rootState,
      commit,
    }, {
      id,
      page = 1,
    }) {
      return new Promise((resolve, reject) => {
        const sorts = [
          {
            field: "name",
            direction: "asc",
          },
        ];
        const isAgency = rootGetters["Authorization/isAgency"]

        API({
          url: constants.advertiserCampaigns({ isAgency }),
          method: "get",
          params: {
            advertiser: id,
            ...rootState.Globals.dateRange,
            sorts,
            page,
          },
          requestId: "AdvertiserCampaignsList",
        })
          .then((data) => {
            commit("SET_CAMPAIGN_PAGINATION", data.data.payload.pagination)
            commit("SET_CAMPAIGNS", data.data.payload.data)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    createAdvertiser({ rootGetters }, { formData }) {
      return new Promise((resolve, reject) => {
        const isAgency = rootGetters["Authorization/isAgency"]

        API({
          url: constants.addAdvertiser({ isAgency }),
          method: "post",
          data: formData,
          params: {
            file: true,
          },
          requestId: "createAdvertiser",
        })
          .then((data) => {
            resolve(data)
          }).catch(() => {
            reject()
          })
      })
    },

    editAdvertiser({ rootGetters }, {
      id,
      formData,
    }) {
      return new Promise((resolve, reject) => {
        const isAgency = rootGetters["Authorization/isAgency"]
        API({
          url: constants.updateAdvertiser({ advertiserId: id, isAgency }),
          method: "post",
          data: formData,
          params: {
            file: true,
          },
          requestId: "editAdvertiser",
        })
          .then((data) => {
            resolve(data)
          }).catch(() => {
            reject()
          })
      })
    },
    updatePocs({
      dispatch,
      rootGetters,
    }, {
      id,
      isEditMode,
      ...payload
    }) {
      return new Promise((resolve, reject) => {
        const isAgency = rootGetters["Authorization/isAgency"]

        API({
          url: constants.advertiserPOCs({ advertiserId: id, isAgency }),
          method: "post",
          showGlobalAlert: false,
          requestId: "manageAdvertiserPointOfContacts",
          ...payload,
        })
          .then(() => {
            trackResponse({
              type: "success",
              msg: i18n.t(
                `messages.${isEditMode
                  ? "advertiserUpdatedSuccessfully"
                  : "advertiserCreatedSuccessfully"
                }`,
              ),
            });
            dispatch("getDetails", { id })
            dispatch("getCampaigns", { id })
            resolve()
          })
          .catch(() => {
            trackResponse({
              type: "error",
              msg: i18n.t("messages.SomeOfCreatedPOCsEmailExistsBefore"),
              time: 5000,
            });
            reject()
          });
      })
    },
    getBrands({
      commit,
      rootGetters,
    }, {
      page = 1,
      advertiserId,
    }) {
      const isAgency = rootGetters["Authorization/isAgency"]

      API({
        url: constants.getBrands({ advertiserId, isAgency }),
        method: "get",
        requestId: "getBrands",
        params: {
          page,
        },
      })
        .then((data) => {
          commit("SET_BRANDS", data.data.payload.data)
        })
    },
    createBrand({
      state,
      dispatch,
      rootGetters,
    }, { data }) {
      return new Promise((resolve, reject) => {
        const isAgency = rootGetters["Authorization/isAgency"]

        API({
          url: constants.createBrand({ isAgency }),
          method: "post",
          requestId: "createBrand",
          data: {
            ...data,
            advertiser_id: state.details.id,
          },
        })
          .then(() => {
            trackResponse({
              type: "success",
              msg: i18n.t("messages.brandCreatedSuccessfully"),
            });
            dispatch("getBrands", {
              advertiserId: state.details.id,
            })
            resolve()
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    updateBrand({
      state,
      dispatch,
      rootGetters,
    }, { data }) {
      return new Promise((resolve, reject) => {
        const isAgency = rootGetters["Authorization/isAgency"]

        API({
          url: constants.updateBrand({
            brandId: data.id,
            isAgency,
          }),
          method: "put",
          requestId: "updateBrand",
          data,
        })
          .then(() => {
            trackResponse({
              type: "success",
              msg: i18n.t("messages.brandUpdatedSuccessfully"),
            });
            dispatch("getBrands", {
              advertiserId: state.details.id,
            })
            resolve()
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    deleteBrand({
      state,
      dispatch,
      rootGetters,
    }, { id }) {
      return new Promise((resolve, reject) => {
        const isAgency = rootGetters["Authorization/isAgency"]

        API({
          url: constants.deleteBrand({
            brandId: id,
            isAgency,
          }),
          method: "delete",
          requestId: "deleteBrand",
        })
          .then(() => {
            trackResponse({
              type: "success",
              msg: i18n.t("messages.branddeletedSuccessfully"),
            });
            dispatch("getBrands", {
              advertiserId: state.details.id,
            })
            resolve()
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
  },
}
