<script>
  import SystemDown from "../../components/errors/SystemDown[503].vue"

  export default {
    components: {
      SystemDown,
    },
    props: {},
    data() {
      return {};
    },

    computed: {},

    methods: {},
  };
</script>

<template>
  <SystemDown />
</template>

<style scoped lang='sass'></style>
