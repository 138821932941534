<script>
  import types from "../shared/types/data";
  import Footer from "../shared/notification-footer/Index.vue";

  export default {
    components: { Footer },
    props: {
      content: {
        type: Object,
        required: true,
      },
    },

    computed: {
      unreadNotifications() {
        return this.$store.state.Notifications.unreadNotifications;
      },
      types() {
        return types;
      },
    },

    methods: {
      setNotificationAsRead() {
        this.$emit("set-notification-as-read");
      },
    },
  };
</script>

<template>
  <li class="p-y-18 p-x-15 notification-item-container">
    <div class="d-flex align-items-center">
      <component
        :is="types[content.type]"
        :notification="content"
        :is-dropdown-view="true"
        @on-popup-state-update="$emit('on-popup-state-update', $event)"
        @set-notification-as-read="setNotificationAsRead"
      />
      <div
        v-if="
          unreadNotifications !== 0 &&
            (content.status === 'not-seen' || content.status === 'seen')
        "
        class="unread-dot base-tooltip bg-primary-500 flex-shrink-0 radius-full"
        data-tooltip="Unread"
      />
    </div>

    <Footer
      :type="content.tag"
      :date="content.createdAt"
      :is-dropdown-view="true"
    />
  </li>
</template>

<style scoped lang="sass">
.notification-item-container
  position: relative
  transition: all 0.3s ease-in-out
  &:hover
    background-color: var(--neutral-100)
.unread-dot
  width: 7px
  height: 7px
@import "../../partials/base-timeline/timeline"
</style>
