export default {
  countries: {
    andorra: "اندورا",
    unitedArabEmirates: "United Arab Emirates",
    afghanistan: "Afghanistan",
    antiguaAndBarbuda: "Antigua and Barbuda",
    anguilla: "Anguilla",
    albania: "Albania",
    armenia: "Armenia",
    netherlandsAntilles: "Netherlands Antilles",
    angola: "Angola",
    antarctica: "Antarctica",
    argentina: "Argentina",
    americanSamoa: "American Samoa",
    austria: "Austria",
    australia: "Australia",
    aruba: "Aruba",
    azerbaijan: "Azerbaijan",
    bosniaAndHerzegovina: "Bosnia and Herzegovina",
    barbados: "Barbados",
    bangladesh: "Bangladesh",
    belgium: "Belgium",
    burkinaFaso: "Burkina Faso",
    bulgaria: "Bulgaria",
    bahrain: "Bahrain",
    burundi: "Burundi",
    benin: "Benin",
    saintBarthelemy: "Saint Barthelemy",
    bermuda: "Bermuda",
    brunei: "Brunei",
    bolivia: "Bolivia",
    brazil: "Brazil",
    bahamas: "Bahamas",
    bhutan: "Bhutan",
    botswana: "Botswana",
    belarus: "Belarus",
    belize: "Belize",
    canada: "Canada",
    cocosIslands: "Cocos Islands",
    democraticRepublicOfTheCongo: "Democratic Republic of the Congo",
    centralAfricanRepublic: "Central African Republic",
    republicOfTheCongo: "Republic of the Congo",
    switzerland: "Switzerland",
    ivoryCoast: "Ivory Coast",
    cookIslands: "Cook Islands",
    chile: "Chile",
    cameroon: "Cameroon",
    china: "China",
    colombia: "Colombia",
    costaRica: "Costa Rica",
    cuba: "Cuba",
    capeVerde: "Cape Verde",
    curacao: "Curacao",
    christmasIsland: "Christmas Island",
    cyprus: "Cyprus",
    czechRepublic: "Czech Republic",
    germany: "Germany",
    djibouti: "Djibouti",
    denmark: "Denmark",
    dominica: "Dominica",
    dominicanRepublic: "Dominican Republic",
    algeria: "Algeria",
    ecuador: "Ecuador",
    estonia: "Estonia",
    egypt: "Egypt",
    westernSahara: "Western Sahara",
    eritrea: "Eritrea",
    spain: "Spain",
    ethiopia: "Ethiopia",
    finland: "Finland",
    fiji: "Fiji",
    falklandIslands: "Falkland Islands",
    micronesia: "Micronesia",
    faroeIslands: "Faroe Islands",
    france: "France",
    gabon: "Gabon",
    unitedKingdom: "United Kingdom",
    grenada: "Grenada",
    georgia: "Georgia",
    guernsey: "Guernsey",
    ghana: "Ghana",
    gibraltar: "Gibraltar",
    greenland: "Greenland",
    gambia: "Gambia",
    guinea: "Guinea",
    equatorialGuinea: "Equatorial Guinea",
    greece: "Greece",
    guatemala: "Guatemala",
    guam: "Guam",
    "guinea-Bissau": "Guinea-Bissau",
    guyana: "Guyana",
    hongKong: "Hong Kong",
    honduras: "Honduras",
    croatia: "Croatia",
    haiti: "Haiti",
    hungary: "Hungary",
    indonesia: "Indonesia",
    ireland: "Ireland",
    israel: "Israel",
    isleOfMan: "Isle of Man",
    india: "India",
    britishIndianOceanTerritory: "British Indian Ocean Territory",
    iraq: "Iraq",
    iran: "Iran",
    iceland: "Iceland",
    italy: "Italy",
    jersey: "Jersey",
    jamaica: "Jamaica",
    jordan: "Jordan",
    japan: "Japan",
    kenya: "Kenya",
    kyrgyzstan: "Kyrgyzstan",
    cambodia: "Cambodia",
    kiribati: "Kiribati",
    comoros: "Comoros",
    saintKittsAndNevis: "Saint Kitts and Nevis",
    northKorea: "North Korea",
    southKorea: "South Korea",
    kuwait: "Kuwait",
    caymanIslands: "Cayman Islands",
    kazakhstan: "Kazakhstan",
    laos: "Laos",
    lebanon: "Lebanon",
    saintLucia: "Saint Lucia",
    liechtenstein: "Liechtenstein",
    sriLanka: "Sri Lanka",
    liberia: "Liberia",
    lesotho: "Lesotho",
    lithuania: "Lithuania",
    luxembourg: "Luxembourg",
    latvia: "Latvia",
    libya: "Libya",
    morocco: "Morocco",
    monaco: "Monaco",
    moldova: "Moldova",
    montenegro: "Montenegro",
    saintMartin: "Saint Martin",
    madagascar: "Madagascar",
    marshallIslands: "Marshall Islands",
    macedonia: "Macedonia",
    mali: "Mali",
    myanmar: "Myanmar",
    mongolia: "Mongolia",
    macau: "Macau",
    northernMarianaIslands: "Northern Mariana Islands",
    mauritania: "Mauritania",
    montserrat: "Montserrat",
    malta: "Malta",
    mauritius: "Mauritius",
    maldives: "Maldives",
    malawi: "Malawi",
    mexico: "Mexico",
    malaysia: "Malaysia",
    mozambique: "Mozambique",
    namibia: "Namibia",
    newCaledonia: "New Caledonia",
    niger: "Niger",
    nigeria: "Nigeria",
    nicaragua: "Nicaragua",
    netherlands: "Netherlands",
    norway: "Norway",
    nepal: "Nepal",
    nauru: "Nauru",
    niue: "Niue",
    newZealand: "New Zealand",
    oman: "Oman",
    panama: "Panama",
    peru: "Peru",
    frenchPolynesia: "French Polynesia",
    papuaNewGuinea: "Papua New Guinea",
    philippines: "Philippines",
    pakistan: "Pakistan",
    poland: "Poland",
    saintPierreAndMiquelon: "Saint Pierre and Miquelon",
    pitcairn: "Pitcairn",
    puertoRico: "Puerto Rico",
    palestine: "Palestine",
    portugal: "Portugal",
    palau: "Palau",
    paraguay: "Paraguay",
    qatar: "Qatar",
    reunion: "Reunion",
    romania: "Romania",
    serbia: "Serbia",
    russia: "Russia",
    rwanda: "Rwanda",
    saudiArabia: "Saudi Arabia",
    solomonIslands: "Solomon Islands",
    seychelles: "Seychelles",
    sudan: "Sudan",
    sweden: "Sweden",
    singapore: "Singapore",
    saintHelena: "Saint Helena",
    slovenia: "Slovenia",
    svalbardAndJanMayen: "Svalbard and Jan Mayen",
    slovakia: "Slovakia",
    sierraLeone: "Sierra Leone",
    sanMarino: "San Marino",
    senegal: "Senegal",
    somalia: "Somalia",
    suriname: "Suriname",
    southSudan: "South Sudan",
    saoTomeAndPrincipe: "Sao Tome and Principe",
    elSalvador: "El Salvador",
    sintMaarten: "Sint Maarten",
    syria: "Syria",
    swaziland: "Swaziland",
    turksAndCaicosIslands: "Turks and Caicos Islands",
    chad: "Chad",
    togo: "Togo",
    thailand: "Thailand",
    tajikistan: "Tajikistan",
    tokelau: "Tokelau",
    eastTimor: "East Timor",
    turkmenistan: "Turkmenistan",
    tunisia: "Tunisia",
    tonga: "Tonga",
    turkey: "Turkey",
    trinidadAndTobago: "Trinidad and Tobago",
    tuvalu: "Tuvalu",
    taiwan: "Taiwan",
    tanzania: "Tanzania",
    ukraine: "Ukraine",
    uganda: "Uganda",
    unitedStates: "United States",
    uruguay: "Uruguay",
    uzbekistan: "Uzbekistan",
    vatican: "Vatican",
    saintVincentAndTheGrenadines: "Saint Vincent and the Grenadines",
    venezuela: "Venezuela",
    britishVirginIslands: "British Virgin Islands",
    "u.S.VirginIslands": "U.S.Virgin Islands",
    vietnam: "Vietnam",
    vanuatu: "Vanuatu",
    wallisAndFutuna: "Wallis and Futuna",
    samoa: "Samoa",
    kosovo: "Kosovo",
    yemen: "Yemen",
    mayotte: "Mayotte",
    southAfrica: "South Africa",
    zambia: "Zambia",
    zimbabwe: "Zimbabwe",

  },
}
