<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="19" height="21"
    viewBox="0 0 19 21" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4912 1.00195V1.00167C17.4912 0.936039 17.5041 0.871041 17.5292 0.810391C17.5543 0.749741 17.5911 0.694638 17.6375
      0.648231C17.6839 0.601827 17.739 0.565017 17.7996 0.539915C17.8603 0.514816 17.9253 0.501916 17.9909 0.501954L17.9915
      0.501953C18.0571 0.501915 18.1221 0.514814 18.1828 0.539913C18.2434 0.565011 18.2985 0.601818 18.3449 0.648228C18.3913
      0.694641 18.4282 0.749746 18.4532 0.810389C18.4783 0.871033 18.4912 0.93603 18.4912 1.00166V1.00195V17.002C18.4912 17.1346
      18.4385 17.2617 18.3448 17.3555C18.251 17.4493 18.1238 17.502 17.9912 17.502C17.8586 17.502 17.7314 17.4493 17.6377
      17.3555C17.5439 17.2617 17.4912 17.1346 17.4912 17.002V16.365V16.1881L17.3799 16.0505C16.5608 15.0381 15.5427 14.2044
      14.3888 13.6008C13.2348 12.9973 11.9693 12.6367 10.6705 12.5413L10.3148 12.5152L10.1743 12.843L7.45059 19.1986L7.45032
      19.1992C7.41194 19.2891 7.34799 19.3657 7.26642 19.4195C7.18484 19.4733 7.08924 19.502 6.99151
      19.502H6.99121H2.99121H2.99086C2.90817 19.502 2.82677 19.4815 2.75395 19.4424C2.68113 19.4032 2.61918 19.3465 2.57366
      19.2775C2.52814 19.2085 2.50048 19.1292 2.49316 19.0469C2.48583 18.9645 2.49908 18.8816 2.53171 18.8056L2.53184
      18.8053L4.93457 13.1989L5.23327 12.502H4.475H3.99176C3.32879 12.5012 2.69319 12.2375 2.2244 11.7688L1.87947 12.1137L2.2244
      11.7688C1.75568 11.3 1.492 10.6645 1.49121 10.0017V8.00224C1.492 7.33937 1.75568 6.70387 2.2244 6.23514C2.69309 5.76645
      3.32853 5.50278 3.99135 5.50195C3.99148 5.50195 3.99162 5.50195 3.99176 5.50195L9.99121 5.50196L9.99307 5.50195C11.4108
      5.49668 12.8096 5.17535 14.0875 4.56135C15.3654 3.94735 16.4903 3.05613 17.3804 1.95257L17.4912 1.81518V1.63867V1.00195ZM3.99088
      11.502H3.99121H4.99121H5.49121V11.002V7.00195V6.50195H4.99121H3.99121H3.99087C3.59322 6.50222 3.21193 6.66031 2.93075
      6.94149C2.64957 7.22267 2.49148 7.60396 2.49121 8.00161V8.00195L2.49121 10.002L2.49121 10.0023C2.49148 10.3999 2.64956 10.7812
      2.93075 11.0624C3.21193 11.3436 3.59322 11.5017 3.99088 11.502ZM6.33203 18.502H6.66175L6.79161 18.1989L8.93419 13.1989L9.23284
      12.502H8.47461H6.65039H6.32068L6.19081 12.805L4.04823 17.805L3.74958 18.502H4.50781H6.33203ZM16.6733 13.9097L17.4912
      14.5835V13.5238V4.47956V3.41977L16.6733 4.09367C14.7909 5.64456 12.4294 6.49554 9.99049
      6.5019H6.99121H6.49121V7.0019V11.0019V11.5019H6.99121H9.99073C12.4296 11.5081 14.7909 12.3589 16.6733 13.9097Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
      :stroke="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
