<script>
  import BaseHeader from "./components/base-header/Index.vue";

  export default {
    components: {
      BaseHeader,
    },
    props: {
    },
    data() {
      return {
      };
    },

    methods: {
    },
  };
</script>

<template>
  <div class="w-100 bg-neutral-100 min-h-100vh">
    <BaseHeader />
    <div>
      <slot />
    </div>
  </div>
</template>

<style scoped lang="sass">
.content-container
    position: relative
    width: calc(100% - 120px)
    @media (max-width: 992px)
        width: 100%

.min-h-100vh
    min-height: 100vh !important
</style>
