export default {
  messages: {
    downloadPaymentProof: "Download Payment Proof",
    applicationSent: "Application sent successfully",
    unauthorized: "You are not authorized to access this page",
    noDataToDisplay: "There is no data to display",
    couponsAllocatedSuccessfully: "coupons allocated successfully",
    loading: "Loading...",
    youAreDeletingTheDefaultRecord: "Are you sure you want to delete the default {type}?!",
    areYouSure: "Are you sure?!",
    recordDeletedSuccessfully: "record deleted successfully",
    recordCreatedSuccessfully: "Record created successfully",
    recordUpdatedSuccessfully: "Record updated successfully",
    verificationIDSentSuccessfully: "Verification ID request sent successfully",
    failedToSendID: "Failed to send verification ID request",
    nationalIDOrPassport: "National ID Or Passport",
    editUserPersonalInfo: "User's personal info updated successfully",
    applicationApprovedSuccessfully: "Application approved successfully",
    editUserNotes: "User's notes updated successfully",
    budgetTooltip: "Budget cap is the advertising budget set for this campaign, if reached the campaign could be paused.",
    capCreatedSuccessfully: "Cap created successfully",
    capUpdatedSuccessfully: "Cap updated successfully",
    noSocialMediaAccount: "No social media account",
    paymentLimitsAdded: "Payment limits added successfully",
    editUserCampaigns: "User's campaigns updated successfully",
    deleteCapConfirmation: "Are you sure you want to delete this cap?",
    discardChangesConfirmation: "Any changes made to the cap budget will not be saved. Are you sure you want to discard the changes?",
    revokeAccessHint: `{userName} is the assigned {bus} campaign manager. If you revoke his/her access,
     he/she will no longer be the campaign manager.`,
    capDeletedSuccessfully: "Cap deleted successfully",
    onlyActiveCampaigns: "Only Active Campaigns",
    urlsLimitReached: "You've reached the limit for urls",
    sessionExpired: "Your session has expired",
    couponsUploadedSuccessfully: "Coupons uploaded successfully",
    areYouSureYouWantToAllocated: "Are you sure you want to allocate {number} coupons?",
    areYouSureYouWantToDeleteAllocation: "Are you sure you want to delete this allocation?",
    yourPointsRedeemed: "Your points will be redeemed at the end of the month",
    rewardExtraPayment: "Your redeemed reward will be added as an extra payment",
    contractSignedAndSealed: "The contract has to be signed and sealed",
    wrongData: "Wrong data please check",
    userAlreadyExist: "User already exists, please login",
    youHaveReceivedAResetPasswordLink: "You have received a reset password link, check out your email",
    passwordChangedSuccessfully: "Password reset successfully",
    campaignUpdatedSuccessfully: "Campaign is updated successfully.",
    campaignCreatedSuccessfully: "Campaign is created successfully.",
    campaignUpdatedError: "Error !",
    pleaseFixTheErrorsAndThenSubmit: "please fix the errors and then submit",
    campaignStatusUpdatedSuccessfully: "Campaign status updated successfully",
    areYouSureYouWantToDelete: "Are you sure you want to delete this {type}?",
    noteAdded: "Note added successfully",
    selectPartner: "please select a partner",
    selectCostType: "please select a cost type",
    noNewCampaigns: "there are no campaigns to apply",
    socialToolInfo: "Your social media profile username. Make sure the social media profiles are publicly accessible.",
    noNotes: "No Notes",
    "Sorry, wrong email address or password. Please try again": "Sorry, wrong email address or password. Please try again",
    uploadFileSuccessfully: "File validated successfully",
    noValidFile: "Please choose a valid file",
    somethingWentWrong: "Something went wrong",
    notReady: "Sorry the payment for this month is not ready yet",
    requiredFilters: "Please fill the following required filters: {field}",
    mandatoryFields: "Please fill out mandatory fields",
    cycleClosedSuccessfully: "Cycle closed successfully",

    sameNumberAsWhatsApp: "The same number for whatsApp",
    agreeOn: "I agree on",
    termsAndConditions: "terms & conditions",
    successfulRegistration: "Thank you for the registration!",
    successfulRegistrationMsg: "Our team will review your application. You will be notified on the decision by email, usually in 2/3 business days.",
    bannersUploadedSuccessfully: "Banners added successfully",
    couponsRequestSuccessfully: "Your request has been sent successfully",
    rejectCouponsRequest: "Coupons request rejected successfully",
    settingUpdatedSuccessfully: "Settings updated successfully",
    noReleaseNotesFound: "No release notes found",
    noResultFound: "No Result Found",
    allocateCouponsRequest: "Coupons allocated successfully",

    invalidFile: "Incorrect file headers",
    cantAcceptNewPayment: "The payment for this month is being processed, we can't accept new payment",

    addAnnouncement: "Announcement added successfully",
    partnersWontReceive: "Partners who won't receive your announcement",
    partnersWillReceive: "Partners who will receive your announcement",
    noUpdates: "there is no updates",
    noCoupons: "there is no coupons",
    noEarnings: "there is no Earnings",
    noAnnouncements: "there is no Announcements",
    noPerformance: "there is no Performance",

    paymentCloseWarningMsg: "Are you sure you want to close this Payment cycle?",
    closedSuccess: "Closed successfully",
    approvalMessage: "Are you sure you want to approve this partner?",
    addNewValue: `Please note that computation happens in every 30 mins. So the {activeModule}
     values based on updated condition takes`,
    addNewValueRestMessage: "30 mins to reflect in the reports",

    cantChangeValueMessage: `You can't change the value for this date range,
     as we have already paid to the partner with this value, till {firstDate}.
     We suggest you to change End Date here till {secondDate}.
     For the remaining date range, we suggest you to add a value to create new condition and to avoid having periods without any conditions`,
    requiredDependancyFilter: "Please select {filter}",
    campaignsStatusUpdatedSuccessfully: "Campaigns set to paid successfully",

    avatarUploadedSuccessfully: "Image uploaded successfully",
    fileUploadedSuccessfully: "File uploaded successfully",
    confirmCancellation: "Are you sure you want to cancel this agreement signing?",
    confirmUploadMessage: `Make sure you upload only Signed & Sealed agreement.
     Agreement signing status will be changed to ‘Signed&Sealed’ upon uploading`,
    confirmUploadNewMessage: `Are you sure to upload this new file? Also,
     note that the agreement signing status will be ‘Signed&Sealed’ upon uploading.`,

    agreementHint: "Agreement signing status will be changed to ‘Signed & Sealed’ upon uploading.",
    uploadFile: "Please Upload File",
    selectedFileSuccessfully: "New File Selected",
    failedToRequestDocument: "Failed to request VAT document",
    createNewAdsetConfirmation: "Are you sure you want to create {text} Adset ?",
    updateAdsetConfirmation: "On submission the Adset of the coupons will be changed to the newly selected Adset.",
    addAdset: "Adset added successfully",
    updateAdset: "Adset updated successfully",
    addAdsetFailed: "Failed to add Adset",
    updateAdsetFailed: "Failed to update Adset",
    thereIsNoDataToDisplayInDataTable: "There is no data to display :(",
    pleaseApplyFilters: "Please, apply the filters and run the report",

    advertiserCreatedSuccessfully: "Advertiser created successfully.",
    advertiserUpdatedSuccessfully: "Advertiser updated successfully.",
    addNewUser: "User added Successfully",
    loginGoogleMessage: "Make sure to log in using your Arabyads account, to sign in to Boostiny",
    youWillSendInviteByMailFor: "You will send invite by mail for",
    updatePartnerImageSuccessfully: "Update partner image successfully",
    expirationDate: "<span class=\"text-primary-500\">{expirationDate} day(s)</span> left",
    fileUploadFailed: "File Upload Failed",
    noCampaigns: "There is no campaigns",
    areYouSureToContinue: `As you are not entering any value for the missing period,
        we will end up having that period without {type} condition. Are you sure to continue with that choice?`,
    rateAdded: "Rate Added Successfully",
    noValidationsData: "There are no validations data",
    VATDocumentSentSuccessfully: "VAT document sent successfully",
    sendNotificationConfirmationMessage: `Do you want to notify
    <span class="p-base-bold text-secondary-500">
    partners & advertiser</span> on the campaign status change?`,
    updatesPending: "Thanks for the update. Partner Support team will check the update and the change will reflect after the team's approval.",
    inputChangedSuccessfully: "Input change approved",
    inputRejectedSuccessfully: "Input change rejected",
    yetToApprove: "Yet to approve",
    allEditsRequests: "All edits requests",
    editAnnouncement: "Announcement updated successfully",
    maxCyclesSelected: "The max cycles selected is {number}",
    requestDuplicate: "There are more than one update for the {fields}, please choose one before approved all",
    pocHasCampaigns: "There has to be at least one campaign mapped to the client contact  to send the invite",
    SomeOfCreatedPOCsEmailExistsBefore: "Some of created client contact emails exists before.",
    confirmAAAssociateMessage: `<p class="p-base-medium"> Please note <span class="text-secondary-500"> AA Associate tag </span>
    is exclusively for a few premium publishers who are managed by AA team.
    Are you sure you want to mark <span class="text-secondary-500"> {partnerName} </span> as an 'AA Associate’ ? </p>`,
    PleaseReachOutToTheSupportTeamToClarifyTheSituationToRevertTheSuspension: `Please reach out to the Support team to clarify the situation & to
    revert the suspension: {supportEmail}`,
    areYouSureYouWantToRevertTheSuspension: "Are you sure you want to revert the suspension?",
    failedToCopy: "Failed To Copy",
    textIsCopied: "Text is copied successfully",
    backOfficeChat: "Start a new chat to connect with partners and your account managers",
    partnerChat: "Start a new chat to connect with your account managers",
    userDeletedSuccessfully: "User deleted successfully",
    confirmDeleteTeamUser: `Please make sure there are no coupons allocated to the user to delete
    the account.`,
    confirmDeleteSelectedUser: "Are you sure you want to delete current user",
    deleteUserHint: "Also note that the deleted users mail id can't be used again for another user",
    couponsArchivedSuccessfully: "Coupons archived successfully",
    updateCountry: "Coupon Countries Updated successfully",
    updateCountryFailed: "Failed to update country",
    updateCountryConfirmation: "On submission the Countries of the coupons will be changed to the newly selected countries.",

    partnerCampaignsStats: "Active campaigns with at least one allocated coupon",
    partnerCouponsStats: "{activeAssignedCoupons} out of {totalAssignedCoupons} Active assigned coupons",
    confirmDeleteSheet: "Are you sure you want tp delete this sheet?",
    productStats: "Product Stats",
    confirmDeletePartnerModal: "Are you sure you want to delete your account?",
    verifyOtpMessage: "Enter the OTP sent to your mail",
    verifyOtpBodyMessage: `To ensure that the account deletion is intentional and desired by you,
    we need to verify your identity. We've sent a code to`,
    timeNeedToDeletePartner: "This could take up to 4 months as we settle your pending payments.",
    supportRequestDeletePartner: `Please contact support in case you have any questions about your
    pending payments, or if you wish to recover your account.`,
    deleteIsPendingMessage: "Account deletion is on hold for the time being, as we are awaiting legal clarification. Thank you",
    cancelDeleteRequestMsg: "Are you sure you want to cancel the account deletion request?",
    otpHasBeenSentToYourMail: "OTP has been sent to your mail",
    areYouSureToDeletePromotion: "Are you sure you want to delete this promotion?",
    archiveCoupon: "Are you sure you want to archive the selected code(s)?",
    archiveCouponNote: `Please note that once archived, the code will no longer
    be available for further allocations. Code will be expired for respective partner.
    Existing allocations to BU, TL, TM & Traffic source will remain the same.
    Conversion details in Reports will remain as normal.`,
    infoForValidationSheet: `Please note that the value of Customer_type’ should be: new, returning or NA.
    Similarly ‘Campaign_brand’ value should be {campaignCode}.
    In case the country data is not available, you may use 'NA' as value in the country field.`,
    confirmRevoke: "Are you sure you want to revoke this api token?",
    giveAccess: "Access has been given successfully",
    giveAccessConfirmation: "Are you sure you want to give the campaign access to {partnerName}?",
    deleteComment: "Are you sure you want to delete this comment?",
    referralByPartner: "Refered by an already {appName} partner?",
    rejectCouponRequest: `<p class="p-base-medium">Are you sure you want to reject the coupons request from
    <span class="p-base-bold text-secondary-500">{partnerName}</span>?</p>`,
    refferedBy: "Referred by: {partnerName}",
    allocateCouponRequest: `<p class="p-base-medium"><b class="text-secondary-500">{partnerName} - {partnerId}</b>
    has requested for {couponRequestCount} coupons.
    Are you sure you want to allocate {selectedCoupons} coupons {only}?</p>`,
    confirmSingleRedeem: "Are you sure you want to redeem this prize to the selected partner?",
    confirmBulkRedeem: "Are you sure you want to redeem the prizes to the selected partners?",
    noActiveCampaigns: "You don’t have active campaigns that joined the Boost & Earn program, try joining campaigns with a Boost & Earn program",
    topPartnersMsg: "Top partners will appear here",
    noRedeemPoints: "you have No redeem points",
    canNotChangeCampaignType: `Make sure you select the correct kind of campaign
    (<span class="p-base-bold">Coupon</span> or <span class="p-base-bold">Link</span>). This can’t be modified later on.`,
    addOfferUrl: `Add the offer URL and select the macros required from the advertiser with the
    right format from Boostiny macros. Uncheck any extra queries added from the link that is not a macro`,
    canUpdateMacros: `You can update the macros by changing the <span class="p-small-bold">“Advertiser sent link”</span>
        and clicking the <span class="p-small-bold">“Add”</span> button`,
    referralMessage: `•You’re invited to join Boostiny {bu} by {partner}
    \n•Referral Code: {code} \n•Referral Link: {domain}?code={code}`,
    searchInMessages: "Enter at least 3 characters to start the search",
    hitAddToUpdateMacros: "Hit “Add” after you insert the offer link above to update macros",
    linkWithUpdatedMacros: "Link with the updated boostiny macros",
    customizeYourLink: "Customize your link by selecting the needed parameters from the following.",
    userSourceMessage: "Helps you to aggregate data by the traffic source value in the reports",
    userClickIDMessage: "Helps you to reference individual clicks across tracking systems",
    subIdsMessage: "Helps you to aggregate data by non-unique ID values  (Eg, keyword, subsite, etc.) in the reports",
    trackingLinkCreated: "The new tracking link created successfully",
    searchAfter10Chars: "Please enter at least 10 chars",
    totalConversionsRecorded: "This is the total conversions recorded for the above Clicks for the lifetime.",
    deleteCommission: "Are you sure you want to delete this commission class?",
    duplicatedGroup: "Another group already has the same conditions",
    recordEditedSuccessfully: "Record edited successfully",
    isAovOrOrderSelected: "Category dimension is disabled as you’re using AOV/Order dimension",
    isCategorySelected: "AOV/Orders dimension is disabled as you’re using Category dimension",

    categoryDeletedSuccessfully: "Category Deleted Successfully",
    categoryCreatedSuccessfully: "Category Created successfully",

    noCommissionMessage: "You have no active commissions please contact Account Manager",
    noActiveCommission: "No active commission",
    noDailyTipsMsg: "There are no daily tips added",
    areYouSureToDeleteDailyTip: "Are you sure you want to delete this tip?!",
    deleteTipSuccessfully: "Tip Deleted Successfully",
    undoValidationConfirmation: "Are you sure you want to undo the 'Approved' status of Campaign Validation of {campaignName} for {date}?",
    undoValidationSuccessfully: "Campaign validation has been reset",
    confirmDeleteAnnouncement: `Are you sure you want to delete this announcements.
     Once deleted, partners will no longer be able to see this content?`,
    deleteAnnouncementSuccessfully: "Announcement Deleted Successfully",
    deepLinkMessage: `Helps you to redirect customers to specific category/product pages in the client site.
        Make sure you enter URLs from this client’s website itself.`,
    defaultDeepLinkMessage: `If the partner is not adding any deeplink parameter, the user will land on this URL.
        Also, partners can only add deeplink URLs that belong to this domain.`,
    deepLinkNotSameDefaultDeepLink: "Make sure you only add deeplink URLs that belong to this domain.",
    noDataToExport: "No Data to export",
    agencyPartnersHint: "Conversions & Sale Amount in below table are the last month’s performance for each partner",

    maxSelectedValues: "Max selected values is {max}",
    selectDateRange: "Please select date range",
    globalCommissionRuleCalculation: "Global commission rule is calculated only when there are no <span class='p-base-bold'>defined rules.</span>",
    payoutValueMaximum: "Should be 40% of the defined revenue value",
    globalRuleTooltip: "Will be calculated in case there are no defined rules.",
    fillRequiredFields: "Fill required fields correctly",
    postbackRequestsError: "{percentage} of the requests have been rejected/caused an error",
    confirmDeleteAgreement: "Are you sure you want to delete the current contract? A backup will be saved",
    budgetCapMsg: "Budget Cap is calculated based on the campaign gross revenue or gross conversions.",
    currencyExchangeSupportText: `Setting preferred currency enables you to view
    Boostiny Reports in that currency but the original currency is US Dollar`,
    currencyExchangeHint: "The exchange rate is relative to the dollar.",
    currencyExchangeDisclaimer: `Currency exchange rates is supported via live rates
    Currency Exchange API and updated every 24 hours for the supported currencies.`,
    deletePostBackURL: "Are you sure you want to delete this postback URL?",
    brandCreatedSuccessfully: "Brand created successfully",
    brandUpdatedSuccessfully: "Brand updated successfully",
    branddeletedSuccessfully: "Brand deleted successfully",
    confirmDeleteBrand: "Are you sure you want to delete this",
    selectAdvetiserMsg: "Please, select an advertiser first",
    definedTargetedMessage: "Defined in targeted countries, you’re not allowed to block it",
    definedBlockedMessage: "Defined in blocked countries, you’re not allowed to target it",
    selectAllowOrBlockedCountries: "Select the countries you want to allow or block traffic from, leaving it empty will allow traffic from anywhere",
    partnersAndAdvertisersCurrencyChangeDisclaimer: `{currency} currency exchange rate is applied on
    current live rate it might be different than your invoices`,
    backOfficeCurrencyChangeDisclaimer: "{currency} currency exchange rate is applied on current live rate",
    currencyFilterDisclaimer: "Please enter value in USD",
    pleaseSelectBUAndCycle: "Please select business unit and payment cycle",
    updateStatusConfirmation: "Are you sure you want to update the selected record | Are you sure you want to update the selected {n} records",
    updateStatusConfirmationSecondPart: "status to {status}?",
    updateApprovedAndDelayedConfirmation: "Partner payment below $ {limit} cannot be paid",
    remainingUnvalidatedPartners: `Some partners are still pending status change in validation
    'Not Validated', please check validation status update first`,
    automaticallyDelayedPartnersHint: `{number} out of {total} Partners payment are below
    $ {limit} cannot be paid, and these {number} partners will be delayed automatically`,
    noResolvedComments: "There are no resolved comments",
    noActiveComments: "There are no active comments",
    youCanMentionUsers: "You can mention others by adding (@) before name",
    addNewComment: "Add New Comment",
    updateDelayAndHideConfirmation: `The selected payment will be delayed according to BI team request
    | The selected payments will be delayed according to BI team request`,

    createGlobalPayout: "New Payout added successfully",
    editGlobalPayout: "Payout updated successfully",
    deleteGlobalPayout: "Global payout deleted successfully",
    alertGlobalPayout: "Some campaigns are validated in the defined date period, you’re only able to edit end-date to ",
    confirmDeleteGlobalPayout: "Are you sure you want to delete this global payout?",

    pendingVatInvoice: "Upload your VAT invoice to proceed your payment",
    pendingAcceptance: "Accept your invoice to proceed your payment",
    underReview: "Our team is reviewing your VAT invoice",
    processing: "Invoice is processing and will be paid soon",
    rejectedByTheBank: "Invoice rejected by your bank, please contact partner support to resolve the issue",
    delayed: "Invoice is delayed and will be added to next month payment",

    areYouSureToAcceptInvoice: "Are you sure you want to accept this invoice?",
    areYouSureToRejectInvoice: "Are you sure you want to Reject this invoice?",

    requestSentSuccessfully: "Your request has been sent successfully",
    partnerVatRegisteredSupportText: `You should upload your VAT Invoice to any Processing Invoices with same amount
    displayed in order for payment to be fulfilled`,
    noSavedFilters: "You have no saved filters yet, create a filter and save it",
    recordSavedSuccessfully: "Filters saved successfully",
    maxCharsLimit: "Maximum 40 characters",
    filtersGroupsLimitExceeded: "You exceed the limit for the allowed saved filters group, please delete some of them to be able to add new ones",
    deleteFilterConfirmation: "Are you sure you want to delete {filter} ?!",
    stopBanner: "Are you sure you want to stop this banner?",
    removeBanner: "Are you sure you want to remove this banner?",
    bannerRemovedSuccessfully: "Banner removed successfully",
    bannerStoppedSuccessfully: "Banner stopped successfully",

    thereIsNoDataToBeExported: "There is no data to be exported",
    pushNotifications: "Turn on to receive alerts for notifications",
    activeCouponsHint: "Active coupons are allocated coupons on active campaigns",
    pausedCouponsHint: "Paused coupons are allocated coupons on paused/stopped campaigns",
    inactiveCouponsHint: "Inactive coupons are past allocated coupons",
    undoFileUpload: "Are you sure you want to undo this file upload?",
    undoFileUploadWarning: `Please note that the undo action will remove {records} record |
    Please note that the undo action will remove {records} records`,
    fileDeletedSuccessfully: "File deleted successfully",

    noValidationErrors: "No validation errors",
    partnerRevokementWarning: "Your links on this campaign have been deactivated because {reason} from {date}",
    partnerRevokementWarningOtherReason: "Your links on this campaign have been deactivated from {date} because of the following reason.",
    deactivationHint: {
      links: "Links from",
      campaign: "on campaign",
      activityPaused: "will be broken & activity will be paused",
    },
    campaignRevokementReason: "Reason for deactivating the links, this reason will be shared with the partner",
    accessRevokedSuccessfully: "Access revoked successfully",

    pleaseNoteTheFollowing: "Please note The following",
    customerTypeValue: "Customer type value",
    customerTypes: "New, Returning, or NA",
    shouldBe: "should be",
    campaignBrandValue: "Campaign brand value",
    inCaseCountryNotAvaliable: "In case the country data is not available you may use NA as value in the country field.",
    acceptValidationSheet: "Are you sure you want to accept the uploaded Validation Sheet?",
    undoValidationSheet: "Are you sure you want to undo the uploaded Validation Sheet?",
    flagValidationSheet: "Are you sure you want to flag the uploaded validation sheet?",
    maxCharacter: "Max. {num} character",
    rejectUploadedSheetHint: "You’re about to reject the uploaded validation sheet",
    selectAtLeastOneReason: "Please select at least one reason",
    pleaseSelectCampaign: "Please Select Campaign",
    agencyApprovedMsg: "Are you sure you want to approve this agency?",
    entitiesCountriesWarning: "You must allocate all remaining countries on a new entity, or select all countries for this one.",
    reviewEntities: "Review entities before you proceed",
    useInfoForInvoice: "Use this info for the VAT invoice you are uploading!",
    noInvoices: "No new invoices at the moment.",
    invoicesAppearHere: "All your invoices will appear here.",
    approvePaymentConfirmation: "Are you sure you want to approve the selected record? | Are you sure you want to approve the selected {n} records?",
    autoAcceptInvoicesHint: "Your payment will be auto accepted after 7 days if no actions were taken",
    notAuthorized: "You are not authorized to access this page",
    roleUpdatedSuccessfully: "Role updated successfully",
    userPermissionsUpdatedSuccessfully: "User permissions updated successfully",
    thankYouForContactingBoostiny: "Thanks for contacting Boostiny!",
    weWillContactYouShortly: "We will be in touch with you shortly.",
    haveAGreatDay: "Have a great day!",
    linkCopied: "Link Copied",
    userHasPendingRequest: "You can't request coupons, while you already have a pending request",
    requestValueRange: "You can request coupons between {min} to {max}",
    noReleaseNotes: "There are no release notes",
    releasePublished: "This release is already published.",
    clickToAddNewSection: "Click to add new to add new {section}",
    couponsUnarchivedSuccessfully: "Coupons unarchived successfully",
    UnarchiveCouponsHint: `Unarchiving the coupons doesn’t guarantee retrieving the old gross numbers
    , but any new conversion will be added to Boostiny.`,

    cantCreateRelaseNote: "There is a scheduled release note, you can't create a new one",
    releasePublishTimeHint: "If the release publish time is not set, the release note will published automatically.",
    iconnectTeamHint: `Please note that selected coupons will be
    allocated to unallocated INF team leader and unallocated INF account manager`,
    archivedCouponUpdatedSuccessfully: "Archived coupon updated successfully",
    addRequiredFieldFirst: "Please select {keys} first",
    ignoreCouponHint: "Are you sure you want to ignore the selected code(s)?",
    restoreCouponHint: "Are you sure you want to restore the selected code(s)?",
    pleaseNote: "Please Note",
    accessListedLinkCampaigns: "'s access on the listed link campaigns will be revoked and",
    willGrantAlternativeClonedAccess: "will grant alternative cloned access.",
    totalNumOfAllocations: "Total number of allocations will be reallocated to",
    onTheListedCouponCampaigns: "on the listed coupon campaigns.",
    teamLeaderAccessCampaigns: "and his team members' access on link campaigns will be revoked from the listed link campaigns and",
    teamMembersGrant: "and his team members will grant alternative cloned access",
    willReallocatedTo: "coupons will be reallocated to",
    unAllocatedAccountManager: "and Unallocated Account Manager.",
    validationMigrateErrorMsg: "This campaign is validated in {lastValidatedCycle}, and the minimum valid date is {minValidDate}",
    allocationMigrateErrorMsg: "There are active allocations after the selected start date.",
    actionCannotReverted: "Please note that this action cannot be reverted, are you sure you want to continue?!",
    changeReportingLineFor: "Please Note changing the reporting line for",
    willReallocate: "will reallocate",
    couponsAllocationOn: "coupons allocations on",
    campaignsTo: "campaigns to",
    willSet: "will set",
    couponsAllocationsOn: "coupons allocations on",
    campaignsToUnAllocatedAccountManager: `campaigns to be unallocated account manager depending on each
    allocation business unit & and revoke the user’s access to`,
    linkCampaigns: "link campaigns",
    differentTeamAllocationHint: `Please note that selected coupons will be
    allocated to unallocated INF team leader and unallocated INF account manager`,
    accessMigrateErrorMsg: "Link campaigns cannot be migrated before {value} for media-buying users",
    noAffectedCampaigns: "No Affected Campaigns",
    areYouSureYouWantToApprovePartner: "Are you sure you want to update the selected partner(s) status to approved?",
    areYouSureYouWantToPendingPartner: "Are you sure you want to update the selected partner(s) status to pending?",
    partnerChangedToPendingSuccessfuly: "Partner’s validation changed to pending successfully",
    partnerChangedToApprovedSuccessfuly: "Partner’s validation approved successfully",
    sendValidationSummariesToFinance: "Are you sure you want to send the selected validation summaries to Finance?",
    emailSentSuccessfully: "Email sent successfully",
    delayedUponBIRequest: "Delayed according to BI team request",
    partnerDataFetchError: `Influencer data has not been requested from IDH yet. If this error persists for more than 24 hours,
    please raise a support ticket.`,
    percentageValue: "Note: The value should be between 1 to 100",

    minValue: "Note: The minimum value is {min}",
    maxValue: "Note: The maximum value is {max}",
    minMaxValue: "Note: The value should be between {min} to {max}",
    cannotRevokeCGTeamAccess: "You can't revoke access from the assigned CG team member",
    zeroConversionsForXDays: "After Coupon allocation, there are zero conversions for X consecutive days",
    turnOnToReceiveEmailAlertsAroundPerformanceOfCoupons: "Turn on to receive email alerts around performance of coupons",
    emailNotificationsUpdatedSuccessfully: "Email Notifications updated successfully",
    contactUrBankToGetInfo: "Contact your bank to get this info",
    thereAreCouponsAllocatedToUser: `There are coupons allocated or to be allocated to the user,
     please make sure you un-allocate them or re-allocate them.`,
    approvalConfirmation: "Are you sure you want to approve this partner's application?",
    requiredDimensions: "{dimensions} dimension is dependant on this metric | {dimensions} dimensions are dependant on this metric",

  },
}
