<script>
  import Close from "@/components/partials/icons/Close.vue";
  import Links from "./Links.vue"

  export default {
    name: "MobileView",
    components: { Close, Links },
    props: {
      showSidebar: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      isRevGatePartner() {
        return process.env.VUE_APP_NAME === "revgate"
      },
    },

    methods: {
      close() {
        this.$emit("close")
      },

      toggleLang() {
        window.toggleLang()
      },
    },
  }
</script>

<template>
  <div>
    <div class="overlay" :class="[!showSidebar ? 'hide' : '']">
      <Close
        class="close-btn cursor-pointer"
        width="20"
        height="20"
        color="var(--base-white)"
        @click.native="close"
      />
    </div>
    <div class="mobile-view-sidebar" :class="[!showSidebar ? 'hide' : '']">
      <div class="logo d-flex justify-content-between align-items-center">
        <router-link class="m-t-6" :to="{ name: 'overview' }">
          <img
            :src="require(`@/assets/icons/${isRevGatePartner ? 'revgateLogos/main-logo.svg' : 'boostinyLogos/boostinyWordLogo.svg'}`)"
            width="120"
          >
        </router-link>

        <div class="lang-btn cursor-pointer" @click="toggleLang">
          <span class="heading-6-bold text-uppercase">{{ $i18n.locale === 'ar' ? 'en' : 'ar' }}</span>
        </div>
      </div>
      <div>
        <Links @close="close" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.mobile-view-sidebar
  height: 100vh
  position: fixed
  top: 0
  left: 0
  z-index: 6
  padding-block: 21px
  background-color: var(--base-white)
  padding-inline: 12px
  transition: all .2s ease-in-out
  width: 285px
  border-right: 1px solid var(--neutral-200)
  display: grid
  grid-template-rows: 40px 1fr
  gap: 15px

  [dir=rtl] &
    left: auto
    right: 0

  .lang-btn
    width: 35px
    height: 35px
    display: grid
    place-items: center
    border-radius: 8px
    border: 1px solid var(--neutral-300)
    transition: all .2s ease-in-out

    &:hover
      background-color: var(--primary-50)
      border-color: var(--primary-50)
      span
        color: var(--primary-500)

    span
      color: var(--neutral-500)

  &.hide
    left: -100%

    [dir=rtl] &
      left: auto
      right: -100%

.overlay
  position: fixed
  height: 100vh
  width: 100vw
  inset: 0
  z-index: 6
  background-color: var(--neutral-700-05)
  opacity: 1
  visibility: visible

  &.hide
    opacity: 0
    visibility: hidden

  .close-btn
    position: absolute
    top: 20px
    right: 20px
    z-index: 2

    [dir=rtl] &
      right: auto
      left: 20px

</style>
