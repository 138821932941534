export default [
  {
    path: "/payments",
    component: () => import(/* webpackChunkName: "Payments" */ "@/views/payments/Index.vue"),
    name: "payments",
    // to be handled with permissions if there are any other children
    redirect: { name: "payments-and-invoices" },
    meta: {
      requireAuth: true,
      title: "",
    },
    children: [
      {
        path: "payments-and-invoices",
        name: "payments-and-invoices",
        component: () => import(/* webpackChunkName: "PaymentsAndInvoices" */ "@/views/payments/PaymentsAndInvoices.vue"),
        meta: {
          title: "paymentValidation",
          entityName: "payments-and-invoices",
          filtersScheme: {
            cycle: "",
            businessUnit: "",
            legalEntity: "",
            partners: [],
            isSuspended: "",
            paymentStatus: "",
            invoiceStatus: "",
            paymentMethod: "",
            vatStatus: "",
            bouncedInvoices: 0,
            unresolvedComments: 0,
            sortMetrics: "total_payout",
            sortDirection: "desc",
          },
        },
      },
    ],
  },
]
