import router from "@/router";
import { getToken } from "firebase/messaging";
import { messaging } from "@/config/firebase";
import { setUserTrackingProfile } from "@/services/TrackingManagement";
import API from "@/utils/input-output/api/index";
import constants from "./constants";

export default {
  namespaced: true,

  state: {
    user: {},
    currencyRate: 1,
  },

  mutations: {
    SET_USER: (state, data) => {
      state.user = data;
      window.gtag("config", window.defGTM, {
        user_id: data.id,
      });
    },

    SET_CURRENCY_RATE: (state, data) => {
      state.currencyRate = data
    },

    SET_USER_AVATAR: (state, data) => {
      state.user.avatar = data.avatarUrl
    },
  },

  getters: {
    userBUIds(state) {
      const bus = state.user.bus || []
      return bus.map((el) => el.id);
    },

    userBUNames(state) {
      const bus = state.user.bus || []
      return bus.map((el) => el.name);
    },
    userPersona(state) {
      return state.user.persona
    },

    isUserSuspended(state) {
      return state.user.isSuspended === 1;
    },

    isUserHasDeleteRequest(state) {
      return state.user.requestDeletion;
    },
  },

  actions: {
    setUser({
      commit,
      dispatch,
      rootGetters,
    }, {
      data,
      fetchDependencies = false,
    }) {
      commit("SET_USER", data);

      if (!rootGetters["Authorization/isLoginAsActivated"]) {
        dispatch("sendMessagingToken")
        setUserTrackingProfile({ data })
      }

      dispatch("setUserCurrencyRate", {
        currency: data.settings.currency,
        persona: data.persona,
      })

      /**
       * To depend on for agency check it while fetching the user
       * instead of the domain check in development mode
       */
      localStorage.setItem("user-persona", data.persona)

      /**
       * Actions require user token
       */
      if (fetchDependencies) {
        const { persona } = data
        dispatch("Globals/fetchFiltersCriteria", { isAgency: persona === "agency" }, { root: true })
        dispatch("Globals/fetchCountries", { isAgency: persona === "agency" }, { root: true })
        if (["backoffice", "advertiser"].includes(persona)) dispatch("Lookups/fetchSystemBUs", null, { root: true });
        if (!rootGetters["Authorization/isLoginAsActivated"]) dispatch("Common/fetchUserAnnouncements", null, { root: true });
      }
    },

    setUserCurrencyRate({ commit }, {
      currency,
      persona,
    }) {
      if (new Date(currency.last_updated_at).toLocaleDateString() !== new Date().toLocaleDateString() && persona !== "agency") {
        API(({
          url: constants.getCurrencyRate({ currency: currency.code }),
          method: "get",
          requestId: "getCurrencyRate",
        }))
          .then(({ data }) => {
            commit("SET_CURRENCY_RATE", data.payload.data[currency.code])
          })
      } else {
        commit("SET_CURRENCY_RATE", currency.rate)
      }
    },

    fetchUser({ dispatch }, params = { fetchDependencies: false }) {
      /**
       * this is agency check to be able to implement login as agency
       */
      const isAgency = window.location.hostname === process.env.VUE_APP_AGENCY_HOST_NAME || localStorage.getItem("user-persona") === "agency"

      API({
        url: constants.fetchUser({ isAgency }),
        method: "GET",
        initiator: "global",
        requestId: "fetchUser",
        blockScreen: true,
      })
        .then(({ data }) => {
          dispatch("setUser", {
            ...data.payload,
            fetchDependencies: params.fetchDependencies,
          })

          // if the user is not in the chat, we should subscribe to all current user chats to display the notifications from the chat
          if (router.currentRoute.name !== "chat") {
            dispatch("Chat/fetchUsers", null, { root: true })
          }
        });
    },

    updateUserAvatar({ commit }, data) {
      commit("SET_USER_AVATAR", data)
    },
    sendMessagingToken({ getters }) {
      getToken(messaging, { vapidKey: process.env.VUE_APP_VAPID_KEY })
        .then((currentToken) => {
          API({
            url: constants.sendMessagingToken({ persona: getters.userPersona }),
            method: "PUT",
            data: {
              token: currentToken,
            },
            requestId: "sendMessagingToken",
            initiator: "global",
            blockScreen: true,
          })
        })
    }
    ,
  },
}
