<script>
  export default {
    computed: {
      isLoggedIn() {
        return !!Object.keys(this.$store.state.User.user).length;
      },
    },
  };
</script>

<template>
  <div
    class="not-found-page"
    :style="{
      'background-image': `url('${require('@/assets/images/shared/not-found-bg.png')}')`,
    }"
  >
    <div
      class="content radius-20 bg-base-white text-center d-flex justify-content-center align-items-center flex-column"
    >
      <div
        class="text-404 m-b-21"
        :style="{
          'background-image': `url('${require('@/assets/images/shared/numbers-bg.svg')}')`,
        }"
      >
        <h1 class="display-large-bold">
          404
        </h1>
      </div>
      <h2 class="heading-1-bold text-neutral-700 m-b-9">
        {{ $t("errors.pageNotFound") }}
      </h2>
      <p class="p-large-light text-neutral-500">
        {{ $t("errors.notFoundMessage") }}
      </p>

      <router-link
        v-if="isLoggedIn"
        to="/dashboard"
        tag="div"
        class="go-to bg-primary-500 d-flex justify-content-between align-items-center p-y-12 p-x-21 radius-8 text-base-white m-t-30"
      >
        <span class="p-large-bold">{{
          $t("buttons.goToDashboard")
        }}</span>
        <div
          class="arrow-box bg-base-white radius-6 p-x-3 p-y-3 d-flex justify-content-between align-items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="35"
            height="35"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
              fill="rgba(205,11,25,1)"
            />
          </svg>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.not-found-page
    min-height: calc(100vh - 80px)
    background-position: center
    background-size: cover
    display: grid
    place-items: center

    .content
        width: 62%
        min-height: 85%
        box-shadow: 0px 35px 90px var(--neutral-900-01)
        padding: 40px

        @media (max-width: 991px)
            padding: 20px
            width: 85%

        .text-404
            background-size: cover
            color: var(--base-white)
            -webkit-text-fill-color: transparent
            -webkit-background-clip: text
            transform: scale(2)

            @media (max-width: 767px)
                transform: scale(1.8)

            h1
                font-size: 100px

                @media (max-width: 767px)
                    font-size: 90px

        h2
            font-size: 65px

            @media (max-width: 767px)
                font-size: 45px

        p
            width: 80%

            @media (max-width: 767px)
                font-size: 18px
                width: 100%

        .go-to
            width: 300px
            cursor: pointer
            box-shadow: 1px 3px 20px var(--primary-500-04)

            @media (max-width: 767px)
                width: 240px

            span
                @media (max-width: 767px)
                    font-size: 18px

            .arrow-box
                [dir=rtl] &
                    transform: rotate(180deg)

                @media (max-width: 767px)
                    width: 25px
                    height: 25px

</style>
