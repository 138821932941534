export default {
  notificationSettings: {
    "coupon-allocation-expired": "انتهاء صلاحية رمز الكوبون",
    "coupon-allocation-assigned": "تم تعيين تخصيص الكوبون",
    "coupon-code-request": "طلب رمز الكوبون",
    "coupon-request-created": "طلب رمز الكوبون",
    "coupon-request-rejected": "رفض طلب الكوبون",
    "coupon-request-allocated": "تخصيص رمز الكوبون",
    "campaign-paused": "الحملة متوقفة مؤقتًا",
    "campaign-stopped": "توقفت الحملة",
    "campaign-rejected": "الحملة مرفوضة",
    "campaign-resumed": "استؤنفت الحملة",
    "campaign-application-approved": "تمت الموافقة على طلب الحملة",
    "campaign-application-rejected": "تم رفض طلب الحملة",
    "campaign-status-update": "تحديث حالة الحملة (متوقفة مؤقتًا / متوقفة / مستأنفة)",
    "campaign-application-request": "طلب تطبيق الحملة",
    "partner-validation-approved-for-partners": "تقرير الدفع جاهز",
    "partner-validation-approved-for-team": "دفع الشريك جاهز",

    "payout-expiration": "Payout condition expiration",
    "revenue-expiration": "Revenue condition expiration",
    "campaign-updated-by-advertiser": "Campaign updated by advertiser",
    "campaign-status-changed": "Campaign status change",
    "miscellaneous-request-update-partner-profile-action": "Profile edit approval",
    "miscellaneous-request-update-partner-profile": "Profile edits",
    "miscellaneous-user-tagged-in-partner-comment": "Tagging in partner profile comments",
    "announcements-boostiny": "Boostiny",
    "announcements-promotions": "Promotions",
    "announcements-insights": "Insights",
    "announcements-updates": "Updates",
    "link-campaign-access": "Link Campaign access",
    "campaign-access-agency-given": "Giving campaign access",
    "campaign-access-agency-received": "Receiving campaign access",
    "campaign-created-by-agency": "Campaign created by agency",
    "partner-payment-validation-management-approved": "Payment Actions",
    "partner-payment-validation-bounced": "Payment Failed",
    "partner-payment-validation-paid": "Payment Completed",
    "partners-uploaded-vat-invoices": "Partner Uploaded VAT Invoices",
    "partners-approved-invoices": "Partner Approved Invoices",
    "pending-payment-actions": "Payment Pending Actions",
    "campaign-cap-deleted": "Campaign Cap Deletion",
    "campaign-cap-consumed": "Campaign Cap Consumption",
    "campaign-cap-usage-alert": "Campaign Cap Usage Alert",
    "campaign-cap-increased": "Campaign Cap Increase",
    "campaign-cap-extended": "Campaign Cap Extension",
    "campaign-validation-days-changed": "Campaign Validation Days Change",
    "user-tagged-in-partner-payment-validation-comment": "Tagging in payment comments",
    "campaign-validation-sheet-uploaded": "Validation Sheet Upload",
    "campaign-validation-sheet-accepted": "Validation Sheet Acceptance",
    "undo-campaign-validation-approval": "Validation Undo",
    "campaign-validation-flagged": "Validation Flagged",
    "campaign-validation-sheet-rejected": "Validation Sheet Rejection",
    "campaign-validation-approved": "Validation Done",
    "campaign-access-revoked": "Campaign Access Revoked",

  },
}
