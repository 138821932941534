export default [
  {
    path: "/partners",
    component: () => import(
      /* webpackChunkName: "PartnersIndex" */ "../views/partners/Index.vue"
    ),
    meta: {
      requireAuth: true,
      title: "partners",
    },
    children: [
      {
        path: "",
        name: "partners",
        component: () => import(
          /* webpackChunkName: "PartnersList" */ "../views/partners/List.vue"
        ),
        meta: {
          hasFilters: true,
          filtersScheme: {
            tabName: "campaign-ready",
            scope: "mine",
            advancedFilters: [],
            sortMetrics: "name",
            sortDirection: "asc",
            countries: [],
            credibilityScore: [],
            geographicalReach: [],
            audienceLanguages: [],
            audienceInterests: [],
            categories: [],
            status: "approved",
            bus: [],
            registeredAt: {
              from: "",
              to: "",
            },
            approvedAt: {
              from: "",
              to: "",
            },
            isSuspended: "",
            hasUpdateRequests: false,
            hasRequestDeletion: false,
          },
          title: "partners",
          entityName: "partners-list",
        },
      },
      {
        path: ":id",
        component: () => import(
          /* webpackChunkName: "PartnerDetails" */ "../views/partners/details/Index.vue"
        ),
        meta: {
          title: "partnerDetails",
        },
        children: [
          {
            path: "",
            name: "partner-details",
            component: () => import(
              /* webpackChunkName: "PartnerBasicInfo" */ "../views/partners/details/BasicInfo.vue"
            ),
            meta: {
              title: "partnerBasicInfo",
              entityName: "partner-basic-info",
            },
          },
          {
            path: "global-payouts",
            name: "global-payouts",
            component: () => import(
              /* webpackChunkName: "GlobalPayouts" */ "../views/partners/details/GlobalPayouts.vue"
            ),
            meta: {
              title: "GlobalPayouts",
              entityName: "partner-details-global-payouts",
              hasFilters: true,
              filtersScheme: {
                status: "current",
              },
            },
          },
          {
            path: "partner-commission",
            name: "partner-commission",
            component: () => import(
              /* webpackChunkName: "PartnerCommission" */ "../views/partners/details/PartnerCommission.vue"
            ),
            meta: {
              hasFilters: true,
              requireAuth: true,
              title: "PartnerCommission",
              entityName: "partner-details-partner-commission",
              filtersScheme: {
                campaigns: [],
              },
            },
          },
          {
            path: "social-data",
            name: "social-data",
            component: () => import(
              /* webpackChunkName: "SocialData" */ "../views/partners/details/SocialData.vue"
            ),
            meta: {
              requireAuth: true,
              title: "socialData",
              entityName: "partner-details-social-data",
            },
          },
        ],
      },
    ],
  },
]
