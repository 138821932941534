<script>
  export default {
    props: {
      action: {
        type: String,
        required: false,
        default: "",
      },

      isSmall: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    computed: {
      btnClasses() {
        let classes = "";
        classes = this.action.toLowerCase() !== "reject"
          ? "bg-primary-500 m-b-6"
          : "bg-neutral-400 text-neutral-500";
        return classes;
      },
    },

    methods: {
      actionHandler() {
        this.$emit("action-handler");
      },
    },
  };
</script>

<template>
  <button
    :id="`${action}-btn`"
    type="submit"
    class="radius-6 text-base-white m-r-6 p-y-9"
    :class="[btnClasses, { 'is-small': isSmall }]"
    @click="actionHandler"
  >
    <span class="p-base-medium">{{
      $t(`buttons.${action.toLowerCase()}`)
    }}</span>
  </button>
</template>

<style lang="sass" scoped>
button
  height: auto
  width: 110px
  border: 0
  outline: none
  cursor: pointer

  @media (max-width: 767px)
    margin-bottom: 0
    margin-top: 9px
    width: 80px
    font-size: 12px

    &.is-small
      width: 75px

      span
        font-size: 11px
</style>
