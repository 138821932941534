<script>
  import BaseHeader from "@/layouts/components/base-header/Index.vue";
  import { mapGetters } from "vuex";
  import BaseZohoForm from "@/components/shared/zoho-form/Index.vue";
  import BaseStripe from "@/components/partials/base-stripe/Index.vue"
  import BaseSidebar from "./components/base-side-bar/Index.vue";

  export default {
    components: {
      BaseZohoForm,
      BaseSidebar,
      BaseHeader,
      BaseStripe,
    },

    data() {
      return {
        showSidebar: false,
      };
    },

    computed: {
    ...mapGetters("Authorization", [
      "isAdvertiser",
      "isAgency",
      "isPartner",
      "isBackoffice",
    ]),
    userCurrency() {
        return this.$store.state.User.user.settings?.currency
      },
    userCurrencyName() {
      return this.userCurrency?.name || "USD";
    },

    currentRoute() {
      return this.$router.currentRoute;
    },

    showCurrencyDisclaimer() {
      return (
        this.userCurrency
        && (this.isPartner || this.isAdvertiser || this.isBackoffice)
        && this.currentRoute.name === "overview"
        && this.userCurrency?.code !== "USD"
      );
    },
    },

    methods: {
      toggleSidebar() {
        this.showSidebar = !this.showSidebar;
      },
    },
  };
</script>

<template>
  <div>
    <BaseHeader @close="toggleSidebar" />

    <div class="content-container">
      <BaseSidebar :show-sidebar="showSidebar" @close="toggleSidebar" />

      <BaseZohoForm v-if="!isAdvertiser" />
      <div class="content p-x-24 p-y-24">
        <BaseStripe
          v-if="showCurrencyDisclaimer"
          theme="warning"
          class="p-18 m-b-21"
        >
          <template>
            <p class="p-base-light">
              {{
                isBackoffice
                  ? $t("messages.backOfficeCurrencyChangeDisclaimer", {
                    currency: userCurrencyName,
                  })
                  : $t(
                    "messages.partnersAndAdvertisersCurrencyChangeDisclaimer",
                    { currency: userCurrencyName }
                  )
              }}
            </p>
          </template>
        </BaseStripe>
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.content-container
  display: flex

  .content
    width: 100%
    padding-left: 104px
    padding-right: 24px
    flex-grow: 1

    [dir=rtl] &
      padding-right: 104px
      padding-left: 24px

    @media (max-width: 991px)
      padding-left: 24px
      padding-right: 24px

      [dir=rtl] &
        padding-right: 24px
        padding-left: 24px

    @media (max-width: 600px)
      padding-left: 14px
      padding-right: 14px
</style>
