import moment from "moment"
import Cookies from "js-cookie";
import { resetTracking } from "@/services/TrackingManagement";
import API from "@/utils/input-output/api/index";
import constants from "./constants"

export default {
  namespaced: true,
  state: {
    fetchPartnerDetails: false,
    partner: {},
    esignStatus: {
      document_url: "",
      status: "",
      type: "",
      date: "",
      withExpiration: true,
      expirationDate: 0,
    },
  },
  mutations: {
    SET_FETCH_PARTNER_DETAILS(state, value) {
      state.fetchPartnerDetails = value
    },
    SET_PARTNER_DETAILS(state, payload) {
      state.partner = {
        ...payload,
        paymentMethod: payload.paymentInfo.paymentMethod,
      };
    },

    UPDATE_PARTNER_IMG(state, url) {
      state.partner.pictureUrl = url;
    },

    SET_ESIGN_AGREEMENT_DETAILS(state, data) {
      state.esignStatus = {
        ...data,
        type: data.type || "",
        date:
          moment(data.lastActionDate)
            .format("DD MMM YY")
          || moment()
            .format("DD MMM YY"),
        withExpiration: data.expiresAt
          ? checkExpirationDate(data.status)
          : false,
        expirationDate: getExpirationDate(
          data.expiresAt || moment()
            .format("YYYY-MM-DD"),
        ),
      };
    },
  },

  actions: {
    setFetchPartnerDetails({ commit }, value) {
      commit("SET_FETCH_PARTNER_DETAILS", value)
    },

    updatePartnerAvatar({ commit }, url) {
      commit("UPDATE_PARTNER_IMG", url)
    },

    requestChangeAction(_, {
      id,
      data,
      requestId,
    }) {
      return API({
        url: constants.requestChangeAction({ partnerId: id }),
        method: "post",
        data,
        requestId,
      })
    },

    getPartnerBasicInfo({
      commit,
      dispatch,
    }, {
      partnerId,
      isPartner,
    }) {
      return new Promise((resolve, reject) => {
        API({
          url: constants.partnerDetails({
            partnerId,
            isPartner,
          }),
          method: "GET",
          requestId: "fetchPartnerDetails",
        })
          .then(({ data }) => {
            commit("SET_PARTNER_DETAILS", data.payload.data)
            resolve(data.payload.data)
            /**
             * to avoid calling fetch user API after the partner updates his/her profile picture,
             * just to update teh profile pic in the profile dropdown
             */
            if (isPartner) {
              dispatch("User/updateUserAvatar", { avatarUrl: data.payload.data.pictureUrl }, { root: true })
            }
          })
          .catch((err) => {
            reject(err)
          });
      })
    },

    getEsignAgreementDetails({ commit }, { partnerId }) {
      API({
        url: constants.agreementESignStatus({ partnerId }),
        method: "GET",
        requestId: "fetchPartnerAgreementEsignStatus",
      })
        .then(({ data }) => {
          commit("SET_ESIGN_AGREEMENT_DETAILS", data.payload.data)
        });
    },

    loginAsPartner({
      dispatch,
    }, id) {
      dispatch("Chat/changeFirebaseUserOnlineStatus", false, { root: true });
      return new Promise((res, rej) => {
        API({
          url: constants.loginAsPartner({ id }),
          method: "POST",
          requestId: "loginAsPartner",
          blockScreen: true,
          cancelAllPendingRequest: true,
        })
          .then(({ data }) => {
            resetTracking()

            Cookies.set(process.env.VUE_APP_TOKEN_KEY, data.payload.token, {
              expires: data.payload.expires_in / 86400,
              path: "/",
            });

            /**
             * set login as true, to determine if the original authenticated user
             * is logged in as another user or not
             */
            Cookies.set(process.env.VUE_APP_LOGIN_AS_TOKEN_KEY, 1, {
              expires: data.payload.expires_in / 86400,
              path: "/",
            });

            localStorage.setItem("original-persona", "backoffice")

            dispatch("Authorization/setIsLoginAsActivated", null, { root: true })
            localStorage.removeItem("filters");
            localStorage.removeItem("selectedFiltersOptionsV2");

            window.location.replace("/dashboard")
            res();
          })
          .catch(() => {
            rej();
          });
      });
    },

  },

}

function checkExpirationDate(status) {
  return !(status === "declined"
    || status === "signed_sealed"
    || status === "expired");
}

function getExpirationDate(dateOfExpiration) {
  const expiration = moment(dateOfExpiration, "YYYY-MM-DD");
  const current = moment()
    .format("YYYY-MM-DD");

  // Difference in number of days
  return moment.duration(expiration.diff(current))
    .asDays();
}
