export default {
  namespaced: true,
  state: {
    userProfile: {},
  },

  mutations: {
    SET_USER_PROFILE(state, userProfile) {
      state.userProfile = userProfile
    },
  },

  actions: {
    setUserProfile({ commit }, userProfile) {
      commit("SET_USER_PROFILE", userProfile)
    },
  },

  getters: {
    scopedCampaigns(state) {
      return state.userProfile.scopedCampaigns
    },
  },
}
