export default {
  validations: {
    category: "Category",
    source: "Payment Source",
    campaignName: "Campaign name",
    advertiser: "Advertiser",
    accountManager: "Account Manager",
    code: "Coupon",
    date: "Date",
    originalCurrency: "Original currency",
    country: "Country",
    partner: "Partner",
    aov: "AOV",
    estimated: "Estimated",
    paid: "Paid",
    "partially-paid": "Partially Paid",
    validated: "Validated",
    campaign: "Campaign",
    salesManager: "Sales Manager",
    customerType: "Customer type",
    bu: "Business unit",
    payoutType: "Payout type",
    conversions: "Conversions",
    netConversions: "Net Conversions",
    netOrders: "Net Conversions",
    net_MBCost: "Net MB Cost",
    validatedConversions: "Validated conversions",
    conversionsCancellationRate: "Conversions CR",
    netPayout: "Payouts",
    trafficSource: "Traffic Source",
    revenue: "Revenue",
    netRevenue: "Net Revenue",
    validatedRevenue: "Validated revenue",
    grossRevenue: "Gross Revenue",
    netRevenueUsd: "Revenue",
    finalRevenue: "Final Rev.",
    revenueCancellationRate: "Revenue CR",
    salesAmountUsd: "Sales amount",
    netSalesAmountUsd: "Net Sales amount",
    validatedSalesAmountUsd: "Validated sales amount",
    salesAmountUsdCancellationRate: "Sales amount CR",
    salesAmount: "Sales amount",
    netSalesAmount: "Net Sales amount",
    validatedSalesAmount: "Validated sales amount",
    salesAmountCancellationRate: "Sales amount CR",
    payout: "Payout",
    grossPayout: "Gross Payout",
    netPayoutUsd: "Payout",
    validatedPayout: "Validated payout",
    payoutCancellationRate: "Payout CR",
    numberOfCoupons: "Number of coupons",
    numberOfValidatedCoupons: "Number of validated coupons",
    aovUsd: "AOV",
    netAovUsd: "Net AOV",
    extra: "Extra",
    deduction: "Deduction",
    extraAndDeduction: "Extra & Deduction",
    payoutStatus: "Payout Status",
    partnerPayout: "Partner Payout",
    partners: "Partners",
    lateValidatedConversions: "Late validated conversions",
    lateValidatedRevenue: "Late validated revenue",
    totalValidatedConversions: "Total validated conversions",
    lateValidatedSalesAmountUsd: "Late validated sales amount",
    totalValidatedSalesAmountUsd: "Total validated sales amount",
    totalValidatedRevenue: "Total validated revenue",
    notValidated: "Not validated",
    pending: "Pending",
    approved: "Approved",
    INF: "Influencer",
    AN: "Publisher",
    AALab: "AA Lab",
    "AA-LABS": "AA LABS",
    "aa-labs": "AA LABS",
    MB: "Media Buyer",
    campaignValidationStatus: "Campaign validation Status",
    validate: "Validate",
    reject: "Rejected",
    campaignNotSelected: "Campaign not selected",
    validationComment: "Validation Comment",
    confirmed: "Confirmed",
    rejected: "Rejected",
    validationNotProvided: "Not provided",
    npCoupons: "Number of coupons",
    npValidatedCoupons: "Number of validated coupons",
    validatedNpCoupons: "Number of validated coupons",
    validatedOrders: "Validated conversions",
    orders: "Conversions",
    notProvided: "Not provided",
    status: "Status",
    partnerName: "Partner",
    actions: "Actions",
    managementApproved: "Management approved",
    fine: "Deductions",
    bonus: "Extras",
    totalCost: "Total cost",
    netTotalCost: "Net Total cost",
    profit: "Profit",
    netProfit: "Net Profit",
    margin: "Margin",
    netMargin: "Net Margin",
    fixedCost: "Fixed cost",
    netFixedCost: "Net Fixed cost",
    MBCost: "MB cost",
    mbCost: "MB cost",
    netMBCost: "Net MB cost",
    netMbCost: "Net MB Cost",
    teamLeader: "Team leader",
    teamMember: "Account manager",
    payoutCost: "Payout cost",
    netPayoutCost: "Net Payout cost",
    totalCostUsd: "Total cost",
    contribution: "Contribution",
    ordersCancellationRate: "Conversions CR",
    comment: "Comment",

    view: "View",
    validationResult: "Validation Result",
    notValidatedPartners: "Ignored Partners",
    managementPartnersApproved: "Not Valid Partners",
    successfullyPaidPartners: "Successfully Paid Partners",
    id: "ID",
    name: "Name",
    paymentAmount: "Payment Amount",
    paymentProof: "Payment Proof",
    remainingAmount: "Remaining Amount",
    validatedRevenueUsd: "Validated Revenue",
    lateValidatedRevenueUsd: "Late Validated Revenue",
    totalOrders: "Total Conversions",
    totalRevenue: "Total revenues",
    totalSalesAmountUsd: "Total sales amount",
    totalConversions: "Total conversions",
    lateValidatedOrders: "Late validated conversions",
    currentValidatedOrders: "Current validation conversions",
    currentValidatedRevenueUsd: "Current validated revenue",
    currentValidatedSalesAmountUsd: "Current validated sales amount",
    totalValidatedOrders: "Total validated conversions",
    totalValidatedRevenueUsd: "Total validated revenue",
    campaignCategory: "Campaign Category",
    validationCampaign: "Validation Campaign",
    "not-validated": "not validated",
    "not-Validated": "not validated",
    "financial-pending": "Financial pending",
    "not-provided": "Not provided",
    "management-approved": "Management approved",
    bounced: "Rejected by the bank",
    campaignDetails: "Campaign",
    gross: "Gross",
    net: "Net",
    CR: "CR",
    paymentStatus: "Status",
    statusHeader: "Status",
    businessUnit: "Business Unit",

    // finance renaming
    paidToBeCollected: "collected",
    debitAndCredit: "Debit note & credit note",
    debit: "Debit",
    credit: "Credit",
    collected: "Collected",
    grossRevenueUsd: "Gross Revenue",
    finalEarnedRevenue: "Final Rev.",
    cycle: "Cycle",

    adSet: "Adset",
    integrationType: "Integration type",
    validatedMonth: "Have Net values. Validated months",
    totalCommission: "Total Commission",
    totalCommissionCancellationRate: "Total Commission CR",
    netTotalCommission: "Net Total Commission",

    payoutCostCancellationRate: "Payout Cost CR",
    marginCancellationRate: "Margin CR",
    profitCancellationRate: "Profit CR",
    mbCostCancellationRate: "MB Cost CR",
    aovUsdCancellationRate: "AOV USD CR",
    fixedCostCancellationRate: "Fixed Cost CR",
    totalCostCancellationRate: "Total Cost CR",
    orderId: "Order ID",
    month: "Month",
    salesManagers: "Sales Managers",
    uploadedBy: "Uploaded by",
    uploadedAt: "Uploaded at",
    iconnect: "iConnect",
    iConnect: "iConnect",

    clicksCount: "Clicks",
    clickConversionRate: "CCR",
    earningPerClick: "EPC",
    clickId: "Click ID",
    click: "Click ID",
    ip: "IP",
    timestamp: "Timestamp",
    userClickId: "User click ID",
    publisherClickId: "Publisher click ID",
    language: "Language",
    device: "Device",
    os: "OS",
    browser: "Browser",
    city: "City",
    sub1: "Sub ID 1",
    sub2: "Sub ID 2",
    sub3: "Sub ID 3",
    sub4: "Sub ID 4",
    sub5: "Sub ID 5",
    partnerAndTeam: "Partners/MB Team",
    mbTeam: "MB Team",
    userName: "Partners/MB Team",
    numOfClicks: "Number Of Clicks",
    validatedClicksCount: "Number Of Validated Clicks",
    deviceCategory: "Device Category",
    urlName: "URL Name",
    brand: "Brand",
    head: "Head",
    invoice: "Invoice",
    paidDate: "Paid Date",
    totalPayout: "Total Payout",
    proofOfPayment: "Proof of Payment",
    comments: "Comments",
    unresolvedCommentsCount: "Comments",
    paymentMethod: "Payment Method",
    delayedBonus: "Delayed",
    delayed: "Delayed",
    paymentValidationStatus: "Validation status",
    accountabilityStatus: "Accountability status",
    legalEntitiesCount: "Legal Entities",
    legalEntitiesName: "Legal Entities",
    legalEntityName: "Legal Entity",
    clicksCountCancellationRate: "Clicks CR",
    netClicksCount: "Net Clicks",
    legalEntity: "Legal Entity",
    actionType: "Action Type",
    businessCategory: "Business Category",
    customerReturningDays: "Customer Returning Days",
    campaignStartYear: "Campaign Start Year",
    clientPresence: "Client Presence",
    locationCountry: "Client Location",
    category: "Category",
  },
}
