<script>
  import BaseImage from "@/components/partials/BaseImage.vue";
  import ArrowRightIcon from "@/components/partials/icons/ArrowRightIcon.vue";
  import { mapGetters } from "vuex";
  import Cookies from "js-cookie";
  import trackResponse from "@/utils/miscellaneous/track-response/index";
  import ReleaseNotesIcon from "@/components/partials/icons/ReleaseNotes.vue";
  import API from "@/utils/input-output/api/index";
  import InvoiceIcon from "../icons/Invoice.vue";
  import SettingsIcon from "../icons/Settings.vue";
  import SignOutIcon from "../icons/SignOut.vue";
  import ArrowCircleLeft from "../icons/ArrowCircleLeft.vue";
  import constants from "./constants";

  export default {
    components: {
      ArrowCircleLeft,
      BaseImage,
      ArrowRightIcon,
      InvoiceIcon,
      SettingsIcon,
      SignOutIcon,
      ReleaseNotesIcon,
    },

    data() {
      return {
        showProfileInfo: false,
        latestRelease: "",
      };
    },

    computed: {
    ...mapGetters("User", ["userBUIds", "userPersona"]),
    ...mapGetters("Authorization", ["isPartner", "isAgency"]),

    isUserSuspended() {
      return this.$store.getters["User/isUserSuspended"];
    },

    isUserHasDeleteRequest() {
      return this.$store.getters["User/isUserHasDeleteRequest"];
    },

    user() {
      return this.$store.state.User.user || {};
    },

    isActivePartner() {
      return (
        this.isPartner
        && !this.isUserSuspended
        && !this.isUserHasDeleteRequest
        && this.$root.checkPermission({
          permission: this.$permissions.VIEW_INVOICES,
        })
      );
    },

    hideProfileSettings() {
      return (
        (this.isPartner
          && (this.isUserHasDeleteRequest || this.isUserSuspended))
        || !this.$root.checkPermission({
          permission: this.$permissions.VIEW_USER_SETTINGS,
        })
      );
    },

    isRevgatePartner() {
      return this.isPartner && this.userBUIds.includes(2);
    },

    loginAsToken() {
      return this.$store.getters["Authorization/isLoginAsActivated"];
    },
    canViewReleaseNotes() {
      return this.$root.checkPermission({
        permission: this.$permissions.VIEW_RELEASE_NOTES,
      });
    },
    },

    created() {
      this.getLatestRelease();
    },

    methods: {
      openProfile() {
        this.showProfileInfo = true;
      },

      closeProfile() {
        this.showProfileInfo = false;
      },

      getLatestRelease() {
        const url = this.isAgency
          ? constants.agencyLatestRelease()
          : constants.latestRelease();
        API({
          url,
          method: "GET",
          requestId: "getLatestRelease",
          initiator: "global",
          cancelWhenLeaveInitiator: true,
        })
          .then(({ data }) => {
            this.latestRelease = data.payload.data?.version;
          });
      },

      logOut() {
        this.$store.dispatch("Authentication/logout");
      },

      loginByRefreshToken() {
        const refreshToken = Cookies.get(process.env.VUE_APP_REFRESH_TOKEN_KEY);
        API({
          url: constants.loginByRefreshToken({
            userPersona: localStorage.getItem("original-persona"),
          }),
          method: "get",
          requestId: "loginByRefreshToken",
          token: refreshToken,
          blockScreen: true,
          cancelAllPendingRequest: true,
        })
          .then(({ data }) => {
            Cookies.set(process.env.VUE_APP_TOKEN_KEY, data.payload.token, {
              expires: data.payload.expires_in / 86400,
              path: "/",
            });

            Cookies.set(
              process.env.VUE_APP_FIREBASE_TOKEN_KEY,
              data.payload.firebaseToken,
              {
                expires: data.payload.expires_in / 86400,
                path: "/",
              },
            );

            Cookies.set(
              process.env.VUE_APP_REFRESH_TOKEN_KEY,
              data.payload.refreshToken,
              {
                expires: data.payload.expires_in / 86400,
                path: "/",
              },
            );

            Cookies.remove(process.env.VUE_APP_LOGIN_AS_TOKEN_KEY);

            localStorage.removeItem("original-persona");

            this.$store.dispatch("User/setUser", data.payload);

            localStorage.clear();
            window.location.reload();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              trackResponse({
                type: "error",
                msg: this.$t("messages.sessionExpired"),
              })
              this.$store.dispatch("Authentication/logout");
            }
          });
      },

      backToOriginalAccount() {
        this.$router.push({ name: "overview" }).then(() => {
          this.loginByRefreshToken();
        });
      },
    },
  };
</script>

<template>
  <div v-click-outside="{isActive: ()=> showProfileInfo , callback: closeProfile}" class="p-relative">
    <div
      :class="{ active: showProfileInfo }"
      class="avatar cursor-pointer radius-full"
      @click="openProfile"
    >
      <BaseImage
        :src="user.avatar"
        class="radius-full w-100 h-100"
        :custom-fullback="require('@/assets/images/shared/user-fallback.png')"
      />
    </div>

    <transition name="show">
      <div
        v-if="showProfileInfo"
        class="profile-info radius-8 bg-base-white p-absolute shadow-2"
      >
        <div class="details p-x-15 p-y-15 d-flex gap-12">
          <BaseImage
            :src="user.avatar"
            class="radius-full"
            :custom-fullback="require('@/assets/images/shared/user-fallback.png')"
            height="40"
            width="40"
          />

          <div>
            <p class="p-base-medium text-neutral-900 word-break">
              {{ user.name }}
            </p>
            <p class="p-small-medium text-neutral-500 word-break">
              {{ user.email }}
            </p>
            <router-link
              v-if="isActivePartner"
              :to="{ name: 'profile' }"
              class="text-decoration-none text-primary-500 p-small-medium d-flex align-items-center gap-6 m-t-9"
            >
              <span>{{ $t("labels.viewProfile") }}</span>
              <ArrowRightIcon color="var(--primary-500)" />
            </router-link>
          </div>
        </div>

        <router-link
          v-if="isActivePartner"
          :to="{ name: 'payments-and-invoices' }"
          class="route text-decoration-none d-flex align-items-center gap-12 p-x-18 p-y-15"
        >
          <InvoiceIcon height="17" width="17" />
          <span class="p-base-medium">{{ $t("labels.myInvoices") }}</span>
        </router-link>

        <router-link
          v-if="!hideProfileSettings"
          :to="{ name: 'system-currency' }"
          class="route text-decoration-none d-flex align-items-center gap-12 p-x-18 p-y-15"
        >
          <SettingsIcon height="17" width="17" />
          <span class="p-base-medium">{{ $t("labels.accountSettings") }}</span>
        </router-link>

        <router-link
          v-if="canViewReleaseNotes"
          :to="{ name: 'release-notes-list' }"
          class="route text-decoration-none d-flex align-items-center gap-12 p-x-18 p-y-15"
        >
          <ReleaseNotesIcon
            height="17" width="17"
            color="var(--neutral-900)"
          />
          <span class="p-base-medium">{{ $t("labels.releaseNotes") }}</span>
        </router-link>

        <button
          class="route bg-base-white outline-none border-none w-100 cursor-pointer d-flex align-items-center gap-12 p-x-18 p-y-15"
          @click="logOut"
        >
          <SignOutIcon height="17" width="17" />
          <span class="p-base-medium">{{ $t("header.signOut") }}</span>
        </button>

        <button
          v-if="loginAsToken"
          class="route bg-base-white outline-none border-none w-100 cursor-pointer d-flex align-items-center gap-12 p-x-18 p-y-15"
          @click="backToOriginalAccount"
        >
          <ArrowCircleLeft height="17" width="17" />
          <span class="p-base-medium">{{ $t("header.backToMyAccount") }}</span>
        </button>

        <div
          class="d-flex justify-content-between align-items-center p-x-18 p-y-15"
        >
          <router-link
            v-if="!canViewReleaseNotes"
            :to="{ name: 'release-notes-list' }"
            class="p-small-medium text-neutral-500"
          >
            {{ $t("labels.versionHistory") }}
          </router-link>
          <p class="p-small-medium text-neutral-500">
            {{ $t(`labels.${isRevgatePartner ? "revgate" : "boostiny"}`) }} v{{
              latestRelease
            }}
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="sass" scoped>
.avatar
  width: 45px
  height: 45px
  border: 4px solid transparent

  &.active
    border: 4px solid var(--primary-500-05)

.profile-info
  z-index: 2
  width: 240px
  top: 50px
  right: 10px

  [dir=rtl] &
    right: auto
    left: 10px

  .details
    border-bottom: 1px solid var(--neutral-100)

  .route
    color: var(--neutral-700)
    transition: all .2s ease-in-out
    border-bottom: 1px solid var(--neutral-100)

    &:hover
      background-color: var(--neutral-100)

.show-enter-active
  transition: all .2s ease-in-out

.show-leave-active
  transition: all .2s ease-in-out

.show-enter, .show-leave-to
  transform: translateX(10px)
  opacity: 0
</style>
