import { render, staticRenderFns } from "./ActionBtn.vue?vue&type=template&id=c525d15a&scoped=true"
import script from "./ActionBtn.vue?vue&type=script&lang=js"
export * from "./ActionBtn.vue?vue&type=script&lang=js"
import style0 from "./ActionBtn.vue?vue&type=style&index=0&id=c525d15a&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c525d15a",
  null
  
)

export default component.exports