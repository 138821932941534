export default {
  labels: {
    unknown: "Unknown",
    onTimeValidation: "On Time Validation",
    lateValidation: "Late Validation",
    payments: "Payments",
    totalPayment: "Total Payment",
    newAdset: "Enter new adset name",
    selectAdset: "Select adset from list",
    lastModified: "Last Updated",
    today: "Today",
    yesterday: "Yesterday",
    last7Days: "Last 7 days",
    timePickerLast7Days: "Last Week",
    last30Days: "Last 30 days",
    timePickerLast30Days: "Last Month",
    thisMonth: "This Month",
    lastMonth: "Last Month",
    orders: "Conversions",
    orderID: "Order ID",
    creatives: "Creatives",
    revenues: "Revenues",
    profit: "Profit",
    margin: "Margin",
    delayed: "Delayed",
    noCapAdded: "No Cap Added",
    followers: "Followers",
    engagementRate: "Engagement Rate",
    credibilityScore: "Credibility Score",
    poor: "Poor",
    good: "Good",
    veryGood: "Very Good",
    excellent: "Excellent",
    exceptional: "Exceptional",
    audience: "Audience",
    age: "Age",
    geographicalReach: "Geographical Reach",
    languages: "Languages",
    areasOfInterest: "Areas Of Interest",
    socialData: "Social Data",
    performanceSummary: "Performance Summary",
    currentMonth: "Current Month",
    lastSixMonths: "Last 6 Months",
    budgetCap: "Budget Cap",
    conversionCap: "Conversion Cap",
    daysRemaining: "day remaining | days remaining",
    noCaps: "No caps available",
    totalCost: "Total Cost",
    legalEntities: "Legal Entities",
    partnerRevenue: "Revenues",
    upcoming: "Upcoming",
    running: "Running",
    noBannersYet: "No Ads Banners Yet",
    stop: "Stop",
    remove: "Remove",
    bannerStartDate: "Banner Start Date",
    bannerEndDate: "Banner End Date",
    approvalRejection: "Approval Rejection",
    redirection: {
      campaignName: "Campaign Name",
      link: "Link",
    },
    redirectionType: "Redirection Type",
    redirectionCampaign: "Redirection Campaign",
    redirectionLink: "Redirection Link",
    noExpirationDate: "No Expiration Date",
    overviewBanner: "Overview Banner",
    campaignScreen: "Campaign Screen",
    overviewScreen: "Overview Screen",
    dashboardBanner: "Overview Banner",
    campaignBanner: "Campaign Banner",
    campaignsBanner: "Campaign Banner",
    mobileBanner: "Mobile Banner",
    email: "Email",
    password: "Password",
    conversions: "Conversions",
    salesAmounts: "Sales Amount",
    editCampaign: "Edit campaign",
    showRevenue: "Show revenue",
    shortenURLAppear: "shorten URL will appear here",
    netPayout: "Payouts",
    grossPayout: "Gross Payout",
    campaignTeam: "Team",
    campaignIntegration: "Campaign Integration",
    bonus: "Extra",
    fine: "Deduction",
    fines: "Deductions",
    "extras-and-deductions": "Extras and Deductions",
    "bulk-upload-log": "Bulk Upload Log",
    noOfRecords: "No. of records",
    extrasAndDeductionsFiles: "Extras and Deductions Files",
    undo: "Undo",
    comission: "Commission",
    commissions: "Commissions",
    by: "by",
    howYouWillPromote: "How you will Promote?",
    all: "All",
    active: "Active",
    stopped: "Stopped",
    paused: "Paused",
    campaignStatus: "Status",
    campaignExtraAndDeduction: "Extra And Deduction",
    campaign: "Campaign",
    cycle: "Month",
    cycles: "Cycles",
    comment: "Comment",
    agreement: "Agreement",
    approved: "Approved",
    pending: "Pending",
    rejected: "Rejected",
    missing: "Missing",
    total: "Total",
    notJoined: "Not Joined",
    name: "Name",
    deletedUser: "Deleted User",
    // teamMember: 'Team Member',
    // teamLeader: 'Team Leader',
    roleName: "Role",
    buName: "Business Unit",
    teamLeaderName: "Team Leader",
    contact: "Phone Number",
    costType: "Cost Type",
    isEmpty: "Empty",
    isNotEmpty: "Not empty",
    dateIs: "Date is",
    dateBefore: "Date before",
    dateAfter: "Date after",
    numberIsEqual: "Equal",
    numberNotEqual: "Not equal",
    numberBetween: "Between",
    numberGreaterThan: "Above",
    numberGreaterThanOrEqual: "Above or equal",
    numberLessThan: "Below",
    numberLessThanOrEqual: "Below or equal",
    operators: {
      contains: "Contains",
      "not-contains": "Not contains",
      between: "between",
    },
    textContains: "Contains",
    textNotContains: "Not contains",
    textStartWith: "Starts with",
    textEndWith: "Ends with",
    multipleMatch: "Match",
    textExactly: "Exactly",
    value: "Value",
    filterBy: "Filter by",
    showing: "Showing",
    entries: "entries",
    campaignName: "Name",
    vatRegistered: "VAT Registered",
    vatDocument: "VAT Document",
    status: "Status",
    category: "Category",
    campaignType: "Campaign Type",
    proofOfPayment: "Proof Of Payment",
    coupons: "Coupons",
    links: "Links",
    commission: "Commission",
    oldData: "Old Data",
    newData: "New Data",
    updatedAt: "Updated At",
    campaignId: "Campaign ID",
    adSetId: "Adset ID",
    allocated: "Allocated",
    unallocated: "Unallocated",
    missingCouponsCount: "Missing",
    hasExperienceInCpsModel: "Has CPS Experience",
    applyFor: "Apply",
    edit: "Edit",
    sent: "Sent Immediately",
    viewPayouts: "Payouts",
    viewRevenues: "Revenues",
    verificationID: "Verification ID",
    isVatRegistered: "VAT Registered",
    viewExtrasAndDeductions: "Extras & Deductions",
    loading: "Loading...",
    code: "Coupon",
    syncId: "Sync id",
    bu: "Business Unit",
    teamLeader: "Team Leader",
    teamMember: "Account manager",
    partnerName: "Partner Name",
    partnerId: "Partner ID",
    ratableId: "Ratable ID",
    ratableType: "Ratable Type",
    fileUrl: "File URL",
    expiresAt: "Expires At",
    belongsTo: "Belongs To",
    adSetName: "Adset Name",
    zohoRequestId: "Zoho Request ID",
    performedByName: "Performed By (Name)",
    updatedBy: "Updated By",
    gapsConfirmed: "Gaps Confirmed",
    performedByEmail: "Performed By (Email)",
    startDate: "Start Date",
    endDate: "End Date",
    INF: "Influencer",
    AN: "Publisher",
    AALab: "AA Lab",
    MB: "Media Buyer",
    campaignDetails: "Summary",
    campaignApplications: "Applications",
    campaignPartners: "Partners",
    campaignTeamAccess: "Team",
    campaignCoupons: "Coupons",
    campaignRevenues: "Revenues",
    campaignPayouts: "Payouts",
    activityStatus: "Activity Status",
    campaignCreationYear: "Campaign Creation Year",
    creationYear: "Creation Year",
    allocationStatus: "Allocation Status",
    missingCoupons: "Missing",
    ignoredCoupons: "Ignored",
    campaignList: "Campaign List",
    uploadBanners: "Upload Banners",
    bannerHeight: "Banner Height",
    bannerMaxSize: "Banner Max Size",
    couponsAllocation: "Coupons Allocation",
    selected: "selected",
    CampaignBonusAndFines: "Bonus And Fines",
    inActive: "Inactive",
    partner: "Partner",
    notProvided: "Not provided",
    days: "Days",
    budget: "Budget",
    month: "Month",
    campaignManager: "Campaign Manager",
    phoneNumber: "Phone Number",
    campaignsList: "Campaigns List",
    performanceReport: "Performance",
    financialSummary: "Financial Summary",
    costSummary: "Cost Summary",
    campaignReport: "Campaigns analysis",
    INFAnalysis: "Influencer Analysis",
    AFFAnalysis: "Publisher Analysis",
    ANAnalysis: "Publisher Analysis",
    partnerReport: "Partner Analysis",
    dimensions: "Dimensions",
    metrics: "Metrics",
    partners: "Partners",
    teamLeaders: "Team leaders",
    teamMembers: "Account Managers",
    accountsManager: "Accounts Manager",
    campaigns: "Campaigns",
    revenue: "Revenue",
    payout: "Payout",
    AALabs: "AA Labs",
    level: "Level",
    addRevenue: "Add Revenue",
    addPayout: "Add Payout",
    editPayout: "Edit Payout",
    editRevenue: "Edit Revenue",
    salesAmountUsd: "Sales amount",
    salesAmount: "Sales amount",
    payoutCost: "Payout cost",
    fixedCost: "Fixed cost",
    MBCost: "MB cost",
    aovUsd: "AOV ",
    date: "Date",
    originalCurrency: "Original currency",
    country: "Country",
    at: "at",
    "12MonthsData": "12 Months Data",
    "6MonthsData": "6 Months Data",
    "3MonthsData": "3 Months Data",
    configured: "Configured",
    aov: "AOV",
    lastRequestedAt: "Last requested at",
    customerType: "Customer type",
    totalCostUsd: "Total cost ",
    current: "Current",
    announcement: "Announcement",
    system: "System",
    past: "Past",
    future: "Future",
    condition: "Condition",
    details: "Details",
    type: "Type",
    clone: "Clone",
    deleteRecord: "Delete",
    default: "Default",
    coupon: "Coupon",
    "payout-revenue": "Payouts and Revenues",
    deletePayout: "Delete Payout",
    deleteRevenue: "Delete Revenue",
    deleteExtraDeduction: "Delete Extra Deduction",
    conditions: "Conditions",
    typeOfPayment: "Type of payment",
    paymentType: "Type of payment",
    tips: "Tips",
    payoutType: "Payout Type",
    revenueType: "Revenue Type",
    payment: "Payment",
    fixed: "Fixed",
    perOrder: "Per-order",
    revShare: "Revenue share",
    createRecord: "Add {level} {type}",
    editRecord: "Edit {level} {type}",
    cloneRecord: "Clone {level} {type}",
    valueUSD: "Value USD",
    valuePercentage: "Value %",
    hasPayout: "has a payout",
    hasRevenue: "has a revenue",
    advertiserName: "Advertiser Name",
    revenueModal: "revenue",
    payoutModal: "payout",
    startsIn: "starts in",
    and: "and",
    endsIn: "ends in",
    parntersScope: "Filter By Partners",
    mine: "Mine",
    others: "Others",
    gender: "Gender",
    nbCampaigns: "Campaigns",
    nbCoupons: "Coupons",
    applicationDate: "Application Date",
    promoteCampaignAnswer: "Promotion",
    rejectionReason: "Reason of rejection",
    applicationStatus: "Application Status",

    notes: "Notes",
    personalInfo: "Personal Info",
    sendInvitaion: "Invite",
    loginAs: "Login As",
    goToChat: "Chat",
    sendSummary: "Summary",
    severity: "Severity",
    errorCodes: "Error Code",
    description: "Description",
    error: "High",
    startedWithArabyAds: "Since you started with Boostiny",
    ok: "Low",
    warning: "medium",
    grossConversion: "Gross Conversions",
    netConversion: "Net Conversions",
    grossRevenue: "Gross Revenues",
    netRevenue: "Net Revenues",
    cairo: "Cairo",
    integrationError: "Integration error",
    yesterdayOrders: "yesterday orders",
    yesterdaySalesAmount: "yesterday sales amount",
    last30DaysOrders: "last 30 days orders",
    last30DaysSalesAmount: "last 30 days sales amount",
    allOrders: "all orders",
    allSalesAmount: "all sales amount",
    myCoupons: "My Coupons",
    to: "to",
    of: "of",
    invite: "Invite",
    hi: "Hi",
    invitation: "Invite to join Boostiny",
    invitationInfluencer: "Invite influencer",
    invitationAffiliate: "Invite publisher",
    numberOfCoupons: "Coupons",
    numberOfCampaigns: "Campaigns",
    fileUpload: "File Upload",
    couponsInformation: "Coupons Information",
    browseCouponsFile: "Browse Coupons File",
    or: "Or",
    created: "created",
    createdAt: "Created at",
    invalid: "invalid",
    are: "are {category}",
    is: "is",
    deactivationNote: "Deactivation Note",
    deactivationReason: "Deactivation Reason",
    deactivationReasonId: "Deactivation Reason ID",
    willBe: "will be {category}",
    adSet: "Adset",
    searchToGetMoreData: "search to get more data",
    selectAll: "select all {field}",
    unselectAll: "unselect all {field}",
    advertiser: "Advertiser",
    advertiserId: "Advertiser",
    websiteURLEn: "Website English URL",
    websiteURLAr: "Website Arabic URL",
    creativesURLEn: "Creatives English URL",
    creativesURLAr: "Creatives Arabic URL",
    allocationHistory: "Allocation Life Cycle",
    changeBu: "Change BU",
    changeTeamMember: "Change Team Member",
    changeTeamLeader: "Change Team Leader",
    couponCreated: "Coupon is Created",
    changePartner: "Change Partner",
    actionDate: "Action Date",
    allocationDate: "Allocation Date",
    deleteAllocation: "Delete Allocation",
    passwordConfirmation: "Password Confirmation",
    public: "Public",
    publicWithApproval: "Needs Admin approval",
    inactive: "Inactive",
    category_id: "Category",
    campaign_type: "Campaign Type",
    advertiser_id: "Advertiser",
    private: "Private",
    office: "Office",
    campaignDetailsReport: "Report",
    campaignValidation: "Campaign",
    partnersValidation: "Partners",
    validationStatus: "Validation status",

    selectCycle: "Select cycle",
    deleteExtra: "Delete extra",
    deleteDeduction: "Delete deduction",

    socialAccounts: "Social Accounts",
    bankInfo: "Bank Info",

    english: "English",
    arabic: "Arabic",

    extra: "Extra",
    extras: "Extras",
    deduction: "Deduction",
    maxCancellationRatio: "Max cancellation ratio",

    notValidated: "Not validated",
    managementApproved: "Management approved",
    validationNotProvided: "Validation not provided",
    paid: "Paid",
    partnerPayment: "Partner payment",
    validations: "Validations",
    invalidCell: "Invalid cell",
    validTypes: "The valid cell types are",

    firstName: "First Name",
    lastName: "Last Name",
    countryOfResidence: "Country of residence",
    nationality: "Nationality",
    verificationCode: "Verification code",
    whatsapp: "Whatsapp Number",
    boostinyPasswordRecoveryMessage: "Enter the email you're using for Boostiny",
    revgatePasswordRecoveryMessage: "Enter the email you're using for Revgate",
    administrator: "Administrator",
    head: "Head",
    bI: "BI",
    powerUser: "Power user",

    promotionDescriptionEn: "Promotion Description English",
    promotionDescriptionAr: "Promotion Description Arabic",
    paymentUpdateValidation: "Payment Update",

    browseCsvFiles: "Browse .csv files...",
    runValidation: "Run Validation",
    update: "Update",
    updated: "Updated",

    allocatedCoupons: "Allocated",
    unallocatedCoupons: "Unallocated",
    inactiveApplications: "Inactive",
    team: "Team",
    buIds: "Business unit",
    goToOldVersion: "Go to old version",
    notProvidedCampaign: "Not provided Campaign",

    comments: "Comments",
    paymentMethod: "Payment method",
    bankTransfer: "Bank Transfer",
    city: "City",
    branch: "Branch",
    iban: "IBAN",
    swift: "Swift",
    paypal: "PayPal",
    mobileCash: "Mobile Cash",
    bankName: "Bank Name",
    accountHolderName: "Account Holder Name",
    mobileCashProvider: "Mobile Cash",
    mobileCashId: "Mobile Cash ID",
    mobileCashID: "Mobile Cash ID",
    publisherType: "Publisher Type",
    publisherLevel: "Publisher Level",
    PUBLIC_VISIBLE_WITH_MANUAL_APPROVAL: "Needs Admin Approval",
    PUBLIC_VISIBLE: "Public",
    PRIVATE: "Private",
    profileType: "Profile Type",
    influencerType: "Influencer Type",
    categories: "Categories",
    rateCardFile: "Attach your Rate card",
    rateDetails: "Mention your Rate details",
    verificationIdFile: "Verification ID",
    reference: "ArabyAds Reference",
    workWithAgent: "Do you work with an agent",
    agentName: "Your Agent name",
    agentContact: "Your Agent contact",
    termsAndConditions: "I agree to the Terms & Conditions",
    yes: "Yes",
    no: "No",
    workWithAgentQuestion: "Do you work with an agent?",
    addComment: "Add Comment",
    noCommentsForPartner: "There are no comments for this partner",
    advertiserSentLink: "Advertiser sent link",
    URLName: "URL Name",
    female: "Female",
    male: "Male",
    facebook: "Facebook",
    twitter: "Twitter",
    tiktok: "Tiktok",
    instagram: "instagram",
    youtube: "Youtube",
    telegram: "Telegram",
    na: "prefer not to say",
    accountType: "Account Type",
    facebookUserName: "facebook",
    instagramUserName: "instagram",
    twitterUserName: "twitter",
    tikTokUserName: "tikTok",
    snapchatUserName: "snapchat",
    telegramUserName: "telegram",
    youtubeChannel: "youtube",
    whatsappNumber: "Whatssapp",
    website: "Website",
    websiteLinkMessage: "website link. Ex. https://www.example.com",
    verificationId: "Verification Id",
    signedContract: "Signed Contract",
    rateCard: "Rate Card",
    adress: "Adress",
    companyName: "Company Name",
    websiteLink: "Website Link",
    hasCpsExperience: "Has exprience CPS",
    affiliateNetworks: "Publisher networks you work with",
    skype: "skype",
    paymentModel: "Payment Model",
    bankAccountHolderName: "Holder Name",

    cpsExperienceQuestion: "Do you have experience in CPS Coupon model?",
    yearsAsAffiliate: "Years of experience as publisher",
    affiliateNetwork: "Other Publisher Networks you work with",

    selectWebsite: "Select the website category",
    selectMobile: "Select Mobile App source",
    selectSocial: "Instgram, Facebook, and 7 more…",
    selectMediaBuying: "Select media buying traffic source",
    selectTrafficSource: "Select traffic source",

    vodafonecash: "Vodafon cash",
    wecash: "We Cash",
    orangecash: "Orange cash",
    etisalatcash: "Etisalate cash",
    alahlyphonecash: "Al Ahly Phone Cash (NBE)",

    VODAFONECASH: "Vodafone Cash",
    ETISALATCASH: "Etisalat Cash",
    WECASH: "We Cash",
    ORANGECASH: "Orange cash",
    ALAHLYPHONECASH: "Al Ahly Phone Cash (NBE)",

    influencer: "Influencer",
    agency: "Agency",
    page: "Page",
    publisher: "Publisher",
    action: "Action",
    pleaseSelectReason: "Please select the reason for rejection",
    rejectionReasonsPlaceholder: "Choose rejection reason",
    rejectionReasonTitle: "Rejection Reason",
    snapchat: "Snapchat",
    campaignReadyPartners: "Campaign ready",
    registeredPartners: "Registered",
    registeredAt: "Registered at",
    approve: "Approve",
    reject: "Reject",
    skypeAccount: "Skype",

    arabyadsReference: "Arabyads Reference",
    rate: "Rate",
    partnerType: "Partner Type",

    addAppName: "Add App Name",
    selectAppCategory: "Please select the app category",
    cashback: "Cashback",
    priceComparison: "Price Comparison",
    content: "Content",
    contentEn: "Content (En)",
    contentAr: "Content (Ar)",
    search: "Search",
    social: "Social",
    native: "Native",
    display: "Display",
    video: "Video",
    tikTok: "TikTok",
    pinterest: "Pinterest",
    youTube: "YouTube",
    linkedIn: "LinkedIn",
    linkedin: "LinkedIn",
    whatsApp: "WhatsApp",
    selectMobileAppSource: "Select mobile app source",
    websiteBoxTitle: "Select website category",
    selectMediaBuyingTrafficSource: "Select media buying traffic source",
    socialMediaTitle: "Select Instagram, Facebook, and more…",
    emailTitle: "Select Email traffic source",
    address: "Address",
    attachment: "Attachment",
    yearsOfAffiliateExperience: "Years of publisher experience",
    otherAffiliateNetworks: "Other publisher networks",
    URL: "URL",
    Agency: "Agency",
    Page: "Page",
    Publisher: "Publisher",
    Influencer: "Influencer",
    bankCity: "City",
    bankBranch: "Branch",
    bankHolderName: "Holder Name",
    "fixed&RevShare": "Fixed & RevShare",
    Fixed: "Fixed",
    RevShare: "Revenue share",
    "Fixed&RevShare": "Fixed and Revenue share",
    revenueUsd: "Commission",
    influenceType: "Influencer type",
    trafficSource: "Traffic Source",
    mobile_app: "Mobile app",
    selectedMacros: "Selected Macros",
    media_buying: "Media buying",
    channels: "Channels",
    appName: "App name",

    basicInfo: "Basic Info",
    socialMediaInfo: "Social Media info",
    paymentDetails: "Payment details",
    preferences: "Preferences",
    trafficSources: "Traffic Sources",

    managementUnit: "Partner Management Unit",

    assetOwners: "Asset owners",
    network: "Network",
    socialAssets: "Social assets",
    loyaltyPrograms: "Boost & Earn",
    organicActivities: "Organic activities",
    mediaBuying: "Media buying",
    countrySet: "Country set",
    offer: "offer",
    nano: "Nano",
    micro: "Micro",
    macro: "Macro",
    mega: "Mega",
    Nano: "Nano",
    Micro: "Micro",
    Macro: "Macro",
    Mega: "Mega",
    has: "Has",
    emailDataCollectionSource: "Email data collection source",
    individual: "Individual",
    yearsOfExperience: "Years of experience",
    mobileApp: "Mobile App",
    andOthers: "and others",
    affiliateYearsOfExperience: "Publisher years of experience",
    seeAll: "See All",
    addWebsiteName: "Add website name",
    shortDescriptionForInfluencer: "You have built your own following on social platforms and able to influence follower’s choices.",
    shortDescriptionForPublisher: `You own a website or an app or runs a social page or does digital marketing
     and can promote products or services to bring customers in.`,

    // download app labels

    getApp: "Get App",
    downloadApp: "Download {appName} App",
    downloadAppBtn: "Download App",
    continueInBrowser: "Continue in browser",
    downloadAppMessage: "Scan code to download the {appName} App",

    // notifications
    allNotifications: "All Notifications",
    couponRequests: "Coupon Requests",
    couponRequestsCount: "Coupon Requests",

    requestCouponTitle: "Request Coupons",
    howManyCouponsYouWant: "How many coupons do you want?",
    notifSettingsDescription: "Turn notifications on or off based on your own preferences",
    couponCodeAllocation: "Coupon code allocation",
    couponCodeExpiration: "Coupon code expiration",
    couponRequestRejection: "Coupon request rejection",
    campaignAllocationRejection: "Campaign application rejection",
    campaignStatusUpdate: "Campaign status update (paused/stopped/resumed)",
    campaignApplicationApproval: "Campaign application approval",

    versionHistory: "Version History",
    whatsNew: "What's New ?",
    boostinyWebVersion: "Boostiny Web Version",
    revgateWebVersion: "Revgate Web Version",

    approvalConfirmation: "Approval Confirmation",

    only: "only",
    allocatedCouponsLabel: "Allocated Coupons",
    numRequestCoupons: "Num. of Requested Coupons",

    // versions
    releaseDate: "Release Date",
    features: "Features",
    enhancements: "Enhancements",
    bugFixes: "Bug Fixes",
    more: "more",
    less: "less",
    gross: "Gross Value",
    net: "Net Value",
    cycleClosed: "Cycle closed",

    createdBy: "Added by",

    nonePerformingCode: "Non - Performing Codes",
    whatIsNew: "What's New",
    latestVersionMessage: "We have updated you  to the latest version <b>of {projectName}</b>",
    payoutTypes: "Payout types",
    check: "Check",
    lastPayment: "Last Payment",
    inProgress: "In progress",
    upcomingPayment: "Upcoming Payment",
    // announcements

    announcementsType: "Announcements Type",
    promotions: "Promotions",
    insights: "Insights",
    updates: "Updates",
    both: "Both",
    subject: "Subject",
    subjectEn: "Subject (En)",
    subjectAr: "Subject (العربية)",
    announcementLinkEn: "Content (Announcement Link) (En)",
    announcementLinkAr: "Content (Announcement Link) (Ar)",
    allPartners: "All Partners",
    partnersExcept: "Partners except",
    specificPartners: "Specific partners",
    thereIsNoAnnouncements: "There is no announcements",
    sendAt: "Send At",
    sentAt: "Sent At",
    actions: "Actions",

    validatedDate: "Validated till {date}",
    tillToday: "Till today",
    validatedTill: "Validated from March 2021 till",
    compareDates: `Comparing <span class="text-secondary-500 p-base-bold">last {comparingDate} days</span> to
    <span class="text-secondary-500 p-base-bold">{from} -{to}</span>`,
    fromTwoDatePeriod: `<p class='p-small-medium test-neutral-600'> <span class='text-neutral-400'>
    From </span> {from} <span  class='text-neutral-400'>to</span> {to} </p>`,
    adset: "Adset",
    allCountries: "All Countries",
    countries: "Countries",
    comparing: "Comparing",
    revenueTypes: "Revenue Types",
    "revenue-payout": "Payout & Revenue",
    expiringSoon: "Expiring soon",
    expiresIn: "Expires in",
    expired: "Expired",
    newStartDate: "New Start Date",
    newEndDate: "New End Date",
    newValue: "New Value",
    newMaxValue: "New Max Value",
    paymentCycles: "Payment Cycles",
    chooseCountries: "Choose Countries",
    specificCountries: "Specific Countries",
    dateTime: "Date&Time",
    closedBy: "Closed by",
    noEntityAdded: "No legal entity added",
    allOther: "All Other",
    addNewValueForPayouts: "Add new value for this missing payout period",
    addNewValueForRevenues: "Add new value for this missing revenue period",
    validationComment: "Validation Comment",
    paymentCycle: "Payment Cycle",
    arabyadsFZ: "Arabyads FZ",
    arabyadsEgypt: "Arabyads Egypt",
    "arabyadsM&K": "Arabyads M&K",
    chooseEntities: "Choose entities for campaign",
    viewLess: "View Less",
    otherCountries: "Other Countries",
    profile: "Profile",
    uploadPartnersFile: "Upload File",
    searchPartners: "Search partners",
    member: "Member",
    notMember: "Not member",
    campaignsSummary: "Summary",
    bus: "Business Units",
    team_leaders: "Team Leaders",
    team_members: "Account managers",
    "not-Validated": "not validated",
    "financial-pending": "Financial pending",
    "not-provided": "Not provided",
    "management-approved": "CEO approved",
    expiresToday: "Expires today",
    campaignCode: "Code",
    logo: "Campaign Image",
    salesManagerContact: "Sales Manager Contact",
    estimatedCancellationRate: "Estimated Cancellation Rate %",
    partnerPayout: "Partner payout",
    globalPayout: "Global Payout",
    roles: "Roles",
    accountNumber: "Account Number",
    currency: "Currency",
    influencerPaymentLimit: "Influencer Payment Limit",
    publisherPaymentLimit: "Publisher Payment Limit",
    yet_to_share: "Yet To Share",
    signed_sealed: "Signed & Sealed",
    signed: "Signed",
    viewed: "Viewed",
    agreementEsign: "Agreement E-sign",
    initiated: "Initiated",
    declined: "Declined",
    approved_no_agreement: "Approved with no Agreement",
    agreementAvailable: "Agreement Available",
    cancellationAgreement: "Cancellation agreement signing",
    confirmUpload: "Confirm Upload File",
    confirmUploadMessage: "Are you sure you want to upload new file",
    payoutSupportingText: "A percentage of the overall revenue",
    changeTrafficSource: "Change Traffic Source",
    agreementUnavailable: "Agreement Unavailable",
    agreementUploaded: "Agreement Uploaded",
    salesManager: "Sales Manager",
    validationDays: "Validation Days",
    finance: "Finance",

    // finance renaming
    paidToBeCollected: "Paid to be collected",
    debitAndCredit: "Debit note & Credit note",
    debit: "Debit",
    credit: "Credit",
    debitNote: "Debit Note",
    creditNote: "Credit  Note",
    recordType: "Type",
    send: "Send",

    role: "Role",
    userPMU: "Partner Management Unit",
    dubai: "Dubai",
    jordan: "Jordan",
    amman: "Amman",
    bi: "BI",
    admin: "Administrator",
    "team-member": "Account Manager",
    "team-leader": "Team Leader",
    "sales-manager": "Sales Manager",
    "power-user": "Power User",
    lastUpdated: "Last Updated",
    dailyPerformanceSummary: "Daily Performance Summary",
    joined: "Joined",
    an: "Publisher",
    inf: "Influencer",
    dateRange: "Date Range",
    chooseDate: "Choose Date",
    sendInvitation: "Invite",
    approvedAt: "Approved at",
    cost: "Cost",
    requested: "Requested",

    confirm: "Confirm",

    nameDetails: "Name/ID/Email/Phone",
    partnersNameDetails: "Partners Name/ID/Email",
    boostiny: "Boostiny",
    rateCount: `<p class="p-small-medium text-neutral-400 m-l-6">
    {rateCount} Ratings
  </p>`,
    rateNumber: `<p  class="p-base-medium text-neutral-400 m-l-9">
    {stars} out of 5
  </p>`,

    // dashboard
    performing: "Performing",
    nonPerforming: "Non-Performing",
    rating: "ratings",
    validated: "Validated",
    canceled: "Cancelled",
    validationCycle: "Validation cycle",
    cancellationRate: "Cancellation rate",
    approvalRate: "Approval rate",
    "not-validated": "Yet to approve",

    advertisers: "Advertisers",
    confirmation: "Confirmation",
    myConversions: "My Conversions",

    performanceReportTest: "Performance Testing",
    paypalAccount: "Paypal account",
    accountName: "Account name",
    showProfileWithUpdates: "Show profile with updates",
    from: "from",
    present: "Present",
    on: "on",
    miscellaneous: "Miscellaneous",
    addClientContact: "Add New Client Contact",
    clientContactEmail: "Client Contact Email",
    clientContactName: "Client Contact Name",
    chooseCampaign: "Choose your Campaign",
    suspend: "Suspend",
    reasonForSuspend: "Reason For Suspend",
    intimatePartnerViaMail: "Intimate {partnerName} via mail",
    revertSuspension: "Revert Suspension",
    suspended: "Suspended",
    deleted: "Deleted",
    online: "Online",
    sorryYourAccountIsCurrentlySuspended: "Sorry,Your account is currently suspended!",
    maxValueUSD: "Max Value (USD)",
    maxValue: "Max Value",
    haveNetValues: "Have Net Values",
    clientContact: "Client Contact",
    clientContacts: "Client Contacts",
    pointToContact: "Point To Contact",
    chooseTheClientContact: "Choose the Client contact",
    newClientContact: "New Client Contact",
    mbCost: "MB Cost",
    "<< Unallocated - MB >>": "Unallocated",
    google: "Google",
    aaAssociate: "AA Associate",
    notAaAssociateMember: "Not AA Associate member",
    aaAssociatedMember: "AA Associated member",
    reasonForSuspension: "Reason for suspension",
    infCampaignManagerId: "INF Campaign Manager",
    anCampaignManagerId: "AN Campaign Manager",
    alreadyApplied: "Already Applied",
    campaignCommissions: "Commissions",
    commissionsType: "Commissions Type",
    revenuesType: "Revenues Type",
    viewMore: "View more",
    hometown: "City",
    match: "Match",
    salesManagers: "Sales Managers",
    apiConfigurations: "API Configurations",
    generatesApiAccessMsg: "This API key generates access to your campaigns, coupons and reports",
    learnMoreAbout: "Learn more about",
    boostinyApiDocumentation: "Boostiny API documentation",
    generateYourApiNow: "Generate your API now!",
    revokeApi: "Revoke API",
    copy: "Copy",
    activeCampaignCouponEndpointUrl: "Active campaigns & coupons endpoint URL",
    activeCampaignEndpointUrl: "Active campaigns endpoint URL",
    activeCouponEndpointUrl: "Active coupons endpoint URL",
    reportEndpointUrl: "Reports endpoint URL",
    linkReportEndpointUrl: "Link reports endpoint URL",
    apiDetails: "API details",
    apiKey: "API Key",
    revokeMsg: "Revoke API will remove API access!",
    sendMessage: "Send Message",
    deleteUser: "Delete user",
    deletedUsers: "Deleted users",
    deletedBy: "Deleted By",
    deletedAt: "Deleted At",

    dateOfRequest: "Date of request",
    id: "ID",
    archivedCoupons: "Archived Coupons",
    archivedAt: "Archived At",
    archivedBy: "Archived By",
    archive: "Archive",
    archived: "Archived",
    allocate: "Allocate",
    ignore: "Ignore",
    add: "Add",
    updateAdset: "Update Adset",
    archiveCoupon: "Archived Coupons",
    updateCountry: "Update Country",
    selectCountry: "Select Country",
    selectUnselectAll: "Select / Un-Select All {field}",
    couponType: "Coupon Type",
    validationSheet: "Validation Sheet",
    downloadSampleFile: "Sample File",
    downloadSampleFileCategoryLevel: "Sample File Category Level",
    downloadListsOfReference: "Download lists of reference",
    file: "File",
    deleteSheet: "Delete Sheet",
    uploaded: "Uploaded",
    pleaseFixThisIssueInTheFileAndUploadItAgain: "Please fix this issue in the file and upload it again",
    uploadValidationSheet: "Upload Validation Sheet",
    beforeValidationValue: "Before validation value",
    afterValidationValue: "After validation value",
    productStats: "Product Stats",
    promotionName: "Promotion Name",
    totalCampaigns: "Total Campaigns",
    campaignApplicationsStats: "Campaign Applications",
    totalCampaignApplications: "Total Campaign Applications",
    couponsRequests: "Coupons Requests",
    totalCouponsRequests: "Total Coupons Requests",
    announcements: "Announcements",
    campaignPromotions: "Promotions",
    customActions: "Actions",
    offerTypes: "Offer Types",
    createNewPromotion: "Create New Promotion",
    promotionInfo: "Promotion Info",
    noData: "No data",
    uploadCreatives: "Upload Creatives",
    uploadCreative: "Upload Creative",
    changeCreative: "Change Creative",
    noPromotions: "No Promotions",
    uploadMore: "Upload More",
    imagesMaxSize: "Images Max Size",
    videosMaxSize: "Videos Max Size",
    supportedFormats: "Supported Formats",
    promotionDetails: "Promotion Details",
    addNewPromotion: "Add New Promotion",
    categoriesList: "Categories",
    addCountry: "Add Country",
    addCategory: "Add Category",
    categorySet: "Category Set",
    specific: "Specific",
    allProductsOrCategories: "All Products/Categories",
    specificProductsOrCategories: "Specific Products/Categories",
    flat: "Flat",
    upTo: "Up To",
    range: "Range",
    addOffer: "Add Offer",
    promotion: "Promotion",
    noDataToDisplay: "No data to display :(",
    deletePromotion: "Delete Promotion",
    deleteAccount: "Delete Account",
    reason: "Reason",
    optional: "Optional",
    required: "Required",
    otp: "OTP",
    accountDeletionPending: "Account Deletion Pending",
    yourAccountIsUnderDeletionProcess: "your account is currently undergoing a deletion process!",
    requestedForAccountDeletion: "Requested for Account deletion",
    deleteRequest: "Delete Request",
    thisAccoutIsRequestingForDeletion: "This accout is requesting for deletion",
    didntGetCode: "Didn't get a code?",
    unableToResendFor30Seconds: "Unable to resend new otp For 30 seconds",
    deleteMyAccount: "Delete my account",
    editPromotion: "Edit Promotion",
    uploadedOffline: "Uploaded Offline",
    phoneNumberCountryCode: "Phone Number Country Code",
    viewProfile: "View Profile",
    resetPassword: "Reset Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    newPasswordConfirmation: "Confirm New Password",
    passwords: "Passwords",
    validation: "Validation",

    viewLinks: "View Links",
    deactivatePartner: "Deactivate Partner",
    chooseDateOfRevokement: "Choose Date of Revokement",
    chooseReasonOfRevokement: "Choose Reason of Revokement",
    selectedPartners: "selected partners",
    otherReason: "Other Reason",
    entity: "Entity",
    entityId: "Entity Id",
    entities: "Entities",
    users: "Users",
    userId: "User Id",
    logUserName: "User Name",
    batch: "Batch",
    logDetails: "Log Details",
    upload: "Upload",
    download: "Download",
    showDetails: "Show details",
    currenciesReference: "Currencies Reference",
    countriesReference: "Countries Reference",
    iconnect: "iConnect",
    iConnect: "iConnect",
    conversion: "Conversion",
    capPoints: "Cap points",

    shareReferral: "Share your referral code & earn.",
    sendCode: "Send Code",
    register: "They Register",
    earn: "You Earn",
    referralsMakeConversation: "When your referrals make their first conversion",
    referralsNumber: '<p class="text-primary-500 p-base-medium  m-r-6">{referralNumber} Referrals</p>',
    redeemPoints: "Points",
    registred: "Registered",
    referalPrize: "Referal prize",
    registredReason: "Publisher hasn’t yet made his first conversion",
    rejectedReason: "Publisher rejected for not being compatible with boostiny publisher program",
    getPrize: `<p class="text-info-700">
    You will get $<span class="p-base-bold">{reward}</span> when the referred publisher makes
    his first conversion, please note that referral Conversions could take up to 24 hours to reflect on the system
    </p>
    `,
    gettingStarted: "getting started",
    partnerCode: "Partner Code",
    referred: "Referred",
    publishersPerformance: "Publishers Performance",
    redeemHistory: "Redeem History",
    prize: "Prize",
    rewardType: "Reward Type",
    physicalReward: "Physical Reward",
    extraPayment: "Extra Payment",
    delivered: "delivered",
    redeemed: "redeemed",
    havePrize: "Have Prize",
    none: "None",
    redeem: "Redeem",
    points: "Points",
    currentPrize: "Current Prize",
    redeemConfirmation: "Redeem Confirmation",
    conversionReward: "<p class='p-small-medium'>{conversionFactor} conversion = {rewardPoints} points </p>",
    remainingDays: "days remaining",
    redeemedPoints: "Redeemed points",
    loyaltyProgramDesc: "More conversions, more points, and more prizes",
    collectPoints: "Earn Points",
    collectPointsDesc: "Earn points through making conversions on campaigns,",
    upgradeTiers: "Upgrade Tiers",
    upgradeTiersDesc: "With more points comes higher tiers with bigger rewards",
    winPrizes: "Earn Reward",
    winPrizesDesc: "Get rewarded with extra money & gifts",
    reached: "Reached",
    remainingPoints: "Remaining points",
    nextTier: "Next tier",
    1: "st",
    2: "nd",
    3: "rd",
    th: "th",
    whatsappNumberCountryCode: "whatsapp number country code",
    accessedAt: "Accessed At",
    accessedBy: "Accessed By",
    provideCampaign: "Provide Campaign access to",
    partnerNameOrId: "Partner Name or Id",

    trackingLink: "Tracking Link",
    originalTrackingLink: "Original Tracking Link",
    longLink: "Long link",
    customizedTrackingLinks: "Customized Tracking Links",
    link: "Link",
    publisherSource: "Publisher Source",
    publisherClickId: "Publisher Click ID",
    postback: "Server Postback",
    pixel: "Pixel Tracking",
    attributionWindow: "Attribution Window",
    cookieLifeTime: "Cookie Life Time",
    offerUrl: "Offer Url",
    boostinyMacrosDescription: "Boostiny macros description",
    boostinyMacro: "Boostiny Macros",
    selectMacro: "Select Macro",
    macros: "Macros",
    campaignModel: "Campaign Type",
    clicks: "Clicks",
    urlLog: "URL log",
    hasUpdatedUrl: "has updated the URL.",
    groups: "Groups",
    group: "Group",
    perSalesAmount: "Per Sales Amount",
    commissionType: "Commission Type",
    addDimension: "Add Dimension",
    "customer-type": "User",
    "less-than": "Less than",
    "greater-than": "Greater than",
    new: "New",
    returning: "Returning",
    contains: "Contains",
    not: "Not",
    equals: "Equals",
    user: "User",
    localCurrency: "Local Currency",
    commissionGroups: "Commission Groups",
    viewDetails: "View Details",
    moreGroup: "More Group | More Groups",
    model: "Model",
    cap: "Cap",
    usd: "usd ($)",
    AOV: "AOV",
    in: "In",
    contain: "Contain",
    between: "Between",
    "not-equals": "Not Equals",
    "not-contains": "Not Contains",
    cps: "CPS",
    cpc: "CPC",

    loyaltyProgram: "Boost & Earn",
    termsConditions: "Terms and conditions",
    totalReferralsNumber: "Referrals",
    registered: "Registered",
    referralPrizeReceived: "You have received your prize check the notifications",
    "AA-LABS": "AA LABS",
    "aa-labs": "AA LABS",
    searchMessages: "Search Messages",
    assignBu: "Business Unit",
    assignTeamLeader: "Team Leader",
    assignTeamMember: "Team Member",
    assignPartner: "Partner",
    assignTrafficSource: "Traffic Source",
    wefoundA: "We found a",
    missingPeriod: "Missing Period",
    restOfmissingMessage: "that has no revenue or payout. This time period will be missing if you click Continue.",
    asANewCategory: "as a new category",
    pleaseEnterValue: "Please Enter Value",

    // black friday
    yesterdayPerformance: "Yesterday’s Performance",
    monthToDatePerformance: "Month-to-Date Performance",
    hurryUp: "Hurry up",
    makeMoreConversions: "make more conversions",
    tipDate: "Tip Date",
    titleEn: "Title (EN)",
    titleAr: "Title (AR)",
    descriptionEn: "Description (EN)",
    descriptionAr: "Description (AR)",

    TLTMName: "TL/TM Name",
    TMName: "TM Name",
    updatedLink: "Updated Link",
    selectMacros: "Select Macros",
    reset: "Reset",
    addAsMacro: "Add as macro",
    notMacro: "Not a macro",
    sub1: "Sub ID 1",
    sub2: "Sub ID 2",
    sub3: "Sub ID 3",
    sub4: "Sub ID 4",
    sub5: "Sub ID 5",
    customizedLinksHistory: "Customized Links history will show here",
    updatedTrackingLink: "Updated tracking link",
    userSource: "User Source",
    clickToCopy: "Click To Copy!",
    copied: "Copied!",
    clickAnalysis: "Click analysis",
    clicksVsConversions: "Clicks vs. Conversions history",
    clicksCount: "Clicks",
    mbTeam: "MB Team",
    clickId: "Click ID",
    userName: "Partners/MB Team",
    partnerAndTeam: "Partners/MB Team",
    linkStatsMsg: "Link stats over the selected time.",
    clickConversionRate: "CCR",
    earningPerClick: "EPC",
    conversionLinkDocument: "Conversion link document",
    clickIds: "Click IDs",
    NA: "NA",
    selectAnotherCampaignType: "another campaign type",
    noConditions: "No conditions",
    activeCoupons: "Active Coupons",
    undoValidation: "Undo Validation",
    customizeLink: "Customize Link",
    title: "Title",
    url: "URL",
    deepLink: "Deep Link",
    linksList: "Links List",
    defaultDeepLinkURL: "Default Deeplink URL",
    en: "English",
    ar: "Arabic",
    agencyName: "Agency Name",
    websiteURL: "Website URL",
    backofficeUsers: "Backoffice Users",
    agencyUsers: "Agency Users",
    "agency-campaign-manager": "Campaign Manager",
    "agency-account-manager": "Account Manager",
    "agency-admin": "Agency Admin",
    lastYearRevenue: "Last year revenue USD",
    ready: "Ready",
    agencyAdmin: "Admin",
    approvalData: "Approval Info",
    year: "Year",
    quarter: "Quarter",
    youCanSelect: "You can select",
    q1JanMar: "Q1 Jan-Mar",
    q2AprJun: "Q2 Apr-Jun",
    q3JulSep: "Q3 Jul-Sep",
    q4OctDec: "Q4 Oct-Dec",
    boostAndEarnStayTuned: "Boost & Earn will return again! Stay Tuned for more rewards soon!",
    week: "Week",
    day: "Day",
    campaignSource: "Campaign Source",
    backoffice: "Backoffice",
    campaign_source: "Campaign Source",
    businessUnit: "Business Unit",
    source: "Source",
    teamLeaderId: "Team Leader ID",
    teamMemberId: "Team Member ID",
    campaignCouponId: "Campaign Coupon ID",
    nonPerformingCoupons: "Non Performing Coupons",
    requestCouponsSuccessMessage: "Coupons request sent successfully",
    accountManager: "Account Manager",
    campaignManger: "Campaign Manger",
    agencyCampaignManager: "Campaign Manager",
    agencyAccountManager: "Account Manager",
    assignCampaignManager: "Campaign Manager",
    assignAccountManager: "Account Manager",
    changeCampaignManager: "Change Campaign Manager",
    changeAccountManager: "Change Account Manager",
    publisherSupport: "Publisher Support",
    APIConfiguration: "API Configuration",
    accountSettings: "Account Settings",
    generateAPIkey: "Generate API key",
    deleteAgreement: "Delete Agreement",
    revgate: "Revgate",
    restore: "Restore",
    capValue: "CAP value",
    currentCap: "Current CAP",
    futureCap: "Future CAP",
    showCapLog: "Show CAP log",
    capHistory: "CAP history",
    systemCurrency: "System Currency",
    postBackTracking: "Postback Tracking",
    requestsError: "Requests Error",
    targetedCountries: "Targeted Countries",
    allowedCountries: "Allowed Countries",
    blockedCountries: "Blocked Countries",
    back: "Back",
    brands: "Brands",
    brand: "Brand",
    brandId: "Brand",
    addNewURL: "Add New URL",
    editURL: "Edit URL",
    requiredMacro: "Required Macro",
    optionalMacro: "Optional Macro",
    allCampaigns: "All Campaigns",
    sea: "SEA",
    datePeriod: "Date Period",
    inSmall: "in",
    capType: "Cap type",
    newCap: "New Cap",
    heads: "Heads",
    reportsTo: "Reports To",
    "customer-returning-days": "User Returning Days",
    "action-type": "Action Type",
    myNumbers: "My numbers",
    maximum: "Maximum",
    paymentValidation: "Payment Validation",
    resolved: "Resolved",
    resolve: "Resolve",
    unResolve: "Un Resolve",
    delete: "Delete",
    vatUnregistered: "Vat Unregistered",
    close: "Close",
    "bank-transfer": "Bank Transfer",
    "mobile-cash": "Mobile Cash",
    cashId: "Cash Id",
    cash: "Cash",
    notAvailable: "Not Available",
    pendingVatUpload: "Pending VAT invoice",
    accepted: "Accepted",
    invoice: "Invoice",
    validationPending: "Validation Pending",
    financeApproved: "Finance Approved",
    CEOApproved: "CEO Approved",
    estimated: "Estimated",
    partnerStatus: "Partner Status",
    paymentStatus: "Payment Status",
    vatStatus: "Vat Status",
    bouncedInvoices: "Bounced Invoices",
    unresolvedComments: "Unresolved Comments",
    viewLog: "View activity log",
    invoiceIssueDate: "Issue date",
    delay: "Delay",
    validationApproved: "Validation Approved",
    delayAndHide: "Delay and hide",
    approveAsFinance: "Approve as finance",
    approveAsManagement: "Approve as CEO",
    viewRejectionReason: "View rejection reason",
    "delayed-and-hidden": "approve & delay",
    "finance-approved": "finance approved",
    ApproveAndDelay: "Approve & Delay",

    day_s: "day(s)",
    left: "left",
    partnerGlobalPayouts: "Partner Global Payouts",
    partnerCommission: "Partner Commission",
    appliedOn: "Applied on",
    percentageOfBoostinyCommission: "Percentage of Boostiny Commission",

    invoiceId: "Invoice ID",
    invoiceDate: "Invoice Date",
    amount: "Amount",
    pendingVatInvoice: "Pending VAT Invoice",
    pendingAcceptance: "Pending Acceptance",
    pendingInvoice: "Pending Invoice",
    underReview: "Under Review",
    processing: "Processing",
    bounced: "Bounced",

    sendAnnouncement: "Send the announcement right now",
    chooseAnnouncementDate: "Choose date & time for your announcement to be published",
    chooseDateAndTime: "Choose Date and Time",
    cairoTimeZone: "(Cairo time zone)",
    selectDateAndTime: "Select Date and Time",
    cairoZoneEquivalentTo: "Current time zone in Cairo is equivalent to",
    uae: "UAE",
    sau: "SAU",
    acceptInvoice: "Accept Invoice",
    rejectInvoice: "Reject Invoice",

    clickToUpload: "Click to upload",
    orDragAndDrop: "or drag and drop",
    pdfFilesOnly: "PDF files only",

    myInvoices: "My invoices",

    vatInvoice: "VAT Invoice",
    downloadInvoice: "Download Invoice",
    downloadProofOfPayment: "Download Proof Of Payment",

    reasonOfDelay: "Reason of delay",
    rejectedByTheBank: "Rejected by the bank",

    delayToNextMonthPayment: "Delay to next month payment",
    requestReview: "Request Review",
    contactSupport: "Contact Support",
    other: "Other",

    systemNotifications: "System Notifications",
    savedFilters: "Saved Filters",
    saveFilters: "Save Filters",
    addFilterName: "Add name to filter",
    saveDateFilter: "Save date period",
    "sales-amount-usd": "Sales amount USD",
    fileSize: "File size",
    imageFileSize: "Image file size",
    gifFileSize: "GIF file size",

    campaignsScreen: "Campaigns Screen",
    campaignsScreenDimensions: "Dimensions 1256px * 280px",
    overviewScreenDimensions: "Dimensions 408px * 225px",
    uploadEnCreative: "Upload En Creative",
    uploadArCreative: "Upload Ar Creative",
    mobileScreen: "Mobile Screen",
    mobileScreenDimensions: "Dimensions 343px * 148px",

    pushNotifications: "Push Notifications",
    clicksWithConversions: "Display only clicks with conversions",
    referenceFiles: "Reference Files",
    sampleFiles: "Sample Files",
    customCampaignsSampleFiles: "Custom Campaigns Sample Files",
    "pending-cgm": "Pending CGM",

    clickToSelectFile: {
      click: "Click",
      toSelect: "to select file",
    },
    adminName: "Admin name",
    approvedBy: "Approved by",
    noCommentAdded: "No Comment Added",
    noTeamAdded: "No Team Added Yet",
    rejectedBy: "Rejected By",
    log: "Log",
    campaignEntity: "the campaign entity",
    flagged: "Flagged",
    accept: "Accept",
    validate: "Validate",
    flag: "Flag",
    chooseReasonsOfRejection: "Choose reasons of rejection",
    reupload: "Reupload",
    theValidationSheet: "the validation sheet",
    "pending-cg": "Pending CG",
    "pending-bi": "Pending BI",
    legalEntity: "Legal entity",
    paymentId: "Payment ID",
    issuingDate: "Issuing Date",
    paymentMonth: "Payment Month",
    partiallyPaid: "Partially Paid",
    paidAt: "Paid At",
    tagRegistrationNumber: "Tax Registration Number (TRN)",
    company: "Company",
    copyDetails: "Copy Details",
    viewYourInvoice: "View your invoice",
    draft: "Draft",
    changeToBounced: "Change to bounced",
    payInvoice: "Pay invoice",
    processPayment: "Process payment",
    mixed: "Mixed",
    updatedStatusTo: "updated status to",
    "partially-paid": "Partially Paid",
    viewInvoice: "View Invoice",
    uploadYourInvoice: "Upload Your Invoice",
    reUpload: "Re-upload",
    nextPayment: "Next Payment",
    roleHolder: "Role Holder",
    roleDisplayName: "Role Name",
    fullAccess: "Full Access",
    rolePermissions: "Role Permissions",
    teamManagement: "Team Management",
    numOfHolders: "No. of holders",
    userPermissions: "User Permissions",
    editRole: "Edit Role",
    numOfCoupons: "No of Coupons",
    inPrivate: "inPrivate",
    couponsPending: "Coupons pending",
    order_id: "Order ID",
    urlShortener: "URL shortener",
    generateAndCopy: "Generate & Copy",
    linkCustomization: "Link Customization",
    attribution: "Attribution",
    deepLinkSupported: "Deep Link Supported",
    onlyDeepLinkSupported: "Only Deep Link Supported",
    noCustomizedLinksAvailable: "No Customized Links Available",
    assigned: "Assigned",
    unarchive: "Unarchive",
    rejectPartner: "Reject Partner",

    releaseNotes: "Release Notes",
    scheduled: "Scheduled",
    releaseNote: "Release Note",
    releaseType: "Release Type",
    releasePublishTime: "Release Publish Time (GMT)",
    releaseVersion: "Release Version",
    major: "Major",
    minor: "Minor",
    patch: "Patch",
    item: "Item",
    mainInfo: "Main Info",
    releaseScope: "Release Scope",
    releaseAnnouncements: "Release Announcements",
    createReleaseNote: "Create Release Note",
    version: "Version",
    publishedAt: "Published At",
    localTime: "Local Time",
    selectArchiveDate: "Select Archive Date",
    updateArchivingDate: "Update Archiving Date",
    invoiceStatus: "Invoice Status",
    headId: "head Id",
    migrateUserCampaigns: "Migrate user campaigns",
    changeReportingLine: "Change reporting line",
    migrationStartDate: "Migration start date",
    allocatedCouponsFull: "allocated coupons",
    validationError: "Validation error",
    allocationError: "Allocation error",
    accessError: "Access error",
    allSelectedCampaigns: "All selected campaigns",
    newAccountManager: "New Account manager",
    newTeamLeader: "New Team leader",
    newUser: "New User",
    currentManager: "Current Manager",
    reportingStartDate: "Reporting start date",
    reportingLineChange: "Reporting line change",
    till: "Till",
    allocationSource: "Allocation Source",
    oldUser: "Old user",
    affectedCampaigns: "Affected Campaigns",
    migrateFrom: "Migrate From",
    migrateTo: "Migrate To",
    migrationInfo: "Migration Info",
    migrationCampaigns: "Migration Campaigns",
    reportingLineChangeInfo: "Reporting Line Change Info",
    newManager: "New manager",
    oldManager: "Old manager",
    onlyWithIntegrationErrors: "Show only campaigns with integration error",
    lastEventDateTime: "Last Event Date Time",
    maximumHoursBetweenEvents: "Maximum Hours Between Events",
    LastFileProcessedDate: "Last File Processed Date",
    sheet: "Sheet",
    bonuses: "Bonuses",
    uploadedAt: "Uploaded At",
    numOfFollowers: "No. Followers",
    audienceGender: "Audience Gender",
    scope: "Scope",
    audienceLanguages: "Audience Languages",
    audienceInterests: "Audience Interests",
    socialFilters: "Social Filters",
    audienceAge: "Audience Age",
    sendToFinance: "Send to Finance",
    chooseTemplate: "Choose template",
    emailSent: "Email Sent",
    sendToFinanceOn: "Sent to Finance on",
    futureAllocations: "With Future allocations",
    partnersReadyForApproval: "Partner’s validation ready for approval",
    partnersFailedToBeApproved: "Partner’s validation failed to be approved",
    partnersReadyToChangeToPending: "Partner's validation ready to change to Pending",
    partnerReadyToChangeToPending: "{name}'s validation ready to change to Pending",
    partnerReadyForApproval: "{name}’s validation ready for approval",
    failedToChangeToPending: "Partner's validation failed to change to pending",
    outOf: "out of",
    partnerNameAndId: "Partner name/ID",
    partnersWithPendingInvoices: "Partners with pending invoices",
    partnersWithPendingValidation: "Partners with pending validation",
    cgm: "CGM",
    cgd: "CGD",
    cgmId: "cgm",
    cgTeam: "CG Team",
    CGM: "CGM",
    CGD: "CGD",
    payoneer: "Payoneer",
    emailNotifications: "Email Notifications",
    xDaysOfZeroConversions: "X-days of zero conversions",
    requiredDimension: "Required Dimension",
    payoneerAccount: "Payoneer Account",
    clientPresence: "Client Presence",
    locationCountry: "Client Location",
    global: "Global",
    regional: "Regional",
  },
  campaignValidationSheetLog: {
    uploadedTheValidationSheet: "uploaded the validation sheet",
    acceptedTheValidationSheet: "accepted the validation sheet",
    rejectedTheValidationSheet: "rejected the validation sheet",
    validatedTheValidationSheet: "validated the validation sheet",
    undoTheValidationSheet: "undo the validation sheet",
    flaggedTheValidationSheet: "flagged the validation sheet",
    sentValidationSheetToFinance: "sent the validation sheet to finance",
  },

  comparisonReport: {
    orders: "Orders",
    revenue: "Gross Revenue",
    totalCost: "Gross Cost",
    profit: "Gross Profit",
    profitPercentage: "Gross Profit (%)",
    growthRate: "Growth Rate (%)",
  },
  bankInfo: {
    branch: "Branch",
    accountName: "Account Name",
    iban: "Iban",
    swift: "Swift",
    english: "English",
    arabic: "Arabic",
  },

  validationStatus: {
    "not-validated": "Validation in Progress",
    "not-provided": "Not shared yet",
    approved: "Approved",
    pending: "Validation in Progress",
    rejected: "Rejected",
  },

  header: {
    email: "Email",
    phone: "Phone",
    signOut: "sign out",
    backToMyAccount: "Back to my account",
  },

  dashboardChart: {
    title: "Daily Performance Summary",
    legends: {
      revenue: "Revenue",
      salesAmount: "Sales Amount",
      conversion: "Conversion",
    },
  },
  newCampaigns: {
    title: "New Campaigns",
    apply: "Apply",
  },

  team: {
    title: "Users List",
    filter: {
      roles: {
        title: "Role",
      },
      bus: {
        title: "Business Unit",
      },
    },
  },
};
