import { generateInitiator, getRequestId } from "@/utils/input-output/api/config";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    pendingRequests: [],
  },

  mutations: {
    SET_IS_LOADING: (state, data) => {
      state.isLoading = data
    },

    SET_PENDING_REQUESTS: (state, data) => {
      state.pendingRequests = data
    },
  },

  actions: {
    setIsLoading({ commit }, val) {
      commit("SET_IS_LOADING", val)
    },

    setPendingRequests({ commit }, data) {
      commit("SET_PENDING_REQUESTS", data)
    },

    /**
    * if we need to cancel specific requests in the same route.
    * like if we have a popup that has the option to close it while there is a running request on the background
    */
    cancelRequests({ state }, { requests }) {
      requests.forEach(({ id, initiator }) => {
        const requestId = getRequestId({ id, initiator: generateInitiator({ initiator }) })

        const selectedRequest = state.pendingRequests.find((el) => el.id === requestId)

        if (selectedRequest) {
          selectedRequest.cancel({
            requestId: selectedRequest.id,
          })
        }
      })
    },
  },
}
