<script>
  export default {
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },

      isHoveredOn: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <svg
    width="22" height="22"
    viewBox="0 0 22 22" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 8H14C14.2652 8 14.5196 7.89464 14.7071 7.70711C14.8946 7.51957 15 7.26522 15 7C15 6.73478 14.8946 6.48043 14.7071
      6.29289C14.5196 6.10536 14.2652 6 14 6H12V5C12 4.73478 11.8946 4.48043 11.7071 4.29289C11.5196 4.10536 11.2652 4 11 4C10.7348 4
      10.4804 4.10536 10.2929 4.29289C10.1054 4.48043 10 4.73478 10 5V6C9.20436 6 8.44129 6.31607 7.87868 6.87868C7.31608 7.44129
      7.00001 8.20435 7.00001 9C7.00001 9.79565 7.31608 10.5587 7.87868 11.1213C8.44129 11.6839 9.20436 12 10 12H12C12.2652 12 12.5196
      12.1054 12.7071 12.2929C12.8946 12.4804 13 12.7348 13 13C13 13.2652 12.8946 13.5196 12.7071 13.7071C12.5196 13.8946 12.2652
      14 12 14H8.00001C7.73479 14 7.48044 14.1054 7.2929 14.2929C7.10536 14.4804 7.00001 14.7348 7.00001 15C7.00001 15.2652 7.10536
      15.5196 7.2929 15.7071C7.48044 15.8946 7.73479 16 8.00001 16H10V17C10 17.2652 10.1054 17.5196 10.2929 17.7071C10.4804 17.8946
      10.7348 18 11 18C11.2652 18 11.5196 17.8946 11.7071 17.7071C11.8946 17.5196 12 17.2652 12 17V16C12.7957 16 13.5587 15.6839
      14.1213 15.1213C14.6839 14.5587 15 13.7956 15 13C15 12.2044 14.6839 11.4413 14.1213 10.8787C13.5587 10.3161 12.7957 10 12
      10H10C9.73479 10 9.48044 9.89464 9.2929 9.70711C9.10536 9.51957 9.00001 9.26522 9.00001 9C9.00001 8.73478 9.10536 8.48043
      9.2929 8.29289C9.48044 8.10536 9.73479 8 10 8ZM11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049
      0.83733 6.79048C0.00476617 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021
      20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462
      17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28473 18.7782 3.22183C16.7153 1.15893 13.9174 0 11 0ZM11 20C9.21997
      20 7.47992 19.4722 5.99987 18.4832C4.51983 17.4943 3.36628 16.0887 2.68509 14.4442C2.0039 12.7996 1.82567 10.99 2.17294
      9.24419C2.5202 7.49836 3.37737 5.89471 4.63604 4.63604C5.89472 3.37737 7.49836 2.5202 9.24419 2.17293C10.99 1.82567 12.7996
      2.0039 14.4442 2.68508C16.0887 3.36627 17.4943 4.51983 18.4832 5.99987C19.4722 7.47991 20 9.21997 20 11C20 13.3869 19.0518
      15.6761 17.364 17.364C15.6761 19.0518 13.387 20 11 20Z"
      :fill="isActive || isHoveredOn ? 'var(--primary-500)' : 'var(--neutral-500)'"
    />
  </svg>
</template>

<style scoped lang="sass">

</style>
